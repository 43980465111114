import React, { useRef, useState, useEffect, useLayoutEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import AddIcon from '@mui/icons-material/Add';

import { useDrag, useDrop } from 'react-dnd';

import memoizeOne from 'memoize-one';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './CmsColumnList.scss';
import { useLocalStorage } from '../../commons/CustomHooks';
import ConfirmRemoveBlockDialog from '../components/ConfirmRemoveBlockDialog';
import i18n from '../../i18n';
import OfficialButton from '../../components/OfficialButtons';
import { Ids } from '../../commons/pendoTaggings';

const ColumnItem = (props) => {
  const { index, id, canMove, canRemove, hideDeleteButton } = props;
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ColumnItem.name,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    // drop(item) {
    //   props.onDropped(item);
    // },
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.moveColumn(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ColumnItem.name,
    item: { type: ColumnItem.name, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      props.onDropped();
    },
  });

  useLayoutEffect(() => {
    drag(drop(ref));
    return () => {
      drag(null);
      drop(null);
    };
  }, [drag, drop, ref]);

  const className = ` ${isDragging ? ' dragging' : ''} ${canMove ? '' : 'disabled-drag'}`;

  const handleOnRemoveColumn = () => {
    props.onRemoveColumn(index);
  };

  return (
    <div ref={ref} className={`cms-column-item ${className}`} data-handler-id={handlerId}>
      <span className="cms-column-item-left">
        <span className="icon-columns"></span>
        {props.column.name}
      </span>
      {!hideDeleteButton && (
        <IconButton className="delete-button" disabled={!canRemove} onClick={handleOnRemoveColumn}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      )}
    </div>
  );
};

ColumnItem.propTypes = {
  column: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  id: PropTypes.number,
  canMove: PropTypes.bool,
  canRemove: PropTypes.bool,
  hideDeleteButton: PropTypes.bool,
  moveColumn: PropTypes.func,
  onRemoveColumn: PropTypes.func,
  onDropped: PropTypes.func,
};

const getList = memoizeOne((columns, label) => {
  const array = [];
  for (let index = 0; index < columns; index += 1) {
    array.push({ id: index, name: `${label} ${index + 1}` });
  }
  return array;
});

const ColumnList = (props) => {
  const {
    name,
    label,
    labelName,
    columns,
    max,
    min,
    onChangeColumn,
    onChangePositionColumn,
    hideDeleteButton,
    hideDivider,
  } = props;
  const [list, setList] = useState(getList(columns, label));
  const [dismissConfirmRemove, setDismissConfirmRemove] = useLocalStorage(
    'dismissConfirmRemoveColumn',
    ''
  );

  useEffect(() => {
    setList(getList(columns, label));
  }, [columns, label]);

  const moveColumn = useCallback((dragIndex, hoverIndex) => {
    setList((prevList) => {
      return update(prevList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevList[dragIndex]],
        ],
      });
    });
  }, []);

  const onDropped = () => {
    onChangePositionColumn(list);
    setList(list.map((item, index) => ({ ...item, id: index })));
  };

  const handleRemoveColumn = async (index) => {
    if (!dismissConfirmRemove) {
      const { hasConfirmed, doNotShow } = await ConfirmRemoveBlockDialog.show(
        'Remove Column',
        i18n.t('Are you sure you want to remove this column?'),
        true,
        `Don't ask me again`
      );
      if (hasConfirmed) {
        setDismissConfirmRemove(doNotShow);
        props.onChangeColumn('remove', index);
      }
    } else {
      props.onChangeColumn('remove', index);
    }
  };

  const handleAddColumn = () => {
    onChangeColumn('add');
  };

  return (
    <div className="cms-column-list">
      <div className="label">{labelName}</div>
      <div>
        {list.map((item, index) => (
          <ColumnItem
            name={name}
            index={index}
            key={`column-${item.id}`}
            id={item.id}
            column={item}
            canMove={list.length > 1}
            canRemove={list.length > min}
            hideDeleteButton={hideDeleteButton}
            moveColumn={moveColumn}
            onDropped={onDropped}
            onRemoveColumn={handleRemoveColumn}
          />
        ))}
      </div>
      {list.length < max && (
        <OfficialButton
          label={i18n.t('Add')}
          onClick={handleAddColumn}
          variant="rectangle-primary"
          dataId={Ids.AddColumnButton}
          icon={<AddIcon />}
        />
      )}
      {!hideDivider && <div className="divider mt-24"></div>}
    </div>
  );
};

ColumnList.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  columns: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  hideDeleteButton: PropTypes.bool,
  onChangeColumn: PropTypes.func,
  onChangePositionColumn: PropTypes.func,
  labelName: PropTypes.string,
  hideDivider: PropTypes.bool,
};

export default ColumnList;
