import React from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import Loading from '../../components/Loading';
import ErrorMessage from '../../components/ErrorMessage';
import i18n from '../../i18n';
import { ResourceType, SupportedExtensionsByCategory } from '../../app/appConstants';
import VideoMessage from '../../vaam/VideoMessage';
import { ResourceInteractionContextProvider } from '../../resources/ResourceInteractionContextProvider';
import { useCommentContext } from './context/CommentContextProvider';

function isResourceImage(extension) {
  let ext = extension?.toLowerCase();
  if (ext && !ext.startsWith('.')) {
    ext = `.${ext}`;
  }
  return SupportedExtensionsByCategory.image.includes(ext);
}

function AttachFilePreview(props) {
  const {
    resourceId,
    resourceName,
    serviceProvider,
    materialId,
    spaceId,
    channelId,
    commentId,
    spaceName,
  } = props;
  const { isLoading, error, resource } = useCommentContext();

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justityContent: 'center', alignItems: 'center' }}>
        <Loading />
      </div>
    );
  }

  if (error) {
    return <ErrorMessage message={i18n.t('Sorry, this attach file is not available.')} />;
  }

  const isVaam = resource?.resourceType === ResourceType.vaam;

  if (!resourceId || (!resourceName && !isVaam) || !resource) {
    return null;
  }

  if (isVaam) {
    return (
      <ResourceInteractionContextProvider
        isPortal={false}
        spaceId={spaceId}
        resourceId={resourceId}
        materialId={materialId}
      >
        <VideoMessage
          spaceId={spaceId}
          vaamId={resource.externalDocumentSettings.documentId}
          title={resource.resourceName}
          description={resource.resourceDescription}
          createdByUserInfoId={resource.createdByUserInfoId}
          createdDate={resource.createdDate}
          resourceId={resourceId}
          commentId={commentId}
          channelId={channelId}
          spaceName={spaceName}
          materialId={materialId}
        />
      </ResourceInteractionContextProvider>
    );
  }

  const handleOpenResource = () => {
    if (serviceProvider !== null && serviceProvider !== undefined) {
      const externalResource = {
        externalResourceUrl: resource.url,
      };
      props.onResourceView(externalResource);
    } else {
      props.onResourceView(resource);
    }
  };

  const rName = resource.resourceDisplayName || resource.resourceName;
  const extension = resource.extension || rName?.split('.').pop() || resourceName?.split('.').pop();
  const isImage = resource.resourceType === ResourceType.image || isResourceImage(extension);
  if (isImage) {
    return (
      <div className="image-container" onClick={handleOpenResource}>
        <img src={resource.url} alt="ddd" />
      </div>
    );
  }

  const url = resource?.resourceType === ResourceType.cad ? resource.orgSrc : resource.url;
  return (
    <Link href={url} target="_blank" rel="noopener" className="file-name">
      {resourceName}
    </Link>
  );
}

AttachFilePreview.propTypes = {
  channelId: PropTypes.number,
  resourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  resourceName: PropTypes.string,
  serviceProvider: PropTypes.number,
  materialId: PropTypes.number,
  spaceId: PropTypes.string,
  onResourceView: PropTypes.func,
  commentId: PropTypes.number,
  spaceName: PropTypes.string,
};

export default AttachFilePreview;
