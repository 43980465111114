import React from 'react';
import PropTypes from 'prop-types';
import './TextPreview.scss';

function TextPreview(props) {
  const { type, style } = props;
  const classText = `text text-${type}`;
  return (
    <div style={style} className={classText}>
      {props.children}
    </div>
  );
}

TextPreview.propTypes = {
  style: PropTypes.instanceOf(Object),
  type: PropTypes.string,
  children: PropTypes.string,
};

export default TextPreview;
