import React from 'react';
import i18n from '../i18n';
import { SubscriptionType } from '../commons/Subscription/subscriptionConstants';
import Icons from '../components/Icons';

export const SERVICE_PROVIDER = {
  SPCE: 'Spce',
  ONEDRIVE: 'OneDrive',
  GOOGLE_DRIVE: 'GoogleDrive',
  LOCAL_MACHINE: 'LocalMachine',
  GLOBAL_RESOURCES: 'GlobalResources',
  NORMAL_FOLDER: 'NormalFolder',
  LMS: 'LMS',
};

export const SERVICE_PROVIDER_CATEGORY = {
  COLLABORATION: 'Collaboration',
  STORAGE: 'Resource Management',
};

export const serviceProviderList = [
  {
    id: 7,
    name: i18n.t('OneDrive'),
    type: SERVICE_PROVIDER.ONEDRIVE,
    learnUrl: 'https://www.spce.com/productutorials/onedrive-integration/',
    description: i18n.t(
      'Connect SPCE and OneDrive to skip a step in the content management process. With this integration new or modified files in OneDrive will be available to add in all spaces instantly. Replacing a file with a new version can also be done in OneDrive and applies to all spaces where the file is used.'
    ),
    comingSoon: false,
    category: SERVICE_PROVIDER_CATEGORY.STORAGE,
    logo: <Icons name="icon-onedrive" />,
  },
  {
    id: 8,
    name: i18n.t('Google Drive'),
    type: SERVICE_PROVIDER.GOOGLE_DRIVE,
    learnUrl: 'https://www.spce.com/productutorials/connect-google-drive-to-global-resources/',
    description: i18n.t(
      'Connect SPCE and Google Drive to skip a step in the content management process. With this integration new or modified files in Google Drive will be available to add in all spaces instantly. Replacing a file with a new version can also be done in Google Drive and applies to all spaces where the file is used.'
    ),
    comingSoon: false,
    category: SERVICE_PROVIDER_CATEGORY.STORAGE,
    logo: <Icons name="icon-googledrive" />,
  },
];

export const subscriptionIntegration = {
  [SubscriptionType.free]: {
    visible: [],
    upgradeSubscriptionRequire: [],
  },
  [SubscriptionType.premium]: {
    visible: [SERVICE_PROVIDER.ONEDRIVE, SERVICE_PROVIDER.GOOGLE_DRIVE],
    upgradeSubscriptionRequire: [SERVICE_PROVIDER.ONEDRIVE, SERVICE_PROVIDER.GOOGLE_DRIVE],
  },
  [SubscriptionType.enterprise]: {
    visible: [SERVICE_PROVIDER.ONEDRIVE, SERVICE_PROVIDER.GOOGLE_DRIVE],
    upgradeSubscriptionRequire: [],
    disabled: [],
  },
};

export function getServiceProviderTag(serviceProvider) {
  return Object.entries(SERVICE_PROVIDER).find((value) => value[1] === serviceProvider)[0];
}

export const ExternalInitProcessState = {
  Unknown: 'Unknown',
  Started: 'Started',
  Completed: 'Completed',
  Failed: 'Failed',
};
