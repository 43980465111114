import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { InputLabel, Popover } from '@mui/material';

import { changePassword } from './UserServices';
import { sendNotification } from '../../commons/utils';
import i18n from '../../i18n';
import PasswordField from '../../components/PasswordField';
import OfficialButtons from '../../components/OfficialButtons';
import './ChangePasswordForm.scss';

function ChangePasswordForm({ open, onClose }) {
  const [isChanging, setIsChanging] = useState(false);

  const [changePasswordForm, setChangePasswordForm] = useState({
    oldPassword: '',
    newPassword: '',
  });

  const newPasswordRef = useRef(null);
  const oldPasswordRef = useRef(null);

  const handleOnChange = (name, value) => {
    setChangePasswordForm({ ...changePasswordForm, [name]: value });
  };

  const checkValidate = () => {
    const validNewPassword = newPasswordRef.current.isValid();
    const validOldPassword = oldPasswordRef.current.isValid();
    return validNewPassword && validOldPassword;
  };

  async function onSubmit() {
    const isValid = checkValidate();
    if (!isValid) {
      return;
    }
    setIsChanging(true);
    // Call API to update password
    changePassword(changePasswordForm.oldPassword, changePasswordForm.newPassword)
      .then((resp) => {
        setIsChanging(false);
        if (resp) {
          onClose();
          setChangePasswordForm({ oldPassword: '', newPassword: '' });
          sendNotification(i18n.t('Change password successfully!'), { type: 'success' });
        }
      })
      .catch((error) => {
        setIsChanging(false);
        sendNotification(error.message, { type: 'error' });
        console.log('result: ', error.message);
      });
  }

  function validatePassword(value) {
    let error = null;
    if (!value?.length) {
      error = i18n.t('This field is required');
    }
    return error;
  }
  function validateNewPassword(value) {
    let error = null;
    if (!value?.length) {
      error = i18n.t('This field is required');
    }
    return error;
  }

  if (!open) {
    return null;
  }

  return (
    <Popover
      open
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      style={{
        marginTop: '4px',
      }}
      className="spce-popover new-password-popover"
      anchorEl={open}
      onClose={onClose}
    >
      <div className="content">
        <form name="change-password-form" autoComplete="off">
          <div className="current-password">
            <InputLabel shrink htmlFor="oldPassword">
              Current Password
            </InputLabel>
            <PasswordField
              key="oldPassword"
              ref={oldPasswordRef}
              id="oldPassword"
              name="oldPassword"
              onChange={handleOnChange}
              validate={validatePassword}
            />
          </div>
          <div className="new-password">
            <InputLabel shrink htmlFor="newPassword">
              New Password
            </InputLabel>
            <PasswordField
              key="newPassword"
              ref={newPasswordRef}
              id="newPassword"
              name="newPassword"
              onChange={handleOnChange}
              validate={validateNewPassword}
            />
          </div>
          <div className="button">
            <OfficialButtons
              label="Save"
              type="submit"
              variant="regular-primary"
              className="password-btn"
              onClick={onSubmit}
              disabled={isChanging}
              isProcessing={isChanging}
            />
          </div>
        </form>
      </div>
    </Popover>
  );
}

ChangePasswordForm.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.instanceOf(Object),
};

export default ChangePasswordForm;
