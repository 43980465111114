import React, { useCallback, useEffect, useRef } from 'react';
import { useBlocker } from 'react-router-dom';

import UnsavedChangesDialog from '../../components/UnsavedChangesDialog';
import i18n from '../../i18n';
import DesignSetting from './DesignSetting';

const DesignSettingsContainer = () => {
  const containerRef = useRef(null);

  async function renderPopUp() {
    const { isSaved, discard } = await UnsavedChangesDialog.show(
      i18n.t('UNSAVED CHANGES'),
      <>
        {i18n.t(`You have made changes.`)}
        <br />
        {i18n.t(`Would you like to save?`)}
      </>
    );
    if (isSaved) {
      await containerRef.current?.save();
    } else if (discard) {
      await containerRef.current?.cancel();
    } else {
      return null;
    }
    return isSaved;
  }

  async function confirmNavigation() {
    const { isConfirm } = await renderPopUp();
    return isConfirm;
  }
  const shouldBlock = useCallback(({ currentLocation, nextLocation }) => {
    return containerRef.current?.hasChange && currentLocation?.pathname !== nextLocation?.pathname;
  }, []);

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirmNavigation().then((isConfirm) => {
        if (!isConfirm) {
          blocker.proceed();
        } else {
          blocker.reset();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker]);

  return (
    <>
      <DesignSetting ref={containerRef} />
    </>
  );
};

export default DesignSettingsContainer;
