/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Grid, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import './PasswordSettingPage.scss';
import i18n from '../i18n';
import { sendRequestForgotPassword } from './UserServices';
import Logo from '../images/spce-logo.png';
import { goToHomePage, sendNotification } from '../commons/utils';
import { emailRegex } from '../commons/ValidationUtils';
import InsightContent from './InsightContent';
import OfficialButton from '../components/OfficialButtons';

function ForgotPassword() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(); // initialize the hook
  const [successMessage, setSuccessMessage] = React.useState();

  React.useEffect(() => {
    if (errors?.email?.message) {
      setSuccessMessage('');
    }
  }, [errors?.email?.message]);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      await sendRequestForgotPassword(data.email);
      setIsSubmitting(false);
      setSuccessMessage('Please check your email to reset password');
      reset();
      // sendNotification(
      //   i18n.t('Request sent successfully! Please check your email to reset your password.'),
      //   { type: 'success' }
      // );
    } catch (e) {
      setIsSubmitting(false);
      if (e.error) {
        sendNotification(e.error.title, { type: 'error' });
      } else {
        sendNotification(e.message, { type: 'error' });
      }
    }
  };
  function handleOnClickLogin(e) {
    e.preventDefault();
    goToHomePage();
  }
  return (
    <Grid container className="password-container">
      <Grid item xs={12} md={6} sm={6} className="left-column">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="password-box">
          <form className="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <p className="title">{i18n.t('Forgot password?')}</p>
            <p className="">
              {i18n.t("Enter your email below and we'll send a link to reset your password.")}
            </p>
            <label className="label">{i18n.t('Email')}</label>
            <TextField
              {...register('email', {
                required: i18n.t('This field is required'),
                maxLength: {
                  value: 100,
                  message: i18n.t('Email is not valid'),
                },
                pattern: {
                  value: emailRegex,
                  message: i18n.t('Email is not valid'),
                },
              })}
              placeholder={i18n.t('Enter your email')}
              error={!!errors?.email}
              helperText={errors?.email?.message}
              fullWidth
            />
            {successMessage && <p className="success-message">{successMessage}</p>}
            <p></p>
            <div className="form-button-container">
              <OfficialButton
                label={i18n.t('Send')}
                type="submit"
                variant="regular-green"
                isProcessing={isSubmitting}
              />
            </div>
          </form>
          <p className="go-back">
            {i18n.t('Remember password? ')}
            <a className="text-link" onClick={handleOnClickLogin}>
              {i18n.t('Log in')}
            </a>
          </p>
        </div>
      </Grid>
      <InsightContent />
    </Grid>
  );
}

export default ForgotPassword;
