import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button } from '@mui/material';
import './NoDaTaBox.scss';

const NoDaTaBox = (props) => {
  const { icon, title, description, buttonLabel, onClickButton, buttonComponent } = props;
  return (
    <Box className="no-data no-data-box">
      <span className="no-data-box-icon">{icon}</span>
      <div className="no-data-box-title">{title}</div>
      <div className="no-data-box-description">{description}</div>
      {buttonComponent && buttonComponent}
      {buttonLabel && (
        <Button className="no-data-box-btn" onClick={onClickButton}>
          {buttonLabel}
        </Button>
      )}
    </Box>
  );
};

NoDaTaBox.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  buttonLabel: PropTypes.string,
  buttonComponent: PropTypes.element,
  onClickButton: PropTypes.func,
};

export default NoDaTaBox;
