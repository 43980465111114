import { useQuery } from '@tanstack/react-query';
import queryCache, { CacheKeys } from '../../app/queryCache';
import { fetchCheckExistingTagName } from '../UserServices';
import currentOrganization from '../../commons/CurrentOrganization';

const useCheckExistingTagNameQuery = (tagName, hasChange) => {
  const currentOrganizationId = currentOrganization.getOrganizationId();
  const getCheckExistingTagNameQuery = useQuery({
    queryKey: [CacheKeys.checkExistingTagNameQuery, tagName],
    queryFn: async () => {
      if (!hasChange || !tagName.trim().length) {
        return false;
      }
      const resp = await fetchCheckExistingTagName(currentOrganizationId, tagName);
      return resp;
    },
    enabled: false,
  });

  const isCheckingTagName = getCheckExistingTagNameQuery.isFetching;
  const isExistingTagName = getCheckExistingTagNameQuery.data || false;

  const refetchCheckExistingTagName = async () => {
    const oldData = queryCache.getQueryData([CacheKeys.checkExistingTagNameQuery, tagName]);
    if (oldData === undefined) {
      const data = await getCheckExistingTagNameQuery.refetch();
      return data.data;
    } else {
      return oldData;
    }
  };
  return { isCheckingTagName, isExistingTagName, refetchCheckExistingTagName };
};

export default useCheckExistingTagNameQuery;
