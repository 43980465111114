/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { FormControlLabel, Checkbox, TextField, InputLabel } from '@mui/material';

import i18n from '../../i18n';
import LightTooltip from '../../components/LightTooltip';
import './UserManagementAddUser.scss';
import '../settings/UserProfileDetails.scss';
import { emailRegex, regexNumber } from '../../commons/ValidationUtils';
import { OrganizationUserRole, OrganizationUserState } from '../../app/appConstants';
// import currentOrganization from '../../commons/CurrentOrganization';
import Icons from '../../components/Icons';
import { newDate } from '../../commons/DateTimeUtils';
import OfficialButtons from '../../components/OfficialButtons';
import { isOrgRole } from '../../commons/utils';
import HeaderUser from '../settings/HeaderUser';
import { useUserContainerContext } from '../settings/UserContainerProvider';
import RoleUserSelect from './RoleUserSelect';
// import SpacesManagement from './SpacesManagement';

const ManagementAddUser = (props) => {
  const { open, handleDrawerToggle, isMobile } = useUserContainerContext();

  const { isSubmitting, userInfo, onClose, onAddUser, isEdit, onEditUser, isHost } = props;
  // const organization = currentOrganization.getData();
  const defaultValues = useMemo(() => {
    return {
      firstName: userInfo?.firstName || '',
      lastName: userInfo?.lastName || '',
      email: userInfo?.contactEmail || '',
      phoneNumber: userInfo?.phoneNumber || '',
      role: userInfo?.role || 'User',
      shouldSendInvitation: userInfo?.shouldSendInvitation || false,
    };
  }, [userInfo]);

  const isPartner = isOrgRole(defaultValues.role, OrganizationUserRole.Partner);

  const {
    register,
    handleSubmit,
    values,
    setValue,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: defaultValues,
  });

  const handleOnChange = ({ target: input }) => {
    const { name, value, type, checked } = input;
    const finalValue = type === 'checkbox' ? checked : value;
    setValue(name, finalValue, { shouldDirty: true });
  };

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      fullName: `${data?.firstName} ${data?.lastName}`,
    };
    if (!isEdit) {
      await onAddUser(payload);
    } else {
      if (data.shouldSendInvitation) {
        payload.lastInvited = newDate().format();
        if (data.state === OrganizationUserState.Created) {
          payload.state = OrganizationUserState.InActive;
        }
      }
      await onEditUser(userInfo.userInfoId, payload);
    }
  };

  const validates = useMemo(
    () => ({
      required: { value: true, message: i18n.t('This field is required') },
      email: { value: emailRegex, message: i18n.t('Please input valid email address') },
      maxLength: { value: 50, message: i18n.t('Email is too long') },
      phone: { value: regexNumber, message: i18n.t('Please input valid phone number') },
    }),
    []
  );

  return (
    <>
      <HeaderUser
        onClick={handleDrawerToggle}
        isOpen={open}
        className="add-user"
        title={isEdit ? i18n.t('Edit user') : i18n.t('Add user')}
        isButtonClose={!isMobile}
      >
        <div className="button-right">
          <OfficialButtons
            variant="regular-secondary"
            disabled={isSubmitting}
            onClick={onClose}
            label={i18n.t('Cancel')}
          />
          <OfficialButtons
            disabled={isSubmitting || !isDirty}
            isProcessing={isSubmitting}
            onClick={handleSubmit(onSubmit)}
            variant="regular-green"
            label={i18n.t('Save')}
          />
        </div>
      </HeaderUser>
      <div className="user-form-wrapper  styled-scrollbar">
        {/* form */}
        <div className="user-form">
          <div className="user-form-title">{i18n.t('Personal Details')}</div>

          <form
            className="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            id="add-user-form"
          >
            <div className="form-row col-2">
              <div>
                <InputLabel>{i18n.t('First Name')}</InputLabel>
                <Controller
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <TextField
                        {...field}
                        error={!!error?.message}
                        helperText={error?.message}
                        placeholder={i18n.t('Jane')}
                      />
                    );
                  }}
                  name="firstName"
                  control={control}
                  rules={{ required: validates.required }}
                  defaultValue={defaultValues.firstName}
                />
              </div>
              <div>
                <InputLabel>{i18n.t('Last Name')}</InputLabel>
                <Controller
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <TextField
                        {...field}
                        error={!!error?.message}
                        helperText={error?.message}
                        placeholder={i18n.t('Cooper')}
                      />
                    );
                  }}
                  name="lastName"
                  control={control}
                  rules={{ required: validates.required }}
                  defaultValue={defaultValues.lastName}
                />
              </div>
            </div>
            <div className="form-row">
              {isEdit ? (
                <div className="form-row-info">
                  <div className="form-row-info-label">{i18n.t('Email')}</div>
                  <div className="form-row-info-text">{userInfo.contactEmail}</div>
                </div>
              ) : (
                <div>
                  <InputLabel>{i18n.t('Email')}</InputLabel>
                  <Controller
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextField
                          {...field}
                          error={!!error?.message}
                          helperText={error?.message}
                          placeholder={i18n.t('janecooper@example.com')}
                        />
                      );
                    }}
                    name="email"
                    control={control}
                    defaultValue={defaultValues.email}
                    rules={{
                      required: validates.required,
                      pattern: validates.email,
                      maxLength: validates.maxLength,
                    }}
                  />
                </div>
              )}
            </div>
            <div className="form-row col-2">
              <div>
                <InputLabel>
                  {i18n.t('Phone number ')} <span>(optional)</span>
                </InputLabel>
                <Controller
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <TextField
                        {...field}
                        onChange={field.onChange}
                        error={!!error?.message}
                        helperText={error?.message}
                      />
                    );
                  }}
                  name="phoneNumber"
                  control={control}
                  defaultValue={defaultValues.phoneNumber}
                  rules={{
                    pattern: validates.phone,
                  }}
                />
              </div>
              <div>
                <InputLabel shrink htmlFor="role">
                  {i18n.t('Role')}
                </InputLabel>
                <RoleUserSelect
                  inputRef={register}
                  name="role"
                  onChange={handleOnChange}
                  defaultValue={defaultValues?.role}
                  isEdit={isEdit}
                  disabled={isHost || (isEdit && isPartner)}
                />
              </div>
            </div>

            {userInfo?.state !== OrganizationUserState.Active && (
              <FormControlLabel
                className="send-invitation-checkbox"
                name="shouldSendInvitation"
                checked={values?.shouldSendInvitation}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                onChange={handleOnChange}
                control={<Checkbox color="primary" />}
                placeholder="send-invitation-checkbox"
                label={
                  <div className="send-invitation-checkbox-label">
                    <span className="text">{i18n.t('Send invitation to the user')}</span>
                    <LightTooltip
                      title={i18n.t('You can save user information now and send invitation later.')}
                    >
                      <div className="icon">
                        <Icons name="icon-new-info" className="icon" />
                      </div>
                    </LightTooltip>
                  </div>
                }
              />
            )}
          </form>
        </div>

        {/* <SpacesManagement userInfoId={userInfo?.userInfoId} /> */}
      </div>
    </>
  );
};

ManagementAddUser.propTypes = {
  userInfo: PropTypes.instanceOf(Object),
  isSubmitting: PropTypes.bool,
  isHost: PropTypes.bool,
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  onAddUser: PropTypes.func,
  onEditUser: PropTypes.func,
};

export default ManagementAddUser;
