import React, { PureComponent } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import memoizeOne from 'memoize-one';
import { ThemeProvider } from '@mui/system';
import queryCache from '../app/queryCache';
import { getTheme } from '../theme';
import ResponsiveDialog from '../components/ResponsiveDialog';
import ResourceViewer from './ResourceViewer';
import {
  canViewIntegrationResource,
  isIntegrationResource,
} from '../integrations/integrationResourceUtils';
import IntegrationResourceViewer from '../integrations/IntegrationResourceViewer';
import {
  ResourceInteractionType,
  ResourceType,
  ResourceTypeNames,
  SpaceInteractionType,
} from '../app/appConstants';
import './ResourcePreviewDialog.scss';
import { ResourceInteractionContextProvider } from './ResourceInteractionContextProvider';
import ResourceViewerSlider from './ResourceViewerSlider';
import eventBus, { EVENT_BUS } from '../commons/EventBus';
import { createResourceInteraction } from '../commons/CommonServices';
import VideoMessageViewer from '../vaam/VideoMessageViewer';
import { canViewResource, formatBytes } from '../commons/ResourceUtils';
import CertificateViewer from '../assessments/components/CertificateViewer';
import RoiCalculationViewer from '../assessments/components/RoiCalculationViewer';

export const getResourcePreviewContext = memoizeOne((type) => {
  return { type };
});

let resolve;
let containerElement;
let rootPreviewEl;

class ResourcePreviewDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      material: props.material,
    };
    console.log('props.material', props.material);
    this.handleClose = this.handleClose.bind(this);
    this.handleOnChangeMaterial = this.handleOnChangeMaterial.bind(this);
    this.handleSentViewedEventToInsight = this.handleSentViewedEventToInsight.bind(this);
    this.renderVaamPlayer = this.renderVaamPlayer.bind(this);
    this.renderIntegrationResourceViewer = this.renderIntegrationResourceViewer.bind(this);
    this.renderViewerBody = this.renderViewerBody.bind(this);
  }

  componentDidMount() {
    this.unsubscribe = eventBus.subscribe(EVENT_BUS.ClosePreviewResourceDialog, this.handleClose);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isOpen !== prevState.isOpen && !this.state.isOpen) {
      ResourcePreviewDialog.destroy();
    }
  }

  componentWillUnmount() {
    this.unsubscribe();

    if (rootPreviewEl) {
      setTimeout(() => {
        rootPreviewEl.unmount();
        containerElement = null;
        resolve = null;
      }, 100);
    }
  }

  static isOpen() {
    return !!containerElement;
  }

  static destroy(retVal = false) {
    eventBus.publish(EVENT_BUS.ToggleUploadResourcePopup, true);
    if (rootPreviewEl) {
      setTimeout(() => {
        rootPreviewEl.unmount();
        containerElement = null;
        if (resolve) {
          resolve(retVal);
        }
      }, 100);
    }
  }

  static show(material, context, enableSlider = false, resourceHubConnection = null) {
    if (!material) {
      console.log('ERROR: material is required to show ResourcePreviewDialog');
      return Promise.resolve();
    }
    const isViewFromGlobalResource = !material.spaceId;
    containerElement = document.createElement('div');
    rootPreviewEl = createRoot(containerElement);
    rootPreviewEl.render(
      <QueryClientProvider client={queryCache}>
        <ThemeProvider theme={getTheme()}>
          <ResourcePreviewDialog
            context={context}
            material={material}
            enableSlider={enableSlider}
            resourceHubConnection={resourceHubConnection}
            isViewFromGlobalResource={isViewFromGlobalResource}
          />
        </ThemeProvider>
      </QueryClientProvider>
    );
    document.body.appendChild(containerElement);
    return new Promise((res) => {
      resolve = res;
    });
  }

  handleClose() {
    this.setState({ isOpen: false }, () => {
      this.props.onClose();
      ResourcePreviewDialog.destroy();
    });
  }

  handleOnChangeMaterial(material) {
    this.setState({ material });
  }

  handleSentViewedEventToInsight(totalTime) {
    const selectedMaterial = this.state.material;
    if (totalTime > 0) {
      eventBus.publish(EVENT_BUS.SpaceAnalyticsEvents, SpaceInteractionType.VIEW_RESOURCE, {
        resourceId: selectedMaterial.resourceId,
        materialId: selectedMaterial.id,
        isCms: false,
        duration: Math.round(totalTime),
        resourceType: ResourceTypeNames.video,
      });
    }
  }

  renderVaamPlayer() {
    const selectedMaterial = this.state.material;
    console.log('selectedMaterial', selectedMaterial);
    return (
      <div className="content">
        <VideoMessageViewer
          spaceId={selectedMaterial.spaceId}
          vaamId={selectedMaterial.externalDocumentSettings.documentId}
          title={
            selectedMaterial.displayName === '' ? 'Video Message' : selectedMaterial.displayName
          }
          description={selectedMaterial.description}
          createdByUserInfoId={selectedMaterial.createdByUserInfoId}
          createdDate={selectedMaterial.created}
          handleOnClosed={this.props.onClose}
          handleViewMessageViewedTime={this.handleSentViewedEventToInsight}
          handleOnStart={() => {
            createResourceInteraction(selectedMaterial.resourceId, {
              materialId: selectedMaterial.id,
              interactionType: ResourceInteractionType.Click,
              IsVideoMessageViewed: true,
              spaceName: this.props.spaceName,
              commentTitle: selectedMaterial.displayName ?? 'Video Message',
            });
          }}
        />
      </div>
    );
  }

  renderIntegrationResourceViewer() {
    const material = this.state.material;
    const { id, name, resourceType, orgSrc, extension, fileSize } = material;
    const src = material.src || material.url;
    const isViewable = canViewResource(material);
    // const canViewSisFile = canViewIntegrationResource(src);
    const isUnSupportedToView = !isViewable;
    const formatedSize = fileSize ? formatBytes(fileSize, 1) : '';
    return (
      <IntegrationResourceViewer
        materialId={id}
        src={src}
        name={name}
        type={resourceType}
        publicUrl={orgSrc}
        showInfoView={isUnSupportedToView}
        isPortal={this.props.context?.type === 'portal'}
        extension={extension}
        fileSize={formatedSize}
        onClose={this.handleClose}
      />
    );
  }

  renderResourceViewer() {
    const material = this.state.material;
    const {
      id,
      resourceId,
      name,
      externalResourceUrl,
      resourceName,
      isExternal,
      directoryId,
      isRecordingFile,
      isDownloadable,
    } = material;

    const spaceId = material.spaceId || 'global-resource';
    const src = material.src || material.url;
    const isViewable = canViewResource(material);
    // const canViewSisFile = canViewIntegrationResource(src);
    const isUnSupportedToView = !isViewable;
    // const showInfoView = isUnSupportedToView && !material.isDownloadable;
    const fileSize = material.fileSize || 0;
    const formatedSize = fileSize ? formatBytes(fileSize, 1) : '';
    return (
      <ResourceViewer
        context={this.props.context}
        resourceId={resourceId}
        materialId={id}
        isDownloadable={isDownloadable}
        documentName={name || resourceName}
        // showDownloadView={showDownloadView}
        showInfoView={isUnSupportedToView}
        externalResourceUrl={externalResourceUrl}
        isExternal={isExternal}
        isLms={material.isLms}
        isRoi={material.isRoi}
        directoryId={directoryId}
        spaceId={spaceId}
        resourceHubConnection={this.props.resourceHubConnection}
        isRecordingFile={isRecordingFile}
        onClose={this.handleClose}
        fileSize={formatedSize}
        isIntegrationMaterial={isIntegrationResource(src)}
      />
    );
  }

  renderViewerBody() {
    const material = this.state.material;
    const src = material.src || material.url;
    const { resourceId } = material;
    if (material.isLms) {
      return <CertificateViewer userCredentialId={material.userCredentialId} />;
    } else if (material.isRoi) {
      return <RoiCalculationViewer documentId={material.documentId} />;
    }
    if (material.type === ResourceType.vaam) {
      return this.renderVaamPlayer();
    }
    if (isIntegrationResource(src) && !resourceId) {
      // for one drive, google drive resources which haven't been added to any space, we can't get resourceId
      // it falls to this case when we open resource preview dialog from global resource or when select files to add to space
      return this.renderIntegrationResourceViewer();
    }
    return this.renderResourceViewer();
  }

  render() {
    const { isOpen } = this.state;
    if (!isOpen) {
      return null;
    }
    const material = this.state.material;
    const { id, resourceId, externalId } = material;
    const spaceId = material.spaceId || 'global-resource';
    const src = material.src || material.url;
    const isViewable = canViewResource(material);
    const canViewSisFile = canViewIntegrationResource(src);
    const isUnSupportedToView = !isViewable || !canViewSisFile;
    const isPdf = material.type === ResourceType.pdf;

    let dialogCssClass = 'resource-preview-dialog slider-wrapper';
    let childCssClass = `resource-preview-container ${isPdf ? 'pdf-viewer' : ''}`;
    if (material?.type === ResourceType.vaam) {
      dialogCssClass = 'video-message-dialog slider-wrapper';
    } else if (isUnSupportedToView && !this.props.enableSlider) {
      dialogCssClass = 'download-resource-dialog';
      childCssClass = '';
    }

    return (
      <ResponsiveDialog maxWidth="lg" className={dialogCssClass} onClose={this.handleClose}>
        <ResourceInteractionContextProvider
          spaceId={spaceId}
          resourceId={resourceId}
          materialId={id}
          isPortal={this.props.context?.type === 'portal'}
          isViewable={!isUnSupportedToView && canViewSisFile}
          isViewFromGlobalResource={this.props.isViewFromGlobalResource}
        >
          {this.props.enableSlider ? (
            <ResourceViewerSlider
              selectedId={externalId || resourceId || id}
              onChange={this.handleOnChangeMaterial}
              isPortal={this.props.context?.type === 'portal'}
            >
              <div className={childCssClass} id={childCssClass}>
                {this.renderViewerBody()}
              </div>
            </ResourceViewerSlider>
          ) : (
            <div className={childCssClass} id={childCssClass}>
              {this.renderViewerBody()}
            </div>
          )}
        </ResourceInteractionContextProvider>
      </ResponsiveDialog>
    );
  }
}

ResourcePreviewDialog.propTypes = {
  context: PropTypes.instanceOf(Object),
  material: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  resourceHubConnection: PropTypes.instanceOf(Object),
  spaceName: PropTypes.string,
  enableSlider: PropTypes.bool,
  isViewFromGlobalResource: PropTypes.bool,
};

ResourcePreviewDialog.defaultProps = {
  onClose: () => null,
};

export default ResourcePreviewDialog;
