import moment from 'moment';
import { formatDateTime, timeUnitsFromNow } from '../../commons/DateTimeUtils';
import i18n from '../../i18n';

export function getTimeActive(time, isOnline = false) {
  if (isOnline) {
    return 'Now';
  }
  if (!time) {
    return null;
  }

  const { date, asDays } = timeUnitsFromNow(time);

  if (asDays > 7) {
    return formatDateTime(date, 'MMM DD, YYYY');
  }
  date.locale('spce-en');

  const timeActive = date.fromNow();

  return `${timeActive}`;
}

export function formatTimeLastActive(time) {
  const isToday = moment(time).isSame(moment(), 'day');

  const isYesterday = moment(time).isSame(moment().subtract(1, 'day'), 'day');

  if (isToday) {
    return `${i18n.t('today')} ${formatDateTime(time, 'hh:mm A')}`;
  } else if (isYesterday) {
    return `${i18n.t('yesterday')} ${formatDateTime(time, 'hh:mm A')}`;
  } else {
    return `${i18n.t('on')} ${formatDateTime(time, 'MMM DD, YYYY hh:mm A')}`;
  }
}
