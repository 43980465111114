import _find from 'lodash/find';
import _cloneDeep from 'lodash/cloneDeep';
import memoizeOne from 'memoize-one';
import {
  CMS_COMPONENT_STYLE,
  CMS_COMPONENT_TYPE,
  CMS_DEFAULT_VALUE,
  BlockActionType,
} from './cmsConstants';
import {
  getUrlParameters,
  newGuid,
  openWindow,
  sendNotification,
  setURLParams,
} from '../commons/utils';
import { isValidEmail } from '../commons/ValidationUtils';
import eventBus, { EVENT_BUS } from '../commons/EventBus';
import { VIDEO_CONTENT_FORM_COMPONENT, VIDEO_LAYOUT_FORM_COMPONENT } from './cmsConstantsForVideo';
import { updateCustomThemeSpace } from '../createSpace/services';
import { addMaterial, copyPasteMaterials, updateMaterial } from '../resources/ResourceServices';
import { addResource, updateResource } from '../commons/CommonServices';
import i18n from '../i18n';

export function moveArrayItem(arr, fromIndex, toIndex) {
  if (!arr || fromIndex < 0 || toIndex < 0 || toIndex >= arr.length) return arr; // do nothing
  const deletedItem = arr.splice(fromIndex, 1)[0];
  arr.splice(toIndex, 0, deletedItem);
  return arr;
}

export function moveArrayItemUp(arr, fromIndex) {
  return moveArrayItem(arr, fromIndex, fromIndex - 1);
}

export function moveArrayItemDown(arr, fromIndex) {
  return moveArrayItem(arr, fromIndex, fromIndex + 1);
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function getShowOptions(block) {
  const options = {
    showHeading: false,
    showDescription: false,
    showButton: false,
    showSecondaryButton: false,
  };

  const type = block?.items?.[0]?.type;

  if (!type) return options;

  const items = type === CMS_COMPONENT_TYPE.CAROUSEL ? block?.items?.[0]?.items : block?.items;

  items.forEach((item) => {
    if (item.heading !== null && item.heading !== undefined) {
      options.showHeading = true;
    }
    if (item.description !== null && item.description !== undefined) {
      options.showDescription = true;
    }
    if (item.buttonLabel !== null && item.buttonLabel !== undefined) {
      if (
        type !== CMS_COMPONENT_TYPE.VIDEO ||
        (type === CMS_COMPONENT_TYPE.VIDEO &&
          (block.layout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.UNDER ||
            block.layout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.BESIDE))
      ) {
        options.showButton = true;
      }
    }
    if (item.secondaryButtonLabel !== null && item.secondaryButtonLabel !== undefined) {
      if (
        type !== CMS_COMPONENT_TYPE.VIDEO ||
        (type === CMS_COMPONENT_TYPE.VIDEO &&
          (block.layout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.UNDER ||
            block.layout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.BESIDE))
      ) {
        options.showSecondaryButton = true;
      }
    }
  });

  return options;
}

export function getWidthCssClass(size) {
  let cssClass;
  switch (size) {
    case 'fullWidth':
      cssClass = 'xl';
      break;
    case 'fixedWidth':
    case 'large':
      cssClass = 'lg';
      break;
    case 'medium':
      cssClass = 'md';
      break;
    case 'small':
      cssClass = 'sm';
      break;
    case 'banner':
      cssClass = 'xl';
      break;
    case 'tiny':
      cssClass = 'xs';
      break;
    default:
      cssClass = 'xl';
      break;
  }
  return cssClass;
}

export function generateComponentByType(componentType) {
  let component;
  switch (componentType) {
    case CMS_COMPONENT_TYPE.VIDEO:
      component = CMS_DEFAULT_VALUE.VIDEO_COMPONENT;
      break;
    case CMS_COMPONENT_TYPE.CARD_TEXT:
      component = CMS_DEFAULT_VALUE.CARD_TEXT_COMPONENT;
      break;
    case CMS_COMPONENT_TYPE.CARD_IMAGE_AND_TEXT:
      component = CMS_DEFAULT_VALUE.CARD_IMAGE_AND_TEXT_COMPONENT;
      break;
    case CMS_COMPONENT_TYPE.CARD_IMAGE_OVERLAY:
      component = CMS_DEFAULT_VALUE.CARD_IMAGE_OVERLAY_COMPONENT;
      break;
    case CMS_COMPONENT_TYPE.CARD_HERO:
      component = CMS_DEFAULT_VALUE.CARD_HERO_COMPONENT;
      break;
    case CMS_COMPONENT_TYPE.CAROUSEL:
      component = CMS_DEFAULT_VALUE.CAROUSEL_COMPONENT;
      break;
    case CMS_COMPONENT_TYPE.WIDGET:
      component = CMS_DEFAULT_VALUE.WIDGET_COMPONENT;
      break;
    case CMS_COMPONENT_TYPE.WEB_CONTENT:
      component = CMS_DEFAULT_VALUE.WEB_CONTENT_COMPONENT;
      break;
    case CMS_COMPONENT_TYPE.SCRIPT:
      component = CMS_DEFAULT_VALUE.SCRIPT_COMPONENT;
      break;
    case CMS_COMPONENT_TYPE.HTML_CONTENT:
      component = CMS_DEFAULT_VALUE.HTML_CONTENT_COMPONENT;
      break;
    case CMS_COMPONENT_TYPE.ASSESSMENT_WIDGET:
      component = CMS_DEFAULT_VALUE.ASSESSMENT_WIDGET_COMPONENT;
      break;
    default:
      break;
  }
  if (component) component.id = newGuid();
  return component;
}

export function getBlankThemeWithACardImageOverlay(
  backgroundImageResourceId = null,
  backgroundImageMaterialId = null
) {
  const item = {
    ...CMS_DEFAULT_VALUE.CARD_IMAGE_OVERLAY_COMPONENT,
    heading: null,
    description: null,
    buttonLabel: null,
    secondaryButtonLabel: null,
  };
  if (backgroundImageResourceId) item.backgroundImageUrl = backgroundImageResourceId;
  if (backgroundImageMaterialId) {
    item.backgroundImageUrlMaterialId = backgroundImageMaterialId;
    item.backgroundImageUrlResourceId = backgroundImageResourceId;
  }
  return {
    items: [
      {
        columns: 1,
        size: 'fullWidth',
        items: [item],
        id: newGuid(),
      },
    ],
  };
}

export function getTextLineHeight(value) {
  const valueToCalculateHeightOn = value || '15';
  const fontSize = Number(valueToCalculateHeightOn?.replace('px', ''));
  let percentage = 0.33;
  if (fontSize > 25) percentage = 0.15;

  return `${fontSize + Math.round(fontSize * percentage)}px`;
}

export function getTextPositionCssClasses(textPosition = 'middleCenter', isMobile = false) {
  let positionClass = textPosition;
  let textAlignClass;

  if (isMobile) {
    positionClass = 'middleCenter';
    textAlignClass = 'center';
    return { positionClass, textAlignClass };
  }

  switch (textPosition) {
    case CMS_COMPONENT_STYLE.TEXT_POSITION.TOP_LEFT:
    case CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE_LEFT:
    case CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM_LEFT:
      textAlignClass = 'left';
      break;
    case CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE:
    case CMS_COMPONENT_STYLE.TEXT_POSITION.TOP_CENTER:
    case CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE_CENTER:
    case CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM_CENTER:
      textAlignClass = 'center';
      break;
    case CMS_COMPONENT_STYLE.TEXT_POSITION.TOP_RIGHT:
    case CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE_RIGHT:
    case CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM_RIGHT:
      textAlignClass = 'right';
      break;
    default:
      break;
  }

  return { positionClass, textAlignClass };
}

export function getSavedColors(savedColors, blocks) {
  const properties = [
    'blockBackgroundColor',
    'headingColor',
    'descriptionColor',
    'buttonLabelColor',
    'buttonBackgroundColor',
    'secondaryButtonLabelColor',
    'secondaryButtonBackgroundColor',
  ];
  const newColors = [...savedColors];
  blocks.forEach((theme) => {
    if (
      theme.backgroundColor &&
      !newColors.includes(theme.backgroundColor) &&
      theme.backgroundColor !== '#ffffff' &&
      theme.backgroundColor !== '#000000'
    ) {
      newColors.unshift(theme.backgroundColor);
    }
    theme.items.forEach((item) => {
      properties.forEach((property) => {
        if (
          item[property] &&
          !newColors.includes(item[property]) &&
          item[property] !== '#ffffff' &&
          item[property] !== '#000000'
        ) {
          newColors.unshift(item[property]);
        }
      });
    });
  });
  return newColors;
}

export function validateUrl(urlValue) {
  let hostUrl = '';
  if (urlValue?.startsWith('https://')) {
    hostUrl = urlValue.replace('https://', '');
  } else if (urlValue?.startsWith('mailto:')) {
    hostUrl = urlValue.replace('mailto:', '');
  }
  let url = '';

  if (urlValue?.startsWith('http://')) {
    url = urlValue;
  } else if (hostUrl.length === 0) {
    url = '';
  } else if (isValidEmail(hostUrl)) {
    url = `mailto:${hostUrl}`;
  } else {
    url = `https://${hostUrl}`;
  }

  return url;
}

export function getVideoLayoutFormByTextPosition(textPosition) {
  let layoutForm = null;
  switch (textPosition) {
    case CMS_COMPONENT_STYLE.TEXT_POSITION.LEFT:
    case CMS_COMPONENT_STYLE.TEXT_POSITION.RIGHT:
      layoutForm = VIDEO_LAYOUT_FORM_COMPONENT.BESIDE;
      break;
    // case CMS_COMPONENT_STYLE.TEXT_POSITION.TOP:
    case CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM:
      layoutForm = VIDEO_LAYOUT_FORM_COMPONENT.UNDER;
      break;
    default:
      layoutForm = VIDEO_LAYOUT_FORM_COMPONENT.OVERLAY;
      break;
  }
  return layoutForm;
}

export function getVideoContentFormByTextPosition(textPosition) {
  let contentForm = null;
  switch (textPosition) {
    case CMS_COMPONENT_STYLE.TEXT_POSITION.LEFT:
    case CMS_COMPONENT_STYLE.TEXT_POSITION.RIGHT:
      contentForm = VIDEO_CONTENT_FORM_COMPONENT.BESIDE;
      break;
    case CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM:
      contentForm = VIDEO_CONTENT_FORM_COMPONENT.UNDER;
      break;
    default:
      contentForm = VIDEO_CONTENT_FORM_COMPONENT.OVERLAY;
      break;
  }
  return contentForm;
}

function stripUnit(value) {
  return value / (value * 0 + 1);
}

export function getHeadingFontSize(value, isMobile) {
  const minVW = 320; // Min screen
  const maxVW = 1680; // Max screen

  const minFS = value * 0.7; // Smaller 70% with current value
  const maxFS = value;

  const containerWidth = isMobile ? '375px' : '100vw';

  const currentWindowWidth = window.innerWidth;

  if (currentWindowWidth < minVW) {
    return `${minFS}px`;
  }

  if (value <= 28 || minFS <= 28) {
    return `${value}px`;
  }

  if ((currentWindowWidth < 1280 && currentWindowWidth > minVW) || isMobile) {
    return `calc(${minFS}px + ${stripUnit(
      maxFS - minFS
    )} * ((${containerWidth} - ${minVW}px) / ${stripUnit(maxVW - minVW)}))`;
  }

  return `${value}px`;
}

function processBlocks(blocks, action) {
  const newBlocks = [];
  const size = blocks.length;
  for (let index = 0; index < size; index += 1) {
    const prevBlock = index > 0 ? blocks[index - 1] : null;
    const cBlock = blocks[index];
    const nextBlock = index < size - 1 ? blocks[index + 1] : null;
    const block = {
      id: cBlock.id,
      action,
      prevId: prevBlock?.id,
      nextId: nextBlock?.id,
    };
    newBlocks.push(block);
  }
  return newBlocks;
}

export const getPagesStyleSettings = memoizeOne((pagesBlock) => {
  if (!pagesBlock) return null;
  const firstElement = pagesBlock?.items?.[0];
  const styleSettings = {
    backgroundColor: pagesBlock?.backgroundColor,
    bottomPaddingSize: pagesBlock?.bottomPaddingSize,
    topPaddingSize: pagesBlock?.topPaddingSize,
    rightPaddingSize: pagesBlock?.rightPaddingSize,
    leftPaddingSize: pagesBlock?.leftPaddingSize,
    underlineColor: firstElement?.underlineColor,
    headingDefaultColor: firstElement?.headingColor,
    headingDefaultFontFamily: firstElement?.headingFontFamily,
    headingDefaultFontSize: firstElement?.headingFontSize,
    headingSelectedColor: firstElement?.headingSelectedColor,
    headingSelectedFontFamily: firstElement?.headingSelectedFontFamily,
    headingSelectedFontSize: firstElement?.headingSelectedFontSize,
  };
  return styleSettings;
});

export const getCompareablePagesBlock = (pagesBlock) => {
  if (!pagesBlock) return null;
  const styleSettings = getPagesStyleSettings(pagesBlock);
  let pages = [];
  if (pagesBlock?.items?.[0]?.pages) {
    pages = pagesBlock?.items?.[0]?.pages.map((page) => ({
      id: page.id,
      name: page.name,
      position: page.position,
    }));
  }
  return { id: pagesBlock.id, styleSettings, pages, hidden: pagesBlock.hidden };
};

export function getModifiedPagesBlock(originPagesBlock, currentPagesBlock) {
  try {
    if (!originPagesBlock && !currentPagesBlock) {
      return null;
    }
    if (!originPagesBlock && currentPagesBlock) {
      return {
        action: BlockActionType.Add,
        id: currentPagesBlock.id,
        type: CMS_COMPONENT_TYPE.PAGES,
      };
    } else if (originPagesBlock && !currentPagesBlock) {
      return {
        action: BlockActionType.Delete,
        id: originPagesBlock.id,
        type: CMS_COMPONENT_TYPE.PAGES,
      };
    } else if (originPagesBlock && currentPagesBlock) {
      const pagesBlockChanges = {
        action: BlockActionType.Update,
        id: originPagesBlock.id,
        type: CMS_COMPONENT_TYPE.PAGES,
        hasOrderChanged: false,
        modifiedPages: [],
      };
      const visibilityChanged = originPagesBlock.hidden !== currentPagesBlock.hidden;
      // check if pages has been changed
      for (let index = 0; index < originPagesBlock.pages.length; index += 1) {
        const originPage = originPagesBlock.pages[index];
        const pageId = originPage.id;
        const foundPage = currentPagesBlock.pages.find((page) => page.id === pageId);
        if (!foundPage) {
          pagesBlockChanges.modifiedPages.push({
            id: pageId,
            action: BlockActionType.Delete,
          });
        } else if (originPage.name !== foundPage.name) {
          pagesBlockChanges.modifiedPages.push({
            id: pageId,
            action: BlockActionType.Update,
          });
        } else if (originPage.position !== foundPage.position) {
          pagesBlockChanges.hasOrderChanged = true;
          // pagesBlockChanges.modifiedPages.push({
          //   id: pageId,
          //   action: BlockActionType.Update,
          // });
        }
      }

      for (let index = 0; index < currentPagesBlock.pages.length; index += 1) {
        const currentPage = currentPagesBlock.pages[index];
        const pageId = currentPage.id;
        const foundPage = originPagesBlock.pages.find((page) => page.id === pageId);
        if (!foundPage) {
          pagesBlockChanges.modifiedPages.push({
            id: pageId,
            action: BlockActionType.Add,
          });
        }
      }

      if (
        !visibilityChanged &&
        pagesBlockChanges.modifiedPages.length === 0 &&
        !pagesBlockChanges.hasOrderChanged
      ) {
        if (
          JSON.stringify(originPagesBlock.styleSettings) ===
          JSON.stringify(currentPagesBlock.styleSettings)
        ) {
          // nothing has been changed
          return null;
        }
      }

      return pagesBlockChanges;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
}

export function getModifiedBlocksInSameSpace(originBlocks, currentBlocks) {
  console.log('### 279 getModifiedBlocksInSameSpace:', originBlocks, currentBlocks);
  // NOTE: this function compares cms themes of the same space. (before and after changes)
  let modifiedBlocks = [];
  let hasOrderChanged = false;

  // update a block
  // delete a block
  // delete a block and add a new block
  // delete a block and add 2 new blocks
  // add a block

  // case: originBlocks & currentBlocks are empty => no change
  if (!originBlocks && !currentBlocks) {
    return { modifiedBlocks, hasOrderChanged };
  }

  // case: originBlocks is empty & currentBlocks is not => all blocks are newly added
  if (!originBlocks?.length && currentBlocks?.length > 0) {
    modifiedBlocks = processBlocks(currentBlocks, BlockActionType.Add);
    return { modifiedBlocks, hasOrderChanged };
  }

  // case: currentBlocks is empty & originBlocks is not => all blocks are deleted
  if (!currentBlocks?.length && originBlocks?.length > 0) {
    modifiedBlocks = processBlocks(originBlocks, BlockActionType.Delete);
    return { modifiedBlocks, hasOrderChanged };
  }

  // case:
  const dicOriginBlocks = originBlocks.reduce((dict, item) => {
    if (!item.id) return dict;
    // eslint-disable-next-line no-param-reassign
    dict[item.id] = item;
    return dict;
  }, {});

  const dicOriginIndexes = originBlocks.reduce((dict, item, index) => {
    if (!item.id) return dict;
    // eslint-disable-next-line no-param-reassign
    dict[item.id] = index;
    return dict;
  }, {});

  for (let index = 0; index < currentBlocks.length; index += 1) {
    const prevBlock = index > 0 ? currentBlocks[index - 1] : null;
    const block = currentBlocks[index];
    const nextBlock = index < currentBlocks.length - 1 ? currentBlocks[index + 1] : null;
    let action;
    if (!dicOriginBlocks[block.id]) {
      // not exists in originBlocks => add new
      action = BlockActionType.Add;
    } else {
      // exists in originBlocks
      const oldIndex = dicOriginIndexes[block.id];
      const hasIndexChanged = oldIndex !== index; // order has been changed

      if (hasIndexChanged) {
        // case: move up / down
        // case: move up / down and update the content.
        // action = BlockActionType.Move;
        hasOrderChanged = true;
      }

      // if their content are different => update
      const oldBlock = dicOriginBlocks[block.id];
      const hasContentChanged = JSON.stringify(oldBlock) !== JSON.stringify(block);
      if (hasContentChanged) {
        action = BlockActionType.Update;
      }
    }

    if (action >= 0) {
      const modifiedBlock = {
        id: block.id,
        action,
        prevId: prevBlock?.id,
        nextId: nextBlock?.id,
      };
      modifiedBlocks.push(modifiedBlock);
    }
  }

  // case:
  const dicCurrentBlocks = currentBlocks.reduce((dict, item) => {
    if (!item.id) return dict;
    // eslint-disable-next-line no-param-reassign
    dict[item.id] = item;
    return dict;
  }, {});

  for (let index = 0; index < originBlocks.length; index += 1) {
    const prevBlock = index > 0 ? originBlocks[index - 1] : null;
    const block = originBlocks[index];
    const nextBlock = index < originBlocks.length - 1 ? originBlocks[index + 1] : null;
    let action;
    if (!dicCurrentBlocks[block.id]) {
      // not exists in currentBlocks => delete
      action = BlockActionType.Delete;
    } else {
      // exists in currentBlocks => if they are different => update
      // already add above For loop
    }

    if (action >= 0) {
      const modifiedBlock = {
        id: block.id,
        action,
        prevId: prevBlock?.id,
        nextId: nextBlock?.id,
      };
      modifiedBlocks.push(modifiedBlock);
    }
  }

  return { modifiedBlocks, hasOrderChanged };
}

function getMaterialIdsFromObject(obj) {
  if (!obj) return [];
  const properties = Object.keys(obj);
  const materialIds = properties.reduce((acc, property) => {
    if (property.endsWith('MaterialId')) {
      const materialId = obj[property];
      if (materialId > 0) {
        acc.push(materialId);
      }
    }
    return acc;
  }, []);
  // console.log('### 279 getMaterialIdsFromObject:', obj, materialIds);
  return materialIds;
}

function getMaterialIdsFromList(items) {
  if (!items?.length) return [];
  let materialIds = [];
  items.forEach((item) => {
    const ids = getMaterialIdsFromObject(item);
    if (ids.length > 0) {
      materialIds = materialIds.concat(ids);
    }
  });
  // console.log('### 279 getMaterialIdsFromList:', items, materialIds);
  return materialIds;
}

function getMaterialIdsFromBlock(block) {
  if (!block) return [];
  // for widget's background
  let materialIds = getMaterialIdsFromObject(block);
  // console.log('### 279 getMaterialIdsFromBlock 1:', materialIds);

  if (block.items?.length > 0) {
    block.items.forEach((item) => {
      // for columns / components
      let ids = getMaterialIdsFromObject(item);
      if (ids.length > 0) {
        materialIds = materialIds.concat(ids);
      }
      // for carousel
      ids = getMaterialIdsFromList(item.items);
      if (ids.length > 0) {
        materialIds = materialIds.concat(ids);
      }
    });
    // console.log('### 279 getMaterialIdsFromBlock 2:', materialIds);
  }
  materialIds = [...new Set(materialIds)]; // distinct
  return materialIds;
}

// export async function removeMaterialsInBlock(block, spaceId, folderId, isPortal = false) {
//   if (!block || !spaceId || !folderId) return;
//   const materialIds = getMaterialIdsFromBlock(block);
//   if (materialIds.length > 0) {
//     const promises = materialIds.map((materialId) => {
//       if (isPortal) {
//         return removeMaterial(spaceId, materialId, folderId);
//       }
//       return removeRuntimeMaterial(spaceId, folderId, materialId);
//     });
//     await Promise.all(promises);
//   }
// }

export function getMaterialIdsFromBlocks(blocks) {
  if (!blocks?.length) return [];
  let materialIds = [];
  blocks.forEach((block) => {
    const ids = getMaterialIdsFromBlock(block);
    if (ids.length > 0) {
      materialIds = materialIds.concat(ids);
    }
  });
  materialIds = [...new Set(materialIds)]; // distinct
  return materialIds;
}

export const checkAvailableBlock = memoizeOne((bcId, pagesBlock, currentPage) => {
  let blocks = [];
  if (pagesBlock?.items?.[0]?.pages) {
    blocks = pagesBlock?.items?.[0]?.pages.flatMap((page) => page.blocks);
  } else {
    blocks = currentPage?.blocks;
  }
  const foundBlock = blocks?.find((block) => block.id === bcId);
  if (foundBlock) {
    return foundBlock;
  }
  return false;
});

export const getPageIndex = memoizeOne((slug, pages) => {
  if (!slug || !pages) {
    return null;
  }
  const index = pages.findIndex((page) => page.slug === slug);
  if (index !== -1) {
    return index;
  }
  return null;
});

export function isSharingContentInSpace(link, spaceId, urlSlug) {
  if (!link) return false;

  const urlWithSpaceId = `/s/${spaceId}`;
  let isInTheSameSpaceRef = link?.includes(urlWithSpaceId);
  if (urlSlug) {
    const urlWithSlug = `/s/${urlSlug}`;
    isInTheSameSpaceRef = isInTheSameSpaceRef || link?.includes(urlWithSlug);
  }
  return isInTheSameSpaceRef;
}

export function isSharingPageOrBlockInSpace(link, spaceId, urlSlug) {
  if (!link) return false;
  const params = getUrlParameters(link);
  const cmsBlockId = params.cbId;
  const cmsPagesId = params.pageId;
  const isInTheSameSpaceRef = isSharingContentInSpace(link, spaceId, urlSlug);
  if (!isInTheSameSpaceRef || (isInTheSameSpaceRef && !cmsBlockId && !cmsPagesId)) {
    return false;
  }
  const dataIds = {};
  if (cmsBlockId) {
    dataIds.cmsBlockId = cmsBlockId;
  }

  if (cmsPagesId) {
    dataIds.cmsPagesId = cmsPagesId;
  }
  return dataIds;
}

export const findAndScrollToBlock = async (
  dataIds,
  pagesBlock,
  currentPage,
  onChangePage,
  setBlockId,
  isPortal
) => {
  const { cmsBlockId, cmsPagesId } = dataIds;
  const pages = pagesBlock?.items?.[0]?.pages;
  let notFoundContent = false;

  if (cmsPagesId) {
    const pageIndex = getPageIndex(cmsPagesId, pages);
    if (pageIndex !== null) {
      await onChangePage(pageIndex);
    } else {
      notFoundContent = (!!pages && pageIndex === null) || (!pages && !cmsBlockId);
    }
  }
  if (cmsBlockId) {
    const block = checkAvailableBlock(cmsBlockId, pagesBlock, currentPage);
    if (!block || (!isPortal && block.hidden)) {
      notFoundContent = true;
    } else {
      setBlockId(cmsBlockId);
    }
  }
  if (notFoundContent) {
    const msg = i18n.t('Content is not available.');
    sendNotification(msg, { duration: 5000, type: 'error' });
  }
};

export async function handleLinkNavigation(link, spaceId, urlSlug, isPortal) {
  // console.log('### 105 handleNavigation:', link, spaceId, urlSlug, isPortal, setSidebar);
  if (!link) return;
  const params = getUrlParameters(link);
  const isInTheSameSpaceRef = isSharingContentInSpace(link, spaceId, urlSlug);
  if (isInTheSameSpaceRef) {
    if (isPortal) {
      openWindow(link, '_blank');
    } else {
      if (params.srId || params.drId) {
        const newParams = {};
        if (params.srId) newParams.srId = params.srId;
        if (params.drId) newParams.drId = params.drId;
        setURLParams(newParams);
        // setSidebar(SidebarType.Material);
        // reload Space container
        eventBus.publish(EVENT_BUS.ReloadSpaceContainer);
      } else {
        openWindow(link, '_blank');
      }
    }
  } else {
    if (isValidEmail(link)) {
      window.open(`mailto:${link}`, '_blank');
      return;
    }
    if (link.startsWith('mailto:')) {
      window.open(link, '_blank');
      return;
    }
    openWindow(link, '_blank');
  }
}

export function findComponentByType(blocks, type) {
  return _find(blocks, (row) => {
    const foundColumn = _find(row.items, (column) => column.type === type);
    return !!foundColumn;
  });
}

export const mapPagesBlockToBlock = memoizeOne((pagesBlock) => {
  if (!pagesBlock) return null;
  return {
    id: pagesBlock.id,
    isPagesBlock: true,
    hidden: pagesBlock.hidden,
    columns: 1,
    size: CMS_COMPONENT_STYLE.SIZE.FULLWIDTH,
    bottomPaddingSize: pagesBlock.bottomPaddingSize,
    topPaddingSize: pagesBlock.topPaddingSize,
    leftPaddingSize: pagesBlock.leftPaddingSize,
    rightPaddingSize: pagesBlock.rightPaddingSize,
    backgroundColor: pagesBlock.backgroundColor,
    items: [
      {
        ...pagesBlock,
        type: CMS_COMPONENT_TYPE.PAGES,
        headingColor: pagesBlock.headingDefaultColor,
        headingFontFamily: pagesBlock.headingDefaultFontFamily,
        headingFontSize: pagesBlock.headingDefaultFontSize,
      },
    ],
  };
});

export async function updateBlocksMaterials(
  cmsDirectoryId,
  spaceId,
  currentPageId,
  newBlocks,
  colorsOptions
) {
  const unUsedMaterialIds = [];
  // inner functions
  async function handleAddMaterial(resource) {
    const material = {
      resourceId: resource.resourceId,
      fileName: resource.fileName,
      fileDescription: resource.fileDescription,
      resourceType: resource.type,
    };
    const resp = await addMaterial(spaceId, cmsDirectoryId, material);
    if (resp) {
      return resp.materialId;
    }
    return null;
  }

  async function handleAddResource(resource) {
    // console.log('### 269 handleAddResource', resource);
    const data = resource?.data;
    let resourceId = data?.resourceId;
    let materialId = data?.materialId;
    try {
      const flag = spaceId && cmsDirectoryId;
      if (!resourceId && flag) {
        const resp = await addMaterial(spaceId, cmsDirectoryId, data);
        if (resp) {
          resourceId = resp.resourceId;
          materialId = resp.materialId;
        }
      }

      if (!resourceId) {
        resourceId = await addResource(data);
      }

      if (!materialId && flag) {
        materialId = await handleAddMaterial({ ...data, resourceId });
      }
    } catch (error) {
      console.log(error);
    }
    return {
      uniqueId: resource?.uniqueId,
      resourceId,
      materialId,
    };
  }

  async function handleUpdateResource(resource) {
    try {
      const data = resource.data;
      if (data?.resourceId) {
        const updatingResource = {
          fileName: data.fileName,
          fileDescription: data.fileDescription,
          isCms: true,
        };
        if (data.src) {
          updatingResource.src = data.src;
        }
        await updateResource(data.resourceId, updatingResource);
      } else if (data?.materialId) {
        if (spaceId && cmsDirectoryId) {
          await updateMaterial(spaceId, cmsDirectoryId, data.materialId, {
            name: data.fileName,
            description: data.fileDescription,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }

    return {
      uniqueId: resource?.uniqueId,
      result: true,
    };
  }

  async function handleRemoveMaterial(materialId, uniqueId) {
    try {
      if (spaceId && cmsDirectoryId) {
        // await removeMaterial(spaceId, materialId, cmsDirectoryId);
        unUsedMaterialIds.push(materialId);
        return {
          uniqueId,
          result: true,
        };
      }
    } catch (error) {
      console.log('remove material Error ', error);
    }
    return {
      uniqueId,
      result: false,
    };
  }

  async function handleCopyResources(materialIds, directoryIds, fromSpaceId, id) {
    if (!fromSpaceId || (!materialIds?.length && !directoryIds?.length)) return null;
    try {
      const resp = await copyPasteMaterials(spaceId, materialIds, directoryIds, fromSpaceId);
      // console.log('### 253 handleCopyResources: ', resp);
      return { ...resp, action: 'copy', id };
    } catch (error) {
      console.log(error);
    }
    return null;
  }

  function handleAddPromises(updatingBlock) {
    const materialIds = [];
    const directoryIds = [];
    let fromSpaceId = null;
    const promises = [];
    const resources = updatingBlock?.resources;
    if (resources && resources.length > 0) {
      let deletingMaterialId = null;
      let deletingResourceId = null;

      resources.forEach((resource) => {
        switch (resource.action) {
          case 'add':
            // handle add resource and material
            promises.push(handleAddResource(resource));
            break;
          case 'update':
            // handle add resource and material
            promises.push(handleUpdateResource(resource));
            break;
          case 'delete':
            if (resource.fieldName === 'descriptionLinks') {
              deletingMaterialId = resource.materialId;
              deletingResourceId = resource.resourceId;
            } else {
              // handle delete material
              deletingMaterialId = updatingBlock[`${resource.fieldName}MaterialId`];
              deletingResourceId = updatingBlock[`${resource.fieldName}ResourceId`];
            }
            // console.log('updatingBlock: ', updatingBlock);
            if (deletingMaterialId) {
              // double check if there is any update event trigger on this material after deleting, if yes skip this removal, otherwise continue removing
              const foundUpdatingResource = _find(
                resources,
                (item) => item.data?.resourceId === deletingResourceId && item.action === 'update'
              );
              if (!foundUpdatingResource) {
                // console.log('push handleRemoveMaterial');
                // back-end already handled delete coressponding resource when deleting material so we don't need to explicitly call it from frontend
                promises.push(handleRemoveMaterial(deletingMaterialId, resource.uniqueId));
                // Handle deleted cropped material base on original image;
              }
            }
            break;
          case 'copy': {
            // handle copy/paste materials & folders
            const data = resource.data;
            if (data) {
              fromSpaceId = data.fromSpaceId;
              if (data.materialId > 0) {
                materialIds.push(data.materialId);
              }
              if (data.directoryId > 0) {
                directoryIds.push(data.directoryId);
              }
            }
            break;
          }
          default:
            break;
        }
      });
    }

    if (fromSpaceId && (materialIds.length > 0 || directoryIds.length > 0)) {
      promises.push(handleCopyResources(materialIds, directoryIds, fromSpaceId, updatingBlock?.id));
    }

    return promises;
  }

  function handleUpdateResourceIds(uploadedResources, block) {
    if (!uploadedResources?.length) return;

    const updatingBlock = block;
    const resources = updatingBlock?.resources;
    if (resources && resources.length > 0) {
      resources.forEach((resource) => {
        switch (resource.action) {
          case 'add': {
            const foundUploadedResource = _find(
              uploadedResources,
              (item) => item.uniqueId === resource.uniqueId
            );
            // handle add resource and material
            if (foundUploadedResource) {
              if (resource.fieldName === 'descriptionLinks') {
                const descriptionLinks = updatingBlock.descriptionLinks;
                if (descriptionLinks) {
                  const foundDescriptionLink = _find(descriptionLinks, (item) => {
                    return item.uniqueId === resource.uniqueId;
                  });
                  if (foundDescriptionLink) {
                    foundDescriptionLink.resourceId = foundUploadedResource.resourceId;
                    foundDescriptionLink.materialId = foundUploadedResource.materialId;
                    delete foundDescriptionLink.uniqueId;
                  }
                }
              } else {
                updatingBlock[`${resource.fieldName}ResourceId`] = foundUploadedResource.resourceId;
                updatingBlock[`${resource.fieldName}MaterialId`] = foundUploadedResource.materialId;
                // Updated value for file uploader, not apply for resource of links(buttons,title,description,..)
                if (resource.isFileUploader) {
                  updatingBlock[`${resource.fieldName}`] = foundUploadedResource.resourceId;
                }
              }
            }
            break;
          }
          case 'delete':
            // handle delete material
            if (resource.fieldName === 'descriptionLinks') {
              const descriptionLinks = updatingBlock.descriptionLinks;
              if (descriptionLinks) {
                const foundDescriptionLink = _find(descriptionLinks, (item) => {
                  return item.uniqueId === resource.uniqueId;
                });
                if (foundDescriptionLink) {
                  delete foundDescriptionLink.resourceId;
                  delete foundDescriptionLink.materialId;
                  delete foundDescriptionLink.uniqueId;
                }
              }
            } else {
              delete updatingBlock[`${resource.fieldName}ResourceId`];
              delete updatingBlock[`${resource.fieldName}MaterialId`];
            }
            break;
          case 'copy': {
            const data = resource.data;
            const foundData = _find(
              uploadedResources,
              (item) => item.id === updatingBlock?.id && item.action === 'copy'
            );

            const foldersMap = foundData?.foldersMap;
            const materialsMap = foundData?.materialsMap;
            const resourcesMap = foundData?.resourcesMap;

            if (data) {
              const value = updatingBlock[resource.fieldName];
              const { materialId, directoryId, resourceId } = data;

              const newFolderId = foldersMap?.[directoryId];
              if (directoryId > 0 && newFolderId > 0) {
                if (resource.fieldName.endsWith('Link')) {
                  const oldPattern = `drId=${directoryId}`;
                  const newPattern = `drId=${newFolderId}`;
                  if (value.endsWith(oldPattern)) {
                    const newValue = value.replace(oldPattern, newPattern);
                    updatingBlock[resource.fieldName] = newValue;
                  }
                }
                if (resource.fieldName === 'settings') {
                  const settings = value;
                  if (settings?.directoryId === directoryId) {
                    settings.directoryId = newFolderId;
                  }
                }
              }

              const newMaterialId = materialsMap?.[materialId];
              if (materialId > 0 && newMaterialId > 0) {
                if (resource.fieldName.endsWith('Link')) {
                  const oldPattern = `srId=${materialId}`;
                  const newPattern = `srId=${newMaterialId}`;
                  if (value.endsWith(oldPattern)) {
                    const newValue = value.replace(oldPattern, newPattern);
                    updatingBlock[resource.fieldName] = newValue;
                  }
                }
                const pField = `${resource.fieldName}MaterialId`;
                if (updatingBlock[pField] === materialId) {
                  updatingBlock[pField] = newMaterialId;
                }
              }

              const newResourceId = resourcesMap?.[resourceId];
              if (resourceId > 0 && newResourceId > 0) {
                const pField = `${resource.fieldName}ResourceId`;
                if (updatingBlock[pField] === resourceId || value === resourceId) {
                  updatingBlock[pField] = newResourceId;
                  updatingBlock[resource.fieldName] = newResourceId;
                }
              }
            }
            break;
          }
          default:
            break;
        }
      });
      delete updatingBlock.resources;
    }
  }

  async function handleUploadMaterials(updatingThemeSettings) {
    console.log('handleUploadMaterials updatingThemeSettings', updatingThemeSettings);
    let updatingRow = null;
    let updatingBlock = null;
    let blockItems = [];
    let blockPromises = [];
    const promises = [];
    const newThemeSettings = _cloneDeep(updatingThemeSettings);
    newThemeSettings.forEach(async (row) => {
      // background image
      updatingRow = row;
      blockPromises = handleAddPromises(updatingRow);
      blockPromises.forEach((promise) => {
        promises.push(promise);
      });
      updatingRow.items.forEach(async (block) => {
        updatingBlock = block;
        blockPromises = handleAddPromises(updatingBlock);
        blockPromises.forEach((promise) => {
          promises.push(promise);
        });

        // carousel
        blockItems = block.items;
        if (blockItems) {
          blockItems.forEach((blockItem) => {
            updatingBlock = blockItem;
            blockPromises = handleAddPromises(updatingBlock);
            blockPromises.forEach((promise) => {
              promises.push(promise);
            });
          });
        }
      });
    });

    const uploadedResources = await Promise.all(promises);
    // console.log('### 253 uploadedResources: ', uploadedResources);
    // reUpdate blocks with resourceId and materialId
    newThemeSettings.forEach((row) => {
      handleUpdateResourceIds(uploadedResources, row);
      row.items.forEach((block) => {
        handleUpdateResourceIds(uploadedResources, block);
        // carousel
        blockItems = block.items;
        if (blockItems) {
          blockItems.forEach((blockItem) => {
            handleUpdateResourceIds(uploadedResources, blockItem);
          });
        }
      });
    });

    return newThemeSettings;
  }

  const latestBlocks = await handleUploadMaterials(newBlocks);

  // update UI
  const hasResourceChanged = JSON.stringify(latestBlocks) !== JSON.stringify(newBlocks);

  if (!hasResourceChanged) {
    return { latestBlocks: newBlocks, unUsedMaterialIds };
  }

  // save to db
  const space = {
    customTheme: { items: latestBlocks, colors: colorsOptions },
  };
  await updateCustomThemeSpace(spaceId, space, currentPageId);

  return { latestBlocks, unUsedMaterialIds };
}

export const checkMaterialFirstBlock = memoizeOne((block, isTriggered = false) => {
  if (!block || isTriggered) return false;
  const columns = block.items || [];
  if (columns.length > 0) {
    const firstComponent = columns[0];
    const { backgroundImageUrl, backgroundImageUrlMaterialId } = firstComponent;
    if (backgroundImageUrl > 0 && !backgroundImageUrlMaterialId) {
      return backgroundImageUrl;
    }
  }
  return false;
});

function getAllAssessmentsFromBlocks(blocks) {
  const allAssessments = [];
  blocks?.forEach((block) => {
    const assessmentWidget = block.items[0];
    if (assessmentWidget?.type === CMS_COMPONENT_TYPE.ASSESSMENT_WIDGET) {
      const assessments = assessmentWidget?.assessments ?? [];
      assessments.forEach((assessment) => {
        if (!allAssessments.some((item) => item.assessmentId === assessment.assessmentId)) {
          allAssessments.push(assessment);
        }
      });
    }
  });
  return allAssessments;
}

export function getAllAssessments(space) {
  if (!space) {
    return [];
  }
  let allAssessments = [];
  if (space?.pagesBlock != null) {
    space.pagesBlock.pages.forEach((page) => {
      const pageAssessments = allAssessments.concat(getAllAssessmentsFromBlocks(page.blocks));
      pageAssessments.forEach((assessment) => {
        if (!allAssessments.some((item) => item.assessmentId === assessment.assessmentId)) {
          allAssessments.push(assessment);
        }
      });
    });
  } else {
    allAssessments = getAllAssessmentsFromBlocks(space.customTheme.items);
  }
  return allAssessments;
}

export function hasRoiCalculationInCMS(space) {
  // check if there is any ROI calculation in CMS the custom theme has item with type is script and script has loadSpaceWidgets('roi')
  if (!space) return false;
  let hasRoiCalculation = false;
  if (space?.pagesBlock != null) {
    space.pagesBlock.pages.forEach((page) => {
      page.blocks.forEach((block) => {
        const script = block.items[0];
        if (
          script?.type === CMS_COMPONENT_TYPE.SCRIPT &&
          script?.script?.includes("loadSpaceWidgets('roi')")
        ) {
          hasRoiCalculation = true;
        }
      });
    });
  } else {
    space.customTheme.items?.forEach((block) => {
      const script = block.items[0];
      if (
        script?.type === CMS_COMPONENT_TYPE.SCRIPT &&
        script?.script?.includes("loadSpaceWidgets('roi')")
      ) {
        hasRoiCalculation = true;
      }
    });
  }
  return hasRoiCalculation;
}
