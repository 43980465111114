import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import './SelectResourcesActions.scss';
import i18n from '../../i18n';
import ConfirmService from '../../components/ConfirmService';
import { deletedBulkResources } from '../../resources/ResourceServices';
import { sendNotification } from '../../commons/utils';
import MaterialsMoveDialog from '../../components/Material/MaterialsMoveDialog';
import OfficialButton from '../../components/OfficialButtons';

const SelectResourcesActions = forwardRef((props, ref) => {
  const [selectedAll, setSelectedAll] = useState(props.defaultCheckValue);
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const styleButton = props.variant === 'white' ? 'rectangle-secondary' : 'rectangle-primary';

  React.useEffect(() => {
    if (props.defaultCheckValue && !selectedAll) {
      setSelectedAll(true);
    }
  }, [props.defaultCheckValue, selectedAll]);

  function handleSelectedAll(event) {
    event.preventDefault();
    event.stopPropagation();
    setSelectedAll(!selectedAll);
    props.onSelectAll(!selectedAll);
  }

  async function handleOnDelete(event) {
    event.preventDefault();
    event.stopPropagation();
    const isConfirmed = await ConfirmService.show(
      `${i18n.t('DELETE RESOURCE')}`,
      <>
        <b>This can’t be undone</b>
        <br />
        <span>Are you sure you want to delete the selected resources?</span>
      </>,
      i18n.t('Yes'),
      i18n.t('Cancel')
    );
    if (!isConfirmed) {
      return;
    }
    try {
      const payload = { directoryIds: props.selectedFolderIds, materialIds: props.selectedFileIds };
      const resp = await deletedBulkResources(props.isPortal, props.spaceId, payload);
      if (resp) {
        props.resetSelected();
        sendNotification(i18n.t('Deleted successfully'), { type: 'success' });
      }
    } catch (error) {
      sendNotification(error.message, { type: 'error' });
      console.log(error);
    }
  }

  async function handleOnDownload() {
    if (!props.onDownload) {
      return;
    }
    try {
      setIsDownloading(true);
      const payload = {
        directoryIds: props.selectedFolderIds,
        materialIds: props.selectedFileIds,
        smartFolders: props.selectedSmartFolders,
        smartFiles: props.selectedSmartFiles,
      };
      await props.onDownload(props.isPortal, props.spaceId, payload);
      setIsDownloading(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDownloadButtonClick(event) {
    event.preventDefault();
    event.stopPropagation();
    handleOnDownload();
  }

  function handleMoveButtonClick() {
    setShowMoveDialog(true);
  }

  function handleCloseShowMoveDialog() {
    setShowMoveDialog(false);
  }

  useImperativeHandle(ref, () => ({
    showMoveDialog: handleMoveButtonClick,
  }));

  return (
    <div className={`selected-action ${props.variant}`}>
      <OfficialButton
        label={selectedAll ? i18n.t('Deselect all') : i18n.t('Select all')}
        onClick={handleSelectedAll}
        variant={styleButton}
      />
      <div className="buttons-right">
        {props.canShowDownloadButton && (
          <OfficialButton
            label={i18n.t('DownLoad')}
            disabled={isDownloading}
            icon={
              isDownloading ? (
                <span className="icon icon-spinner" />
              ) : (
                <span className="icon icon-download"></span>
              )
            }
            onClick={handleDownloadButtonClick}
            variant={styleButton}
          />
        )}
        {props.canMoveResources && (
          <OfficialButton
            label={i18n.t('Move')}
            icon={<span className="icon icon-move"></span>}
            onClick={handleMoveButtonClick}
            variant={styleButton}
          />
        )}
        {props.canRemoveResources && (
          <OfficialButton
            label={i18n.t('Delete')}
            icon={<span className="icon icon-delete"></span>}
            onClick={handleOnDelete}
            variant={styleButton}
          />
        )}
      </div>
      {showMoveDialog && (
        <MaterialsMoveDialog
          spaceId={props.spaceId}
          isPublicSpace={props.isPublicSpace}
          onClose={handleCloseShowMoveDialog}
          selectedFileIds={props.selectedFileIds}
          selectedFolderIds={props.selectedFolderIds}
          defaultActiveTab={props.defaultActiveTab}
        />
      )}
    </div>
  );
});

SelectResourcesActions.propTypes = {
  variant: PropTypes.string,
  onSelectAll: PropTypes.func,
  spaceId: PropTypes.string,
  isPortal: PropTypes.bool,
  resetSelected: PropTypes.func,
  canRemoveResources: PropTypes.bool,
  canMoveResources: PropTypes.bool,
  canShowDownloadButton: PropTypes.bool,
  defaultActiveTab: PropTypes.number,
  selectedSmartFiles: PropTypes.instanceOf(Array),
  selectedSmartFolders: PropTypes.instanceOf(Array),
  selectedFolderIds: PropTypes.instanceOf(Array),
  selectedFileIds: PropTypes.instanceOf(Array),
  defaultCheckValue: PropTypes.bool,
  isPublicSpace: PropTypes.bool,
  // hasSelectedExternalFolders: PropTypes.bool,
  onDownload: PropTypes.func,
};

SelectResourcesActions.defaultProps = {
  variant: '',
  defaultCheckValue: false,
};

export default SelectResourcesActions;
