import React from 'react';
import PropTypes from 'prop-types';

import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const HeaderUser = (props) => {
  const {
    title,
    onClick,
    isOpen,
    subTitle,
    isButtonClose,
    children,
    subContent,
    renderFilterTags,
    className,
  } = props;
  const isMobile = useMediaQuery('(max-width: 1023px)');

  const renderTitle = () => {
    if (!title) return null;
    if (React.isValidElement(title)) {
      return <>{title}</>;
    }
    return title;
  };

  return (
    <div
      className={`col-right-header${
        renderFilterTags && !isMobile ? '-with-filter-tags' : ''
      } user-management-header user-header ${className}`}
    >
      <Box display="flex" alignItems="center" className={`header-title`}>
        <div className="title">
          {isButtonClose && (
            <IconButton onClick={onClick} className="button-icon">
              {isOpen ? <span className="icon-close-bar"></span> : <MenuIcon />}
            </IconButton>
          )}
          {renderTitle()}
        </div>
        {subTitle && !isMobile && <Typography className="sub-title">{subTitle}</Typography>}
        {subContent && (
          <Box display="flex" alignItems="center">
            <KeyboardArrowRightIcon />
            <Typography className="title">{subContent}</Typography>
          </Box>
        )}
      </Box>
      {children}
      {subTitle && isMobile && <Typography className="sub-title">{subTitle}</Typography>}
      {!isMobile && renderFilterTags}
    </div>
  );
};
HeaderUser.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  isButtonClose: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  subTitle: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  subContent: PropTypes.string,
  renderFilterTags: PropTypes.element,
  className: PropTypes.string,
};

HeaderUser.defaultProps = {
  subTitle: '',
  isButtonClose: true,
  renderFilterTags: null,
};

export default HeaderUser;
