import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { fetchTagsOrganization } from '../UserServices';
import currentOrganization from '../../commons/CurrentOrganization';

const useTagsOrganization = (enabled = true) => {
  const [tags, setTags] = useState([]);
  const organizationId = currentOrganization.getOrganizationId();

  const getTagsOrganizationQuery = useQuery({
    queryKey: [CacheKeys.fetchTagsOrganization],
    queryFn: async () => {
      const resp = await fetchTagsOrganization(organizationId);
      return resp;
    },
    enabled,
    retry: 1,
  });

  useEffect(() => {
    if (getTagsOrganizationQuery.data) {
      const sortedTags = sortBy(getTagsOrganizationQuery.data, (item) => item.name);
      setTags(sortedTags);
    }
  }, [getTagsOrganizationQuery.data]);

  const { isLoading } = getTagsOrganizationQuery;

  return { tags, isLoading, getTagsOrganizationQuery };
};

export default useTagsOrganization;
