import React from 'react';
import PropTypes from 'prop-types';

import { messageTemplates } from './constants';
import {
  ExternalInitProcessState,
  SERVICE_PROVIDER,
} from '../../integrations/integrationConstants';

function renderNouns(text, number) {
  const isVowelSounds = ['a', 'e', 'i', 'o', 'u'].includes(text.charAt(0));
  if (number === 1) {
    return `${isVowelSounds ? 'an' : 'a'} ${text}`;
  } else {
    const texts = text === 'activity' ? 'activities' : `${text}s`;
    return `${number} ${texts}`;
  }
}

function getName(list, index) {
  const name = list[index].fullName.trim().length;
  if (!name) {
    return 'No name';
  } else {
    return list[index].fullName;
  }
}

function renderNames(senders) {
  if (!senders.length) {
    return '';
  }
  const firstSender = <span>{getName(senders, 0)}</span>;
  if (senders.length === 1) {
    return firstSender;
  }
  const sender2 = <span>{getName(senders, 1)}</span>;

  if (senders.length === 2) {
    return (
      <>
        {firstSender} and {sender2}
      </>
    );
  }
  const sender3 = <span>{getName(senders, 2)}</span>;

  if (senders.length === 3) {
    return (
      <>
        {firstSender}, {sender2} and {sender3}
      </>
    );
  }
  const others = senders.length - 2;
  return (
    <>
      {firstSender}, {sender2} and {others} others
    </>
  );
}

function NotificationMessage({ messageTemplateId, spaceName, senders, messageTemplateData }) {
  const names = renderNames(senders);
  switch (messageTemplateId) {
    case messageTemplates.JoinedSpace:
      return (
        <div>
          {names} joined <span>{spaceName}</span> for the first time
        </div>
      );
    case messageTemplates.AssignedHost:
      return (
        <div>
          {names} assigned you to be a host of <span>{spaceName}</span>
        </div>
      );
    case messageTemplates.AddedResource:
      return (
        <div>
          {names} added {renderNouns('resource', messageTemplateData?.numResources)} in{' '}
          <span>{spaceName}</span>
        </div>
      );
    case messageTemplates.AddedFolder:
      return (
        <div>
          {names} added {renderNouns('folder', messageTemplateData?.numFolders)} in{' '}
          <span>{spaceName}</span>
        </div>
      );
    case messageTemplates.SentVideoMessage:
      if (messageTemplateData?.isPrivateMessage) {
        return (
          <div>
            {names} posted a video message in a private chat in <span>{spaceName}</span>
          </div>
        );
      }
      return (
        <div>
          {names} posted a video message in the public chat in <span>{spaceName}</span>
        </div>
      );
    case messageTemplates.SentChatMessage:
      if (messageTemplateData?.isPrivateMessage) {
        return (
          <div>
            {names} sent a message in a private chat in <span>{spaceName}</span>
          </div>
        );
      }
      return (
        <div>
          {names} sent a message in the public chat in <span>{spaceName}</span>
        </div>
      );
    case messageTemplates.VideoMessageViewed:
      return (
        <div>
          {names} watched the video message <span>{messageTemplateData?.messageTitle}</span> in{' '}
          <span>{spaceName}</span>
        </div>
      );
    case messageTemplates.AddNewResourceVersion:
      return (
        <div className="message-wrapper">
          The file <span>{messageTemplateData?.resourceName}</span> has been updated to a new
          version.
        </div>
      );
    case messageTemplates.AddNewOneDriveResourceVersion:
      return (
        <div className="message-wrapper">
          The OneDrive file <span>{messageTemplateData?.resourceName}</span> has been updated to a
          new version.
        </div>
      );
    case messageTemplates.DeleteOneDriveResource:
      return (
        <div className="message-wrapper">
          The OneDrive file <span>{messageTemplateData?.resourceName}</span> has been deleted.
        </div>
      );
    case messageTemplates.AddNewGoogleDriveResourceVersion:
      return (
        <div className="message-wrapper">
          The GoogleDrive file <span>{messageTemplateData?.resourceName}</span> has been updated to
          a new version.
        </div>
      );
    case messageTemplates.DeleteGoogleDriveResource:
      return (
        <div className="message-wrapper">
          The GoogleDrive file <span>{messageTemplateData?.resourceName}</span> has been deleted.
        </div>
      );
    case messageTemplates.RequestAccessToSpace:
      return (
        <div className="message-wrapper">
          <span>{`${messageTemplateData.firstName} ${messageTemplateData.lastName} (${messageTemplateData.email})`}</span>{' '}
          requests access to <span>{spaceName}</span>
        </div>
      );
    case messageTemplates.SpaceAccessGranted:
      return (
        <div className="message-wrapper">
          {names} has accepted your request to join <span>{spaceName}.</span>
        </div>
      );
    case messageTemplates.ExternalInitProcessCompleted: {
      if (
        messageTemplateData?.provider === SERVICE_PROVIDER.ONEDRIVE &&
        messageTemplateData?.state === ExternalInitProcessState.Completed
      ) {
        return <div className="message-wrapper">Your OneDrive folders added successfully.</div>;
      } else if (
        messageTemplateData?.provider === SERVICE_PROVIDER.GOOGLE_DRIVE &&
        messageTemplateData?.state === ExternalInitProcessState.Completed
      ) {
        return <div className="message-wrapper">Your Google Drive folders added successfully.</div>;
      }
      return '';
    }
    case messageTemplates.GlobalTemplateUpdated:
      return (
        <div className="message-wrapper">
          {names} has updated template <span>{messageTemplateData?.templateName}</span>. The changes
          apply to some of your spaces.
        </div>
      );
    default:
      return '';
  }
}

NotificationMessage.propTypes = {
  messageTemplateId: PropTypes.string,
  spaceName: PropTypes.string,
  senders: PropTypes.instanceOf(Array),
  messageTemplateData: PropTypes.instanceOf(Object),
};

export default NotificationMessage;
