import React from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableRow, TableCell, Checkbox, TableSortLabel } from '@mui/material';

const EnhancedTableHeader = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    isSelectable,
    numSelected,
    rowCount,
    onSelectAllClick,
    className,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  // if (rowCount === 0) return null;

  return (
    <TableHead className={`table-head ${className}`}>
      <TableRow>
        {isSelectable && (
          <TableCell className="header" style={{ width: '40px' }}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all items' }}
              color="primary"
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ?? 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={`header ${headCell.disableHovering ? 'disableHovering' : ''} ${
              headCell.className ?? ''
            }`}
            colSpan={headCell.colSpan ?? 1}
            style={headCell.width ? { width: headCell.width } : {}}
          >
            {headCell.disableSorting ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      align: PropTypes.string,
      disablePadding: PropTypes.bool,
      label: PropTypes.string,
      width: PropTypes.string,
      disableSorting: PropTypes.bool,
      colSpan: PropTypes.number,
    })
  ).isRequired,
  isSelectable: PropTypes.bool,
  numSelected: PropTypes.number,
  rowCount: PropTypes.number,
  onSelectAllClick: PropTypes.func,
  className: PropTypes.string,
};

EnhancedTableHeader.defaultProps = {
  headCells: [],
  isSelectable: false,
  numSelected: 0,
  rowCount: 0,
  onSelectAllClick: () => {},
};

export default EnhancedTableHeader;
