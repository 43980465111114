import React, { PureComponent } from 'react';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import ReactPlayer from '@spce/react-player/lazy';
import { isChrome, isIOS, isSafari } from 'react-device-detect';
import { Waypoint } from 'react-waypoint';
// import VideoAutoplayHandler from './VideoAutoplayHandler';
import spaceUser from '../spaces/spaceUser';
import './ReactPlayer.css';
import './VideoPlayer.scss';
// import { VideoPlayerState } from '../app/appConstants';
import VideoPlayerReloadButton from './VideoPlayerReloadButton';
import { ViewedTimeConsiderAsViewed } from '../app/appConstants';
// [TODO] AppInsights
// import { LogLevels } from '../app/AppInsights';

const getexternalPlayerConfig = memoizeOne((externalPlayerConfig) => {
  const config = externalPlayerConfig ? { ...externalPlayerConfig } : {};
  if (externalPlayerConfig?.amp && spaceUser.isViewOnly()) {
    config.amp.hasFirewall = true;
  }
  return config;
});

class VideoPlayer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isInView: false,
      isBuffering: true,
      played: 0,
      startPlay: 0,
      lastPlayed: 0,
      viewCount: false,
    };
    this.player = React.createRef();
    this.handleOnReady = this.handleOnReady.bind(this);
    // this.handleOnStart = this.handleOnStart.bind(this);
    this.handleOnPlay = this.handleOnPlay.bind(this);
    this.handleOnPause = this.handleOnPause.bind(this);
    this.handleOnProgress = this.handleOnProgress.bind(this);
    this.handleOnEnded = this.handleOnEnded.bind(this);
    this.handleOnBufferEnd = this.handleOnBufferEnd.bind(this);
    this.handleOnBuffer = this.handleOnBuffer.bind(this);
    this.componentCleanup = this.componentCleanup.bind(this);
    this.handleOnEnter = this.handleOnEnter.bind(this);
    this.getCurrentState = this.getCurrentState.bind(this);
    this.seekTo = this.seekTo.bind(this);
  }

  componentCleanup() {
    // this will hold the cleanup code
    // whatever you want to do when the component is unmounted or page refreshes
    console.log('@@@@ 55 VideoPlayer componentCleanup', this.state.played);
    if (this.playing) {
      this.props.onUnmount(false, this.player.current?.getCurrentTime(), this.state.played);
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.componentCleanup);
  }

  componentDidCatch(error) {
    const ctx = 'VideoPlayer';
    try {
      // Object.assign(error, { severityLevel: LogLevels.CRITICAL });
      console.error(error, { ctx });
    } catch (e) {
      console.error(e, { ctx });
    }
  }

  componentWillUnmount() {
    console.log('### 274 VideoPlayer componentWillUnmount', this.state);
    clearInterval(this.timer);
    if (this.playing) {
      // 3rd issue on spc-4956, cannot open the activity coz the update request is calling.
      // to update the state of the previous video right away, no debounce
      this.props.onUnmount(false, this.player.current?.getCurrentTime(), this.state.played);
    }
    window.removeEventListener('beforeunload', this.componentCleanup); // remove the event handler for normal unmounting
  }

  handleOnEnter() {
    this.setState({ isInView: true });
  }

  handleOnReady(data) {
    // Called when media is loaded and ready to play. If playing is set to true, media will play immediately
    console.log('### videoPlayer handleOnReady', this.props.autoPlay, data);
    this.props.onReady();

    if (this.props.autoPlay) {
      this.play();
    } else {
      this.pause();
    }

    setTimeout(() => {
      this.props.onLoaded(data);
    }, 500);

    if (!this.props.controls && isIOS && isSafari) {
      this.setState({ isBuffering: false });
    }
  }

  handleOnBuffer() {
    if (isIOS && isSafari) {
      return;
    }

    this.setState({ isBuffering: true });
  }

  handleOnBufferEnd() {
    if (isIOS && isSafari) {
      return;
    }
    this.setState({ isBuffering: false });
  }

  // handleOnStart() {
  //   eventBus.publish(EVENT_BUS.SpaceAnalyticsEvents, SpaceInteractionType.VIEW_RESOURCE, {
  //     materialId: this.props.materialId,
  //     time: this.player.current?.getCurrentTime
  //       ? Math.round(this.player.current.getCurrentTime())
  //       : 0,
  //   });
  //   this.timer = setInterval(() => {
  //     if (this.player.current?.player?.isPlaying) {
  //       eventBus.publish(EVENT_BUS.SpaceAnalyticsEvents, SpaceInteractionType.VIEW_RESOURCE, {
  //         materialId: this.props.materialId,
  //         time: this.player.current?.getCurrentTime
  //           ? Math.round(this.player.current.getCurrentTime())
  //           : 0,
  //       });
  //     }
  //   }, 30000);
  // }

  handleOnPlay() {
    // Called when media starts or resumes playing after pausing or buffering
    console.log('### played', this.playing, this.player.current?.getCurrentTime());
    if (this.playing && this.player.current?.getCurrentTime() !== null) {
      this.setState({
        played: 0,
        startPlay: this.player.current?.getCurrentTime(),
        lastPlayed: this.player.current?.getCurrentTime(),
        viewCount: false,
      });
    }

    this.play();
    this.props.onChange(true, this.player.current?.getCurrentTime());
  }

  handleOnPause() {
    // Called when media is paused
    // console.log('### paused', this.playing);
    this.pause();
    this.props.onChange(false, this.player.current?.getCurrentTime());
    this.props.sentViewedEventToInsight(this.state.played);
    this.setState({
      lastPlayed: this.player.current?.getCurrentTime(),
      played: 0,
      startPlay: this.player.current?.getCurrentTime(),
    });
  }

  handleOnProgress(progress) {
    // Callback containing played and loaded progress as a fraction, and playedSeconds and loadedSeconds in seconds
    const duration = this.player.current?.getDuration();
    if (progress.playedSeconds !== duration) {
      if (!this.state.viewCount) {
        const shouldCount =
          progress.playedSeconds - this.state.lastPlayed > ViewedTimeConsiderAsViewed;
        this.setState({ viewCount: shouldCount });
      }

      if (this.state.viewCount) {
        const totalTime = progress.playedSeconds - this.state.startPlay;
        this.setState({ lastPlayed: progress.playedSeconds, played: totalTime });
        if (totalTime > this.props.sentToInsightEventBatchTimeInSeconds) {
          this.props.sentViewedEventToInsight(this.state.played);
          this.setState({
            lastPlayed: progress.playedSeconds,
            played: 0,
            startPlay: progress.playedSeconds,
          });
        }
      }
    }

    // console.log('### handleOnProgress', progress, this.playing, duration);
    if (progress.loaded === 0) {
      if (duration > 0 && Math.abs(duration - progress.playedSeconds) < 0.5) {
        console.log('### handleOnProgress 2');
        this.props.onChange(false, 0);
        this.forceUpdate();
      }
    }
  }

  handleOnEnded() {
    // Called when media finishes playing
    console.log('### videoPlayer ended');
    this.pause(true);
    this.props.onChange(false, this.player.current?.getCurrentTime());
    this.props.sentViewedEventToInsight(this.state.played);
    this.setState({
      lastPlayed: 0,
      played: 0,
      startPlay: 0,
      viewCount: false,
    });

    // console.log('### ended', this.player.current.getDuration());
  }

  getCurrentState() {
    return {
      isPlaying: this.playing,
      currentTime: this.player.current?.getCurrentTime(),
      playerState: this.playerState,
      duration: this.player.current?.getDuration(),
    };
  }

  play() {
    console.log('### videoPlayer play');
    // this.playerState = VideoPlayerState.Playing;
    this.playing = true;
    if (this.player.current?.getCurrentTime() !== null) this.props.onPlayed(this.playing);
    this.forceUpdate();
  }

  seekTo(time, type = null) {
    console.log('### videoPlayer seekTo', time);
    try {
      this.player.current?.seekTo(time, type);
    } catch (error) {
      const url = this.props?.url;
      console.error(`Could not seekTo: ${error.message}`, { url, time, type });
    }
  }

  pause(isEnded = false) {
    console.log('### videoPlayer pause', isEnded);
    // this.playerState = !isEnded ? VideoPlayerState.Paused : VideoPlayerState.Ended;
    this.playing = false;
    this.forceUpdate();
  }

  render() {
    const { url, controls, autoPlay, externalPlayerConfig, muted } = this.props;
    const isMuted = muted || autoPlay;
    const isShowButton =
      !this.state.isBuffering &&
      !controls &&
      (isSafari || isChrome) &&
      ((externalPlayerConfig?.amp && !isIOS) || isIOS);
    // console.log('### videoPlayer: ', muted, autoPlay, isMuted, this.player.current);
    return (
      <>
        <Waypoint
          onEnter={this.handleOnEnter}
          fireOnRapidScroll={false}
          debug={false}
          topOffset={'45%'}
          bottomOffset={'-200px'}
        >
          <div className="waypoint-holder" />
        </Waypoint>
        {this.state.isInView && (
          <>
            <ReactPlayer
              volume={this.props.volume}
              muted={isMuted}
              playing={this.playing}
              ref={this.player}
              width="100%"
              height="100%"
              style={{ position: 'absolute' }}
              url={url}
              autoPlay={this.playing}
              controls={controls}
              config={getexternalPlayerConfig(externalPlayerConfig)}
              onStart={this.handleOnStart}
              onReady={this.handleOnReady}
              onPlay={this.handleOnPlay}
              onPause={this.handleOnPause}
              onProgress={this.handleOnProgress}
              onEnded={this.handleOnEnded}
              playsinline={this.props.playsinline}
              onBufferEnd={this.handleOnBufferEnd}
              onBuffer={this.handleOnBuffer}
            />
            {/* {this.state.loaded && !this.props.disableAutoplayHandler && (
              <VideoAutoplayHandler
                muted={isMuted}
                player={this.player.current}
                playing={this.playing}
                controls={controls}
              />
            )} */}
            {isShowButton && (
              <VideoPlayerReloadButton
                player={this.player.current}
                controls={this.props.controls}
                onReload={this.props.onReload}
              />
            )}
          </>
        )}
      </>
    );
  }
}

VideoPlayer.propTypes = {
  url: PropTypes.string,
  // materialId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
  onChange: PropTypes.func,
  onLoaded: PropTypes.func,
  onPlayed: PropTypes.func,
  onReady: PropTypes.func,
  onUnmount: PropTypes.func,
  sentViewedEventToInsight: PropTypes.func,
  sentToInsightEventBatchTimeInSeconds: PropTypes.number,
  externalPlayerConfig: PropTypes.instanceOf(Object),
  playsinline: PropTypes.bool,
  muted: PropTypes.bool,
  // disableAutoplayHandler: PropTypes.bool,
  volume: PropTypes.number,
  onReload: PropTypes.func,
};

VideoPlayer.defaultProps = {
  autoPlay: false,
  controls: true,
  onChange: () => console.log('onChange'),
  onLoaded: () => console.log('onLoaded'),
  onPlayed: () => console.log('onPlayed'),
  onReady: () => console.log('onReady'),
  onUnmount: () => console.log('onUnmount'),
  sentViewedEventToInsight: () => console.log('sentViewedEventToInsight'),
  sentToInsightEventBatchTimeInSeconds: 10000,
  externalPlayerConfig: {},
  playsinline: true,
  muted: false,
  // disableAutoplayHandler: false,
  volume: 1,
};

export default VideoPlayer;
