import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { fetchSpaceUserCertificates } from '../../assessments/services';

const useAssessmentCertificatesQuery = (enabled: boolean) => {
  const assessmentCertificatesQuery = useQuery({
    queryKey: [CacheKeys.assessments_FetchSpaceUserCertificates],
    queryFn: async () => {
      const resp = await fetchSpaceUserCertificates();
      return resp;
    },

    retry: 3,
    retryDelay: () => 5000,
    enabled: enabled,
  });

  const isFetching = assessmentCertificatesQuery.isFetching;
  const data = assessmentCertificatesQuery.data
    ? assessmentCertificatesQuery.data?.assessmentCertificates
    : [];

  return { data, isFetching };
};

export default useAssessmentCertificatesQuery;
