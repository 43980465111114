import React, { useEffect, useRef, useState } from 'react';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { InputAdornment } from '@mui/material';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import TextFieldEnhanced from '../../components/TextFieldEnhanced';
import './TagFormPopup.scss';
import i18n from '../../i18n';
import { TagColors } from './constants';
import useCheckExistingTagNameQuery from './useCheckExistingTagNameQuery';
import { addNewTagOrganization, updateTagOrganization } from '../UserServices';
import queryCache, { CacheKeys } from '../../app/queryCache';
import OfficialButton from '../../components/OfficialButtons';
import { Ids } from '../../commons/pendoTaggings';
import currentOrganization from '../../commons/CurrentOrganization';

const tagColorIds = [
  11, 21, 31, 41, 51, 12, 22, 32, 42, 52, 13, 23, 33, 43, 53, 14, 24, 34, 44, 54,
];
const getTags = memoizeOne(() => {
  return tagColorIds.map((id) => ({ ...TagColors[id], id }));
});

const TagFormDialog = (props) => {
  const { isOpen, onClose, data } = props;
  const organizationId = currentOrganization.getOrganizationId();

  const tags = getTags();

  const [tagName, setTagName] = useState(data?.name);
  const [tagColorId, setTagColorId] = useState(data?.colorId || 11);
  const [isProcessing, setIsProcessing] = useState(false);

  const inputNameRef = useRef();

  const { isCheckingTagName, isExistingTagName, refetchCheckExistingTagName } =
    useCheckExistingTagNameQuery(tagName, tagName !== data?.name);

  useEffect(() => {
    if (data?.name) {
      setTagName(data.name);
    }
  }, [data?.name]);

  if (!isOpen) return null;

  const resetForm = () => {
    setTagName('');
    setTagColorId(11);
    setIsProcessing(false);
  };

  const handleOnCloseForm = () => {
    resetForm();
    onClose();
    setIsProcessing(false);
  };

  const handleOnChangeNameTag = (name, value) => {
    setTagName(value);
  };

  const handleOnChangeColor = (id) => {
    setTagColorId(id);
  };

  const isValidForm = async () => {
    const isValidName = inputNameRef.current.isValid();
    if (!isValidName) {
      return false;
    }

    let isExisting = isExistingTagName;
    if (tagName) {
      try {
        isExisting = await refetchCheckExistingTagName();
      } catch (error) {
        isExisting = true;
      }
    }
    return isValidName && !isExisting;
  };

  const onSubmit = async () => {
    const isValid = await isValidForm();
    if (!isValid) {
      return;
    }
    const newTags = { name: tagName.trim(), colorId: tagColorId };

    setIsProcessing(true);

    if (data?.id) {
      const resp = await updateTagOrganization(organizationId, data.id, newTags);
      if (resp) {
        queryCache.setQueryData([CacheKeys.fetchTagsOrganization], (oldData) => {
          const newData = cloneDeep(oldData);
          if (newData?.length) {
            const foundIndexTag = newData.findIndex((t) => t.id === resp.tag.id);
            if (foundIndexTag !== -1) {
              newData[foundIndexTag] = resp.tag;
            }
          }
          return newData;
        });
        queryCache.removeQueries({ queryKey: [CacheKeys.checkExistingTagNameQuery, data.name] });

        if (props.onUpdatedTag) {
          props.onUpdatedTag(resp.tag);
        }
        handleOnCloseForm();
      }
    } else {
      const resp = await addNewTagOrganization(organizationId, newTags);

      if (resp) {
        queryCache.setQueryData([CacheKeys.fetchTagsOrganization], (oldData) => {
          const newData = cloneDeep(oldData);
          if (newData) {
            newData.push(resp.tag);
          }
          return newData;
        });
        queryCache.removeQueries({ queryKey: [CacheKeys.checkExistingTagNameQuery, newTags.name] });

        if (props.onAddedTag) {
          props.onAddedTag(resp.tag);
        }

        handleOnCloseForm();
      } else {
        setIsProcessing(false);
      }
    }
    setIsProcessing(false);
  };

  const validateTagName = (value) => {
    let error = null;
    if (!value) {
      return error;
    }
    const pattern = /[-|_`@!^&/\\#,=+()$~%.'",;:*?<>\]\\[{}]/g;
    const inValid = pattern.test(value);
    if (inValid) {
      error = i18n.t('Tags can only include letters and numbers');
    }
    if (isExistingTagName) {
      error = i18n.t('This tag already exists');
    }
    return error;
  };

  const onCheckExisting = async () => {
    if (!tagName || !tagName.trim() || tagName === data?.name) {
      return false;
    }
    const isValid = inputNameRef?.current?.isValid();
    let isExisting = isExistingTagName;
    if (isValid) {
      isExisting = await refetchCheckExistingTagName(tagName);
    }
    inputNameRef?.current?.isValid();
    return isExisting;
  };

  return (
    <ResponsiveDialog
      modal
      maxWidth="sm"
      className={`white confirm-dialog tag-form-dialog`}
      onClose={handleOnCloseForm}
      showCloseIcon
    >
      <div className="content">
        <h1 className="title">{data?.id ? 'Edit tag' : 'Add tag'}</h1>
        <div className="control-item">
          <span className="label">{i18n.t('Name')}</span>
          <TextFieldEnhanced
            ref={inputNameRef}
            required
            autoFocus
            defaultValue={tagName}
            name="nameTag"
            onChange={handleOnChangeNameTag}
            validate={validateTagName}
            maxLength={20}
            onBlur={onCheckExisting}
            InputProps={{
              inputProps: { maxLength: 20 },
              endAdornment: (
                <InputAdornment position="end">
                  {isCheckingTagName && <span className="icon-spinner"></span>}
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="control-item">
          <span className="label">{i18n.t('Color')}</span>
          <div className="color-picker">
            {tags.map((item, index) => (
              <div
                onClick={() => handleOnChangeColor(item.id)}
                className={item.id === tagColorId ? 'active' : ''}
                key={index}
                style={{ background: item.background }}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="dialog-actions">
        <div className="action-buttons">
          <OfficialButton
            onClick={handleOnCloseForm}
            label={i18n.t('Cancel')}
            variant="regular-secondary"
          />
          <OfficialButton
            variant="regular-primary"
            onClick={onSubmit}
            isProcessing={isProcessing}
            disabled={isProcessing}
            label="Save"
            dataId={Ids.saveSpaceTag}
          />
        </div>
      </div>
    </ResponsiveDialog>
  );
};

TagFormDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  onUpdatedTag: PropTypes.func,
  onAddedTag: PropTypes.func,
};

export default TagFormDialog;
