import React, { PureComponent, createRef } from 'react';
import { createRoot } from 'react-dom/client';

import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, IconButton, TextField, ThemeProvider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import i18n from '../../i18n';
import OfficialButton from '../OfficialButtons';
import { getTheme } from '../../theme';
import './AddNewParticipantDialog.scss';
import { isValidEmail } from '../../commons/ValidationUtils';
import LightTooltip from '../LightTooltip';
import { OrganizationUserRole } from '../../app/appConstants';
import { inviteOrganizationUser } from '../../users/UserServices';
import { extractNamesFromEmail, sendNotification } from '../../commons/utils';
import ConfirmService from '../ConfirmService';
import queryCache, { CacheKeys } from '../../app/queryCache';

let resolve;
let containerElement;
let rootEl;

class AddNewParticipantDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      email: props.emailDefault || '',
      firstName: '',
      lastName: '',
      errors: null,
      users: props.users,
      isPartnerUser: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.isValid = this.isValid.bind(this);
    this.handleOnChangeCheckbox = this.handleOnChangeCheckbox.bind(this);
    this.dialogRef = createRef();
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handleHistoryStateChanged);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleHistoryStateChanged);
  }

  static destroy(data) {
    if (rootEl) {
      setTimeout(() => {
        rootEl.unmount();
        containerElement = null;
      }, 100);
    }
    if (resolve) {
      resolve(data);
      resolve = null;
    }
  }

  static show({ emailDefault, users }) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    document.body.appendChild(containerElement);
    rootEl.render(
      <ThemeProvider theme={getTheme()}>
        <AddNewParticipantDialog emailDefault={emailDefault} users={users} />
      </ThemeProvider>
    );

    return new Promise((res) => {
      resolve = res;
    });
  }

  handleClose = (data) => {
    this.setState({ isOpen: false }, () => AddNewParticipantDialog.destroy(data));
  };

  handleCancel = () => {
    this.setState({ isOpen: false }, () => AddNewParticipantDialog.destroy());
  };

  handleInputChange(e) {
    const { name, value } = e.target;

    switch (name) {
      case 'email':
        this.setState(() => {
          if (isValidEmail(value)) {
            const { firstName, lastName } = extractNamesFromEmail(value);
            return {
              email: value,
              firstName,
              lastName,
              errors: (prev) => ({ ...prev.errors, email: this.validateEmail(value) }),
            };
          } else {
            return {
              email: value,
              errors: (prev) => ({ ...prev.errors, email: this.validateEmail(value) }),
            };
          }
        });
        break;
      case 'firstName':
        this.setState({ [name]: value });
        this.setState({
          errors: (prev) => ({ ...prev.errors, firstName: this.validateName(value) }),
        });
        break;
      case 'lastName':
        this.setState({ [name]: value });
        this.setState({
          errors: (prev) => ({ ...prev.errors, lastName: this.validateName(value) }),
        });
        break;
      default:
        break;
    }
  }

  handleOnChangeCheckbox(event) {
    this.setState({ isPartnerUser: event.target.checked });
  }

  validateEmail(value) {
    let error = null;
    const lowerCaseValue = value.toLowerCase();
    if (value?.trim() === '') {
      error = i18n.t('This field is required');
    }
    if (!isValidEmail(lowerCaseValue)) {
      error = i18n.t('Please enter a valid email address.');
    }
    if (this.state.users.some((participant) => participant.contactEmail === lowerCaseValue)) {
      error = i18n.t('This email address is already in use by another user.');
    }
    return error;
  }

  validateName(name) {
    console.log('name', this.errors);
    return name?.trim() === '' ? 'This field is required' : '';
  }

  isValid() {
    const { email, firstName, lastName } = this.state;
    const emailError = this.validateEmail(email);
    const firstNameError = this.validateName(firstName);
    const lastNameError = this.validateName(lastName);

    if (emailError || firstNameError || lastNameError) {
      this.setState({
        errors: { email: emailError, firstName: firstNameError, lastName: lastNameError },
      });
    }
    return !emailError && !firstNameError && !lastNameError;
  }

  async addPartnerUser() {
    const { email, firstName, lastName } = this.state;
    const payload = {
      firstName,
      lastName,
      email,
      contactEmail: email,
      role: OrganizationUserRole.Partner,
      shouldSendInvitation: true,
      fullName: `${firstName} ${lastName}`,
    };
    try {
      const resp = await inviteOrganizationUser(payload);
      if (resp) {
        sendNotification('Way to go! The user has been added and invited.', { type: 'success' });
        queryCache.setQueryData(
          [CacheKeys.getOrganizationsUsers, false, 'space-users-select'],
          (oldData) => {
            return [
              ...oldData,
              { ...payload, userInfoId: resp.id, role: OrganizationUserRole.Partner },
            ];
          }
        );
        return { userInfoId: resp.id, role: OrganizationUserRole.Partner };
      }
    } catch (error) {
      sendNotification(error.message, { type: 'error' });
      console.log('Error: ', error);
    }
    return false;
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { email, firstName, lastName, isPartnerUser } = this.state;
    const isValid = this.isValid();
    if (!isValid) {
      return;
    }
    const data = { email, firstName, lastName };
    if (!isPartnerUser) {
      this.handleClose(data);
    } else {
      this.setState({ hidePopup: true });
      const isConfirmed = await ConfirmService.show(
        i18n.t('Add Partner user'),
        <>
          {i18n.t('You are about to add this user as a Partner User.')}
          <br />
          {i18n.t('The user will automatically receive an invitation to set their password.')}
        </>,
        i18n.t('Confirm'),
        i18n.t('Cancel')
      );

      if (isConfirmed) {
        const userInfo = await this.addPartnerUser();

        if (userInfo) {
          data.userInfoId = userInfo.userInfoId;
          data.role = userInfo.role;
        }

        this.handleClose(data);
      } else {
        this.setState({ hidePopup: false });
      }
    }
  }

  render() {
    const { email, firstName, lastName, hidePopup, isPartnerUser } = this.state;

    if (!this.state.isOpen) {
      return null;
    }

    return (
      <div
        className={`white add-new-participant-dialog ${hidePopup ? 'hidden-popup' : ''}`}
        id="add-new-participant-dialog"
      >
        <form onSubmit={this.handleSubmit} ref={this.dialogRef} noValidate>
          <div className="mobile-join-space-box">
            <div className="content" ref={this.dialogRef}>
              <IconButton
                aria-label="close"
                onClick={this.handleCancel}
                className="close-button"
                color="primary"
              >
                <CloseIcon className="close-icon" />
              </IconButton>
              <div className="message">
                <div className="title">{i18n.t(`Add new user`)}</div>
              </div>
              <div className="form-content">
                <div className="form fullwidth">
                  <div className="form-item">
                    <p className="item-label">{i18n.t('Email')}</p>
                    <TextField
                      className="form-input"
                      size="small"
                      autoFocus
                      required
                      name="email"
                      maxLength={100}
                      variant="outlined"
                      placeholder={i18n.t('Your Email')}
                      value={email}
                      onChange={this.handleInputChange}
                      error={!!this.state.errors?.email}
                      helperText={this.state.errors?.email}
                    />
                  </div>
                </div>
                <div className="form">
                  <div className="form-item">
                    <p className="item-label">{i18n.t('First name')}</p>

                    <TextField
                      className="form-input"
                      size="small"
                      required
                      maxLength={100}
                      name="firstName"
                      placeholder={i18n.t('First name')}
                      value={firstName}
                      onChange={this.handleInputChange}
                      error={!!this.state.errors?.firstName}
                      helperText={this.state.errors?.firstName}
                    />
                  </div>
                  <div className="form-item">
                    <p className="item-label">{i18n.t('Last name')}</p>

                    <TextField
                      className="form-input"
                      required
                      size="small"
                      maxLength={100}
                      name="lastName"
                      placeholder={i18n.t('Last name')}
                      value={lastName}
                      onChange={this.handleInputChange}
                      error={!!this.state.errors?.lastName}
                      helperText={this.state.errors?.lastName}
                    />
                  </div>
                </div>
                <FormControlLabel
                  onChange={this.handleOnChangeCheckbox}
                  name="isPartnerUser"
                  checked={isPartnerUser}
                  className="add-user-checkbox"
                  control={<Checkbox color="primary" />}
                  label={
                    <div className="add-user-checkbox-label">
                      <span>{i18n.t('Set as Partner User')} </span>

                      <LightTooltip
                        title={i18n.t(
                          'Select this option to create a Partner user with login credentials. The user will automatically receive an invitation to set their password.'
                        )}
                        placement="bottom-start"
                      >
                        <i className="icon-info" />
                      </LightTooltip>
                    </div>
                  }
                />
              </div>
              <div className="button-wrapper">
                <OfficialButton variant="large-primary" type="submit" label={i18n.t('Add')} />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

AddNewParticipantDialog.propTypes = {
  emailDefault: PropTypes.string,
  users: PropTypes.instanceOf(Array),
};

export default AddNewParticipantDialog;
