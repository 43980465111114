import { Box, Typography } from '@mui/material';
import _cloneDeep from 'lodash/cloneDeep';
import memoizeOne from 'memoize-one';
import AddIcon from '@mui/icons-material/Add';
import _sortBy from 'lodash/sortBy';
import React, { forwardRef, useEffect, useState } from 'react';
import i18n from '../../i18n';
import HeaderUser from '../components/HeaderUser';
import './DesignSetting.scss';
import { sendNotification, sleep } from '../../commons/utils';
import currentOrganization from '../../commons/CurrentOrganization';
import useGetOrganizationByIdQuery from '../access/useGetOrganizationByIdQuery';
import Loading from '../../components/Loading';
import TextSetting from './TextSetting';
import { updateDesignSettings } from '../UserServices';
import { CMS_DEFAULT_DESIGN_SETTING } from '../../cms/cmsConstants';
import BackgroundColorSetting from './BackgroundColorSetting';
import queryCache, { CacheKeys, clearCache } from '../../app/queryCache';
import OfficialButton from '../../components/OfficialButtons';
import useMyFontListQuery from '../../cms/commons/FontSelect/MyFontListQuery';
import UploadFontDialog from './UploadFontDialog';
import { getResourceFileType } from '../../commons/ResourceUtils';
import { FeatureFlagsType, ResourceType, UploadContextTypes } from '../../app/appConstants';
import { deleteTermsAndCondition, updateTermsAndCondition } from '../../resources/ResourceServices';
import TermsAndConditionFileUploader from './TermsAndConditionFileUploader';
import { useFeatureFlagsContext } from '../../commons/FeatureFlags/FeatureFlagsContextProvider';
import { useUserContainerContext } from '../settings/UserContainerProvider';

const initializeDesignSettings = memoizeOne((theme) => {
  const newTheme = _cloneDeep(theme);
  newTheme.largeHeadingFontFamily =
    newTheme.largeHeadingFontFamily || CMS_DEFAULT_DESIGN_SETTING.LARGE_HEADING_FONT_FAMILY;
  newTheme.largeHeadingFontSize =
    newTheme.largeHeadingFontSize || CMS_DEFAULT_DESIGN_SETTING.LARGE_HEADING_FONT_SIZE;
  newTheme.largeHeadingColor =
    newTheme.largeHeadingColor || CMS_DEFAULT_DESIGN_SETTING.LARGE_HEADING_COLOR;

  newTheme.mediumHeadingFontFamily =
    newTheme.mediumHeadingFontFamily || CMS_DEFAULT_DESIGN_SETTING.MEDIUM_HEADING_FONT_FAMILY;
  newTheme.mediumHeadingFontSize =
    newTheme.mediumHeadingFontSize || CMS_DEFAULT_DESIGN_SETTING.MEDIUM_HEADING_FONT_SIZE;
  newTheme.mediumHeadingColor =
    newTheme.mediumHeadingColor || CMS_DEFAULT_DESIGN_SETTING.MEDIUM_HEADING_COLOR;

  newTheme.smallHeadingFontFamily =
    newTheme.smallHeadingFontFamily || CMS_DEFAULT_DESIGN_SETTING.SMALL_HEADING_FONT_FAMILY;
  newTheme.smallHeadingFontSize =
    newTheme.smallHeadingFontSize || CMS_DEFAULT_DESIGN_SETTING.SMALL_HEADING_FONT_SIZE;
  newTheme.smallHeadingColor =
    newTheme.smallHeadingColor || CMS_DEFAULT_DESIGN_SETTING.SMALL_HEADING_COLOR;

  newTheme.descriptionFontFamily =
    newTheme.descriptionFontFamily || CMS_DEFAULT_DESIGN_SETTING.DESCRIPTION_FONT_FAMILY;
  newTheme.descriptionFontSize =
    newTheme.descriptionFontSize || CMS_DEFAULT_DESIGN_SETTING.DESCRIPTION_FONT_SIZE;
  newTheme.descriptionColor =
    newTheme.descriptionColor || CMS_DEFAULT_DESIGN_SETTING.DESCRIPTION_COLOR;

  newTheme.overlayLargeHeadingFontFamily =
    newTheme.overlayLargeHeadingFontFamily ||
    CMS_DEFAULT_DESIGN_SETTING.OVERLAY_LARGE_HEADING_FONT_FAMILY;
  newTheme.overlayLargeHeadingFontSize =
    newTheme.overlayLargeHeadingFontSize ||
    CMS_DEFAULT_DESIGN_SETTING.OVERLAY_LARGE_HEADING_FONT_SIZE;
  newTheme.overlayLargeHeadingColor =
    newTheme.overlayLargeHeadingColor || CMS_DEFAULT_DESIGN_SETTING.OVERLAY_LARGE_HEADING_COLOR;

  newTheme.overlayMediumHeadingFontFamily =
    newTheme.overlayMediumHeadingFontFamily ||
    CMS_DEFAULT_DESIGN_SETTING.OVERLAY_MEDIUM_HEADING_FONT_FAMILY;
  newTheme.overlayMediumHeadingFontSize =
    newTheme.overlayMediumHeadingFontSize ||
    CMS_DEFAULT_DESIGN_SETTING.OVERLAY_MEDIUM_HEADING_FONT_SIZE;
  newTheme.overlayMediumHeadingColor =
    newTheme.overlayMediumHeadingColor || CMS_DEFAULT_DESIGN_SETTING.OVERLAY_MEDIUM_HEADING_COLOR;

  newTheme.overlaySmallHeadingFontFamily =
    newTheme.overlaySmallHeadingFontFamily ||
    CMS_DEFAULT_DESIGN_SETTING.OVERLAY_SMALL_HEADING_FONT_FAMILY;
  newTheme.overlaySmallHeadingFontSize =
    newTheme.overlaySmallHeadingFontSize ||
    CMS_DEFAULT_DESIGN_SETTING.OVERLAY_SMALL_HEADING_FONT_SIZE;
  newTheme.overlaySmallHeadingColor =
    newTheme.overlaySmallHeadingColor || CMS_DEFAULT_DESIGN_SETTING.OVERLAY_SMALL_HEADING_COLOR;

  newTheme.overlayDescriptionFontFamily =
    newTheme.overlayDescriptionFontFamily ||
    CMS_DEFAULT_DESIGN_SETTING.OVERLAY_DESCRIPTION_FONT_FAMILY;
  newTheme.overlayDescriptionFontSize =
    newTheme.overlayDescriptionFontSize || CMS_DEFAULT_DESIGN_SETTING.OVERLAY_DESCRIPTION_FONT_SIZE;
  newTheme.overlayDescriptionColor =
    newTheme.overlayDescriptionColor || CMS_DEFAULT_DESIGN_SETTING.OVERLAY_DESCRIPTION_COLOR;

  newTheme.buttonLabelFontFamily =
    newTheme.buttonLabelFontFamily || CMS_DEFAULT_DESIGN_SETTING.BUTTON_LABEL_FONT_FAMILY;
  newTheme.buttonLabelFontSize =
    newTheme.buttonLabelFontSize || CMS_DEFAULT_DESIGN_SETTING.BUTTON_LABEL_FONT_SIZE;
  newTheme.buttonLabelColor =
    newTheme.buttonLabelColor || CMS_DEFAULT_DESIGN_SETTING.BUTTON_LABEL_COLOR;
  newTheme.buttonBackgroundColor =
    newTheme.buttonBackgroundColor || CMS_DEFAULT_DESIGN_SETTING.BUTTON_BACKGROUND_COLOR;

  newTheme.secondaryButtonLabelFontFamily =
    newTheme.secondaryButtonLabelFontFamily ||
    CMS_DEFAULT_DESIGN_SETTING.SECONDARY_BUTTON_LABEL_FONT_FAMILY;
  newTheme.secondaryButtonLabelFontSize =
    newTheme.secondaryButtonLabelFontSize ||
    CMS_DEFAULT_DESIGN_SETTING.SECONDARY_BUTTON_LABEL_FONT_SIZE;
  newTheme.secondaryButtonLabelColor =
    newTheme.secondaryButtonLabelColor || CMS_DEFAULT_DESIGN_SETTING.SECONDARY_BUTTON_LABEL_COLOR;
  newTheme.secondaryButtonBackgroundColor =
    newTheme.secondaryButtonBackgroundColor ||
    CMS_DEFAULT_DESIGN_SETTING.SECONDARY_BUTTON_BACKGROUND_COLOR;

  newTheme.blockBackgroundColor =
    newTheme.blockBackgroundColor || CMS_DEFAULT_DESIGN_SETTING.BLOCK_BACKGROUND_COLOR;
  // newTheme.topPaddingSize = newTheme.topPaddingSize || CMS_DEFAULT_DESIGN_SETTING.TOP_PADDING_SIZE;
  // newTheme.bottomPaddingSize =
  //   newTheme.bottomPaddingSize || CMS_DEFAULT_DESIGN_SETTING.BOTTOM_PADDING_SIZE;
  // newTheme.leftPaddingSize =
  //   newTheme.leftPaddingSize || CMS_DEFAULT_DESIGN_SETTING.LEFT_PADDING_SIZE;
  // newTheme.rightPaddingSize =
  //   newTheme.rightPaddingSize || CMS_DEFAULT_DESIGN_SETTING.RIGHT_PADDING_SIZE;
  return newTheme;
});

const DesignSetting = forwardRef((props, ref) => {
  const { open, handleDrawerToggle } = useUserContainerContext();
  const organizationId = currentOrganization.getOrganizationId();
  const { organization, isLoading } = useGetOrganizationByIdQuery(organizationId);
  const [processing, setProcessing] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [themeSetting, setThemeSetting] = useState();
  const [hasChange, setHasChange] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState(organization?.termsAndConditions);
  const [hasChangeTermsAndCondition, setHasChangeTermsAndCondition] = useState(false);
  const { myFonts } = useMyFontListQuery(true, false);

  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [isEnabledTC] = checkEnabledFeatures([FeatureFlagsType.TC]);

  useEffect(() => {
    if (organization?.theme) {
      const initializedTheme = initializeDesignSettings(organization.theme);
      setThemeSetting(initializedTheme);
    }
  }, [organization?.theme]);

  const handleOnChange = async (name, value) => {
    setHasChange(true);
    setThemeSetting({ ...themeSetting, [name]: value });
  };

  const handleOnSave = async () => {
    try {
      setProcessing(true);
      await updateDesignSettings(organizationId, themeSetting);
      if (hasChangeTermsAndCondition) {
        if (!termsAndCondition) {
          await deleteTermsAndCondition(organizationId);
        } else {
          await updateTermsAndCondition(organizationId, {
            src: termsAndCondition.src,
            resourceId: termsAndCondition.resourceId,
            title: termsAndCondition.fileName,
          });
        }
      }
      queryCache.removeQueries({ queryKey: [CacheKeys.fetchSpaceInvitation], exact: false });
      queryCache.removeQueries({ queryKey: [CacheKeys.createSpaceInvitation], exact: false });
      clearCache([CacheKeys.getOrganizationById, organizationId], false);
      setHasChange(false);
      setHasChangeTermsAndCondition(false);
    } catch (e) {
      sendNotification(e.message, { type: 'error' });
    }
    setProcessing(false);
  };

  const handleOnCancel = async () => {
    setIsCanceling(true);
    setHasChange(false);
    await sleep(1);
    setThemeSetting(organization?.theme);
    setIsCanceling(false);
  };

  React.useImperativeHandle(ref, () => ({
    save: handleOnSave,
    cancel: handleOnCancel,
    hasChange: hasChange,
  }));

  if (isLoading || !themeSetting) {
    return <Loading />;
  }

  function refetchMyFontsQuery() {
    clearCache([CacheKeys.fetchFonts], false);
  }

  async function showUploadFontDialog(event) {
    const sortedMyFonts = _sortBy(myFonts, (item) => item.resourceName);
    event.preventDefault();
    event.stopPropagation();
    await UploadFontDialog.show(sortedMyFonts, refetchMyFontsQuery);
  }

  function handleTermsAndConditionChange(newResource) {
    console.log('### handleTermsAndConditionChange', newResource);
    setTermsAndCondition(newResource);
    let wasChanged = false;
    if (organization?.termsAndConditions?.resourceId !== newResource?.resourceId) {
      wasChanged = true;
    }
    // in case of null !== undefined
    const hasOldTC = !!organization?.termsAndConditions?.resourceId;
    const hasNewTC = !!newResource?.resourceId;
    if (!hasOldTC && !hasNewTC) {
      wasChanged = false;
    }
    setHasChange(wasChanged);
    setHasChangeTermsAndCondition(wasChanged);
  }

  function validateTermsAndConditionInput(name, value) {
    if (!name) {
      return null;
    }
    if (name === 'name') {
      if (!value || !value.trim()) {
        return { type: 'required' };
      }
    }
    if (name === 'file') {
      const resourceType = getResourceFileType(value);
      if (resourceType === null || resourceType !== ResourceType.pdf) {
        return { type: 'unsupported' };
      }
    }
    const defaultMaxFileSize = 5000; // 5gb
    const maxFileSizeMsg = i18n.t('The file exceeds the maximum file size 5 GB.');
    const fileSize = value.size / 1024 / 1024;
    if (fileSize > defaultMaxFileSize) {
      return { type: 'maxFileSize', message: maxFileSizeMsg };
    }
    return null;
  }

  function renderContent() {
    return (
      <div className="design-setting-body styled-scrollbar">
        <div className="design-setting-box">
          <div className="design-setting-box-info">
            <span className="icon-setting-text"></span>
            <div>
              <div className="description">
                {i18n.t(
                  'Space settings are organization-wide and applied to all blocks in the space designer.'
                )}
                <br></br>
                {i18n.t('The hosts can customize the default design settings in their spaces.')}
              </div>
            </div>
          </div>
        </div>
        {isEnabledTC && (
          <div className="design-setting-box">
            <Typography className="title">{i18n.t('Terms & conditions')}</Typography>
            <div className="description">
              {i18n.t('Upload your organization’s terms and conditions (PDF only).')}
              <br></br>
              {i18n.t(
                'Users can view the terms and conditions when joining restricted or private spaces.'
              )}
            </div>
            <div className="uploader">
              <TermsAndConditionFileUploader
                spaceId="00000000-0000-0000-0000-000000000000"
                hideUrlInput
                onChange={handleTermsAndConditionChange}
                validationMethod={validateTermsAndConditionInput}
                fileInputLabel={i18n.t('Add file')}
                termsAndCondition={organization?.termsAndConditions}
                canEditNameSameStep={false}
                organizationId={organizationId}
                uploadContext={UploadContextTypes.TermsAndConditions}
                // oldResourceId={organization.termsAndConditions?.resourceId}
                isPortal
                canPreview
                canUploadNewVersion
              />
            </div>
          </div>
        )}

        <div className="design-setting-box">
          <div>
            <Typography className="title">{i18n.t('Default background color')}</Typography>
            <div className="description">
              {i18n.t('Set a default background color for all design blocks in a space.')}
            </div>
          </div>
          <div className="background-setting">
            <BackgroundColorSetting
              name="blockBackgroundColor"
              defaultColor={themeSetting?.blockBackgroundColor}
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="design-setting-box list">
          <div className="manage-fonts">
            <Typography className="title">{i18n.t('Text on a colored background')}</Typography>
            <OfficialButton
              variant="rectangle-primary"
              onClick={(event) => showUploadFontDialog(event)}
              label={i18n.t('Manage Fonts')}
              icon={<AddIcon />}
            />
          </div>
          <div className="description">
            {i18n.t(
              'Set a default design for headings and descriptions in "text" and "image with text" blocks.'
            )}
          </div>
          <div className="list-settings">
            <TextSetting
              type="LARGE_TITLE"
              name="largeHeading"
              color={themeSetting?.largeHeadingColor}
              fontFamily={themeSetting?.largeHeadingFontFamily}
              blockBackgroundColor={themeSetting?.blockBackgroundColor}
              onChange={handleOnChange}
              fontSize={themeSetting?.largeHeadingFontSize}
              description="Large heading is used in blocks with one column layout."
            />
            <TextSetting
              type="MEDIUM_TITLE"
              name="mediumHeading"
              color={themeSetting?.mediumHeadingColor}
              fontFamily={themeSetting?.mediumHeadingFontFamily}
              blockBackgroundColor={themeSetting?.blockBackgroundColor}
              onChange={handleOnChange}
              fontSize={themeSetting?.mediumHeadingFontSize}
              description="Medium heading is used in blocks with two columns layout."
            />
            <TextSetting
              type="SMALL_TITLE"
              name="smallHeading"
              color={themeSetting?.smallHeadingColor}
              fontFamily={themeSetting?.smallHeadingFontFamily}
              blockBackgroundColor={themeSetting?.blockBackgroundColor}
              onChange={handleOnChange}
              fontSize={themeSetting?.smallHeadingFontSize}
              description="Small heading is used in blocks with three and four columns layout."
            />
            <TextSetting
              type="PARAGRAPH"
              name="description"
              color={themeSetting?.descriptionColor}
              fontFamily={themeSetting?.descriptionFontFamily}
              blockBackgroundColor={themeSetting?.blockBackgroundColor}
              onChange={handleOnChange}
              fontSize={themeSetting?.descriptionFontSize}
            />
          </div>
        </div>
        <div className="design-setting-box">
          <div>
            <Typography className="title">{i18n.t('Text on an overlay background')}</Typography>
            <div className="description">
              {i18n.t(
                'Set a default design for headings and descriptions in "video", "slideshow" and "image" blocks.'
              )}
            </div>
          </div>
          <div className="list-settings">
            <TextSetting
              type="LARGE_TITLE"
              name="overlayLargeHeading"
              color={themeSetting?.overlayLargeHeadingColor}
              fontFamily={themeSetting?.overlayLargeHeadingFontFamily}
              onChange={handleOnChange}
              hasBackground
              fontSize={themeSetting?.overlayLargeHeadingFontSize}
              description="Large heading is used in blocks with one column layout."
            />
            <TextSetting
              type="MEDIUM_TITLE"
              name="overlayMediumHeading"
              color={themeSetting?.overlayMediumHeadingColor}
              fontFamily={themeSetting?.overlayMediumHeadingFontFamily}
              onChange={handleOnChange}
              hasBackground
              fontSize={themeSetting?.overlayMediumHeadingFontSize}
              description="Medium heading is used in blocks with two columns layout."
            />
            <TextSetting
              type="SMALL_TITLE"
              name="overlaySmallHeading"
              color={themeSetting?.overlaySmallHeadingColor}
              fontFamily={themeSetting?.overlaySmallHeadingFontFamily}
              onChange={handleOnChange}
              hasBackground
              fontSize={themeSetting?.overlaySmallHeadingFontSize}
              description="Small heading is used in blocks with three and four columns layout."
            />
            <TextSetting
              type="PARAGRAPH"
              name="overlayDescription"
              color={themeSetting?.overlayDescriptionColor}
              fontFamily={themeSetting?.overlayDescriptionFontFamily}
              onChange={handleOnChange}
              hasBackground
              fontSize={themeSetting?.overlayDescriptionFontSize}
            />
          </div>
        </div>

        <div className="design-setting-box">
          <div>
            <Typography className="title">{i18n.t('Buttons')}</Typography>
            <div className="description">
              {i18n.t('Set a default design for primary and secondary buttons.')}
            </div>
          </div>
          <div className="list-settings">
            <TextSetting
              type="BUTTON"
              name="buttonLabel"
              color={themeSetting?.buttonLabelColor}
              fontFamily={themeSetting?.buttonLabelFontFamily}
              onChange={handleOnChange}
              buttonBackgroundColor={themeSetting?.buttonBackgroundColor}
              fontSize={themeSetting?.buttonLabelFontSize}
            />
            <TextSetting
              type="SECONDARY_BUTTON"
              name="secondaryButtonLabel"
              color={themeSetting?.secondaryButtonLabelColor}
              fontFamily={themeSetting?.secondaryButtonLabelFontFamily}
              onChange={handleOnChange}
              secondaryButtonBackgroundColor={themeSetting?.secondaryButtonBackgroundColor}
              fontSize={themeSetting?.secondaryButtonLabelFontSize}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="design-setting">
      <HeaderUser onClick={handleDrawerToggle} isOpen={open} title={i18n.t('Space Settings')}>
        <Box className="group-button">
          <OfficialButton variant="regular-secondary" onClick={handleOnCancel} label="Cancel" />
          <OfficialButton
            variant="regular-primary"
            onClick={handleOnSave}
            disabled={!hasChange}
            label="Save"
            isProcessing={processing}
          />
        </Box>
      </HeaderUser>
      {isCanceling && <Loading />}
      {!isCanceling && renderContent()}
    </div>
  );
});

DesignSetting.propTypes = {};

export default DesignSetting;
