import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import './ContentTab.scss';
import { Tab, Tabs } from '@mui/material';
import ContentForm from './ContentForm';
import { useThemeSettingsContext } from '../context/ThemeSettingsContextProvider';
import TabPanel from './TabPanel';
import { CMS_COMPONENT_TYPE } from '../../cmsConstants';
import ShareLinkCmsBlock from '../ShareLinkCmsBlock';
import { useSpaceContext } from '../../../spaces/SpaceContext';

function ContentTab(props) {
  const { onChange, layout, spaceId, defaultTab, isAdmin } = props;
  const { selectedBlock, updateBlock, setWorkingData } = useThemeSettingsContext();
  const { currentPage } = useSpaceContext();
  const [tab, setTab] = useState(0);
  useEffect(() => {
    setTab(defaultTab);
  }, [defaultTab]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    setWorkingData((prev) => ({ ...prev, currentTab: newValue }));
  };

  const handleOnChangeContentForm = useCallback(
    (prop, value, forceUpdate = false) => {
      if (!selectedBlock?.items?.length) return;
      const items = _cloneDeep(selectedBlock.items);
      if (typeof prop === 'object' && typeof value === 'object') {
        prop.forEach((propItem, index) => {
          items[tab][propItem] = value[index];
        });
      } else {
        items[tab][prop] = value;
      }

      onChange(items, forceUpdate);
    },
    [onChange, selectedBlock, tab]
  );

  function handleWidgetBlockContentChange(prop, value) {
    const newBlock = _cloneDeep(selectedBlock);
    if (typeof prop === 'object' && typeof value === 'object') {
      prop.forEach((propItem, index) => {
        newBlock[propItem] = value[index];
      });
      updateBlock(newBlock, true);
    } else if (prop === 'widget') {
      newBlock.items = value;
      updateBlock(newBlock);
    }
  }

  function handleCarouselContentChange(prop, value, forceUpdate = false) {
    // console.log('handleCarouselContentChange: ', prop, value);
    const length = selectedBlock?.items?.[0]?.items?.length;
    if (!length) return;
    const newBlock = _cloneDeep(selectedBlock);
    if (typeof prop === 'object' && typeof value === 'object') {
      prop.forEach((propItem, index) => {
        newBlock.items[0].items[tab][propItem] = value[index];
      });
    } else {
      newBlock.items[0].items[tab][prop] = value;
    }

    updateBlock(newBlock, forceUpdate);
  }

  function renderDefaultForm() {
    const columns = selectedBlock?.columns || 1;
    const tabs = Array.from(Array(columns).keys());
    const content = selectedBlock?.items?.[tab];
    const type = selectedBlock?.items?.[0]?.type;

    return (
      <>
        {columns > 1 && (
          <Tabs
            value={tab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs for cms content tab"
            className="cms-editing-component-panel-number-tabs"
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            {tabs.map((t) => (
              <Tab
                key={t}
                label={t + 1}
                value={t}
                aria-controls={`cms-content-tab-${t}`}
                className="cms-editing-component-panel-number-tab"
              />
            ))}
          </Tabs>
        )}
        <TabPanel
          key={tab}
          value={tab}
          index={tab}
          className="cms-editing-component-panel-number-tabpanel"
        >
          <ContentForm
            key={content?.id}
            type={type}
            layout={layout}
            content={content}
            onChange={handleOnChangeContentForm}
            spaceId={spaceId}
            currentTab={tab}
            isAdmin={isAdmin}
          />
        </TabPanel>
      </>
    );
  }

  function renderCarouselForm(type) {
    const length = selectedBlock?.items?.[0]?.items?.length;
    const columns = length || 1;
    const tabs = Array.from(Array(columns).keys());
    const content = selectedBlock?.items?.[0]?.items?.[tab];
    // console.log('ContentTab content: ', content);

    return (
      <>
        {length > 1 && (
          <Tabs
            value={tab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs for cms content tab"
            className="cms-editing-component-panel-number-tabs"
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            {tabs.map((t) => (
              <Tab
                key={t}
                label={t + 1}
                value={t}
                aria-controls={`cms-content-tab-${t}`}
                className="cms-editing-component-panel-number-tab"
              />
            ))}
          </Tabs>
        )}
        <TabPanel
          key={tab}
          value={tab}
          index={tab}
          className="cms-editing-component-panel-number-tabpanel"
        >
          <ContentForm
            key={content?.id}
            type={type}
            layout={layout}
            content={content}
            onChange={handleCarouselContentChange}
            spaceId={spaceId}
            currentTab={tab}
            isAdmin={isAdmin}
          />
        </TabPanel>
      </>
    );
  }

  function renderContentTab() {
    const type = selectedBlock?.items?.[0]?.type; // the type of the 1st items in a block/row
    let element;
    switch (type) {
      case CMS_COMPONENT_TYPE.WIDGET:
        element = (
          <ContentForm
            // key={selectedBlock?.id}
            type={type}
            layout={layout}
            content={selectedBlock} // a trick
            onChange={handleWidgetBlockContentChange}
            spaceId={spaceId}
            currentTab={tab}
            isAdmin={isAdmin}
          />
        );
        break;
      case CMS_COMPONENT_TYPE.CAROUSEL:
        element = renderCarouselForm(type);
        break;
      default:
        element = renderDefaultForm();
        break;
    }
    return element;
  }

  console.log('currentPage: ', currentPage);
  return (
    <div className="cms-editing-component-panel-tabpanel-content">
      <ShareLinkCmsBlock layoutId={layout.id} pageSlug={currentPage?.slug} />
      {renderContentTab()}
    </div>
  );
}

ContentTab.propTypes = {
  onChange: PropTypes.func,
  layout: PropTypes.instanceOf(Object),
  spaceId: PropTypes.string,
  defaultTab: PropTypes.number,
  isAdmin: PropTypes.bool,
};

export default ContentTab;
