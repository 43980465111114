import React from 'react';
import UserContainerProvider from './UserContainerProvider';

import UserContainer from './UserContainer';

function UserContainerContainer() {
  return (
    <UserContainerProvider>
      <UserContainer />
    </UserContainerProvider>
  );
}

export default UserContainerContainer;
