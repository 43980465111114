import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { getRoiReports } from '../../components/ROICalculation/servise';

const useRoiReportsQuery = (spaceId: string, enabled: boolean) => {
  const roiReportsQuery = useQuery({
    queryKey: [CacheKeys.fetchRoiReports, spaceId],
    queryFn: async () => {
      const resp = await getRoiReports();
      return resp;
    },

    retry: 3,
    retryDelay: () => 5000,
    enabled: !!spaceId && enabled,
  });

  const isFetching = roiReportsQuery.isFetching;
  const data = roiReportsQuery.data ? roiReportsQuery.data : [];

  return { data, isFetching };
};

export default useRoiReportsQuery;
