import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import UserAvatar from '../UserAvatar';
import './NotificationItem.scss';
import { iconsByNotifyContext, messageTemplates } from './constants';
import NotificationMessage from './NotificationMessage';
import { timeFromNow, parseDateTimeStringFromServer } from '../../commons/DateTimeUtils';
import { getNotificationContext } from './utils';

export function NotifyIcon({ type }) {
  const notify = iconsByNotifyContext[type];
  if (!notify) {
    return null;
  }

  return (
    <span
      className={`notify-icon ${notify.icon}`}
      style={{ backgroundColor: notify.color, fontSize: notify.size }}
    >
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="path4"></span>
      <span className="path5"></span>
      <span className="path6"></span>
    </span>
  );
}

NotifyIcon.propTypes = {
  type: PropTypes.string,
};

function NotificationItem(props) {
  const { item, onClick, currentUserInfo } = props;
  const { messageTemplateId, created, seenAt, senders, context, messageTemplateData } = item;
  const spaceName = messageTemplateData?.spaceName;

  const notificationContext = getNotificationContext(
    context,
    messageTemplateId,
    messageTemplateData
  );

  const classActive = !seenAt ? 'active' : '';

  const time = parseDateTimeStringFromServer(created).local();
  const timeInfo = time ? timeFromNow(time) : '';
  const [firstUser, setFirstUser] = useState();

  const bottomContentCssClass = !seenAt ? 'blue-text' : '';

  useEffect(() => {
    let firstSenderFullname;
    if (senders.length > 0) {
      firstSenderFullname = senders[0].fullName.trim();
    }
    if (!firstSenderFullname) {
      firstSenderFullname = currentUserInfo?.fullName;
    }
    setFirstUser(firstSenderFullname);
  }, [senders, currentUserInfo?.fullName]);

  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (onClick) onClick(item);
  };

  if (!messageTemplates[messageTemplateId]) {
    return null;
  }

  return (
    <Box className={`notify-card ${classActive}`} onClick={handleOnClick}>
      <div>
        <div className="notify-card-left">
          <UserAvatar fullName={firstUser} />
          <NotifyIcon type={notificationContext} />
        </div>
      </div>
      <div className="content">
        <NotificationMessage
          messageTemplateId={messageTemplateId}
          spaceName={spaceName}
          senders={senders}
          messageTemplateData={messageTemplateData}
        />

        <div className={`content-bottom ${bottomContentCssClass}`}>
          <Box display="flex">
            <span>{timeInfo}</span>
          </Box>
          {!seenAt && <span className="blue-dot"></span>}
        </div>
      </div>
    </Box>
  );
}

NotificationItem.propTypes = {
  currentUserInfo: PropTypes.instanceOf(Object),
  item: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
};

export default NotificationItem;
