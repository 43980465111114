import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableContainer } from '@mui/material';
import './style.scss';
import ResourceItem from '../ResourcesDisplayView/ResourceItem';
import { ViewModeType } from '../../../resources/resourceConstants';
import { useResourcesViewContext } from '../../ResourcesView/ResourcesViewContextProvider';
import EnhancedTableHeader from '../../ResourceListItem/EnhancedTableHeader';
import ExternalResourceItem from '../ResourcesDisplayView/ExternalResourceItem';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'indicator',
    numeric: false,
    disablePadding: true,
    width: '6%',
    label: '',
    className: 'resource-indicator',
  },
  {
    id: 'lastModified',
    numeric: false,
    disablePadding: false,
    label: 'Last Modified',
    width: '140px',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: true,
    label: '',
    width: '56px',
    disableHovering: true,
  },
];

const ResourcesListView = (props) => {
  const {
    resources,
    isMobile,
    handleSelectAllClick,
    selected,
    order,
    orderBy,
    handleSort,
    canDragDropFile,
    selectedFile,
    newFiles,
    onClick,
    onClickCheckbox,
    onClickMoreButton,
    loadMoreButton,
    onDriveInfoUpdated,
  } = props;
  const { columns, sortOptions } = useResourcesViewContext();
  const { hasSelectedItems, isSelectAll } = useMemo(() => {
    return {
      hasSelectedItems: selected.length > 0,
      isSelectAll: resources.length > 0 && selected.length === resources.length,
    };
  }, [resources.length, selected.length]);

  const renderItem = (resource) => {
    const isItemSelected = resource.id
      ? selected.includes(resource.id)
      : selected.includes(resource.externalId);
    const canDragDrop = canDragDropFile ? canDragDropFile(resource) : false;
    const selectedFileId = selectedFile?.id > 0 && selectedFile?.id === resource.id;

    const isExternalResource = resource.isFolder && !!resource.externalId;

    if (isExternalResource) {
      return (
        <ExternalResourceItem
          viewMode={ViewModeType.LIST}
          isMobile={isMobile}
          key={resource.id > 0 ? resource.id : resource.externalId}
          resource={resource}
          newFiles={newFiles}
          selected={isItemSelected}
          showActionMenu={props.showActionMenu}
          disabledMoreButton={props.disabledMoreButton}
          onClickItem={onClick}
          onClickCheckbox={onClickCheckbox}
          onClickMoreButton={onClickMoreButton}
          processingResources={props.processingResources}
          newResourceId={props.newResourceId}
          selectedFileId={selectedFileId}
          isViewOnly={props.isViewOnly}
          dragObject={canDragDrop ? resource : null}
          isWebinar={props.isWebinar}
          columns={columns}
          onDriveInfoUpdated={onDriveInfoUpdated}
        />
      );
    }

    return (
      <ResourceItem
        viewMode={ViewModeType.LIST}
        isMobile={isMobile}
        key={resource.id > 0 ? resource.id : resource.externalId}
        resource={resource}
        newFiles={newFiles}
        selected={isItemSelected}
        showActionMenu={props.showActionMenu}
        disabledMoreButton={props.disabledMoreButton}
        onClickItem={onClick}
        onClickCheckbox={onClickCheckbox}
        onClickMoreButton={onClickMoreButton}
        processingResources={props.processingResources}
        newResourceId={props.newResourceId}
        selectedFileId={selectedFileId}
        isViewOnly={props.isViewOnly}
        dragObject={canDragDrop ? resource : null}
        isWebinar={props.isWebinar}
        columns={columns}
      />
    );
  };

  return (
    <TableContainer className="table-container resources-table styled-scrollbar">
      <Table className="table" size="medium" aria-label="sticky table" stickyHeader>
        {!isMobile && (
          <EnhancedTableHeader
            headCells={headCells}
            columns={columns}
            sortOptions={sortOptions}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleSort}
            hasSelectedItems={hasSelectedItems}
            isSelectAll={isSelectAll}
          />
        )}
        <TableBody>{resources.map((resource) => renderItem(resource))}</TableBody>
      </Table>
      {loadMoreButton}
    </TableContainer>
  );
};

ResourcesListView.propTypes = {
  resources: PropTypes.instanceOf(Array),
  isMobile: PropTypes.bool,
  handleSelectAllClick: PropTypes.func,
  selected: PropTypes.instanceOf(Object),
  order: PropTypes.string,
  orderBy: PropTypes.string,
  handleSort: PropTypes.func,
  canDragDropFile: PropTypes.func,
  selectedFile: PropTypes.instanceOf(Object),
  newFiles: PropTypes.instanceOf(Array),
  loadMoreButton: PropTypes.instanceOf(Object),
  isWebinar: PropTypes.bool,
  showActionMenu: PropTypes.bool,
  disabledMoreButton: PropTypes.bool,
  processingResources: PropTypes.instanceOf(Array),
  newResourceId: PropTypes.number,
  isViewOnly: PropTypes.bool,
  onClick: PropTypes.func,
  onClickCheckbox: PropTypes.func,
  onClickMoreButton: PropTypes.func,
  onDriveInfoUpdated: PropTypes.func,
};

ResourcesListView.defaultProps = {};

export default ResourcesListView;
