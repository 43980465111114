import React from 'react';
import { hasRoiCalculationInCMS } from '../../cms/cmsUtils';
import { useSpaceContext } from '../SpaceContext';
import { useMaterialsContext } from './MaterialsContextProvider';
import { DirectoryType, ResourceType } from '../../app/appConstants';
import useRoiReportsQuery from './useRoiReportsQuery';

const RoiCalculationHandler = () => {
  const { space } = useSpaceContext();
  const isRoiCalculationBlockExisted = hasRoiCalculationInCMS(space);
  const { flatDirectories, setFlatDirectories } = useMaterialsContext();
  const { data } = useRoiReportsQuery(space?.id, isRoiCalculationBlockExisted);

  React.useEffect(() => {
    if (!space?.id) {
      return;
    }
    if (!!data && data?.length > 0 && !!flatDirectories) {
      const newFlatDirectories = [...flatDirectories];
      const rootFolderId = 'roi-calculation';
      const rootFolder = {
        id: rootFolderId,
        name: 'ROI Calculations',
        parentFolder: null,
        subFolders: [],
        isFolder: true,
        directoryType: DirectoryType.Default,
        spaceResources: [],
      };
      data.forEach((report) => {
        const roiResource = {
          documentId: report.id,
          name: report.name,
          displayName: report.name,
          created: report.completedOn,
          lastModified: report.completedOn,
          date: report.completedOn,
          extension: 'pdf',
          type: ResourceType.pdf,
          isRoi: true,
          spaceId: space.id,
        };
        rootFolder.spaceResources.push(roiResource);
      });

      // Check if the Roi calculation folder already exists to prevent unnecessary updates
      const roiFolderExists = newFlatDirectories.some((folder) => folder.id === rootFolderId);

      if (!roiFolderExists) {
        newFlatDirectories.push(rootFolder);
        console.log('new newFlatDirectories', newFlatDirectories);
        setFlatDirectories(newFlatDirectories);
      }
    }
  }, [flatDirectories, data, setFlatDirectories, space?.id]);

  return null;
};

export default RoiCalculationHandler;
