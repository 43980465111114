export const checkEnabledRule = (rules, rule) => {
  const foundRule = rules.find((r) => r.type === rule.type);
  return !(!rule.enabled && !foundRule.enabled);
};

export const mapTodoActivities = (data) => {
  let newData = [...data];

  const foundAssignTodo = newData.find((item) => item.event === 'EVENT_ACTIVITY_ASSIGED');
  const foundUpdateTodo = newData.find((item) => item.event === 'EVENT_ACTIVITY_UPDATED');

  newData = newData.map((item) => {
    const newItem = { ...item };
    if (foundAssignTodo && foundUpdateTodo) {
      if (item.event === 'EVENT_ACTIVITY_ASSIGED') {
        newItem.hidden = true;
        newItem.synTodoEvent = 'EVENT_ACTIVITY_UPDATED';
      } else if (item.event === 'EVENT_ACTIVITY_UPDATED') {
        newItem.rules = item.rules.map((rule) => ({
          ...rule,
          enabled: checkEnabledRule(foundAssignTodo.rules, rule),
        }));
        newItem.synTodoEvent = 'EVENT_ACTIVITY_ASSIGED';
      }
    }
    return newItem;
  });

  return newData;
};
