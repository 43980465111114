import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { getOrganizationById } from '../UserServices';

const useGetOrganizationByIdQuery = (organizationId, enabled = true) => {
  // console.log('useGetOrganizationByIdQuery', organizationId, enabled);
  const { data, isFetching, isLoading, refetch } = useQuery({
    queryKey: [CacheKeys.getOrganizationById, organizationId],
    queryFn: async () => {
      const resp = await getOrganizationById(organizationId);
      return resp;
    },
    ...{
      retry: 3,
      retryDelay: () => 5000,
      enabled: !!organizationId && enabled,
    },
  });

  const getSubdomain = useCallback(async () => {
    if (data) {
      return data.subdomain;
    }
    const rs = await refetch();
    return rs.data?.subdomain;
  }, [data, refetch]);

  return {
    organization: data,
    data,
    isLoading,
    isFetching,
    getSubdomain,
  };
};

export default useGetOrganizationByIdQuery;
