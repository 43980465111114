import React, { useState, useRef, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import I18n from '../../i18n';
import CommentBox from './CommentBox';
import { deleteComment, addInteraction, deleteInteraction } from './CommentService';
import ConfirmService from '../../components/ConfirmService';
import { sendNotification } from '../../commons/utils';
import AttachFilePreview from './AttachFilePreview';
import Loading from '../../components/Loading';
import ScrollingList from '../../components/ScrollingList';
import spaceUser from '../spaceUser';
import { useSpaceContext } from '../SpaceContext';
import useChatMessages from './context/useChatMessages';
import Icons from '../../components/Icons';
import { useFeatureFlagsContext } from '../../commons/FeatureFlags/FeatureFlagsContextProvider';
import { CommentState, FeatureFlagsType, InteractionType } from '../../app/appConstants';
import CommentContextProvider, { CommentContext } from './context/CommentContextProvider';
import CommentBody from './CommentBody';
import './CommentsContainer.scss';
import { useInViewport } from '../../commons/useInViewport';
import CommentNotificationsHandler from './CommentNotificationsHandler';
import useSpaceUsersQuery from '../SpaceUsers/useSpaceUsersQuery';

let replyFlag = false;

function didILike(comment, participantId) {
  const foundLike = _find(
    comment.commentInteractions,
    (item) => item.type === InteractionType.Like && item.participantId === participantId
  );
  return !!foundLike;
}

const CommentsContainer = forwardRef((props, ref) => {
  const { channelType, spaceId, isPrivate, channelId } = props;
  const { space, isMobile, signalRConnection } = useSpaceContext();
  const getSpaceUsersQuery = useSpaceUsersQuery(spaceId, false, true, true);

  const { comments, isLoading, hasMore, loadMore, handleAddComment, handleUpdateComment } =
    useChatMessages(spaceId, channelId);

  const [parentCommentId, setParentCommentId] = useState(null);
  const [parentComment, setParentComment] = useState(null);
  const [currentEditingComment, setCurrentEditingComment] = useState(null);
  const isViewOnlyMode = spaceUser.isViewOnly() || spaceUser.isVisitor();
  const messagesEndRef = useRef(null);
  const commentBoxRef = useRef(null);
  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [isEnabledVaam] = checkEnabledFeatures([FeatureFlagsType.VAAM]);
  const refEl = useRef(null);
  const { isVisible } = useInViewport(refEl);
  console.log('isVisible: ', isVisible);

  useEffect(() => {
    function handleOnCommentAdded() {
      console.log('handleOnCommentAdded');
      setTimeout(() => {
        if (messagesEndRef?.current) {
          messagesEndRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });
        }
      });
    }
    if (signalRConnection) {
      if (isVisible) {
        signalRConnection.on('CommentAddedEvent', handleOnCommentAdded);
      } else {
        signalRConnection.off('CommentAddedEvent', handleOnCommentAdded);
      }
    }

    return () => {
      if (signalRConnection) {
        signalRConnection.off('CommentAddedEvent', handleOnCommentAdded);
      }
    };
  }, [signalRConnection, isVisible]);

  let isInteracting = false;

  function handleOnAdded(data) {
    setParentCommentId(null);
    setParentComment(null);
    replyFlag = false;
    handleAddComment(data);
  }

  async function handleOnClickLike(comment) {
    if (isInteracting) {
      return;
    }
    isInteracting = true;
    const foundInteraction = _find(
      comment.commentInteractions,
      (item) => item.type === InteractionType.Like && item.participantId === props.participantId
    );
    const data = {
      commentId: comment.id,
      type: InteractionType.Like,
    };
    if (!foundInteraction || (foundInteraction && foundInteraction.externalUserId)) {
      await addInteraction(channelId, data);
    } else {
      await deleteInteraction(channelId, foundInteraction?.id, data);
    }
    isInteracting = false;
  }

  function handleOnClickReply(comment, parentId) {
    if (currentEditingComment) {
      setCurrentEditingComment(null);
    }
    if (!replyFlag) {
      replyFlag = true;
      setParentCommentId(parentId || comment.id);
      setParentComment(comment);
      if (commentBoxRef?.current) {
        commentBoxRef.current?.focusInput();
      }
    } else {
      replyFlag = false;
      setParentCommentId(null);
      setParentComment(null);
    }
  }

  function handleOnCancelEdit() {
    setCurrentEditingComment(null);
  }

  function handleOnEdited() {
    setCurrentEditingComment(null);
  }

  React.useImperativeHandle(ref, () => ({
    handleCloseForm: async () => {
      if (!commentBoxRef.current) {
        return true;
      }
      const isCloseForm = await commentBoxRef.current.handleCloseForm();
      return isCloseForm;
    },
  }));

  function renderAttachFile(comment) {
    const isDeleted = comment.state === CommentState.Deleted;
    if (isDeleted) {
      return null;
    }
    if (comment.attachFileUrl) {
      return <img src={comment.attachFileUrl} alt="" />;
    }

    return (
      <AttachFilePreview
        channelId={channelId}
        resourceId={comment.resourceId}
        resourceName={comment.resourceName}
        materialId={comment.materialId}
        spaceId={props.spaceId}
        onResourceView={props.onResourceView}
        commentId={comment.id}
        spaceName={space.name}
      />
    );
  }

  async function handleOnClickDelete(comment) {
    const isConfirmed = await ConfirmService.show(
      `${I18n.t('DELETE MESSAGE')}`,
      <>
        <b>{I18n.t('This can’t be undone')}</b>
        <br />
        {I18n.t('Are you sure you want to delete this message')}?
      </>,
      I18n.t('Yes'),
      I18n.t('Cancel')
    );
    if (!isConfirmed) {
      return;
    }
    const resp = await deleteComment(channelId, comment.id);
    if (!resp) {
      sendNotification(I18n.t('Something went wrong, please try again'), { type: 'error' });
    }
  }

  function handleOnClickEdit(comment) {
    console.log('handleOnClickEdit', comment);
    setCurrentEditingComment(comment);
  }

  function renderCommentBody(comment, parentId, liked) {
    const hasResource = comment?.resourceId;
    const latestComment = comments && comments.length > 0 ? comments[0] : undefined;
    const latestSentComment = comment.id === latestComment?.id;
    return (
      <CommentBody
        participantId={props.participantId}
        participants={getSpaceUsersQuery.data}
        liked={liked}
        isMobile={isMobile}
        isViewOnlyMode={isViewOnlyMode}
        hasResource={hasResource}
        latestSentComment={latestSentComment}
        handleOnClickDelete={handleOnClickDelete}
        parentId={parentId}
        handleOnClickReply={handleOnClickReply}
        handleOnClickEdit={handleOnClickEdit}
        handleOnClickLike={handleOnClickLike}
        spaceId={spaceId}
        channelType={channelType}
      >
        {renderAttachFile(comment)}
      </CommentBody>
    );
  }

  function renderActiveComment(comment, parentId) {
    const liked = didILike(comment, props.participantId);
    const isMyself = comment?.participantId === props.participantId;

    return (
      <CommentContextProvider
        comment={comment}
        spaceId={spaceId}
        key={comment.id || comment.refId}
        updateComment={handleUpdateComment}
      >
        <CommentContext.Consumer>
          {({ comment: commentData }) => (
            <div className={`comment-item-body ${isMyself ? 'comment-right' : 'comment-left'}`}>
              {renderCommentBody(commentData, parentId, liked)}
            </div>
          )}
        </CommentContext.Consumer>
      </CommentContextProvider>
    );
  }

  function renderCommentItem(comment, parentId) {
    const isDeleted = comment.state === CommentState.Deleted;

    const commentContent = renderActiveComment(comment, parentId);

    // Always process and render replies
    const replies =
      comment.replies && comment.replies.length > 0
        ? comment.replies.map((reply) => renderCommentItem(reply, comment.id))
        : null;

    const isMyself = comment?.participantId === props.participantId;

    return (
      <div
        className={`comment-item ${parentId ? 'level-2' : ''} ${isDeleted ? 'deleted' : ''} ${
          isMyself ? 'comment-right' : 'comment-left'
        }`}
        key={comment.id}
      >
        {comment?.startDateSend && (
          <div className="comment-body-date">{comment?.startDateSend}</div>
        )}
        {commentContent}
        {replies}
      </div>
    );
  }

  function renderBoxSendMessage() {
    if (isViewOnlyMode) {
      return null;
    }
    const isAvailableUser =
      getSpaceUsersQuery.data?.length > 0
        ? getSpaceUsersQuery.data.find((p) => p.userInfoId === props.toParticipantId)
        : false;
    // console.log('isAvailableUser: ', participants, props.toParticipantId, isAvailableUser);

    if (isPrivate && !isAvailableUser) {
      return (
        <div className="comments-header">
          <div className="alert-info">
            <Icons name="icon-new-info" className="icon" />
            <div>
              {I18n.t(
                'You cannot send messages to this user because they no longer have access to the space.'
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="comments-header">
        <CommentBox
          ref={commentBoxRef}
          spaceId={props.spaceId}
          onAdd={handleOnAdded}
          onEdit={handleOnEdited}
          comment={currentEditingComment}
          participantId={props.participantId}
          onFocus={props.onFocus}
          parentCommentId={currentEditingComment?.id || parentCommentId}
          parentComment={parentComment}
          cancelReply={handleOnClickReply}
          onCancel={handleOnCancelEdit}
          channelId={channelId}
          placeholder={props.placeholder}
          channelType={channelType}
          vaamEnabled={isEnabledVaam}
          isChatWiget={props.isChatWiget}
        />
        {props.onClickExpand && (
          <div className="comments-header-right">
            <OpenInNewIcon color="primary" onClick={props.onClickExpand} />
          </div>
        )}
      </div>
    );
  }

  function renderComments() {
    if (isLoading || getSpaceUsersQuery.data === undefined) {
      return <Loading />;
    }
    return (
      <ScrollingList
        className="comments-list styled-scrollbar"
        onScrollToTop={hasMore && !isLoading ? loadMore : null}
      >
        <div ref={messagesEndRef} />
        <div>
          <div ref={refEl} className="inview-element" />
        </div>
        {comments?.map((comment) => renderCommentItem(comment))}
      </ScrollingList>
    );
  }

  return (
    <div className="comments-container">
      {renderComments()}
      {renderBoxSendMessage()}
      <CommentNotificationsHandler
        isVisible={isVisible}
        spaceId={spaceId}
        channelId={channelId}
        isPrivate={isPrivate}
      />
    </div>
  );
});

CommentsContainer.propTypes = {
  participantId: PropTypes.number,
  channelId: PropTypes.number,
  onClickExpand: PropTypes.func,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  spaceId: PropTypes.string,
  channelType: PropTypes.number,
  isPrivate: PropTypes.bool,
  isChatWiget: PropTypes.bool,
  onResourceView: PropTypes.func,
  toParticipantId: PropTypes.number,
};

CommentsContainer.defaultProps = {
  isPrivate: false,
};

CommentsContainer.displayName = 'CommentsContainer';

export default CommentsContainer;
