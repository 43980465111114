import { useCallback } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import { useSpaceContext } from '../../spaces/SpaceContext';
import {
  updateNotificationRead,
  updateNotificationsBadgesBySpaceId,
} from '../../users/notifications/service';

const useNotifications = (spaceId) => {
  const { newNotifications, setNotifications } = useSpaceContext();
  const updateTotalNotifications = useCallback(
    async (seenTotal = false) => {
      let callUpdate = false;
      const payload = {
        context: 'Resources',
      };
      if (newNotifications?.Resources?.total > 0 && seenTotal) {
        payload.totalBadgesSeen = newNotifications?.Resources?.total;
        callUpdate = true;
      }
      if (newNotifications?.Resources?.details?.totalSharedBadges > 0) {
        payload.totalSharedBadgesSeen = newNotifications?.Resources?.details?.totalSharedBadges;
        callUpdate = true;
      }
      if (!callUpdate) {
        return;
      }

      const resp = await updateNotificationsBadgesBySpaceId(spaceId, payload);

      if (resp) {
        const newNotify = _cloneDeep(newNotifications);
        if (payload.totalBadgesSeen && seenTotal) {
          newNotify.Resources.total = 0;
        }
        if (payload.totalSharedBadgesSeen) {
          newNotify.Resources.details.totalSharedBadges = 0;
        }
        if (payload.totalMeetingBadgesSeen) {
          newNotify.Resources.details.totalMeetingBadges = 0;
        }

        setNotifications(newNotify);
      }
    },
    [newNotifications, spaceId, setNotifications]
  );

  const updateFolderNotifications = useCallback(
    (folder) => {
      if (newNotifications?.Resources && folder.isNewFolder) {
        const filtered = newNotifications.Resources.details.newFolders.filter(
          (item) => item !== folder.id
        );
        const data = {
          readItems: {
            folder: [folder.id.toString()],
          },
          context: 'Resources',
        };
        updateNotificationRead(spaceId, data);
        const notifications = {
          ...newNotifications,
          Resources: {
            ...newNotifications.Resources,
            details: {
              ...newNotifications.Resources.details,
              newFolders: filtered,
            },
          },
        };
        setNotifications(notifications);
      }
    },
    [newNotifications, setNotifications, spaceId]
  );

  const updateResourceNotifications = useCallback(
    (material) => {
      if (newNotifications?.Resources?.details?.newMaterials) {
        const filtered = newNotifications.Resources.details.newMaterials.filter(
          (item) => item !== material.id
        );
        const findNotificationItem = newNotifications.Resources.details.newMaterials.find(
          (item) => item === material.id
        );
        if (findNotificationItem) {
          const data = {
            readItems: {
              files: [material.id.toString()],
            },
            context: 'Resources',
          };
          updateNotificationRead(spaceId, data);
        }
        const notifications = {
          ...newNotifications,
          Resources: {
            ...newNotifications.Resources,
            details: {
              ...newNotifications.Resources.details,
              newMaterials: filtered,
            },
          },
        };
        setNotifications(notifications);
      }
    },
    [newNotifications, setNotifications, spaceId]
  );

  return {
    newNotifications,
    updateTotalNotifications,
    updateFolderNotifications,
    updateResourceNotifications,
  };
};

export default useNotifications;
