import { newGuid } from '../commons/utils';
import i18n from '../i18n';
import { CMS_WIDGET_SUBTYPE, WIDGET_NAME } from './cmsConstantsForWidget';

export const CMS_COMPONENT_TYPE = {
  VIDEO: 'video',
  CARD_TEXT: 'cardText',
  CARD_IMAGE_AND_TEXT: 'cardImageAndText',
  CARD_IMAGE_OVERLAY: 'cardImageOverlay',
  CARD_HERO: 'cardHero',
  CAROUSEL: 'carousel',
  WIDGET: 'widget',
  WEB_CONTENT: 'webContent',
  SCRIPT: 'script',
  HTML_CONTENT: 'htmlContent',
  PAGES: 'pagesBlock',
  ASSESSMENT_WIDGET: 'assessmentWidget',
} as const;

export const IMAGE_COMPONENTS = [
  CMS_COMPONENT_TYPE.CARD_IMAGE_AND_TEXT,
  CMS_COMPONENT_TYPE.CARD_IMAGE_OVERLAY,
  CMS_COMPONENT_TYPE.CARD_HERO,
];

export const CMS_COMPONENT_TYPE_NAME = {
  VIDEO: 'Video',
  CARD_TEXT: 'Text',
  CARD_IMAGE_AND_TEXT: 'Image and text under',
  CARD_IMAGE_OVERLAY: 'Image and text overlay',
  CARD_HERO: 'Image beside text',
  CAROUSEL: 'Slideshow',
  WIDGET: 'Resource Widget',
  WEB_CONTENT: 'Website',
  SCRIPT: 'Script',
  HTML_CONTENT: 'HTML / CSS',
  PAGES: 'Pages',
  ASSESSMENT_WIDGET: 'Assessment Widget',
};

export const CMS_COMPONENT_STYLE = {
  SIZE: {
    LARGE: 'large',
    SMALL: 'small',
    MEDIUM: 'medium',
    FULLWIDTH: 'fullWidth',
    FIXEDWIDTH: 'fixedWidth',
    BANNER: 'banner',
  },
  TEXT_ALIGN: {
    LEFT: 'left',
    RIGHT: 'right',
    CENTER: 'center',
  },
  TEXT_POSITION: {
    TOP: 'top',
    MIDDLE: 'middle',
    BOTTOM: 'bottom',
    LEFT: 'left',
    RIGHT: 'right',
    TOP_LEFT: 'topLeft',
    TOP_CENTER: 'topCenter',
    TOP_RIGHT: 'topRight',
    MIDDLE_LEFT: 'middleLeft',
    MIDDLE_CENTER: 'middleCenter',
    MIDDLE_RIGHT: 'middleRight',
    BOTTOM_LEFT: 'bottomLeft',
    BOTTOM_CENTER: 'bottomCenter',
    BOTTOM_RIGHT: 'bottomRight',
  },
  TEXT_SIZE: {
    LARGE: 'large',
    SMALL: 'small',
    MEDIUM: 'medium',
  },
  VIDEO_LAYOUT: {
    OVERLAY: 'overlay',
    UNDER: 'under',
    BESIDE: 'beside',
  },
};

export const CMS_COMPONENT_SIZE_OPTION = [
  { value: CMS_COMPONENT_STYLE.SIZE.FULLWIDTH, name: 'Large' },
  { value: CMS_COMPONENT_STYLE.SIZE.FIXEDWIDTH, name: 'Medium' },
  { value: CMS_COMPONENT_STYLE.SIZE.MEDIUM, name: 'Small' },
];

export const CARD_SIZE_OPTION = [
  { value: CMS_COMPONENT_STYLE.SIZE.FULLWIDTH, name: 'Large' },
  { value: CMS_COMPONENT_STYLE.SIZE.FIXEDWIDTH, name: 'Medium' },
  { value: CMS_COMPONENT_STYLE.SIZE.MEDIUM, name: 'Small' },
  { value: CMS_COMPONENT_STYLE.SIZE.BANNER, name: 'Banner' },
];

export const TEXT_ALIGNMENT_OPTION = [
  { value: CMS_COMPONENT_STYLE.TEXT_ALIGN.LEFT, icon: 'icon-paragraph-left' },
  { value: CMS_COMPONENT_STYLE.TEXT_ALIGN.CENTER, icon: 'icon-paragraph-center' },
  // { value: 'right', icon: 'icon-paragraph-right' },
];

export const CAROUSEL_ALIGNMENT_OPTION = [
  { value: CMS_COMPONENT_STYLE.TEXT_POSITION.TOP_LEFT, icon: 'icon-paragraph-left' },
  { value: CMS_COMPONENT_STYLE.TEXT_POSITION.TOP_CENTER, icon: 'icon-paragraph-center' },
  { value: CMS_COMPONENT_STYLE.TEXT_POSITION.TOP_RIGHT, icon: 'icon-paragraph-right' },
  { value: CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE_LEFT, icon: 'icon-paragraph-left' },
  { value: CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE_CENTER, icon: 'icon-paragraph-center' },
  { value: CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE_RIGHT, icon: 'icon-paragraph-right' },
  { value: CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM_LEFT, icon: 'icon-paragraph-left' },
  { value: CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM_CENTER, icon: 'icon-paragraph-center' },
  { value: CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM_RIGHT, icon: 'icon-paragraph-right' },
];

export const LAYOUT_COMPONENT = {
  POSITION: 'position',
  COLUMN: 'column',
  PAGES: 'pages',
  SHOW_HEADING: 'show_heading',
  SHOW_DESCRIPTION: 'show_description',
  SHOW_BUTTON: 'show_button',
  SHOW_SECONDARY_BUTTON: 'show_secondary_button',
  SIZE: 'size',
  ALIGNMENT: 'alignment',
  WIDGET: 'widget',
  SHOW_BACKGROUND_IMAGE: 'show_background_image',
  OVERLAY_OPACITY: 'overlay_opacity',
  IMAGE_RATIO: 'image_ratio',
  IMAGE_ALIGMENT: 'image_alignment',
  SLIDE: 'slide',
  TEXT_POSITION: 'text_position',
  SELECT_COLUMN: 'select_column',
  AUTOPLAY: 'autoplay',
  VIDEO_LAYOUT: 'video_layout',
  VIDEO_ALIGMENT: 'video_alignment',
  ASSESSMENTS: 'assessments',
};

export const LAYOUT_FORM_COMPONENT = {
  [CMS_COMPONENT_TYPE.VIDEO]: [
    {
      id: 1,
      component: LAYOUT_COMPONENT.POSITION,
    },
    {
      id: 2,
      component: LAYOUT_COMPONENT.COLUMN,
      settings: {
        options: [1, 2, 3],
        max: 3,
        min: 1,
      },
    },
    {
      id: 3,
      component: LAYOUT_COMPONENT.SELECT_COLUMN,
    },
    {
      id: 4,
      component: LAYOUT_COMPONENT.SHOW_HEADING,
    },
    {
      id: 5,
      component: LAYOUT_COMPONENT.SHOW_DESCRIPTION,
    },
    {
      id: 6,
      component: LAYOUT_COMPONENT.AUTOPLAY,
    },
    {
      id: 7,
      component: LAYOUT_COMPONENT.SIZE,
      settings: {
        label: i18n.t('Size'),
        options: CMS_COMPONENT_SIZE_OPTION,
      },
    },
    {
      id: 8,
      component: LAYOUT_COMPONENT.TEXT_POSITION,
      settings: {
        options: CAROUSEL_ALIGNMENT_OPTION,
      },
    },
    {
      id: 9,
      component: LAYOUT_COMPONENT.OVERLAY_OPACITY,
      settings: {
        options: [0.3, 0.2, 0.1, 0],
      },
    },
  ],
  [CMS_COMPONENT_TYPE.CARD_TEXT]: [
    {
      id: 1,
      component: LAYOUT_COMPONENT.POSITION,
    },
    {
      id: 2,
      component: LAYOUT_COMPONENT.COLUMN,
      settings: {
        options: [1, 2, 3, 4],
        max: 4,
        min: 1,
      },
    },
    {
      id: 3,
      component: LAYOUT_COMPONENT.SELECT_COLUMN,
    },
    {
      id: 4,
      component: LAYOUT_COMPONENT.SHOW_HEADING,
    },
    {
      id: 5,
      component: LAYOUT_COMPONENT.SHOW_DESCRIPTION,
    },
    {
      id: 6,
      component: LAYOUT_COMPONENT.SHOW_BUTTON,
    },
    {
      id: 7,
      component: LAYOUT_COMPONENT.SHOW_SECONDARY_BUTTON,
    },
    {
      id: 8,
      component: LAYOUT_COMPONENT.ALIGNMENT,
      settings: {
        options: TEXT_ALIGNMENT_OPTION,
      },
    },
  ],
  [CMS_COMPONENT_TYPE.CARD_IMAGE_AND_TEXT]: [
    {
      id: 1,
      component: LAYOUT_COMPONENT.POSITION,
    },
    {
      id: 2,
      component: LAYOUT_COMPONENT.COLUMN,
      settings: {
        options: [1, 2, 3, 4],
        max: 4,
        min: 1,
      },
    },
    {
      id: 3,
      component: LAYOUT_COMPONENT.SELECT_COLUMN,
    },
    {
      id: 4,
      component: LAYOUT_COMPONENT.SHOW_HEADING,
    },
    {
      id: 5,
      component: LAYOUT_COMPONENT.SHOW_DESCRIPTION,
    },
    {
      id: 6,
      component: LAYOUT_COMPONENT.SHOW_BUTTON,
    },
    {
      id: 7,
      component: LAYOUT_COMPONENT.SHOW_SECONDARY_BUTTON,
    },
    {
      id: 8,
      component: LAYOUT_COMPONENT.SIZE,
      settings: {
        label: i18n.t('Image size'),
        options: CMS_COMPONENT_SIZE_OPTION,
      },
    },
    {
      id: 9,
      component: LAYOUT_COMPONENT.ALIGNMENT,
      settings: {
        label: i18n.t('Text alignment'),
        options: TEXT_ALIGNMENT_OPTION,
      },
    },
  ],
  [CMS_COMPONENT_TYPE.CARD_IMAGE_OVERLAY]: [
    {
      id: 1,
      component: LAYOUT_COMPONENT.POSITION,
    },
    {
      id: 2,
      component: LAYOUT_COMPONENT.COLUMN,
      settings: {
        options: [1, 2, 3, 4],
        max: 4,
        min: 1,
      },
    },
    {
      id: 3,
      component: LAYOUT_COMPONENT.SELECT_COLUMN,
    },
    {
      id: 4,
      component: LAYOUT_COMPONENT.SHOW_HEADING,
    },
    {
      id: 5,
      component: LAYOUT_COMPONENT.SHOW_DESCRIPTION,
    },
    {
      id: 6,
      component: LAYOUT_COMPONENT.SHOW_BUTTON,
    },
    {
      id: 7,
      component: LAYOUT_COMPONENT.SHOW_SECONDARY_BUTTON,
    },
    {
      id: 8,
      component: LAYOUT_COMPONENT.SIZE,
      settings: {
        label: i18n.t('Size'),
        options: CARD_SIZE_OPTION,
      },
    },
    {
      id: 9,
      component: LAYOUT_COMPONENT.TEXT_POSITION,
      settings: {
        options: CAROUSEL_ALIGNMENT_OPTION,
      },
    },
    {
      id: 10,
      component: LAYOUT_COMPONENT.OVERLAY_OPACITY,
      settings: {
        options: [0.3, 0.2, 0.1, 0],
      },
    },
  ],
  [CMS_COMPONENT_TYPE.CARD_HERO]: [
    {
      id: 1,
      component: LAYOUT_COMPONENT.POSITION,
    },
    {
      id: 2,
      component: LAYOUT_COMPONENT.SHOW_HEADING,
    },
    {
      id: 3,
      component: LAYOUT_COMPONENT.SHOW_DESCRIPTION,
    },
    {
      id: 4,
      component: LAYOUT_COMPONENT.SHOW_BUTTON,
    },
    {
      id: 5,
      component: LAYOUT_COMPONENT.SHOW_SECONDARY_BUTTON,
    },
    {
      id: 6,
      component: LAYOUT_COMPONENT.IMAGE_RATIO,
      settings: {
        label: i18n.t('Image ratio'),
        options: ['1:1', '3:2', '2:3'],
      },
    },
    {
      id: 7,
      component: LAYOUT_COMPONENT.IMAGE_ALIGMENT,
      settings: {
        label: i18n.t('Image aligment'),
        options: ['left', 'right'],
      },
    },
  ],
  [CMS_COMPONENT_TYPE.WIDGET]: [
    {
      id: 1,
      component: LAYOUT_COMPONENT.POSITION,
    },
    {
      id: 2,
      component: LAYOUT_COMPONENT.WIDGET,
    },
    {
      id: 3,
      component: LAYOUT_COMPONENT.SHOW_BACKGROUND_IMAGE,
    },
  ],
  [CMS_COMPONENT_TYPE.CAROUSEL]: [
    {
      id: 1,
      component: LAYOUT_COMPONENT.POSITION,
    },
    {
      id: 2,
      component: LAYOUT_COMPONENT.SLIDE,
      settings: {
        options: [2, 3, 4, 5],
        max: 5,
        min: 2,
      },
    },
    {
      id: 3,
      component: LAYOUT_COMPONENT.IMAGE_RATIO,
      settings: {
        options: ['16:9', '2:1'],
      },
    },
    {
      id: 4,
      component: LAYOUT_COMPONENT.SELECT_COLUMN,
    },
    {
      id: 5,
      component: LAYOUT_COMPONENT.SHOW_HEADING,
    },
    {
      id: 6,
      component: LAYOUT_COMPONENT.SHOW_DESCRIPTION,
    },
    {
      id: 7,
      component: LAYOUT_COMPONENT.SHOW_BUTTON,
    },
    {
      id: 8,
      component: LAYOUT_COMPONENT.SHOW_SECONDARY_BUTTON,
    },
    {
      id: 9,
      component: LAYOUT_COMPONENT.TEXT_POSITION,
      settings: {
        options: CAROUSEL_ALIGNMENT_OPTION,
      },
    },
    {
      id: 10,
      component: LAYOUT_COMPONENT.OVERLAY_OPACITY,
      settings: {
        options: [0.3, 0.2, 0.1, 0],
      },
    },
  ],
  [CMS_COMPONENT_TYPE.WEB_CONTENT]: [
    {
      id: 1,
      component: LAYOUT_COMPONENT.POSITION,
    },
    {
      id: 2,
      component: LAYOUT_COMPONENT.COLUMN,
      settings: {
        options: [1, 2, 3],
        max: 3,
        min: 1,
        hide: true,
      },
    },
    {
      id: 3,
      component: LAYOUT_COMPONENT.SELECT_COLUMN,
      settings: {
        hide: true,
      },
    },
    {
      id: 4,
      component: LAYOUT_COMPONENT.SIZE,
      settings: {
        label: i18n.t('Window size'),
        options: CMS_COMPONENT_SIZE_OPTION,
      },
    },
  ],
  [CMS_COMPONENT_TYPE.SCRIPT]: [
    {
      id: 1,
      component: LAYOUT_COMPONENT.POSITION,
    },
  ],
  [CMS_COMPONENT_TYPE.HTML_CONTENT]: [
    {
      id: 1,
      component: LAYOUT_COMPONENT.POSITION,
    },
  ],
  [CMS_COMPONENT_TYPE.PAGES]: [
    {
      id: 1,
      component: LAYOUT_COMPONENT.POSITION,
    },
    {
      id: 2,
      component: LAYOUT_COMPONENT.PAGES,
      settings: {
        max: 7,
        min: 1,
      },
    },
  ],
  [CMS_COMPONENT_TYPE.ASSESSMENT_WIDGET]: [
    {
      id: 1,
      component: LAYOUT_COMPONENT.POSITION,
    },
    {
      id: 2,
      component: LAYOUT_COMPONENT.ASSESSMENTS,
      settings: {
        max: 10,
        min: 0,
      },
    },
  ],
};

export const CONTENT_COMPONENT = {
  HEADING: 'heading',
  HEADING_LINK: 'heading_link',
  DESCRIPTION: 'description',
  DESCRIPTION_LINK: 'description_link',
  BUTTON_LABEL: 'button_label',
  BUTTON_LINK: 'button_link',
  SECONDARY_BUTTON_LABEL: 'secondary_button_label',
  SECONDARY_BUTTON_LINK: 'secondary_button_link',
  FILE_UPLOAD: 'file_upload',
  VIDEO_UPLOAD: 'video_upload',
  IMAGE_UPLOAD: 'image_upload',
  WIDGET: 'widget',
  URL_LINK: 'url_link',
  SHARE_LINK: 'share_link',
  SCRIPT: 'script',
  HTML_CONTENT: 'html_content',
  PAGE_HEADING: 'page_heading',
  PAGE_SHARE_LINK: 'page_share_link',
  ASSESSMENTS_WIDGET_TITLE: 'assessments_widget_title',
};

export const CONTENT_FORM_COMPONENT = {
  [CMS_COMPONENT_TYPE.VIDEO]: [
    {
      id: 1,
      component: CONTENT_COMPONENT.SHARE_LINK,
    },
    {
      id: 2,
      component: CONTENT_COMPONENT.VIDEO_UPLOAD,
    },
    {
      id: 3,
      component: CONTENT_COMPONENT.IMAGE_UPLOAD,
    },
    {
      id: 4,
      component: CONTENT_COMPONENT.HEADING,
    },
    {
      id: 5,
      component: CONTENT_COMPONENT.DESCRIPTION,
    },
  ],
  [CMS_COMPONENT_TYPE.CARD_TEXT]: [
    {
      id: 1,
      component: CONTENT_COMPONENT.SHARE_LINK,
    },
    {
      id: 2,
      component: CONTENT_COMPONENT.HEADING,
    },
    {
      id: 3,
      component: CONTENT_COMPONENT.HEADING_LINK,
    },
    {
      id: 4,
      component: CONTENT_COMPONENT.DESCRIPTION,
    },
    {
      id: 5,
      component: CONTENT_COMPONENT.DESCRIPTION_LINK,
    },
    {
      id: 6,
      component: CONTENT_COMPONENT.BUTTON_LABEL,
    },
    {
      id: 7,
      component: CONTENT_COMPONENT.BUTTON_LINK,
    },
    {
      id: 8,
      component: CONTENT_COMPONENT.SECONDARY_BUTTON_LABEL,
    },
    {
      id: 9,
      component: CONTENT_COMPONENT.SECONDARY_BUTTON_LINK,
    },
  ],
  [CMS_COMPONENT_TYPE.CARD_IMAGE_AND_TEXT]: [
    {
      id: 1,
      component: CONTENT_COMPONENT.SHARE_LINK,
    },
    {
      id: 2,
      component: CONTENT_COMPONENT.FILE_UPLOAD,
    },
    {
      id: 3,
      component: CONTENT_COMPONENT.HEADING,
    },
    {
      id: 4,
      component: CONTENT_COMPONENT.HEADING_LINK,
    },
    {
      id: 5,
      component: CONTENT_COMPONENT.DESCRIPTION,
    },
    {
      id: 6,
      component: CONTENT_COMPONENT.DESCRIPTION_LINK,
    },
    {
      id: 7,
      component: CONTENT_COMPONENT.BUTTON_LABEL,
    },
    {
      id: 8,
      component: CONTENT_COMPONENT.BUTTON_LINK,
    },
    {
      id: 9,
      component: CONTENT_COMPONENT.SECONDARY_BUTTON_LABEL,
    },
    {
      id: 10,
      component: CONTENT_COMPONENT.SECONDARY_BUTTON_LINK,
    },
  ],
  [CMS_COMPONENT_TYPE.CARD_IMAGE_OVERLAY]: [
    {
      id: 1,
      component: CONTENT_COMPONENT.SHARE_LINK,
    },
    {
      id: 2,
      component: CONTENT_COMPONENT.IMAGE_UPLOAD, // FILE_UPLOAD -> IMAGE_UPLOAD, spc-3706
    },
    {
      id: 3,
      component: CONTENT_COMPONENT.HEADING,
    },
    {
      id: 4,
      component: CONTENT_COMPONENT.HEADING_LINK,
    },
    {
      id: 5,
      component: CONTENT_COMPONENT.DESCRIPTION,
    },
    {
      id: 6,
      component: CONTENT_COMPONENT.DESCRIPTION_LINK,
    },
    {
      id: 7,
      component: CONTENT_COMPONENT.BUTTON_LABEL,
    },
    {
      id: 8,
      component: CONTENT_COMPONENT.BUTTON_LINK,
    },
    {
      id: 9,
      component: CONTENT_COMPONENT.SECONDARY_BUTTON_LABEL,
    },
    {
      id: 10,
      component: CONTENT_COMPONENT.SECONDARY_BUTTON_LINK,
    },
  ],
  [CMS_COMPONENT_TYPE.CARD_HERO]: [
    {
      id: 1,
      component: CONTENT_COMPONENT.SHARE_LINK,
    },
    {
      id: 2,
      component: CONTENT_COMPONENT.FILE_UPLOAD,
    },
    {
      id: 3,
      component: CONTENT_COMPONENT.HEADING,
    },
    {
      id: 4,
      component: CONTENT_COMPONENT.HEADING_LINK,
    },
    {
      id: 5,
      component: CONTENT_COMPONENT.DESCRIPTION,
    },
    {
      id: 6,
      component: CONTENT_COMPONENT.DESCRIPTION_LINK,
    },
    {
      id: 7,
      component: CONTENT_COMPONENT.BUTTON_LABEL,
    },
    {
      id: 8,
      component: CONTENT_COMPONENT.BUTTON_LINK,
    },
    {
      id: 9,
      component: CONTENT_COMPONENT.SECONDARY_BUTTON_LABEL,
    },
    {
      id: 10,
      component: CONTENT_COMPONENT.SECONDARY_BUTTON_LINK,
    },
  ],
  [CMS_COMPONENT_TYPE.WIDGET]: [
    {
      id: 1,
      component: CONTENT_COMPONENT.SHARE_LINK,
    },
    {
      id: 2,
      component: CONTENT_COMPONENT.IMAGE_UPLOAD,
    },
    {
      id: 3,
      component: CONTENT_COMPONENT.WIDGET,
    },
  ],
  [CMS_COMPONENT_TYPE.CAROUSEL]: [
    {
      id: 1,
      component: CONTENT_COMPONENT.SHARE_LINK,
    },
    {
      id: 2,
      component: CONTENT_COMPONENT.IMAGE_UPLOAD,
    },
    {
      id: 3,
      component: CONTENT_COMPONENT.HEADING,
    },
    {
      id: 4,
      component: CONTENT_COMPONENT.DESCRIPTION,
    },
    {
      id: 5,
      component: CONTENT_COMPONENT.BUTTON_LABEL,
    },
    {
      id: 6,
      component: CONTENT_COMPONENT.BUTTON_LINK,
    },
    {
      id: 7,
      component: CONTENT_COMPONENT.SECONDARY_BUTTON_LABEL,
    },
    {
      id: 8,
      component: CONTENT_COMPONENT.SECONDARY_BUTTON_LINK,
    },
  ],
  [CMS_COMPONENT_TYPE.WEB_CONTENT]: [
    {
      id: 1,
      component: CONTENT_COMPONENT.SHARE_LINK,
    },
    {
      id: 2,
      component: CONTENT_COMPONENT.URL_LINK,
    },
  ],
  [CMS_COMPONENT_TYPE.SCRIPT]: [
    {
      id: 1,
      component: CONTENT_COMPONENT.SCRIPT,
    },
  ],
  [CMS_COMPONENT_TYPE.HTML_CONTENT]: [
    {
      id: 1,
      component: CONTENT_COMPONENT.SHARE_LINK,
    },
    {
      id: 2,
      component: CONTENT_COMPONENT.HTML_CONTENT,
    },
  ],
  [CMS_COMPONENT_TYPE.PAGES]: [
    {
      id: 1,
      component: CONTENT_COMPONENT.PAGE_HEADING,
    },
    {
      id: 2,
      component: CONTENT_COMPONENT.PAGE_SHARE_LINK,
    },
  ],
  [CMS_COMPONENT_TYPE.ASSESSMENT_WIDGET]: [
    {
      id: 1,
      component: CONTENT_COMPONENT.SHARE_LINK,
    },
    {
      id: 1,
      component: CONTENT_COMPONENT.ASSESSMENTS_WIDGET_TITLE,
    },
  ],
};

// DEFAULT VALUES
const DEFAULT_WIDGET_BACKGROUND_IMAGE = -1;

const DEFAULT_HEADING = i18n.t('Add your heading here');

const DEFAULT_DESCRIPTION = i18n.t(
  'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur'
);

const DEFAULT_ASSESSMENTS_WIDGET_TITLE = i18n.t('Assessments');

const DEFAULT_BUTTON_LABEL = i18n.t('GET STARTED');
const DEFAULT_SECONDARY_BUTTON_LABEL = i18n.t('LEARN MORE');

const NEW_VIDEO_COMPONENT = {
  type: CMS_COMPONENT_TYPE.VIDEO,
  heading: DEFAULT_HEADING,
  description: DEFAULT_DESCRIPTION,
  // backgroundImageUrl: -1,
  // videoUrl: -1,
  textPosition: CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE_CENTER,
  textSize: CMS_COMPONENT_STYLE.TEXT_SIZE.LARGE,
  opacity: 0.3,
  id: newGuid(),
  autoplay: true,
};

const NEW_CARD_TEXT_COMPONENT = {
  type: CMS_COMPONENT_TYPE.CARD_TEXT,
  heading: DEFAULT_HEADING,
  description: DEFAULT_DESCRIPTION,
  buttonLabel: DEFAULT_BUTTON_LABEL,
  secondaryButtonLabel: DEFAULT_SECONDARY_BUTTON_LABEL,
  // buttonLink: '',
  textAlign: CMS_COMPONENT_STYLE.TEXT_ALIGN.CENTER,
  textSize: CMS_COMPONENT_STYLE.TEXT_SIZE.LARGE,
  id: newGuid(),
};

const NEW_CARD_IMAGE_AND_TEXT_COMPONENT = {
  type: CMS_COMPONENT_TYPE.CARD_IMAGE_AND_TEXT,
  heading: DEFAULT_HEADING,
  description: DEFAULT_DESCRIPTION,
  // backgroundImageUrl: -1,
  buttonLabel: DEFAULT_BUTTON_LABEL,
  secondaryButtonLabel: DEFAULT_SECONDARY_BUTTON_LABEL,
  // buttonLink: '',
  textPosition: CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM,
  textAlign: CMS_COMPONENT_STYLE.TEXT_ALIGN.LEFT,
  textSize: CMS_COMPONENT_STYLE.TEXT_SIZE.LARGE,
  id: newGuid(),
};

const NEW_CARD_IMAGE_OVERLAY_COMPONENT = {
  type: CMS_COMPONENT_TYPE.CARD_IMAGE_OVERLAY,
  heading: DEFAULT_HEADING,
  description: DEFAULT_DESCRIPTION,
  // backgroundImageUrl: -1,
  buttonLabel: DEFAULT_BUTTON_LABEL,
  secondaryButtonLabel: DEFAULT_SECONDARY_BUTTON_LABEL,
  // buttonLink: '',
  textPosition: CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE_CENTER,
  textAlign: CMS_COMPONENT_STYLE.TEXT_ALIGN.CENTER,
  textSize: CMS_COMPONENT_STYLE.TEXT_SIZE.LARGE,
  opacity: 0.3,
  id: newGuid(),
};

const NEW_CARD_HERO_COMPONENT = {
  type: CMS_COMPONENT_TYPE.CARD_HERO,
  heading: DEFAULT_HEADING,
  description: DEFAULT_DESCRIPTION,
  // backgroundImageUrl: -1,
  textPosition: CMS_COMPONENT_STYLE.TEXT_POSITION.RIGHT,
  textAlign: CMS_COMPONENT_STYLE.TEXT_ALIGN.LEFT,
  textSize: CMS_COMPONENT_STYLE.TEXT_SIZE.MEDIUM,
  imageRatio: '1:1',
  buttonLabel: DEFAULT_BUTTON_LABEL,
  secondaryButtonLabel: DEFAULT_SECONDARY_BUTTON_LABEL,
  // buttonLink: '',
  id: newGuid(),
};

const NEW_CAROUSEL_COMPONENT = {
  type: CMS_COMPONENT_TYPE.CAROUSEL,
  items: [
    {
      heading: DEFAULT_HEADING,
      description: DEFAULT_DESCRIPTION,
      buttonLabel: DEFAULT_BUTTON_LABEL,
      secondaryButtonLabel: DEFAULT_SECONDARY_BUTTON_LABEL,
      textSize: CMS_COMPONENT_STYLE.TEXT_SIZE.LARGE,
      textPosition: CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE_CENTER,
      // backgroundImageUrl: -1,
      imageRatio: '16:9',
      id: newGuid(),
      opacity: 0.3,
    },
    {
      heading: DEFAULT_HEADING,
      description: DEFAULT_DESCRIPTION,
      buttonLabel: DEFAULT_BUTTON_LABEL,
      secondaryButtonLabel: DEFAULT_SECONDARY_BUTTON_LABEL,
      textSize: CMS_COMPONENT_STYLE.TEXT_SIZE.LARGE,
      textPosition: CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE_CENTER,
      // backgroundImageUrl: -1,
      imageRatio: '16:9',
      id: newGuid(),
      opacity: 0.3,
    },
  ],
  id: newGuid(),
};

const NEW_WIDGET_COMPONENT = {
  type: CMS_COMPONENT_TYPE.WIDGET,
  size: CMS_COMPONENT_STYLE.SIZE.MEDIUM,
  widgetName: WIDGET_NAME[CMS_WIDGET_SUBTYPE.RESOURCES],
  settings: {
    subType: CMS_WIDGET_SUBTYPE.RESOURCES,
  },
  id: newGuid(),
};

const NEW_WEB_CONTENT_COMPONENT = {
  type: CMS_COMPONENT_TYPE.WEB_CONTENT,
  id: newGuid(),
};

const NEW_SCRIPT_COMPONENT = {
  type: CMS_COMPONENT_TYPE.SCRIPT,
  size: CMS_COMPONENT_STYLE.SIZE.MEDIUM,
  id: newGuid(),
};

const NEW_HTML_CONTENT_COMPONENT = {
  type: CMS_COMPONENT_TYPE.HTML_CONTENT,
  id: newGuid(),
};

const NEW_ASSESSMENT_WIDGET_COMPONENT = {
  type: CMS_COMPONENT_TYPE.ASSESSMENT_WIDGET,
  id: newGuid(),
  widgetName: DEFAULT_ASSESSMENTS_WIDGET_TITLE,
};

export const CMS_DEFAULT_VALUE = {
  WIDGET_BACKGROUND_IMAGE: DEFAULT_WIDGET_BACKGROUND_IMAGE,
  HEADING: DEFAULT_HEADING,
  DESCRIPTION: DEFAULT_DESCRIPTION,
  BUTTON_LABEL: DEFAULT_BUTTON_LABEL,
  SECONDARY_BUTTON_LABEL: DEFAULT_SECONDARY_BUTTON_LABEL,
  VIDEO_COMPONENT: NEW_VIDEO_COMPONENT,
  CARD_TEXT_COMPONENT: NEW_CARD_TEXT_COMPONENT,
  CARD_IMAGE_AND_TEXT_COMPONENT: NEW_CARD_IMAGE_AND_TEXT_COMPONENT,
  CARD_IMAGE_OVERLAY_COMPONENT: NEW_CARD_IMAGE_OVERLAY_COMPONENT,
  CARD_HERO_COMPONENT: NEW_CARD_HERO_COMPONENT,
  CAROUSEL_COMPONENT: NEW_CAROUSEL_COMPONENT,
  WIDGET_COMPONENT: NEW_WIDGET_COMPONENT,
  WEB_CONTENT_COMPONENT: NEW_WEB_CONTENT_COMPONENT,
  SCRIPT_COMPONENT: NEW_SCRIPT_COMPONENT,
  HTML_CONTENT_COMPONENT: NEW_HTML_CONTENT_COMPONENT,
  ASSESSMENT_WIDGET_COMPONENT: NEW_ASSESSMENT_WIDGET_COMPONENT,
};

export const TEXT_TYPE_VALUES = {
  LARGE_TITLE: {
    text: 'LARGE HEADING',
    size: 'lager',
  },
  MEDIUM_TITLE: {
    text: 'MEDIUM HEADING',
    size: 'medium',
  },
  SMALL_TITLE: {
    text: 'SMALL HEADING',
    size: 'small',
  },
  PARAGRAPH: {
    text: 'This is a description.',
    size: 'paragraph',
  },
};

export const CMS_DEFAULT_DESIGN_SETTING = {
  LARGE_HEADING_FONT_SIZE: '85px',
  LARGE_HEADING_FONT_FAMILY: 'F37Judge-MediumCondensed',
  LARGE_HEADING_COLOR: '#000000',
  MEDIUM_HEADING_FONT_SIZE: '60px',
  MEDIUM_HEADING_FONT_FAMILY: 'F37Judge-MediumCondensed',
  MEDIUM_HEADING_COLOR: '#000000',
  SMALL_HEADING_FONT_SIZE: '28px',
  SMALL_HEADING_FONT_FAMILY: 'F37Judge-MediumCondensed',
  SMALL_HEADING_COLOR: '#000000',
  DESCRIPTION_FONT_SIZE: '15px',
  DESCRIPTION_FONT_FAMILY: 'MediumLLWeb-Regular',
  DESCRIPTION_COLOR: '#000000',
  OVERLAY_LARGE_HEADING_FONT_SIZE: '85px',
  OVERLAY_LARGE_HEADING_FONT_FAMILY: 'F37Judge-MediumCondensed',
  OVERLAY_LARGE_HEADING_COLOR: '#ffffff',
  OVERLAY_MEDIUM_HEADING_FONT_SIZE: '60px',
  OVERLAY_MEDIUM_HEADING_FONT_FAMILY: 'F37Judge-MediumCondensed',
  OVERLAY_MEDIUM_HEADING_COLOR: '#ffffff',
  OVERLAY_SMALL_HEADING_FONT_SIZE: '28px',
  OVERLAY_SMALL_HEADING_FONT_FAMILY: 'F37Judge-MediumCondensed',
  OVERLAY_SMALL_HEADING_COLOR: '#ffffff',
  OVERLAY_DESCRIPTION_FONT_SIZE: '15px',
  OVERLAY_DESCRIPTION_FONT_FAMILY: 'MediumLLWeb-Regular',
  OVERLAY_DESCRIPTION_COLOR: '#ffffff',
  BUTTON_LABEL_FONT_SIZE: '15px',
  BUTTON_LABEL_FONT_FAMILY: 'MediumLLWeb-Bold',
  BUTTON_LABEL_COLOR: '#ffffff',
  BUTTON_BACKGROUND_COLOR: '#000000',
  SECONDARY_BUTTON_LABEL_FONT_SIZE: '15px',
  SECONDARY_BUTTON_LABEL_FONT_FAMILY: 'MediumLLWeb-Bold',
  SECONDARY_BUTTON_LABEL_COLOR: '#000000',
  SECONDARY_BUTTON_BACKGROUND_COLOR: '#F4F7F8',
  BLOCK_BACKGROUND_COLOR: '#ffffff',
  TOP_PADDING_SIZE: '50px',
  BOTTOM_PADDING_SIZE: '50px',
  LEFT_PADDING_SIZE: '80px',
  RIGHT_PADDING_SIZE: '80px',
  UNDERLINE_COLOR: '#1FDA8B',
  HEADING_STATE_FONT_FAMILY: 'MediumLLWeb-Regular',
  HEADING_STATE_COLOR: '#000000',
  HEADING_STATE_FONT_SIZE: '15px',
  HEADING_SELECTED_FONT_FAMILY: 'MediumLLWeb-Bold',
  PAGE_NAVIGATION_TOP_PADDING: '16px',
  PAGE_NAVIGATION_BOTTOM_PADDING: '8px',
};

export const BlockActionType = {
  Add: 0,
  Update: 1,
  Delete: 2,
  // Move: 3,
};
