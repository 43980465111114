/* eslint-disable react/jsx-props-no-spreading */
/* eslint max-lines: 0 */
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  getUrlParameter,
  openWindow,
  removeURLParams,
  sendNotification,
} from '../../commons/utils';
import I18n from '../../i18n';
import { useMaterialsContext } from './MaterialsContextProvider';
import {
  updateRuntimeMaterial,
  createRuntimeDirectory,
  updateRuntimeDirectory,
  getSpaceDirectoryByType,
  createRootDirectory,
  addRuntimeMaterials,
} from '../../resources/ResourceServices';
import {
  // formatBytes,
  getFilteredDirectory,
  isExternalLinkNotVideo,
} from '../../commons/ResourceUtils';
import Loading from '../../components/Loading';
import useExtraDirectories from '../../commons/useExtraDirectories';
import { DirectoryType, ResourceInteractionType } from '../../app/appConstants';
import { useMultipleSelectResourcesContext } from '../../components/Material/MultipleSelectResourcesContextProvider';
import FolderNotFoundDialog from '../../components/FolderNotFoundDialog';
import { CacheKeys, clearCache } from '../../app/queryCache';
import { useMountEffect } from '../../commons/CustomHooks';
import useNotifications from '../../commons/Resources/useNotifications';
import useShareLink from '../../commons/Resources/useShareLink';
import ErrorMessage from '../../components/ErrorMessage';
import './MaterialsWidget.scss';
import '../../cms/cmsStyle.scss';
import AddResourceButton from './AddResourceButton';
import SelectResourcesActions from './SelectResourcesActions';
import '../../components/Tabs.scss';
import ActionButtons from '../../commons/Resources/ActionButtons';
import FileForm from './FileForm';
import FolderForm from './FolderForm';
import { createResourceInteraction } from '../../commons/CommonServices';
import useDownloadResource from '../../resources/Download/useDownloadResource';
import UploadFolderForm from './UploadFolderForm';
import ResourcesDisplayView from '../../components/Material/ResourcesDisplayView/ResourcesDisplayView';
import OfficialButton from '../../components/OfficialButtons';
import ExternalDirectory from './ExternalDirectory';
import { useAppContext } from '../../components/AppProvider';
import eventBus, { EVENT_BUS } from '../../commons/EventBus';
import { SERVICE_PROVIDER } from '../../integrations/integrationConstants';
import SearchingFolder from './SearchingFolder';
import ResourcesViewControls from '../../components/ResourcesView/ResourcesViewControls';
import { useResourcesViewContext } from '../../components/ResourcesView/ResourcesViewContextProvider';
import FileSettingsForm from './FileSettingsForm';
import useUnmountEffect from '../../commons/useUnmountEffect';
import useSpaceMaterialsQuery from './useSpaceMaterialsQuery';
import { useSpaceContext } from '../SpaceContext';

const MaterialsWidget = React.memo((props) => {
  const { isMobile, isMobilePortrait } = useAppContext();
  const { isFileUploadEnabled } = useSpaceContext();
  // const { checkEnabledFeatures } = useFeatureFlagsContext();
  // const [enabledUploadFileGR] = checkEnabledFeatures([
  //   FeatureFlagsType.CREATE_SPACE, // enabled when account is not user partner
  // ]);
  const {
    spaceId,
    onResourceView,
    hideAddButton,
    onResourceDownload,
    isWebinar,
    spacePrivacy,
    isPublicSpace,
    isGlobalResourcesEnabled,
    space,
  } = props;

  const {
    directories,
    flatDirectories,
    reset,
    onReConversion,
    processingResources,
    currentUser,
    openForm,
    handleOpenForm,
    handleCloseForm,
    selectedFileId,
    selectedDirectoryId,
    parentFolderId,
    setParentFolderId,
    setFlatDirectories,
  } = useMaterialsContext();

  const getMaterialsQuery = useSpaceMaterialsQuery(spaceId, false, true);
  const refetchMaterials = getMaterialsQuery.refetch;

  const { rootShareDirectory, setRootShareDirectory, getSpaceExtraDirectoriesQuery } =
    useExtraDirectories(flatDirectories, spaceId, false);

  const {
    isSelectedResourceIds,
    selectedFileIds,
    selectedSmartFiles,
    selectedSmartFolders,
    selectedFolderIds,
    handleOnSelectAll,
    resetSelected,
    canRemoveResources,
    canMoveResources,
    canShowDownloadButton,
    isCheckedAll,
    selectMonoResource,
  } = useMultipleSelectResourcesContext();

  const { handleShareLinkFileClick, handleShareLinkFolderClick } = useShareLink(space);

  const { handleClickDownloadDirectory, handleClickDownloadBulkResources } = useDownloadResource();

  const [updateAnchorEl, setUpdateAnchorEl] = useState(null);
  const [selectedFolderId, setSelectedFolderId] = useState(() => {
    // if (selectedDirectoryId) {
    //   return {
    //     id: selectedDirectoryId,
    //     type: 'resource',
    //   };
    // }
    if (props.selectedFolderId) {
      return {
        id: props.selectedFolderId,
        type: 'resource',
      };
    }
    return null;
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [processing, setProcessing] = useState(false);

  const { searchText, setSearchText } = useResourcesViewContext();
  const [selectedFolderForActionMenu, setSelectedFolderForActionMenu] = useState(null);
  const [newResourceId, setNewResourceId] = useState(null);

  const rootFolder = rootShareDirectory;

  const folderFormRef = useRef();
  const fileFormRef = useRef();
  const uploadFolderFormRef = useRef();
  const selectActionsRef = useRef();
  const sharingFolderId = getUrlParameter('drId', null, true);
  const searchInputRef = useRef();
  const settingFormRef = useRef();

  const selectedFolder = useMemo(() => {
    if (parentFolderId) {
      return flatDirectories.find((item) => item.id === parentFolderId);
    }
    // console.log('### selectedFolder', selectedFolderId, selectedDirectoryId);
    if (!selectedFolderId || !flatDirectories) {
      return null;
    }

    const foundFolder = flatDirectories.find(
      (item) => item.id === selectedFolderId.id && item.directoryType !== DirectoryType.Extra
    );
    if (selectedFolderId.isExternal && !foundFolder) {
      return selectedFolderId;
    }
    return foundFolder;
  }, [parentFolderId, selectedFolderId, flatDirectories]);

  const isRootFolder =
    !selectedFolder || (selectedFolder && selectedFolder.directoryType === DirectoryType.Extra);

  useEffect(() => {
    if (!selectedFileId) {
      // setSelectedFile(null);
      return;
    }
    // console.log('### useEffect', selectedFolder, selectedDirectoryId, selectedFileId);
    let folder = selectedFolder || flatDirectories?.find((item) => item.id === selectedDirectoryId);
    if (folder) {
      let foundMaterial = folder.spaceResources.find((f) => f.id === selectedFileId);
      if (!foundMaterial && selectedDirectoryId) {
        folder = flatDirectories?.find((item) => item.id === selectedDirectoryId);
        foundMaterial = folder.spaceResources.find((f) => f.id === selectedFileId);
      }
      if (foundMaterial) {
        setSelectedFile(foundMaterial);
        setSelectedFolderId({
          id: foundMaterial.directoryId,
          type: 'resource',
        });
      }
    }
  }, [flatDirectories, selectedFileId, selectedDirectoryId, selectedFolder]);

  useEffect(() => {
    if (selectedFolderForActionMenu?.id) {
      return;
    }
    if (!selectedDirectoryId) {
      setSelectedFolderForActionMenu({
        spaceId: spaceId,
      });
    } else {
      setSelectedFolderForActionMenu(() => {
        return flatDirectories.find((f) => f.id === selectedDirectoryId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatDirectories, selectedDirectoryId]);

  const checkSelectedFolderNotFound = useMemo(() => {
    if (flatDirectories?.length > 0 && sharingFolderId) {
      const defaultFolder = flatDirectories.find((f) => f.id === sharingFolderId);
      if (!defaultFolder) {
        return true;
      }
    }
    return false;
  }, [flatDirectories, sharingFolderId]);

  useEffect(() => {
    if (checkSelectedFolderNotFound && sharingFolderId) {
      FolderNotFoundDialog.show(spaceId, sharingFolderId).then(() => removeURLParams(['drId']));
    }
  }, [spaceId, checkSelectedFolderNotFound, sharingFolderId]);

  const { updateTotalNotifications, updateFolderNotifications, updateResourceNotifications } =
    useNotifications(spaceId);

  useUnmountEffect(() => {
    if (openForm) {
      handleCloseForm();
    }
    if (sharingFolderId) {
      removeURLParams(['drId']);
    }
    reset();
  });

  const handleFocusSearchInput = useCallback(() => {
    searchInputRef.current?.focus();
  }, []);

  useEffect(() => {
    const unsubscribe = eventBus.subscribe(EVENT_BUS.AutoFocusSearch, handleFocusSearchInput);
    return () => {
      unsubscribe();
    };
  }, [handleFocusSearchInput]);

  useMountEffect(() => {
    updateTotalNotifications(true);
  });

  const handleDirectoryClick = useCallback(
    (directory) => {
      resetSelected();
      setParentFolderId(null);
      setSearchText('');
      if (directory.isExternal && directory.isSearchData) {
        setSelectedFolderId({
          id: directory.id,
          spaceId: spaceId,
          spaceFolderId: directory.spaceFolderId,
          isExternal: true,
          externalId: directory.externalId,
          externalFolderSettings: directory?.externalFolderSettings,
          spaceResources: [],
          subFolders: [],
          parentFolderId: directory.parentFolderId || directory.parentId,
          name: directory.name,
        });
      } else {
        setSelectedFolderId({ id: directory.id, type: 'resource' });
      }
      updateFolderNotifications(directory);
    },
    [resetSelected, spaceId, setParentFolderId, updateFolderNotifications, setSearchText]
  );

  const handleMoreButtonClick = useCallback((event, selectingItem) => {
    setUpdateAnchorEl(event.target);
    const isFolder = !!selectingItem.spaceResources;
    if (isFolder) {
      setSelectedFolderForActionMenu(selectingItem);
      setSelectedFile(null);
    } else {
      setSelectedFolderForActionMenu(null);
      setSelectedFile(selectingItem);
    }
  }, []);

  // console.log('selectedFolderForActionMenu?.id', selectedFolderForActionMenu);

  const handleMaterialClick = useCallback(
    (clickingMaterial) => {
      if (!clickingMaterial) return;
      if (clickingMaterial) {
        if (
          isExternalLinkNotVideo(clickingMaterial) &&
          !clickingMaterial.externalDocumentSettings
        ) {
          createResourceInteraction(clickingMaterial.resourceId, {
            materialId: clickingMaterial.id,
            interactionType: ResourceInteractionType.Click,
          });
          openWindow(clickingMaterial.src, '_blank');
        } else {
          if (onResourceView && typeof onResourceView === 'function') {
            onResourceView(clickingMaterial);
          }
        }
        updateResourceNotifications(clickingMaterial);
      }
    },
    [onResourceView, updateResourceNotifications]
  );

  function handleOnClickSelectAll(isSelectedAll) {
    let files = [];
    let subFolders = [];
    if (isSelectedAll) {
      if (!selectedFolder) {
        const mainFolders = getFilteredDirectory(directories);
        files = rootFolder?.spaceResources || [];
        subFolders = mainFolders;
      } else {
        subFolders = selectedFolder.subFolders || [];
        files = selectedFolder.spaceResources || [];
      }
    }
    handleOnSelectAll(subFolders, files);
  }

  async function creatingRootFolder() {
    try {
      const folder = await createRootDirectory(spaceId);
      setRootShareDirectory(folder);
      return folder;
    } catch (error) {
      console.log('error: ', error);
    }
    return null;
  }

  async function getRootFolder() {
    try {
      const extraFolder = await getSpaceDirectoryByType(spaceId, DirectoryType.Extra, false);
      let folder = extraFolder;
      if (folder) {
        setRootShareDirectory(folder);
        return folder.id;
      } else {
        folder = await creatingRootFolder();
        refetchMaterials();
        return folder.id;
      }
    } catch (error) {
      return false;
    }
  }

  // Folder functions
  async function handleNewFolderButtonClick() {
    // console.log('### handleNewFolderButtonClick', selectedFolder, currentUser);
    setSelectedFile(null);
    resetSelected();
    setParentFolderId(null);
    setSelectedFolderForActionMenu({});
    handleOpenForm('folder');
  }

  async function handleNewFileButtonClick() {
    handleOpenForm('file');
    resetSelected();
    setSelectedFile({});
  }

  function handleUploadFolder() {
    handleOpenForm('uploadFolder');
  }

  async function handleAddOrUpdateFileButtonClick(files) {
    // console.log('### handleAddOrUpdateFileButtonClick: ', selectedFile, files);
    if (!files?.length) return false;
    let folderId = selectedFolder?.id || rootFolder?.id;
    if (!folderId) {
      if (currentUser?.isHost) {
        folderId = await getRootFolder(false);
        if (!folderId) {
          return false;
        }
      } else {
        sendNotification('Something went wrong, please contact to host', { type: 'error' });
        handleCloseForm();
        return false;
      }
    }

    try {
      // only create space files here
      setProcessing(true);

      let shouldClearSelectedFolderId = false;
      if (selectedFile?.id) {
        if (files.length > 0) {
          const selectedResource = files[0];
          const materialInfo = {
            ...selectedFile,
            name: selectedResource.name || selectedResource.fileName,
            description: selectedResource.description || '',
            fileDescription: selectedResource.description || '',
            isDownloadable: selectedResource.isDownloadable,
          };
          await updateRuntimeMaterial(spaceId, folderId, selectedFile.id, materialInfo);
          sendNotification(I18n.t('Updated successfully'), { type: 'success' });
          if (openForm === 'fileSetting' || openForm === 'file') {
            const foundFolder = flatDirectories?.find((d) => d.id === selectedFile.directoryId);
            if (foundFolder?.directoryType === DirectoryType.Extra) {
              shouldClearSelectedFolderId = true;
            }
          }
        }
      } else {
        const addingMaterials = files.map((selectedResource) => {
          const materialInfo = {
            ...selectedResource,
            type: selectedResource.resourceType,
            order: 0,
            displayName: selectedResource.name,
          };
          return materialInfo;
        });
        const payload = { materials: addingMaterials };
        const resp = await addRuntimeMaterials(spaceId, folderId, payload);
        if (resp?.length > 0) {
          const firstItem = resp[0];
          if (firstItem) {
            console.log('firstItem: ', firstItem);
            setNewResourceId(firstItem.materialId);
          }
        }
        let successMsg = I18n.t('Nice! Your files have been added!');
        if (files.length === 1) {
          successMsg = I18n.t('Nice! Your file has been added!');
        }
        sendNotification(successMsg, { type: 'success' });
      }
      handleCloseForm();
      setSelectedFile(null);
      setProcessing(false);
      clearCache([CacheKeys.getSpaceExtraDirectory], false);
      if (shouldClearSelectedFolderId) setSelectedFolderId(null);
      return true;
    } catch (e) {
      sendNotification(e.message, { type: 'error' });
      setProcessing(false);
      return false;
    }
  }

  const handleAddOrUpdateFolderButtonClick = useCallback(
    async (folder) => {
      // console.log('handleAddOrUpdateFolderButtonClick: ', data);
      if (!folder) return false;
      try {
        // only create space folders here
        // meeting folders still are created automatically when creating a meeting
        // then we can change meeting folders' names here
        setProcessing(true);

        if (folder.id) {
          // only update the name of folder, not spaceResources or others.
          const data = {
            name: folder.name,
            description: folder.description,
          };
          await updateRuntimeDirectory(spaceId, folder.id, data);
          sendNotification(I18n.t('Updated successfully'), { type: 'success' });
          setSelectedFolderForActionMenu(null);
          handleCloseForm(null);
        } else {
          const folderData = {
            ...folder,
            externalFolder: {
              provider: SERVICE_PROVIDER.NORMAL_FOLDER,
            },
          };
          const resp = await createRuntimeDirectory(spaceId, folderData);
          setNewResourceId(resp.id);
          sendNotification(I18n.t('Created successfully'), { type: 'success' });
          setSelectedFolderForActionMenu(null);
          handleCloseForm(null);
        }

        setProcessing(false);
        return true;
      } catch (e) {
        sendNotification(e.message, { type: 'error' });
        setProcessing(false);
        return false;
      }
    },
    [handleCloseForm, spaceId]
  );

  const handleAddedFolder = useCallback(() => {
    handleCloseForm(null);
    refetchMaterials();
  }, [handleCloseForm, refetchMaterials]);

  if (flatDirectories === null) {
    return <Loading style={{ display: 'flex', justifyContent: 'center' }} />;
  }

  async function onClickAddNewResource() {
    resetSelected();
    setParentFolderId(null);
  }

  function handleCloseFolderActionMenu(isResetFolder = true) {
    setUpdateAnchorEl(null);
    if (isResetFolder) {
      setSelectedFolderForActionMenu(null);
    }
  }

  function handleCloseFileActionMenu(isResetFile = true) {
    setUpdateAnchorEl(null);
    if (isResetFile) {
      setSelectedFile(null);
    }
  }

  function handleGoBackDirectory() {
    resetSelected();
    setSelectedFolderId(() => {
      if (!selectedFolder || selectedFolder.parentFolderId === null) {
        return null;
      }
      const parentFolder = flatDirectories.find(
        (item) => item.id === selectedFolder.parentFolderId
      );
      return { id: parentFolder?.id, type: 'resource' };
    });
  }

  async function handleOnClose() {
    if (!openForm) {
      setParentFolderId(null);
      handleCloseForm();
      props.onClose();
      return;
    }
    if (openForm) {
      let isCloseForm = true;
      if (openForm === 'file') {
        isCloseForm = await fileFormRef.current.handleCloseForm();
      } else if (openForm === 'folder') {
        isCloseForm = await folderFormRef.current.handleCloseForm();
      } else if (openForm === 'uploadFolder') {
        isCloseForm = await uploadFolderFormRef.current.handleCloseForm();
      }
      if (isCloseForm) {
        setSelectedFile(null);
        setSelectedFolderForActionMenu(null);
        handleCloseForm();
        props.onClose();
      }
    }
  }

  async function handleGoBackWhenFormIsOpened() {
    let isCloseForm = true;
    if (openForm === 'file') {
      isCloseForm = await fileFormRef.current.handleCloseForm();
    } else if (openForm === 'folder') {
      isCloseForm = await folderFormRef.current.handleCloseForm();
    } else if (openForm === 'uploadFolder') {
      isCloseForm = await uploadFolderFormRef.current.handleCloseForm();
    } else if (openForm === 'fileSetting') {
      isCloseForm = await settingFormRef.current.handleCloseForm();
    }
    if (isCloseForm) {
      setSelectedFile(null);
      setParentFolderId(null);
      setSelectedFolderForActionMenu(null);
      handleCloseForm();
      if (openForm === 'fileSetting' || openForm === 'file') {
        const foundFolder = flatDirectories?.find((d) => d.id === selectedFolderId?.id);
        if (foundFolder?.directoryType === DirectoryType.Extra) {
          setSelectedFolderId(null);
        }
      }
    }
  }

  async function handleOnChangeText(value) {
    setSearchText(value);
  }

  function handleMoveResources(data) {
    selectMonoResource(data);
    setTimeout(() => {
      selectActionsRef.current.showMoveDialog();
    }, 200);
  }
  function handleToggleCanDownload(canDownload) {
    setSelectedFile({ ...selectedFile, isDownloadable: canDownload });
  }

  function renderFolder(renderingFolder) {
    if (searchText?.length > 0) {
      return (
        <SearchingFolder
          searchText={searchText}
          renderingFolder={renderingFolder}
          isWebinar={isWebinar}
          handleMoreButtonClick={handleMoreButtonClick}
          handleDirectoryClick={handleDirectoryClick}
          handleMaterialClick={handleMaterialClick}
          newResourceId={newResourceId}
          selectedFile={selectedFile}
          isPortal={false}
        />
      );
    }

    if (renderingFolder.isExternal === true) {
      return (
        <ExternalDirectory
          key={renderingFolder.id}
          isPortal={false}
          setFlatDirectories={setFlatDirectories}
          directory={renderingFolder}
          isWebinar={isWebinar}
          selectedFile={selectedFile}
          handleMoreButtonClick={handleMoreButtonClick}
          handleDirectoryClick={handleDirectoryClick}
          handleMaterialClick={handleMaterialClick}
          isMobile={isMobilePortrait}
        />
      );
    }

    return (
      <ResourcesDisplayView
        data={renderingFolder}
        isWebinar={isWebinar}
        onClickFolder={handleDirectoryClick}
        onClickFile={handleMaterialClick}
        onClickMoreButton={handleMoreButtonClick}
        processingResources={processingResources}
        newResourceId={newResourceId}
        isMobile={isMobilePortrait}
        selectedFile={selectedFile}
        spaceFolderId={renderingFolder.id}
        isPortal={false}
      />
    );
  }

  function renderItems() {
    const folder = isRootFolder && rootFolder ? rootFolder : selectedFolder;
    if (!folder) {
      return null;
    }

    if (isRootFolder && rootFolder) {
      const mainFolders = getFilteredDirectory(directories);
      folder.subFolders = mainFolders;
    }
    const noData = folder?.subFolders?.length === 0 && folder?.spaceResources?.length === 0;
    const isFetchingExternalResources = folder?.isExternal && !folder.fetchedExternalResources;

    if ((!folder.isExternal || !isFetchingExternalResources) && noData) {
      return <ErrorMessage message={I18n.t('No materials available.')} />;
    }

    const isInsideGoogleDriveFolder =
      folder && folder.externalFolderSettings?.provider === SERVICE_PROVIDER.GOOGLE_DRIVE;

    return (
      <>
        {!isFetchingExternalResources && (
          <ResourcesViewControls
            autoFocus={!isMobile}
            searchInputRef={searchInputRef}
            searchText={searchText}
            onSearchChange={handleOnChangeText}
            disabledSearchMessage={
              isInsideGoogleDriveFolder
                ? I18n.t('It is not supported to search in Google Drive folders.')
                : null
            }
          />
        )}
        {renderFolder(folder)}
      </>
    );
  }

  function renderActionButtons() {
    if (!selectedFile && !selectedFolderForActionMenu) {
      return null;
    }
    const isHost = currentUser?.isHost;

    return (
      <ActionButtons
        currentUserInfoId={currentUser.userInfoId}
        spaceId={spaceId}
        flatDirectories={flatDirectories}
        selectedFile={selectedFile}
        selectedFolderForActionMenu={selectedFolderForActionMenu}
        isHost={isHost}
        updateAnchorEl={updateAnchorEl}
        handleOpenFileFormAction={handleOpenForm}
        handleCloseFileActionMenu={handleCloseFileActionMenu}
        handleResourceDownload={onResourceDownload}
        handleResourceView={onResourceView}
        handleShareLinkFileClick={handleShareLinkFileClick}
        handleOpenFormAction={handleOpenForm}
        handleCloseFolderActionMenu={handleCloseFolderActionMenu}
        handleShareLinkFolderClick={handleShareLinkFolderClick}
        handleDownloadFolder={handleClickDownloadDirectory}
        handleRetryConversion={onReConversion}
        handleMoveResources={!isMobilePortrait ? handleMoveResources : null}
        handleToggleCanDownload={handleToggleCanDownload}
      />
    );
  }

  function renderRightButton() {
    if (isSelectedResourceIds) {
      return (
        <OfficialButton
          variant="rectangle-secondary"
          label={I18n.t('Cancel')}
          onClick={resetSelected}
        />
      );
    }
    if (!isFileUploadEnabled) {
      return null;
    }
    if (hideAddButton || isMobile || selectedFolder?.isExternal || !!openForm) {
      return null;
    }

    return (
      <AddResourceButton
        onNewFileButtonClick={handleNewFileButtonClick}
        onNewFolderButtonClick={handleNewFolderButtonClick}
        onClick={onClickAddNewResource}
        onUploadFolderButtonClick={currentUser?.isHost ? handleUploadFolder : null}
      />
    );
  }

  function handleOnClickBackButton() {
    setSearchText(null);
    setSelectedFile(null);
    setParentFolderId(null);
    handleGoBackDirectory();
  }

  const getTitleHeader = () => {
    let title = '';
    switch (openForm) {
      case 'file':
      case 'folder':
      case 'uploadFolder':
        if (!selectedFolder && !selectedFolderForActionMenu?.id) {
          title = 'Resources';
        } else {
          if (openForm === 'file') {
            title = selectedFile?.id ? 'Rename resource' : selectedFolder?.name || I18n.t('Shared');
          } else if (openForm === 'folder') {
            title = selectedFolderForActionMenu?.id
              ? I18n.t('Rename folder')
              : selectedFolder?.name || I18n.t('Shared');
          } else {
            title = selectedFolder?.name || I18n.t('Shared');
          }
        }
        break;
      case 'fileSetting':
        title = 'Settings';
        break;
      default:
        title = selectedFolder?.name || I18n.t('Resources');
        break;
    }
    return title;
  };

  const renderLeftHeader = () => {
    const title = getTitleHeader();
    const selectedItems =
      (selectedFileIds?.length ?? 0) +
      (selectedFolderIds?.length ?? 0) +
      (selectedSmartFolders?.length ?? 0) +
      (selectedSmartFiles?.length ?? 0);

    return (
      <div className="left-section alignItemsCenter">
        {(openForm || selectedFolder) && (
          <ArrowBackIosIcon
            fontSize="small"
            style={{ marginRight: 8, cursor: 'pointer' }}
            onClick={openForm ? handleGoBackWhenFormIsOpened : handleOnClickBackButton}
          />
        )}

        <div className="side-panel-title-bold side-panel-material" title={title}>
          {title}
          {isSelectedResourceIds && (
            <div className="side-panel-subtitle">
              {selectedItems} {I18n.t('selected')}
            </div>
          )}
        </div>
      </div>
    );
  };

  function renderHeader() {
    const showCloseButton = props.onClose && !isSelectedResourceIds;
    return (
      <div
        className={`side-panel-title runtime-materials-header  ${showCloseButton ? '' : 'pr-24'}`}
      >
        {renderLeftHeader()}
        {<div className="side-panel-header-right">{renderRightButton()}</div>}
        {showCloseButton && (
          <div className="close-button-container">
            <IconButton className="close-icon" onClick={handleOnClose}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
      </div>
    );
  }

  function renderBody() {
    if (getMaterialsQuery.isLoading || getSpaceExtraDirectoriesQuery.isLoading) {
      return (
        <div className="loading-container">
          <Loading />
        </div>
      );
    }

    if (!openForm) {
      return (
        <Grid item xs={12} sm={12} md={12} className="material-container styled-scrollbar">
          {renderActionButtons()}
          {renderItems()}
        </Grid>
      );
    }

    return (
      <>
        {openForm === 'file' && (
          <Grid item xs={12} sm={12} md={12} className="material-container material-form">
            <FileForm
              ref={fileFormRef}
              isPortal={false}
              key={selectedFile?.id}
              file={selectedFile}
              spacePrivacy={spacePrivacy}
              spaceId={spaceId}
              processing={processing}
              onSave={handleAddOrUpdateFileButtonClick}
              isGlobalResourcesEnabled={isGlobalResourcesEnabled}
              isAdmin={currentUser?.isHost}
              isPartner={currentUser?.isPartner}
            />
          </Grid>
        )}
        {openForm === 'fileSetting' && (
          <div className="material-container material-form">
            <FileSettingsForm
              ref={settingFormRef}
              key={selectedFile?.id}
              file={selectedFile}
              processing={processing}
              onSave={handleAddOrUpdateFileButtonClick}
            />
          </div>
        )}
        {openForm === 'folder' && (
          <div className="material-container">
            <FolderForm
              ref={folderFormRef}
              key={selectedFolderForActionMenu?.id}
              data={selectedFolderForActionMenu}
              onSave={handleAddOrUpdateFolderButtonClick}
              processing={processing}
              parentFolderId={selectedFolder?.id || null}
            />
          </div>
        )}
        {openForm === 'uploadFolder' && (
          <Grid item xs={12} sm={12} md={12} className="material-container material-form">
            <UploadFolderForm
              ref={uploadFolderFormRef}
              onClose={handleCloseForm}
              isPortal={false}
              key={selectedFile?.id}
              file={selectedFile}
              spacePrivacy={spacePrivacy}
              spaceId={spaceId}
              parentFolderId={selectedFolder?.id || null}
              isGlobalResourcesEnabled={isGlobalResourcesEnabled}
              isAdmin={currentUser?.isHost}
              isPartner={currentUser?.isPartner}
              onSave={handleAddedFolder}
            />
          </Grid>
        )}
      </>
    );
  }

  function renderSelectResourcesActions() {
    if (openForm || !isSelectedResourceIds) return null;
    const isSmartSubFolder = selectedFolder?.isExternal && selectedFolder?.externalId;

    return (
      <SelectResourcesActions
        ref={selectActionsRef}
        variant="white"
        isPortal={false}
        resetSelected={resetSelected}
        spaceId={spaceId}
        selectedSmartFiles={selectedSmartFiles}
        selectedSmartFolders={selectedSmartFolders}
        selectedFolderIds={selectedFolderIds}
        selectedFileIds={selectedFileIds}
        onSelectAll={handleOnClickSelectAll}
        canRemoveResources={canRemoveResources && !isSmartSubFolder}
        canShowDownloadButton={canShowDownloadButton}
        canMoveResources={canMoveResources && !isSmartSubFolder}
        defaultCheckValue={isCheckedAll(selectedFolder, rootFolder, directories)}
        isPublicSpace={isPublicSpace}
        defaultActiveTab={0}
        onDownload={handleClickDownloadBulkResources}
      />
    );
  }

  return (
    <Grid className="runtime-materials" container spacing={0}>
      {renderHeader()}
      {renderSelectResourcesActions()}
      {renderBody()}
    </Grid>
  );
});

MaterialsWidget.propTypes = {
  hideAddButton: PropTypes.bool,
  spaceId: PropTypes.string,
  onResourceView: PropTypes.func,
  onResourceDownload: PropTypes.func,
  selectedFolderId: PropTypes.number,
  isWebinar: PropTypes.bool,
  onClose: PropTypes.func,
  spacePrivacy: PropTypes.string,
  isPublicSpace: PropTypes.bool,
  isGlobalResourcesEnabled: PropTypes.bool,
  space: PropTypes.instanceOf(Object),
};

MaterialsWidget.displayName = 'MaterialsWidget';

export default MaterialsWidget;
