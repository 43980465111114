// /* eslint react/jsx-props-no-spreading: 0 */
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PeopleIcon from '@mui/icons-material/People';

import React from 'react';
import UserAvatar from '../../components/UserAvatar';
import I18n from '../../i18n';
import LightTooltip from '../../components/LightTooltip';
import './UserManagementMobile.scss';
import { IconItem } from './UserManagementTable';
import RoleUserSelect from './RoleUserSelect';
import { formatTimeLastActive, getTimeActive } from './function';
import { OrganizationUserRole, OrganizationUserState } from '../../app/appConstants';
import { Tag } from '../../components/Tags';
import NoDaTaBox from '../../components/NoDataBox';
import { isOrgRole } from '../../commons/utils';

const UserManagementTable = (props) => {
  const {
    data,
    handleFormEditUser,
    handleResentEmail,
    handleDeleteUser,
    handleChangeRole,
    isActivatedTab,
  } = props;

  const sortData = data.sort((a, b) => {
    return a.role - b.role || a.fullName.localeCompare(b.fullName);
  });

  if (!data?.length) {
    return <NoDaTaBox icon={<PeopleIcon />} title="No users to display" />;
  }

  return (
    <div className="list-user-management">
      {sortData.map((p) => {
        return (
          <div key={p.id} className="user-card">
            <UserAvatar fullName={p?.fullName} />
            <div className="user-card-right">
              <div>
                <div className="details-name">{p.fullName}</div>
                <div className="details-email">{p.contactEmail}</div>
                {isActivatedTab ? (
                  <div>{getTimeActive(p.lastActive, p.isCurrentUser)}</div>
                ) : (
                  <div>
                    {p.state === OrganizationUserState.Created ? (
                      <Tag color="#000" backgroundColor="#DBE1E3">
                        Not invited
                      </Tag>
                    ) : (
                      <Tag color="#000" backgroundColor="#FAADAD">
                        pending activation
                      </Tag>
                    )}
                    {p.lastInvited && (
                      <div className="text-desc">
                        {I18n.t('Last invited')} {formatTimeLastActive(p.lastInvited)}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="user-card-actions">
                <RoleUserSelect
                  disabled={p.isCurrentUser || isOrgRole(p.role, OrganizationUserRole.Partner)}
                  defaultValue={p.role}
                  isEdit
                  onChange={({ target: input }) => handleChangeRole(input.value, p)}
                />
                <div className="user-action">
                  <LightTooltip title={I18n.t('Edit')} placement="bottom-start">
                    <IconButton onClick={() => handleFormEditUser(p)}>
                      <EditIcon />
                    </IconButton>
                  </LightTooltip>
                  {!isActivatedTab && (
                    <IconItem
                      onClick={() => handleResentEmail(p)}
                      icon="icon-send-email"
                      title="Resend invitation"
                    />
                  )}

                  {!p.isCurrentUser && (
                    <LightTooltip title={I18n.t('Delete user')} placement="bottom-start">
                      <IconButton onClick={() => handleDeleteUser(p)}>
                        <DeleteIcon />
                      </IconButton>
                    </LightTooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

UserManagementTable.propTypes = {
  data: PropTypes.instanceOf(Array),
  handleChangeRole: PropTypes.func,
  handleFormEditUser: PropTypes.func,
  handleResentEmail: PropTypes.func,
  handleDeleteUser: PropTypes.func,
  isActivatedTab: PropTypes.bool,
};

export default UserManagementTable;
