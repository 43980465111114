import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { Grid } from '@mui/material';
import _ from 'lodash';
import memoizeOne from 'memoize-one';
import _groupBy from 'lodash/groupBy';
import _filter from 'lodash/filter';
import cloneDeep from 'lodash/cloneDeep';
import { useDebouncedCallback } from 'use-debounce';
import { sendNotification } from '../../commons/utils';
import queryCache, { CacheKeys } from '../../app/queryCache';
import useUnmountEffect from '../../commons/useUnmountEffect';

import './Notifications.scss';
import SettingForm from './SettingForm';
import { fetchNotificationSetting, updateNotificationRules } from './service';
import { notificationTitles, labelSetting } from './constant';
import i18n from '../../i18n';
import { mapTodoActivities } from './function';
import Loading from '../../components/Loading';
import currentOrganization from '../../commons/CurrentOrganization';

const groupSettings = memoizeOne((data) => {
  let newData = [...data];
  if (currentOrganization.isPartner()) {
    newData = _filter(
      newData,
      (g) => !['Participants', 'ParticipantInteraction'].includes(g.context)
    );
    newData = mapTodoActivities(newData);

    const group = _groupBy(newData, 'context');
    return group;
  }
  // Sort ParticipantInteraction in the top of list;
  newData = _.sortBy(newData, (g) => g.context !== 'ParticipantInteraction');

  newData = mapTodoActivities(newData);

  const group = _groupBy(newData, 'context');

  const participantJoinFirstTime = group.Participants.filter(
    (participant) => participant.event === 'EVENT_PARTICIPANT_JOINED_SPACE_FIRST_TIME'
  );
  _.remove(
    group.Participants,
    (participant) => participant.event === 'EVENT_PARTICIPANT_JOINED_SPACE_FIRST_TIME'
  );
  group.ParticipantInteraction.unshift(participantJoinFirstTime[0]);

  return group;
});

const NotificationsForYou = () => {
  const [settings, setSettings] = useState({});
  const isPartner = currentOrganization.isPartner();

  const notificationQuery = useQuery({
    queryKey: [CacheKeys.fetchNotificationSettings],
    queryFn: async () => {
      const resp = await fetchNotificationSetting();
      return resp;
    },
    retry: 1,
    retryDelay: () => 5000,
  });

  useEffect(() => {
    if (notificationQuery?.data) {
      const notification = groupSettings(notificationQuery?.data.ruleSettings);
      setSettings(notification);
    }
  }, [notificationQuery?.data]);

  async function handleOnchangeSetting(setting) {
    try {
      queryCache.setQueryData([CacheKeys.fetchNotificationSettings], (oldData) => {
        const newSettings = cloneDeep(oldData?.ruleSettings);
        const foundIndex = newSettings.findIndex(
          (item) => item.context === setting.context && item.event === setting.event
        );
        newSettings[foundIndex] = setting;
        if (setting.event === 'EVENT_ACTIVITY_UPDATED') {
          const index = newSettings.findIndex(
            (item) => item.context === setting.context && item.event === 'EVENT_ACTIVITY_ASSIGED'
          );
          newSettings[index].rules = setting.rules;
        }
        return {
          ...oldData,
          ruleSettings: newSettings,
        };
      });
      await updateNotificationRules(setting);
      if (setting.synTodoEvent) {
        updateNotificationRules({
          ...setting,
          event: setting.synTodoEvent,
        });
      }
    } catch (e) {
      sendNotification(e.message, { type: 'error' });
    }
  }

  const handleOnChangeDebounced = useDebouncedCallback(
    (setting) => handleOnchangeSetting(setting),
    0
  );

  useUnmountEffect(() => {
    handleOnChangeDebounced.flush();
  });

  const renderSettingBox = (value) => {
    const info = notificationTitles[value];
    if (!info) {
      return null;
    }
    return (
      <>
        {settings[value].map((setting, index) => (
          <SettingForm
            type={`${info.title}-for-you-tab`}
            key={index}
            name={value.type}
            defaultValue={setting}
            onChange={handleOnChangeDebounced}
            labelSetting={labelSetting}
            contextSetting="you"
          />
        ))}
      </>
    );
  };

  const renderSettingForYou = () => {
    if (notificationQuery.isLoading) {
      <Loading />;
    }

    if (!settings) {
      return '';
    }

    return (
      <div className="user-limit-content styled-scrollbar">
        <div className="message-info">
          <div className="title">{i18n.t('Email notifications')}</div>
          <span className="message">
            {isPartner
              ? i18n.t('Choose which notifications you want to get, and how often.')
              : i18n.t(
                  'Choose which notifications you want to get, and how often. Any changes will apply to all existing and future spaces that you are the host of.'
                )}
          </span>
        </div>
        <div className="setting-boxs">
          <Grid container className="setting-row header" spacing={2}>
            <Grid xs={8} md={8} item>
              {i18n.t('Engagement')}
            </Grid>
            <Grid xs={2} md={3} item>
              {i18n.t('Frequency')}
            </Grid>
            <Grid xs={2} md={1} item />
          </Grid>
          {Object.keys(settings).map((item) => renderSettingBox(item))}
        </div>
      </div>
    );
  };

  return renderSettingForYou();
};

NotificationsForYou.propTypes = {
  handleDrawerToggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default NotificationsForYou;
