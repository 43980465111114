import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import SidePanel from '../components/designer/SidePanel';
import Header from '../components/designer/Header';
import ThemePreview from '../ThemePreview';
import './SpaceTemplateDesigner.scss';
import { useThemeSettingsContext } from '../components/context/ThemeSettingsContextProvider';
import { useSpaceContext } from '../../spaces/SpaceContext';
import SpaceFooter from '../../spaces/SpaceFooter';
import ResourcePreviewDialog, {
  getResourcePreviewContext,
} from '../../resources/ResourcePreviewDialog';
import { getUrlParameter, sendNotification, sleep } from '../../commons/utils';
import spaceUser from '../../spaces/spaceUser';
import currentOrganization from '../../commons/CurrentOrganization';
// import i18n from '../../i18n';
import MaterialsContextProvider from '../../spaces/Materials/MaterialsContextProvider';
import IntegrationRuntimeContextProvider from '../../spaces/integrations/IntegrationRuntimeContextProvider';
import LightTooltip from '../../components/LightTooltip';
import BeforeLeaveHandler from './BeforeLeaveHandler';
import { useAppContext } from '../../components/AppProvider';
import { CMS_COMPONENT_TYPE } from '../cmsConstants';
import SavingSnackbar from '../../components/ProcessingSnackbar';
import i18n from '../../i18n';
import queryCache, { CacheKeys } from '../../app/queryCache';
import AssessmentPopup from '../../spaces/assessment/AssessmentPopup';

function SpaceTemplateDesigner() {
  const {
    selectedBlock,
    setSelectedBlock,
    openSidePanel,
    setOpenSidePanel,
    spaceId,
    isTemplate,
    saveSpaceCMSDesign,
    space,
    setOpenUnsplashPreview,
    setWorkingData,
    workingData,
    handleScrollingToBlock,
    setSiblingBlock,
    siblingBlock,
    handleCopyBlock,
    handlePasteBlock,
    hasChanges,
    globalTemplateHasChanges,
    isSaving,
  } = useThemeSettingsContext();
  // console.log('###spaceId', spaceId);
  const { isPublicSpace, isPreview, onChangeNavigation, pagesBlock, currentPage } =
    useSpaceContext();

  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const isPreviewMobileView = getUrlParameter('view', null, false) === 'mobile';

  const [isMobileMode, setIsMobileMode] = useState(isPreviewMobileView || false);
  const { isMobile } = useAppContext();

  const navigate = useNavigate();
  // const currentPageId = currentPage?.id;

  const isAdmin = currentOrganization?.isAdmin();

  // only admin or host can pin/unpin folder/tab
  // only designer page can pin/unpin folder/tab
  const canPinResource = (isAdmin || spaceUser.isHost()) && !isPreview;
  // const preventReload = useRef(false);

  function handleAddButtonClick() {
    setOpenUnsplashPreview(false);
    setSelectedBlock(null);
    setSiblingBlock(null);
    setOpenSidePanel(true);
  }

  const handleAddButtonOnFloatingMenuClick = useCallback(
    (block) => {
      setOpenUnsplashPreview(false);
      setSiblingBlock(block);
      setSelectedBlock(null);
      setOpenSidePanel(true);
    },
    [setOpenSidePanel, setOpenUnsplashPreview, setSelectedBlock, setSiblingBlock]
  );

  function handleCloseButtonClick() {
    setOpenSidePanel(false);
    setSelectedBlock(null);
    setSiblingBlock(null);
  }

  async function handlePublishButtonClick() {
    // just save and go to space.
    try {
      // console.log(
      //   '### 51 handlePublishButtonClick',
      //   enabledCMSGlobalTemplate,
      //   appliedGlobalTemplate
      // );

      await saveSpaceCMSDesign();

      queryCache.removeQueries([CacheKeys.fetchSpaceAcessInfo, space.urlSlug ?? space.id]);
      if (!isTemplate) {
        queryCache.removeQueries({ queryKey: [CacheKeys.fetchSpacesList], exact: false });
      } else {
        queryCache.refetchQueries({ queryKey: [CacheKeys.fetchSpaceTemplates], exact: false });
      }
      await sleep(1);
      navigate(spaceUser.getSpaceUrl(), { isTemplate });
    } catch (e) {
      sendNotification(e.message, { type: 'error' });
    }
  }

  const handleOnSelectBlock = useCallback(
    (block) => {
      if (isPreview) {
        return;
      }
      setSiblingBlock(null);
      setSelectedBlock(block);
      setOpenSidePanel(true);
      setOpenUnsplashPreview(false);
      setWorkingData({ contentType: block?.items?.[0]?.type, currentTab: 0 });
      handleScrollingToBlock(block);
    },
    [
      handleScrollingToBlock,
      isPreview,
      setOpenSidePanel,
      setOpenUnsplashPreview,
      setSelectedBlock,
      setSiblingBlock,
      setWorkingData,
    ]
  );

  const handleOnSelectColumn = useCallback(
    (block, index) => {
      if (isPreview) {
        return;
      }
      if (selectedBlock) {
        if (index >= 0) {
          setOpenSidePanel(true);
          setWorkingData({ contentType: block.items[0].type, currentTab: index });
        }
      }
    },
    [isPreview, selectedBlock, setOpenSidePanel, setWorkingData]
  );

  function onChangeMobileMode() {
    setIsMobileMode(!isMobileMode);
  }

  function renderResourceViewer() {
    if (!selectedMaterial) return null;

    return (
      <ResourcePreviewDialog
        context={getResourcePreviewContext('space')}
        material={selectedMaterial}
        onClose={() => setSelectedMaterial(null)}
      />
    );
  }

  function handleOnCopy(event) {
    const selection = document.getSelection();
    // console.log('### 254 handleOnCopy 1:', event, selection?.type);
    if (selection?.type === 'Range') return;
    event.preventDefault();
    const type = selectedBlock?.items?.[0]?.type;
    const allowCopyPasteBlock = !selectedBlock.isPagesBlock;

    if (
      !allowCopyPasteBlock ||
      type === CMS_COMPONENT_TYPE.SCRIPT ||
      type === CMS_COMPONENT_TYPE.WIDGET
    )
      return;
    handleCopyBlock();
  }

  function handleOnKeyDown(event) {
    // console.log('### 404 handleOnKeyDown 2:', event, selectedBlock);
    event.preventDefault();
    const ctrlDown = event.ctrlKey || event.metaKey;
    // const ctrlKey = 17;
    // const cmdKey = 91;
    const vKey = 86;
    const cKey = 67;

    const type = selectedBlock?.items?.[0]?.type;
    if (type === CMS_COMPONENT_TYPE.CAROUSEL) {
      /* NOTE:
       * for carousel only since it cannot listen onCopy event on react-slick library.
       * select the area outside of <Slider> component, can listen onCopy event.
       */
      if (ctrlDown && event.keyCode === cKey) {
        // console.log('### catch Ctrl+C');
        handleCopyBlock();
      }
    } else {
      // console.log('### 404 handleOnKeyDown 2:', event, selectedBlock);
    }

    if (ctrlDown && event.keyCode === vKey) {
      // console.log('### catch Ctrl+V');
      handlePasteBlock();
    }
  }

  function handleOnPaste(event) {
    // console.log('### 404 handleOnPaste 3:', event, selectedBlock);
    event.preventDefault();
    handlePasteBlock();
  }

  function renderAssessmentPopup() {
    return <AssessmentPopup isMobile={isMobileMode} />;
  }

  // if (isFetchingToken) {
  //   return null;
  // }

  const shouldUseBeforeLeaveHandler = !isPreview && (hasChanges || globalTemplateHasChanges);

  const className = `
    ${isPreview ? 'preview-mode' : ''}
    ${isPreviewMobileView ? 'preview-mode-mobile' : ''}`;

  return (
    <IntegrationRuntimeContextProvider>
      <MaterialsContextProvider spaceId={spaceId} isPortal>
        <SavingSnackbar
          show={isSaving}
          message={i18n.t('Automatically saving your space details')}
        />
        {renderResourceViewer()}
        <div className={`space-template-designer ${className}`}>
          <Header
            isPreviewMode={isPreview}
            space={space}
            isMobile={isMobile}
            // onSaveButtonClick={handleSaveButtonClick}
            onPublishButtonClick={handlePublishButtonClick}
            isSidePanelOpened={openSidePanel}
            isMobileMode={isMobileMode}
            onChangeMobileMode={onChangeMobileMode}
          />
          <div
            className="space-template-designer-body"
            onCopy={handleOnCopy}
            onKeyDown={handleOnKeyDown}
            onPaste={handleOnPaste}
          >
            {!isPreview && (
              <>
                {!openSidePanel && (
                  <LightTooltip title="Add block" placement="right">
                    <IconButton className="add-block-btn" onClick={handleAddButtonClick}>
                      <AddIcon className="add-icon" />
                    </IconButton>
                  </LightTooltip>
                )}
                <SidePanel
                  open={openSidePanel}
                  selectedBlock={selectedBlock}
                  siblingBlock={siblingBlock}
                  setSiblingBlock={setSiblingBlock}
                  onClose={handleCloseButtonClick}
                  spaceId={spaceId}
                  isAdmin={isAdmin}
                />
              </>
            )}
            <ThemePreview
              key={currentPage?.id}
              blocks={currentPage?.blocks}
              onSelectBlock={handleOnSelectBlock}
              onAddButtonOnFloatingMenuClick={handleAddButtonOnFloatingMenuClick}
              selectedBlock={selectedBlock}
              spaceId={spaceId}
              onSelectColumn={handleOnSelectColumn}
              workingData={workingData}
              pagesBlock={pagesBlock}
              currentPage={currentPage}
              hideRightContent
              hideHeader
              hideSpaceName
              hideShareLink
              hideDivider
              hideTemplateTag
              canPinResource={canPinResource}
              isPortal
              isEdit
              isMobile={isMobile}
              isPreviewMode={isPreview}
              isMobileMode={isMobileMode}
              onChangeNavigation={onChangeNavigation}
            />
            {renderAssessmentPopup()}
          </div>
        </div>
        {isPreview && (
          <SpaceFooter
            isMobileMode={isMobileMode}
            isDemoView
            position={isPreviewMobileView ? 'bottom' : 'left'}
            className="cms-preview-footer"
            isPublicSpace={isPublicSpace}
          />
        )}
        {shouldUseBeforeLeaveHandler && <BeforeLeaveHandler />}
      </MaterialsContextProvider>
    </IntegrationRuntimeContextProvider>
  );
}

SpaceTemplateDesigner.propTypes = {};

export default SpaceTemplateDesigner;
