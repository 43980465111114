import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import { fetchFolderDetails } from '../../resources/ResourceServices';
import Loading from '../../components/Loading';
import i18n from '../../i18n';
import BlockUI from '../../components/BlockUI';
import ResourcesDisplayView from '../../components/Material/ResourcesDisplayView/ResourcesDisplayView';
import { useMaterialsContext } from './MaterialsContextProvider';
import ErrorMessage from '../../components/ErrorMessage';

const LoadMoreButton = ({ onLoadMore }) => {
  const [processing, setProcessing] = useState(false);
  const onClick = async () => {
    if (processing) return;
    setProcessing(true);
    await onLoadMore();
    setProcessing(false);
  };
  return (
    <span className="load-more" onClick={onClick}>
      {i18n.t(processing ? 'Loading...' : 'Load more')}
    </span>
  );
};

const ExternalDirectory = (props) => {
  const {
    isPortal,
    directory,
    isWebinar,
    canDragDrop,
    selectedFile,
    handleMoreButtonClick,
    handleDirectoryClick,
    handleMaterialClick,
    setFlatDirectories,
    disabledMoreButton,
  } = props;

  const spaceId = directory?.spaceId;
  const folderId = directory?.id;
  const spaceFolderId = directory.spaceFolderId > 0 ? directory.spaceFolderId : folderId;
  const externalId = directory?.externalId;
  const spaceResources = directory?.spaceResources;
  const fetched = directory?.fetchedExternalResources === true;
  const parentFolderId = directory?.parentFolderId;

  const { getResourceIdAndMaterialIdExternalFile } = useMaterialsContext();

  const [isLoading, setIsLoading] = useState(true);
  const [pageToken, setPageToken] = useState(null);
  const [creatingExternalConnection, setCreatingExternalConnection] = useState(false);

  const resourcesOfFolder = useMemo(() => {
    const files = directory.spaceResources || [];
    const folders = directory.subFolders || [];
    const data = [...folders, ...files];
    return data;
  }, [directory]);

  const getFolderDetails = useCallback(async () => {
    const resp = await fetchFolderDetails(spaceId, spaceFolderId, isPortal, externalId, pageToken);
    setIsLoading(false);
    setPageToken(resp?.pageToken);
    if (resp?.folder === null) {
      return;
    }
    const folders = [];
    if (resp.folder) {
      const newMaterials = spaceResources || [];
      resp.folder.spaceResources.forEach((material) => {
        const index = newMaterials.findIndex((m) => m.externalId === material.externalId);
        if (index > -1) {
          newMaterials[index] = material;
        } else {
          newMaterials.push({ ...material, spaceId: spaceId, spaceFolderId });
        }
      });

      folders.push({
        spaceResources: newMaterials,
        fetchedExternalResources: true,
        id: resp.folder.id > 0 ? resp.folder.id : resp.folder.externalId,
        name: resp.folder.name,
        parentFolderId: parentFolderId,
        isExternal: resp.folder.isExternal,
        spaceFolderId: resp.folder.spaceFolderId,
        spaceId: spaceId,
        externalFolderSettings: resp.folder.externalFolderSettings,
      });
    }
    if (resp.subFolders) {
      resp.subFolders.forEach((subFolder) => {
        folders.push({
          ...subFolder,
          id: subFolder.externalId,
          parentFolderId: folderId,
        });
      });
    }
    if (folders.length > 0) {
      setFlatDirectories((prevData) => {
        const newData = prevData ? prevData.slice() : [];
        folders.forEach((folder) => {
          const foundIndex = newData.findIndex((item) => item.id === folder.id);
          if (foundIndex === -1) {
            newData.push(folder);
          } else {
            // Prevent overwriting spaceResources already fetched
            const newspaceResources = newData[foundIndex].spaceResources?.length
              ? newData[foundIndex].spaceResources
              : folder.spaceResources;

            newData[foundIndex] = {
              ...newData[foundIndex],
              ...folder,
              spaceResources: newspaceResources,
            };
          }
        });
        return newData;
      });
    }
  }, [
    spaceId,
    spaceFolderId,
    isPortal,
    externalId,
    pageToken,
    spaceResources,
    parentFolderId,
    folderId,
    setFlatDirectories,
  ]);

  const onMaterialClick = useCallback(
    async (material) => {
      if (!material.id) {
        setCreatingExternalConnection(true);
        const newMaterial = await getResourceIdAndMaterialIdExternalFile(material, spaceFolderId);
        setCreatingExternalConnection(false);
        handleMaterialClick(newMaterial);
      } else {
        handleMaterialClick(material);
      }
    },
    [getResourceIdAndMaterialIdExternalFile, handleMaterialClick, spaceFolderId]
  );

  const loadMoreButton = useMemo(() => {
    if (pageToken) {
      return <LoadMoreButton onLoadMore={getFolderDetails} />;
    }
    return null;
  }, [getFolderDetails, pageToken]);

  const fetchFolderDetailsDebounced = useDebouncedCallback(() => {
    getFolderDetails();
  }, 500);

  useEffect(() => {
    if (!spaceId || !spaceFolderId || fetched) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
      fetchFolderDetailsDebounced();
    }
    return () => {
      fetchFolderDetailsDebounced.cancel();
    };
  }, [fetchFolderDetailsDebounced, spaceFolderId, spaceId, fetched]);

  if (isLoading) {
    return <Loading />;
  }

  if (resourcesOfFolder.length === 0) {
    return <ErrorMessage message={i18n.t('No materials available.')} />;
  }

  return (
    <>
      {creatingExternalConnection && <BlockUI fullScreen display />}
      <ResourcesDisplayView
        data={resourcesOfFolder}
        isWebinar={isWebinar}
        canDragDrop={canDragDrop}
        onClickFolder={handleDirectoryClick}
        onClickFile={onMaterialClick}
        onClickMoreButton={handleMoreButtonClick}
        isMobile={props.isMobile}
        selectedFile={selectedFile}
        loadMoreButton={loadMoreButton}
        spaceFolderId={spaceFolderId}
        isPortal={isPortal}
        disabledMoreButton={disabledMoreButton}
      />
    </>
  );
};

ExternalDirectory.propTypes = {
  isMobile: PropTypes.bool,
  isWebinar: PropTypes.bool,
  isPortal: PropTypes.bool,
  canDragDrop: PropTypes.bool,
  directory: PropTypes.instanceOf(Object),
  selectedFile: PropTypes.instanceOf(Object),
  handleMoreButtonClick: PropTypes.func,
  handleDirectoryClick: PropTypes.func,
  handleMaterialClick: PropTypes.func,
  setFlatDirectories: PropTypes.func,
  disabledMoreButton: PropTypes.bool,
};

LoadMoreButton.propTypes = {
  onLoadMore: PropTypes.func,
};

export default ExternalDirectory;
