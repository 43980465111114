import { Typography, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import React, { useState } from 'react';

import './SettingForm.scss';
import CustomSwitch from './CustomSwitch';
import { descriptionRuleSetting, ruleFields } from './constant';
import { Tag } from '../../components/Tags';

const RuleSettings = (props) => {
  const { defaultValue, onChange, labelSetting, type, description, frequency } = props;
  const [rule, setRule] = useState(defaultValue);
  const labels = labelSetting[rule.type];

  const handleOnChangeSwitch = (event) => {
    const newValue = {
      ...rule,
      enabled: event.target.checked,
    };
    setRule(newValue);
    onChange(newValue);
  };

  if (!rule) {
    return '';
  }

  return (
    <Grid container className="notify-control setting-row" spacing={2}>
      <Grid xs={8} md={8} item className="setting-col-1">
        <Typography>{description}</Typography>
      </Grid>
      <Grid xs={2} md={3} item className="setting-col-2">
        <Tag backgroundColor={frequency === 'Daily' ? '#CCDDFF' : '#B8F5DB'} color="#000000">
          {frequency}
        </Tag>
      </Grid>
      <Grid xs={2} md={1} item className="setting-col-3">
        <CustomSwitch
          color="primary"
          name={labels.name}
          checked={rule?.enabled}
          onChange={handleOnChangeSwitch}
          className={`${type}-${labels.className}`}
        />
      </Grid>
    </Grid>
  );
};

const SettingForm = (props) => {
  const { defaultValue, onChange, labelSetting, hideAppNotification, type, contextSetting } = props;
  const [setting, setSetting] = useState(defaultValue);
  const description = descriptionRuleSetting[contextSetting][setting.event];

  const handleOnChange = (rule) => {
    const newSettings = cloneDeep(setting);
    const indexEl = newSettings.rules.findIndex((item) => item.type === rule.type);
    newSettings.rules[indexEl] = rule;
    setSetting(newSettings);
    onChange(newSettings);
  };

  const renderItem = (item, index) => {
    if (item.type === 'Push') {
      return null;
    }
    return (
      <RuleSettings
        type={type}
        key={index}
        defaultValue={item}
        onChange={handleOnChange}
        labelSetting={labelSetting}
        hideAppNotification={hideAppNotification}
        frequency={ruleFields[setting.event].frequency}
        description={description}
      />
    );
  };
  if (defaultValue.hidden) {
    return null;
  }
  return (
    <div className="setting-form">
      {setting.rules.map((item, index) => renderItem(item, index))}
    </div>
  );
};

SettingForm.propTypes = {
  defaultValue: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  labelSetting: PropTypes.instanceOf(Object),
  hideAppNotification: PropTypes.bool,
  type: PropTypes.string,
  contextSetting: PropTypes.string,
};

SettingForm.defaultValue = {
  hideAppNotification: false,
  contextSetting: 'you',
};

RuleSettings.propTypes = {
  defaultValue: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  labelSetting: PropTypes.instanceOf(Object),
  type: PropTypes.string,
  description: PropTypes.string,
  frequency: PropTypes.string,
};

export default SettingForm;
