import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

export function a11yProps(index) {
  return {
    id: `groupby-tab-${index}`,
    'aria-controls': `groupby-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="tabpanel styled-scrollbar"
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
};

export default TabPanel;
