import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useQuery } from '@tanstack/react-query';
import memoizeOne from 'memoize-one';
import _cloneDeep from 'lodash/cloneDeep';
import { Table, TableBody, TableCell, TableContainer, TableRow, Checkbox } from '@mui/material';

import EnhancedTableHeader from '../../components/EnhancedTable/EnhancedTableHeader';
import EnhancedTablePagination from '../../components/EnhancedTable/EnhancedTablePagination';
import { CacheKeys } from '../../app/queryCache';
import { getCreatedFromTemplateSpaces } from '../../homepage/spaceService';
import OfficialButton from '../../components/OfficialButtons';
import i18n from '../../i18n';
import './ApplyChangesTable.scss';
import Loading from '../../components/Loading';
import { openWindow } from '../../commons/utils';
import SearchField from '../../components/SearchField';
import { Ids } from '../../commons/pendoTaggings';
import { getLastUpdatedTime } from '../../commons/DateTimeUtils';
import LightTooltip from '../../components/LightTooltip';

const descendingComparator = memoizeOne((a, b, orderBy) => {
  const valueA = a[orderBy];
  const valueB = b[orderBy];

  if (valueB !== null && valueB !== undefined && (valueA === undefined || valueA === null))
    return -1;
  if ((valueB === undefined || valueB === null) && valueA !== null && valueA !== undefined)
    return 1;

  if (valueB < valueA) return -1;
  if (valueB > valueA) return 1;

  return 0;
});

const getComparator = memoizeOne((order, orderBy) => {
  return order === 'desc'
    ? (a, b) => {
        const t1 = descendingComparator(a, b, orderBy);
        if (t1 === 0) {
          if (orderBy === 'name') {
            return -descendingComparator(a, b, 'owner');
          } else if (orderBy === 'owner') {
            return -descendingComparator(a, b, 'name');
          }
        }
        return t1;
      }
    : (a, b) => {
        const t1 = -descendingComparator(a, b, orderBy);
        if (t1 === 0) {
          if (orderBy === 'name') {
            return -descendingComparator(a, b, 'owner');
          } else if (orderBy === 'owner') {
            return -descendingComparator(a, b, 'name');
          }
        }
        return t1;
      };
});

const stableSort = memoizeOne((array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1]; // index
  });
  return stabilizedThis.map((el) => el[0]);
});

const getHeadCells = memoizeOne((showVisitSpace = false, showLastUpdated = false) => {
  const headCells = [
    { id: 'name', disablePadding: false, label: 'Space', width: 'auto' },
    { id: 'owner', disablePadding: false, label: 'Space Owner', width: '245px' },
  ];
  if (showLastUpdated) {
    headCells.push({
      id: 'reflectedOn',
      disablePadding: false,
      label: 'Last Updated',
      width: '150px',
    });
  }
  if (showVisitSpace) {
    headCells.push({
      id: null,
      align: 'center',
      disablePadding: false,
      label: 'Action',
      width: '100px',
      disableSorting: true,
    });
  }
  return headCells;
});

const disabledRowMessage = i18n.t(
  'Changes only apply to spaces created directly from the template. Use the override feature for duplicated spaces.'
);

const ApplyChangesTable = (props) => {
  const {
    spaceTemplateId,
    handleUpdateRowCount,
    handleUpdateSelected,
    logId,
    isSelectable,
    selectAll,
    error,
    isOverridden,
    showVisitSpace,
    showLastUpdated,
  } = {
    isSelectable: false,
    selectAll: false,
    error: false,
    showVisitSpace: false,
    showLastUpdated: false,
    isOverridden: false,
    ...props,
  };

  const [spaces, setSpaces] = useState([]); // rows

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [rowCount, setRowCount] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [selected, setSelected] = useState([]);

  const oneTimeRef = React.useRef(false); // at the first time only.

  const headCells = getHeadCells(showVisitSpace, showLastUpdated);

  // console.log('### ApplyChangesTable', rowCount, selected);

  const { data, isLoading } = useQuery({
    queryKey: [CacheKeys.getCreatedFromTemplateSpaces, spaceTemplateId, logId],
    queryFn: async () => {
      const resp = await getCreatedFromTemplateSpaces(spaceTemplateId, logId);
      // console.log('### getCreatedFromTemplateSpaces resp', resp);
      return resp;
    },
    config: {
      retry: 1,
      retryDelay: () => 3000,
    },
  });

  useEffect(() => {
    console.log('### ApplyChangesTable fetching data', data);
    if (data) {
      const fetchedSpaces = data;
      setSpaces(fetchedSpaces);

      const totalItems = fetchedSpaces.length;
      setRowCount(totalItems);
      if (handleUpdateRowCount) handleUpdateRowCount(totalItems);

      console.log('### useEffect 1', oneTimeRef.current, isSelectable, selectAll);
      if (!oneTimeRef.current) {
        oneTimeRef.current = true;
        if (isSelectable && selectAll) {
          // console.log('### useEffect 2');
          const newSelected = fetchedSpaces.map((n) => n.id);
          setSelected(newSelected);
          if (handleUpdateSelected) handleUpdateSelected(newSelected);
        }
      }
    }
  }, [data, handleUpdateRowCount, handleUpdateSelected, isSelectable, selectAll]);

  const displaySpaces = React.useMemo(() => {
    if (!spaces?.length) return [];
    let items = _cloneDeep(spaces);
    if (keyword) {
      // const lowerKeyword = keyword.toLowerCase();
      items = items.filter(
        (x) => x.name.toLowerCase().includes(keyword) || x.owner.toLowerCase().includes(keyword)
      );
    }
    const comparator = getComparator(order, orderBy);
    items = stableSort(items, comparator);
    return items; // filtered & sorted items, no paging
  }, [keyword, order, orderBy, spaces]);

  const dictDerivedSpaces = React.useMemo(() => {
    if (!spaces?.length) return {};
    const derivedSpaces = spaces.filter((s) => s.isDerived);
    const result = derivedSpaces.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});
    return result;
  }, [spaces]);

  useEffect(() => {
    if (!isOverridden && selected?.length > 0) {
      setSelected((prev) => {
        if (!prev?.length) return prev;
        const newSelected = selected.filter((id) => dictDerivedSpaces[id]);
        if (JSON.stringify(prev) !== JSON.stringify(newSelected)) {
          if (handleUpdateSelected) handleUpdateSelected(newSelected);
          return newSelected;
        }
        return prev;
      });
    }
  }, [dictDerivedSpaces, handleUpdateSelected, isOverridden, selected]);

  const handleRequestSort = (event, property) => {
    // console.log('### handleRequestSort', order, orderBy, property);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    // console.log('### changed page', newPage);
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log('### changed page size', event.target);
    const newRowsPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleOnSearch = (searchText) => {
    const newKeyword = searchText?.toLowerCase()?.trim();
    setPage(0);
    setKeyword(newKeyword);
  };

  const handleOnSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = isOverridden
        ? displaySpaces.map((n) => n.id)
        : displaySpaces.filter((s) => s.isDerived).map((n) => n.id);
      setSelected(newSelected);
      if (handleUpdateSelected) handleUpdateSelected(newSelected);
      return;
    }
    setSelected([]);
    if (handleUpdateSelected) handleUpdateSelected([]);
  };

  const handleOnRowClick = (row) => {
    if (!row) return;
    const selectedIndex = selected.indexOf(row.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    if (handleUpdateSelected) handleUpdateSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const renderSearch = () => {
    return (
      <div className="search-field-wrapper">
        {!logId && (
          <div>
            <label>{i18n.t('Spaces')}</label>
            <div className="selected-count">
              <span className="text">{`${selected.length} / ${rowCount} selected`}</span>
            </div>
          </div>
        )}
        <SearchField
          // ref={inputRef}
          placeholder={i18n.t('Search space or space owner')}
          variant="outlined"
          onChange={handleOnSearch}
          // onPressEnter={handleOnPressEnter}
          disabled={isLoading}
        />
      </div>
    );
  };

  const renderNoData = () => {
    if (isLoading) return null;
    // if (rowCount > 0) return null;
    if (displaySpaces.length > 0) return null;
    let title = i18n.t('No spaces to display');
    let description = i18n.t('');
    if (keyword?.length > 0) {
      title = i18n.t('No spaces found');
      description = i18n.t('Choose another keyword and try again');
    }
    return (
      <section className="no-data-section">
        <span className="icon icon-door"></span>
        <span className="text">{title}</span>
        <span className="sub-text">{description}</span>
      </section>
    );
  };

  const renderTableRow = (row, index, isDisabled) => {
    const isItemSelected = isSelected(row.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const displayReflectedOn = getLastUpdatedTime(row.reflectedOn);
    return (
      <TableRow
        hover
        tabIndex={-1}
        key={row.id}
        role="checkbox"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          if (!isDisabled) handleOnRowClick(row);
        }}
        aria-checked={isItemSelected}
        selected={isItemSelected}
        className={clsx('space-item', {
          disabled: isDisabled,
        })}
      >
        {isSelectable && (
          <TableCell>
            <Checkbox
              checked={isItemSelected}
              inputProps={{ 'aria-labelledby': labelId }}
              color="primary"
              disabled={isDisabled}
            />
          </TableCell>
        )}
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.owner}</TableCell>
        {showLastUpdated && <TableCell>{displayReflectedOn}</TableCell>}
        {showVisitSpace && (
          <TableCell align="center">
            <OfficialButton
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                const spaceUrl = `${location.origin}/s/${row.id}`;
                openWindow(spaceUrl, '_blank');
              }}
              label={i18n.t('Visit space')}
              variant="rectangle-secondary"
              dataId={Ids.ApplyChangesDialog.visit}
            />
          </TableCell>
        )}
      </TableRow>
    );
  };

  const renderTableBody = () => {
    const pagedSpaces = displaySpaces.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return pagedSpaces.map((row, index) => {
      const isDisabled = !row.isDerived && !isOverridden;
      if (isDisabled) {
        return (
          <LightTooltip
            title={disabledRowMessage}
            placement="bottom"
            style={{ width: '300px' }}
            disableHoverListener={!isDisabled}
            disableTouchListener={!isDisabled}
            // disableFocusListener={!isDisabled}
          >
            {renderTableRow(row, index, isDisabled)}
          </LightTooltip>
        );
      }
      return renderTableRow(row, index, isDisabled);
    });
  };

  const renderError = () => {
    return (
      error && (
        <div className="error-text">{error && i18n.t('Select at least one space to proceed.')}</div>
      )
    );
  };

  const renderTable = () => {
    if (isLoading) {
      return (
        <section className="loading-section">
          <Loading style={{ textAlign: 'center' }} />
        </section>
      );
    }
    // console.log('### renderTable displaySpaces', displaySpaces);
    // if (rowCount === 0) return null;
    if (displaySpaces.length === 0) return null;
    return (
      <>
        <TableContainer className="table-container">
          <Table className="table" size="medium" aria-label="sticky table" stickyHeader>
            <EnhancedTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              isSelectable={isSelectable}
              numSelected={selected?.length}
              // rowCount={rowCount}
              rowCount={displaySpaces.length}
              onSelectAllClick={handleOnSelectAllClick}
            />
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableContainer>
        <EnhancedTablePagination
          page={page}
          // rowCount={rowCount}
          rowCount={displaySpaces.length}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[7, 25, 50]}
        />
      </>
    );
  };

  const renderContent = () => {
    return (
      <>
        {renderSearch()}
        {renderError()}
        {renderNoData()}
        {renderTable()}
      </>
    );
  };

  return <div className="apply-changes-table">{renderContent()}</div>;
};

export default ApplyChangesTable;
