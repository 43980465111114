import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';

import { CacheKeys } from '../../app/queryCache';
import { getOrganizationUsers } from '../UserServices';
import './UsersSelect.scss';
import { OrganizationUserRole } from '../../app/appConstants';
import Tags from '../../components/Tags';
import { MenuEnhancedProps } from '../../components/SelectEnhanced';
import { isOrgRole } from '../../commons/utils';
import { fetchSpaceUsers } from '../../spaces/SpaceUsers/spaceUsersServices';

const tags = [
  {
    backgroundColor: '#DBE1E3',
    color: '#000',
    text: 'Admin',
  },
];

const getAvailableUsers = memoizeOne((users, userId, isCheckAdmin) => {
  const newUsers = users?.filter((item) => item.role !== OrganizationUserRole.Partner);

  if (!newUsers.length) {
    return [];
  }

  return newUsers.filter((item) => {
    if (isCheckAdmin) {
      return item.userInfoId !== userId && isOrgRole(item.role, OrganizationUserRole.Admin);
    } else {
      return item.userInfoId !== userId;
    }
  });
});

const UsersSelect = forwardRef((props, ref) => {
  const {
    label,
    disabled,
    placeholder,
    name,
    required,
    spaceId,
    exceptUserId,
    isCheckAdmin,
    onReady,
    organizationId,
  } = props;
  const [value, setValue] = useState(null);
  const [error, setErrors] = useState(false);
  const selectRef = useRef();

  const [users, setUsers] = useState([]);

  const getUsersQuery = useQuery({
    queryKey: [CacheKeys.getOrganizationUsers],
    queryFn: async () => {
      let resp;
      if (spaceId) {
        resp = await fetchSpaceUsers(spaceId, true);
      } else {
        resp = await getOrganizationUsers(organizationId);
      }
      return resp;
    },
    retry: 3,
    retryDelay: () => 5000,
    enabled: !!organizationId,
  });

  useEffect(() => {
    if (!getUsersQuery.data) {
      return;
    }
    const availableUsers = getAvailableUsers(getUsersQuery.data, exceptUserId, isCheckAdmin);
    if (availableUsers.length) {
      setUsers(availableUsers);
    } else {
      setUsers([{ userInfoId: null, fullName: 'No options' }]);
    }
    if (onReady) {
      onReady(getUsersQuery.data);
    }
  }, [getUsersQuery.data, exceptUserId, isCheckAdmin, onReady]);

  function handleOnChange(event) {
    setValue(event.target.value);
    setErrors(!event.target.value);
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  }

  useImperativeHandle(ref, () => ({
    isValid() {
      setErrors(!value);
      return !!value;
    },
  }));

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormControl
      ref={selectRef}
      required={required}
      fullWidth
      disabled={disabled}
      className="user-select-control"
    >
      {label && <label id="label">{label}</label>}
      <Select
        menuProps={MenuEnhancedProps}
        onChange={handleOnChange}
        value={value}
        labelId="label"
        displayEmpty
        name={name}
        className="user-select"
        renderValue={(d) => {
          if (!d) {
            return placeholder;
          }
          return `${d?.fullName}  ${d?.contactEmail ? `- ${d?.contactEmail}` : ''}`;
        }}
        variant="outlined"
      >
        {users?.map((item) => (
          <MenuItem value={item} key={item.id} className="user-select-item">
            <div className="user-select-item-name">
              {item.fullName}
              {!isCheckAdmin && isOrgRole(item.role, OrganizationUserRole.Admin) && (
                <Tags items={tags} />
              )}
            </div>
            <span>{item.contactEmail}</span>
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error={error}>This field is required</FormHelperText>}
    </FormControl>
  );
});

UsersSelect.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  spaceId: PropTypes.string,
  exceptUserId: PropTypes.number,
  isCheckAdmin: PropTypes.bool,
  onReady: PropTypes.func,
  organizationId: PropTypes.string,
};

UsersSelect.defaultProps = {
  required: true,
  name: null,
  displayEmpty: false,
  isCheckAdmin: false,
};

export default UsersSelect;
