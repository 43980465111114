/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { Grid, InputLabel, TextField, Typography } from '@mui/material';

import i18n from '../../i18n';
import './UserProfileDetails.scss';

import ConfirmService from '../../components/ConfirmService';
import { updateUserProfile } from './UserServices';
import { sendNotification } from '../../commons/utils';

import authService from '../../components/api-authorization/AuthorizeService';
import HeaderUser from './HeaderUser';
import { useUserContainerContext } from './UserContainerProvider';
import OfficialButton from '../../components/OfficialButtons';
import ChangePasswordForm from './ChangePasswordForm';
import { useAppContext } from '../../components/AppProvider';

function UserProfile(props) {
  const userInfo = props.userInfo;
  const { updateUserInfo } = useAppContext();
  const [openForm, setOpenForm] = useState(null);

  const { open, handleDrawerToggle } = useUserContainerContext();
  const defaultValues = useMemo(
    () => ({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      phoneNumber: userInfo.phoneNumber,
    }),
    [userInfo]
  );
  const {
    register: registerProfile,
    formState: { errors: errorsProfile, isSubmitting, isDirty, isValid },
    control,
    reset,
    handleSubmit: handleSubmitProfile,
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  function handleResetForm() {
    reset(defaultValues);
  }

  async function onUpdateProfile(data) {
    if (openForm || !isValid) {
      return;
    }
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
    };

    try {
      const result = await updateUserProfile(payload);
      if (result) {
        await authService.signInSilent();
        updateUserInfo(result);
        reset({
          firstName: result.firstName,
          lastName: result.lastName,
          phoneNumber: result.phoneNumber,
        });
        sendNotification(i18n.t('Your profile has been updated successfully!'), {
          type: 'success',
        });
      }
    } catch (error) {
      console.log('result: ', error.message);
      sendNotification(error.message, { type: 'error' });
    }
  }

  const handleDeleteProfile = async () => {
    const description = i18n.t(
      'Contact an admin in your organization to delete your account and data.'
    );

    const isConfirmed = await ConfirmService.show(
      `${i18n.t('DELETE PROFILE')}`,
      description,
      i18n.t('ok'),
      '',
      false,
      <span className="icon-info" style={{ color: '#4783FF' }} />,
      true,
      'delete-profile'
    );

    if (isConfirmed) {
      const subject = 'Delete profile';
      const body = 'I would like delete my profile.';

      window.open(`mailto:support@spce.com?subject=${subject}&body=${body}`);
    }
  };

  function openChangePasswordForm(event) {
    event.stopPropagation();
    event.preventDefault();
    setOpenForm(event?.target);
  }

  function closeChangePasswordForm() {
    setOpenForm(null);
  }

  return (
    <div className="profile-right">
      <div className="profile-details-header">
        <HeaderUser onClick={handleDrawerToggle} isOpen={open} title={i18n.t('Profile')}>
          <div className="button-right">
            <OfficialButton
              onClick={handleResetForm}
              disabled={isSubmitting || !isDirty}
              variant="regular-secondary"
              size="medium"
              label={i18n.t('Cancel')}
            />
            <OfficialButton
              disabled={isSubmitting || !isDirty}
              isProcessing={isSubmitting}
              variant="regular-green"
              onClick={handleSubmitProfile(onUpdateProfile)}
              size="medium"
              label={i18n.t('Save')}
            />
          </div>
        </HeaderUser>
      </div>
      {/* form */}
      <div className="user-limit-content user-profile styled-scrollbar">
        <div className="user-form">
          <div className="user-form-title">{i18n.t('Personal Details')}</div>
          <Grid>
            <form
              className="form"
              onSubmit={handleSubmitProfile(onUpdateProfile)}
              autoComplete="off"
            >
              <div className="form-row col-2">
                <div>
                  <InputLabel shrink htmlFor="firstName">
                    {i18n.t('First Name')}
                  </InputLabel>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        error={!!errorsProfile.firstName}
                        helperText={errorsProfile.firstName && i18n.t('This field is required')}
                      />
                    )}
                    name="firstName"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={defaultValues.firstName}
                  />
                </div>
                <div>
                  <InputLabel shrink htmlFor="lastName">
                    {i18n.t('Last Name')}
                  </InputLabel>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        error={!!errorsProfile.lastName}
                        helperText={errorsProfile.lastName && i18n.t('This field is required')}
                      />
                    )}
                    name="lastName"
                    control={control}
                    defaultValue={defaultValues.lastName}
                    rules={{ required: true }}
                  />
                </div>
              </div>
              <div className="form-row  col-2">
                <div className="form-row-info">
                  <div className="form-row-info-label">{i18n.t('Email')}</div>
                  <div className="form-row-info-text">{userInfo.contactEmail}</div>
                </div>

                <div className="password">
                  <div className="change-password-btn">
                    <div className="form-row-info">
                      <div className="form-row-info-label">{i18n.t('Password')}</div>
                      <div className="form-row-info-text">••••••••••</div>
                    </div>
                    <OfficialButton
                      label="Change Password"
                      variant="rectangle-primary"
                      onClick={openChangePasswordForm}
                    />
                  </div>

                  <ChangePasswordForm open={openForm} onClose={closeChangePasswordForm} />
                </div>
              </div>

              <div className="form-row  col-2">
                <div className="form-row-info">
                  <div className="form-row-info-label">{i18n.t('Organization')}</div>
                  <div className="form-row-info-text">{userInfo.organizationName}</div>
                </div>
                <div className="form-row-info">
                  <div className="form-row-info-label">{i18n.t('Organization ID')}</div>
                  <div className="form-row-info-text">{userInfo.organizationId}</div>
                </div>
              </div>
              <div className="form-row">
                <div className="phone-number">
                  <InputLabel shrink htmlFor="phoneNumber">
                    {i18n.t('Phone number')}
                    <span> {` (optional)`}</span>
                  </InputLabel>
                  <Controller
                    render={({ field }) => <TextField {...field} type="number" />}
                    name="phoneNumber"
                    control={control}
                    {...registerProfile('phoneNumber')}
                  />
                </div>
              </div>
            </form>
          </Grid>
          {/* delete profile  */}
          <div className="delete-profile">
            <div className="delete-container">
              <div>
                <div>Delete profile</div>
                <Typography className="note">
                  You will receive an email to confirm your decision. Please note that all spaces
                  you have created will be permanently deleted.
                </Typography>
              </div>

              <div className="delete-button">
                <OfficialButton
                  onClick={handleDeleteProfile}
                  label="Delete my profile"
                  variant="rectangle-primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

UserProfile.propTypes = {
  userInfo: PropTypes.instanceOf(Object),
};

export default UserProfile;
