import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { sendNotification } from '../../commons/utils';
import ConfirmService from '../../components/ConfirmService';
import Icons from '../../components/Icons';
import i18n from '../../i18n';
import BlockUI from '../../components/BlockUI';
import SavingSnackbar from '../../components/ProcessingSnackbar';
import { useAppContext } from '../../components/AppProvider';

const UserManagementHandler = (props) => {
  const { onFinished, isTransferUser, isListenerEventDelete, isDeletePartner } = props;
  const { portalHubConnection } = useAppContext();

  function showDeleteUserSuccessPopup(message) {
    ConfirmService.show(
      i18n.t('Success!'),
      message,
      i18n.t('OK'),
      i18n.t(''),
      null,
      <Icons name="icon-correct" />,
      true
    );
  }

  useEffect(() => {
    const handleDeleteUserDataCompleted = () => {
      showDeleteUserSuccessPopup(
        isDeletePartner
          ? 'Partner data were successfully deleted.'
          : 'Spaces and templates were successfully deleted.'
      );
      onFinished();
    };

    const handleTransferUserDataCompleted = (message) => {
      const { toUserInfoName, isTransferSpace, isTransferSpaceTemplate, transferAllData } = message;
      let messageText = '';
      if (transferAllData) {
        messageText = 'All spaces and templates have been transferred to the new owner.';
      } else if (isTransferSpace) {
        messageText = 'Space has been transferred to the new owner. ';
      } else if (isTransferSpaceTemplate) {
        messageText = 'Template has been transferred to the new owner. ';
      }

      const description = (
        <div>
          {messageText}
          <br />
          <div style={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '18px' }}>{toUserInfoName}</div>
        </div>
      );
      showDeleteUserSuccessPopup(description);
      onFinished();
    };

    const handleDeleteUserDataFailed = () => {
      onFinished();
      sendNotification('Could not process your request, please contact administration', {
        type: 'error',
      });
    };

    if (portalHubConnection) {
      if (isListenerEventDelete) {
        portalHubConnection.on('DeleteUserDataCompleted', handleDeleteUserDataCompleted);
        portalHubConnection.on('DeleteUserDataFailed', handleDeleteUserDataFailed);
      }

      portalHubConnection.on('TransferUserDataCompleted', handleTransferUserDataCompleted);
      portalHubConnection.on('TransferUserDataFailed', handleDeleteUserDataFailed);
    }
    return () => {
      if (portalHubConnection) {
        if (isListenerEventDelete) {
          portalHubConnection.off('DeleteUserDataCompleted', handleDeleteUserDataCompleted);
          portalHubConnection.off('DeleteUserDataFailed', handleDeleteUserDataFailed);
        }

        portalHubConnection.off('TransferUserDataCompleted', handleTransferUserDataCompleted);
        portalHubConnection.off('TransferUserDataFailed', handleDeleteUserDataFailed);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalHubConnection]);

  const getMessageProcessing = () => {
    if (isDeletePartner) {
      return i18n.t('Deleting partner data...');
    } else if (isListenerEventDelete) {
      return isTransferUser
        ? i18n.t('Transferring spaces and templates...')
        : i18n.t('Deleting spaces and templates...');
    } else {
      return i18n.t('Transferring ownership...');
    }
  };

  return (
    <>
      <BlockUI
        fullScreen
        backgroundColor="#000"
        backgroundOpacity={0.5}
        textColor="#000"
        display
        showLoading={false}
      />
      <SavingSnackbar show message={getMessageProcessing()} />
    </>
  );
};

UserManagementHandler.propTypes = {
  isTransferUser: PropTypes.bool,
  isListenerEventDelete: PropTypes.bool,
  onFinished: PropTypes.func,
  isDeletePartner: PropTypes.bool,
};

UserManagementHandler.defaultProps = {
  isListenerEventDelete: false,
};

export default UserManagementHandler;
