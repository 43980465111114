import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import queryCache, { CacheKeys } from '../../app/queryCache';
import { fetchRuntimeMaterials, fetchSpaceFolders } from '../../resources/ResourceServices';
import spaceUser from '../spaceUser';

const useSpaceMaterialsQuery = (
  spaceId,
  isPortal = false,
  enabled = true,
  refetchOnMount = true
) => {
  const getMaterialsQuery = useQuery({
    queryKey: [CacheKeys.fetchMaterials, spaceId],
    queryFn: async () => {
      if (isPortal) {
        const resp = await fetchSpaceFolders(spaceId);
        return resp;
      } else {
        const isVisitor = spaceUser?.isVisitor();

        // Supervisor role (spc-1119 admins can access the spaces of other owners)
        if (!spaceUser.getInvc() && !spaceUser.isSupervisor() && !isVisitor) {
          return null;
        }
        const resp = await fetchRuntimeMaterials(spaceId);
        return resp;
      }
    },
    enabled,
    retry: 3,
    retryDelay: () => 5000,
    refetchOnMount: refetchOnMount ? 'always' : false,
  });

  getMaterialsQuery.invalidate = useCallback(() => {
    queryCache.invalidateQueries({ queryKey: [CacheKeys.fetchMaterials, spaceId] });
  }, [spaceId]);

  return getMaterialsQuery;
};

export default useSpaceMaterialsQuery;
