import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ColorPicker from '../../cms/commons/ColorPicker';
import './BackgroundColorSetting.scss';

const BackgroundColorSetting = (props) => {
  const { name, defaultColor } = props;
  const [backgroundColor, setBackgroundColor] = useState(defaultColor);

  // React.useEffect(() => {
  //   // console.log('### BackgroundColorSetting useEffect: ', defaultColor);
  //   setBackgroundColor(defaultColor);
  // }, [defaultColor]);

  const handleOnChange = (value) => {
    setBackgroundColor(value);
    props.onChange(name, value);
  };

  return (
    <div className="background-color-setting">
      <div className="background-color-box" style={{ backgroundColor: backgroundColor }}></div>
      <ColorPicker label="Color" defaultColor={backgroundColor} onChange={handleOnChange} />
    </div>
  );
};

BackgroundColorSetting.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  defaultColor: PropTypes.string,
};

export default BackgroundColorSetting;
