import React, { useEffect, useRef, useState } from 'react';
import memoizeOne from 'memoize-one';
import { cloneDeep, groupBy, mapValues, sortBy } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockUI from '../../components/BlockUI';
import HeaderUser from '../components/HeaderUser';
import i18n from '../../i18n';

import './TagsManagement.scss';
import { Tag } from '../../components/Tags';
import queryCache, { CacheKeys } from '../../app/queryCache';
import ConfirmService from '../../components/ConfirmService';
import TagFormDialog from './TagFormPopup';
import { deleteTagOrganization } from '../UserServices';
import { TagColors } from './constants';
import { sendNotification } from '../../commons/utils';
import NoDaTaBox from '../../components/NoDataBox';
import Loading from '../../components/Loading';
import SearchField from '../../components/SearchField';
import useTagsOrganization from './useTagsOrganization';
import OfficialButton from '../../components/OfficialButtons';
import { useUserContainerContext } from '../settings/UserContainerProvider';
import currentOrganization from '../../commons/CurrentOrganization';

const getTagsByGroup = memoizeOne((tags, keywords) => {
  let filterTags = tags;
  if (keywords && keywords.length) {
    filterTags = tags.filter((o) => o.name.toLowerCase().includes(keywords?.toLowerCase()));
  }

  // Group tags by colorGroup
  const data = groupBy(filterTags, 'colorGroup');
  const tagsByGroup = [];
  mapValues(data, (o) => {
    const sortData = sortBy(o, (item) => item.name);
    tagsByGroup.push(sortData);
  });
  return tagsByGroup;
});

const TagsManagement = () => {
  const { open, handleDrawerToggle } = useUserContainerContext();
  const [tags, setTags] = useState([]);
  const [keywords, setKeywords] = useState('');
  const [isOpenDialog, setIsOpenDiaLog] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const inputRef = useRef();
  const organizationId = currentOrganization.getOrganizationId();

  const { getTagsOrganizationQuery } = useTagsOrganization();

  useEffect(() => {
    if (getTagsOrganizationQuery.data) {
      setTags(getTagsByGroup(getTagsOrganizationQuery.data, keywords));
    }
  }, [getTagsOrganizationQuery.data, keywords]);

  const handleAddTag = () => {
    setSelectedTag();
    setIsOpenDiaLog(true);
    setKeywords('');
    inputRef.current.reset();
  };

  const handleAddNewTag = () => {
    setSelectedTag({ name: keywords });
    setIsOpenDiaLog(true);
    setKeywords('');
    inputRef.current.reset();
  };

  const handleEditTag = (value) => {
    setKeywords('');
    setIsOpenDiaLog(true);
    setSelectedTag(value);
    inputRef.current.reset();
  };

  const handleCloseDialog = () => {
    setIsOpenDiaLog(false);
    setSelectedTag();
  };

  const handleRemoveCache = () => {
    queryCache.removeQueries({ queryKey: [CacheKeys.getSpaceDetailsQuery], exact: false });
    queryCache.removeQueries({ queryKey: [CacheKeys.fetchSpaceTemplates], exact: false });
    queryCache.removeQueries({ queryKey: [CacheKeys.fetchSpacesList], exact: false });
  };

  const handleOnDeleteTag = async (id) => {
    const isConfirmed = await ConfirmService.show(
      `${i18n.t('DELETE TAG')}`,
      <>
        <b>{`${i18n.t('Are you sure you want to delete this tag')} `}?</b>
        <br />
        <span>{`${i18n.t(
          'The tag will be removed from all spaces and templates where it is being used.'
        )}`}</span>
      </>,
      i18n.t('Delete'),
      i18n.t('Cancel')
    );
    if (isConfirmed) {
      await deleteTagOrganization(organizationId, id);
      sendNotification(i18n.t('Successfully!'), {
        type: 'success',
      });

      queryCache.setQueryData([CacheKeys.fetchTagsOrganization], (oldData) => {
        let newData = cloneDeep(oldData);
        if (!newData) {
          return newData;
        }
        newData = newData.filter((item) => item.id !== id);
        return newData;
      });
    }
  };

  const renderTags = () => {
    if (getTagsOrganizationQuery.isLoading) {
      return (
        <div className="loading-box">
          <Loading />
        </div>
      );
    }
    if (!tags.length) {
      return (
        <NoDaTaBox
          icon={<i className="icon-plus-solid" />}
          title={i18n.t('It’s empty now')}
          description={i18n.t('Click the "Add" button to add tags for your organization ')}
        />
      );
    }
    return (
      <div className="content-boxes">
        {tags.map((tagsByGroup, indexGroup) => (
          <div key={indexGroup} className="tags-list">
            {tagsByGroup.map((tag, index) => (
              <div key={index} className="tag-item">
                <Tag
                  backgroundColor={TagColors[tag.colorId].background}
                  color={TagColors[tag.colorId].color}
                >
                  {tag.name}
                </Tag>
                <div className="action-buttons">
                  <IconButton onClick={() => handleEditTag(tag)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={() => handleOnDeleteTag(tag.id)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="tags-management">
      <BlockUI fullScreen backgroundColor="#fff" textColor="#000" display={false}>
        <span>Processing...</span>
      </BlockUI>

      <HeaderUser onClick={handleDrawerToggle} isOpen={open} title={i18n.t('Tags')}>
        <OfficialButton
          label={i18n.t('Add')}
          onClick={handleAddTag}
          variant="rectangle-primary"
          icon={<AddIcon />}
        />
      </HeaderUser>
      <div className="search-input">
        <SearchField
          ref={inputRef}
          placeholder={i18n.t('Search tag name')}
          variant="outlined"
          onChange={(value) => setKeywords(value)}
        />
        {!!keywords.length && !tags.length && (
          <div
            className="search-input-action"
            onClick={handleAddNewTag}
          >{`Add "${keywords}" to tags`}</div>
        )}
      </div>

      <div className="tags-management-body">{renderTags()}</div>
      <TagFormDialog
        key={selectedTag?.id}
        isOpen={isOpenDialog}
        onClose={handleCloseDialog}
        onUpdatedTag={handleRemoveCache}
        data={selectedTag}
      />
    </div>
  );
};

export default TagsManagement;
