// import _find from 'lodash/find';
import _ from 'lodash';
import _memoize from 'lodash/memoize';
import _isString from 'lodash/isString';
import RuntimeApi from '../commons/RuntimeApi';
import Api from '../commons/api';
import { getCoreApi, getResourceApi, getResourceRuntimeApi } from '../commons/utils';
import { SERVICE_PROVIDER } from '../integrations/integrationConstants';
import {
  getIntegrationResourceDetails,
  isIntegrationResource,
  isIntegrationResourceDeleted,
} from '../integrations/integrationResourceUtils';
import { getIntegrationResourceUrl } from '../integrations/integrationService';
import spaceUser from '../spaces/spaceUser';
import { fetchResourcesByIdsInPortal } from './fetchResourcesByIdsInPortal';
import { fetchResourcesByIdsInRuntime } from './fetchResourcesByIdsInRuntime';
// import MockApi from '../../commons/mockApi';

export function fetchSpaceFolders(spaceId) {
  return Api.get(`${getResourceApi()}/api/spaces/${spaceId}/directories`).then((resp) => resp);
}

export function createDirectory(spaceId, data) {
  return Api.post(`${getResourceApi()}/api/spaces/${spaceId}/directories`, data);
}

export function updateDirectory(spaceId, directoryId, data) {
  return Api.put(`${getResourceApi()}/api/spaces/${spaceId}/directories/${directoryId}`, data);
}

export function bulkMoveDirectory(spaceId, data) {
  return Api.post(`${getResourceApi()}/api/spaces/${spaceId}/materials/move`, data);
}

export function removeDirectory(spaceId, directoryId) {
  return Api.delete(`${getResourceApi()}/api/spaces/${spaceId}/directories/${directoryId}`);
}

export function addMaterial(spaceId, directoryId, data) {
  return Api.post(
    `${getResourceApi()}/api/spaces/${spaceId}/directories/${directoryId}/materials`,
    data
  );
}

export function updateMaterial(spaceId, directoryId, materialId, data) {
  return Api.put(
    `${getResourceApi()}/api/spaces/${spaceId}/directories/${directoryId}/materials/${materialId}`,
    data
  );
}

export function removeMaterial(spaceId, materialId, directoryId) {
  return Api.delete(
    `${getResourceApi()}/api/spaces/${spaceId}/directories/${directoryId}/materials/${materialId}`
  );
}

export async function fetchRuntimeMaterials(spaceId) {
  const resp = await RuntimeApi.get(
    `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/runtime-materials`
  );
  return resp;
}

export async function addRuntimeMaterial(spaceId, directoryId, data) {
  try {
    const resp = await RuntimeApi.post(
      `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/directories/${directoryId}/runtime-materials`,
      data
    );
    return resp;
  } catch (error) {
    return null;
  }
}

export async function addRuntimeMaterials(spaceId, directoryId, data) {
  try {
    const resp = await RuntimeApi.post(
      `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/directories/${directoryId}/runtime-materials/bulk`,
      data
    );
    return resp;
  } catch (error) {
    return null;
  }
}

export function removeRuntimeMaterial(spaceId, directoryId, materialId) {
  return RuntimeApi.delete(
    `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/directories/${directoryId}/runtime-materials/${materialId}`
  );
}

export function updateRuntimeMaterial(spaceId, directoryId, materialId, data) {
  return RuntimeApi.put(
    `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/directories/${directoryId}/runtime-materials/${materialId}`,
    data
  );
}

export function createRuntimeDirectory(spaceId, data) {
  return RuntimeApi.post(
    `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/runtime-directories`,
    data
  );
}

export function updateRuntimeDirectory(spaceId, directoryId, data) {
  return RuntimeApi.put(
    `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/runtime-directories/${directoryId}`,
    data
  );
}

export function removeRuntimeDirectory(spaceId, directoryId) {
  return RuntimeApi.delete(
    `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/runtime-directories/${directoryId}`
  );
}

export function getResourceInfo(resourceId, isPortal = false) {
  if (!resourceId) {
    return null;
  }
  if (isPortal) {
    return Api.get(`${getResourceApi()}/api/resources/${resourceId}`);
  }
  return RuntimeApi.get(`${getResourceRuntimeApi()}/api/runtime/resources/${resourceId}`);
}

export async function getMaterialResource(spaceId, materialId, isPortal = false) {
  let resource;
  if (isPortal) {
    resource = await Api.get(
      `${getResourceApi()}/api/spaces/${spaceId}/materials/${materialId}/resource`
    );
  } else {
    resource = await RuntimeApi.get(
      `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/runtime-materials/${materialId}/resource`
    );
  }

  if (isIntegrationResource(resource.url)) {
    const integrationResourceUrl = await getIntegrationResourceUrl(resource.url, isPortal);
    resource.url = integrationResourceUrl;
  }

  return resource;
}

export async function getResourceForViewer(resourceId, isPortal = false) {
  if (!resourceId) {
    return null;
  }
  let resource;
  if (isPortal) {
    resource = await Api.get(`${getResourceApi()}/api/resources/${resourceId}/src`);
  } else {
    resource = await RuntimeApi.get(
      `${getResourceRuntimeApi()}/api/runtime/resources/${resourceId}/src`
    );
  }
  if (isIntegrationResource(resource.url)) {
    const integrationResourceUrl = await getIntegrationResourceUrl(resource.url, isPortal);
    const integrationResourceDetails = getIntegrationResourceDetails(resource.url);
    if (integrationResourceDetails?.provider === SERVICE_PROVIDER.GOOGLE_DRIVE) {
      const currentDomain = window.location.host;
      if (isIntegrationResourceDeleted(integrationResourceUrl)) {
        resource.url = integrationResourceUrl;
      } else {
        const urlWithSubdomain = new URL(integrationResourceUrl);
        urlWithSubdomain.hostname = currentDomain;
        resource.url = urlWithSubdomain.href;
      }
    } else {
      resource.url = integrationResourceUrl;
    }
  }

  return resource;
}

export async function getResourcesForViewer(
  resourceIds,
  isPortal = false,
  includesResourceInteractions = false,
  includeRecordedFiles = false
) {
  if (!resourceIds || resourceIds.length === 0) {
    return [];
  }

  let resources;
  if (isPortal) {
    // const body = { resourceIds };
    // if (includesResourceInteractions) {
    //   body.includesResourceInteractions = includesResourceInteractions;
    // }

    // if (includeRecordedFiles) {
    //   body.includeRecordedFiles = includeRecordedFiles;
    // }
    resources = await fetchResourcesByIdsInPortal(
      resourceIds,
      includesResourceInteractions,
      includeRecordedFiles
    );
    console.log('fetchResourcesByIdsInPortal', resourceIds, resources);
  } else {
    resources = await fetchResourcesByIdsInRuntime(resourceIds);
    console.log('fetchResourcesByIdsInRuntime', resourceIds, resources);
  }

  if (_.some(resources, (resource) => isIntegrationResource(resource.url))) {
    const newResp = await Promise.all(
      resources.map(async (res) => {
        if (!isIntegrationResource(res.url)) {
          return res;
        }
        const integrationResourceUrl = await getIntegrationResourceUrl(res.url, isPortal);
        const newRes = res;
        newRes.url = integrationResourceUrl;
        return newRes;
      })
    );
    return newResp;
  }

  return resources;
}

export function fetchVideoIndexerAccessToken() {
  if (spaceUser.hasLoggedIn()) {
    return RuntimeApi.get(`${getResourceRuntimeApi()}/api/runtime/resources/insights/access-token`);
  } else {
    return Api.get(`${getResourceApi()}/api/resources/insights/access-token`);
  }
}

export function convertResource(spaceId, directoryId, materialId, isPortal = false) {
  if (!materialId) {
    return null;
  }
  if (isPortal) {
    return Api.post(
      `${getResourceApi()}/api/spaces/${spaceId}/directories/${directoryId}/materials/${materialId}/conversions`
    );
  }
  return RuntimeApi.post(
    `${getResourceRuntimeApi()}/api/runtime/resources/directories/${directoryId}/runtime-materials/${materialId}/conversions`
  );
}

export function triggerResourceConversionRequest(
  spaceId,
  directoryId,
  materialId,
  resourceId,
  isPortal = false
) {
  // For global resources
  if (!materialId && resourceId) {
    return Api.post(
      `${getResourceApi()}/api/global-resources/directories/0/resources/${resourceId}/conversions`
    );
  }
  return convertResource(spaceId, directoryId, materialId, isPortal);
}

export function getFonts(isPortal = false, includeDeletedFonts = false) {
  let params = '';
  if (includeDeletedFonts) {
    params = `includeDeletedFonts=${includeDeletedFonts}`;
  }
  if (isPortal) {
    let url = `${getResourceApi()}/api/fonts`;
    if (params) {
      url = `${url}?${params}`;
    }
    return Api.get(url);
  }
  return RuntimeApi.get(`${getResourceRuntimeApi()}/api/runtime/resources/fonts`);
}

export function deleteResource(resourceId) {
  return Api.delete(`${getResourceApi()}/api/resources/${resourceId}`);
}

export function fetchSpaceDirectoryByType(spaceId, directoryType, isPortal = false) {
  if (isPortal) {
    return Api.get(
      `${getResourceApi()}/api/spaces/${spaceId}/directories?directoryType=${directoryType}`
    );
  }
  return RuntimeApi.get(
    `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/runtime-materials?directoryType=${directoryType}`
  );
}

export async function getSpaceDirectoryByType(spaceId, directoryType, isPortal = false) {
  if (!spaceId || !directoryType) return null;
  try {
    const resp = await fetchSpaceDirectoryByType(spaceId, directoryType, isPortal);
    if (resp) {
      if (!resp.spaceDirectories?.length) {
        throw new Error('Not found directory');
      }
      const foundDirectory = resp.spaceDirectories[0];
      return foundDirectory;
    }
    return null;
  } catch (ex) {
    console.log('getSpaceDirectoryByType ERROR ', ex);
    return null;
  }
}

export function downloadDirectory(portal, spaceId, directoryId, data) {
  const spaceFolderId = data?.spaceFolderId;
  const isExternal = data?.isExternal || false;
  const isPortal = portal || false;
  if (isPortal) {
    let url = `/api/spaces/${spaceId}/directories/${directoryId}/download`;
    if (isExternal && _isString(directoryId) && spaceFolderId > 0)
      url = `${url}?spaceFolderId=${spaceFolderId}`;
    return Api.get(`${getResourceApi()}${url}`);
  } else {
    let url = `/api/runtime/spaces/${spaceId}/runtime-directories/${directoryId}/download`;
    if (isExternal && _isString(directoryId) && spaceFolderId > 0)
      url = `${url}?spaceFolderId=${spaceFolderId}`;
    return RuntimeApi.get(`${getResourceRuntimeApi()}${url}`);
  }
}

export function getResourceThumbnailUrl(resourceId, isPortal = false) {
  if (isPortal) return Api.get(`${getResourceApi()}/api/resources/${resourceId}/thumbnail`);
  return RuntimeApi.get(`${getResourceRuntimeApi()}/api/runtime/resources/${resourceId}/thumbnail`);
}

export const getResourceByIds = _memoize(
  async (ids) => {
    let filterString = '';
    if (ids && ids.length > 0) {
      ids.forEach((id) => {
        if (filterString) {
          filterString += '&';
        }
        filterString += `resourceids=${id}`;
      });
    }
    return Api.get(`${getResourceApi()}/api/resources/names?${filterString}`);
  },
  (ids) => ids
);
export function checkUREmbeddable(url) {
  let finalUrl = url;
  const isHttp = url.includes('http://');
  if (url.match(/^https?:\/\//)) {
    finalUrl = url.replace(/^https?:\/\//, '');
  }
  finalUrl = encodeURIComponent(finalUrl);
  return Api.get(`${getResourceApi()}/api/resources/linkinfo?url=${finalUrl}&isHttp=${isHttp}`);
}

export function downloadBulkResources(portal, spaceId, data) {
  const isPortal = portal || false;
  if (isPortal) {
    return Api.post(`${getResourceApi()}/api/spaces/${spaceId}/materials/download`, data);
  } else {
    return RuntimeApi.post(
      `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/runtime-materials/download`,
      data
    );
  }
}

export function deletedBulkResources(portal, spaceId, data) {
  const isPortal = portal || false;

  if (isPortal) {
    return Api.delete(`${getResourceApi()}/api/spaces/${spaceId}/materials/delete`, data);
  } else {
    return RuntimeApi.delete(
      `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/runtime-materials/delete`,
      data
    );
  }
}

export function getResourceUsageData(resourceId) {
  if (resourceId) {
    return Api.get(`${getResourceApi()}/api/global-resources/resources/${resourceId}/usage`);
  }
  return null;
}

export function fetchFolderDetails(
  spaceId,
  folderId,
  isPortal = false,
  externalId = null,
  pageToken = null
) {
  let url;
  if (isPortal) {
    url = `/api/spaces/${spaceId}/directories/${folderId}`;
  } else {
    url = `/api/runtime/spaces/${spaceId}/directories/${folderId}`;
  }
  if (externalId) {
    url = `${url}?externalId=${externalId}`;
  }
  if (pageToken) {
    url = `${url}&pageToken=${pageToken}`;
  }
  const promise = isPortal
    ? Api.get(`${getResourceApi()}${url}`)
    : RuntimeApi.get(`${getResourceRuntimeApi()}${url}`);
  return promise
    .then((resp) => resp)
    .catch((error) => {
      console.error(error);
      return {};
    });
}

export function createRootDirectory(spaceId) {
  return RuntimeApi.post(`${getResourceApi()}/api/runtime/spaces/${spaceId}/root-directories`);
}

export function updateTermsAndCondition(organizationId, tAC) {
  return Api.put(`${getCoreApi()}/organizations/${organizationId}/tac`, tAC);
}

export function deleteTermsAndCondition(organizationId) {
  return Api.delete(`${getCoreApi()}/organizations/${organizationId}/tac`);
}

export function fetchDownloadRequestIds(requestIds, isPortal = true) {
  let filterString = '';
  if (requestIds && requestIds.length > 0) {
    requestIds.forEach((id) => {
      if (filterString) {
        filterString += '&';
      }
      filterString += `ids=${id}`;
    });
  }
  if (isPortal) {
    return Api.get(`${getResourceApi()}/api/resources/download/requests?${filterString}`);
  }
  return RuntimeApi.get(
    `${getResourceRuntimeApi()}/api/runtime/resources/download/requests?${filterString}`
  );
}

export function cancelSessionsId(sessionIds, isPortal = true) {
  if (isPortal) {
    return Api.put(`${getResourceApi()}/api/upload/sessions/cancel`, { sessionIds });
  }
  return RuntimeApi.put(`${getResourceRuntimeApi()}/api/runtime/upload/sessions/cancel`, {
    sessionIds,
  });
}

export function createExternalConnectionIfNotExists(spaceId, data, isPortal = false) {
  if (isPortal) {
    return Api.post(
      `${getResourceApi()}/api/spaces/${spaceId}/materials/external-connections`,
      data
    );
  } else {
    return RuntimeApi.post(
      `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/runtime-materials/external-connections`,
      data
    );
  }
}

export function fetchSpaceMaterialsByIds(
  spaceId,
  joinedMaterialIds,
  shouldCreateInteractionToken = false
) {
  return RuntimeApi.get(
    `${getResourceRuntimeApi()}/api/runtime/spaces/${spaceId}/materials-by-ids?materialIds=${joinedMaterialIds}&shouldCreateInteractionToken=${shouldCreateInteractionToken}`
  )
    .then((resp) => resp.items)
    .catch((error) => {
      console.error(error);
      return [];
    });
}

export function copyPasteMaterials(spaceId, materialIds, directoryIds, fromSpaceId) {
  const data = { materialIds, directoryIds, fromSpaceId };
  return Api.post(`${getResourceApi()}/api/spaces/${spaceId}/materials/copy-paste`, data);
}

export function fetchCadToken(isPortal) {
  if (isPortal) {
    return Api.get(`${getResourceApi()}/api/resources/cad-token`);
  }
  return RuntimeApi.get(`${getResourceRuntimeApi()}/api/runtime/resources/cad-token`);
}

export function createLmsDirectories(spaceId, data) {
  return Api.post(`${getResourceApi()}/api/spaces/${spaceId}/directories/lms`, {
    folders: data,
  });
}
