import React, { createRef, PureComponent } from 'react';
import clsx from 'clsx';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';

import PropTypes from 'prop-types';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { defaultTheme } from '../../theme';
import queryCache from '../../app/queryCache';

// import BlockUI from '../components/BlockUI';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import i18n from '../../i18n';
import './DeleteUserDialog.scss';
import UsersSelect from './UsersSelect';
import OfficialButton from '../../components/OfficialButtons';
import { OrganizationUserRole } from '../../app/appConstants';
import { isOrgRole } from '../../commons/utils';
import currentOrganization from '../../commons/CurrentOrganization';

function StyledRadio(props) {
  return (
    <Radio
      className="spce-radio-button"
      disableRipple
      color="default"
      checkedIcon={<span className="spce-radio-button-icon-checked" />}
      icon={<span className="spce-radio-button-icon" />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

let resolve;
let containerElement;
let rootEl;

class DeleteUserDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      isProcessing: false,
      isTransferData: '1',
      userTransfer: null,
    };
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.onChangeTransformData = this.onChangeTransformData.bind(this);
    this.handleSelectedUserTransfer = this.handleSelectedUserTransfer.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.userSelectRef = createRef(null);
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handleHistoryStateChanged);
  }

  componentWillUnmount() {
    console.log('componentWillUnmount ConfirmRemoveBlockDialog');
    window.removeEventListener('popstate', this.handleHistoryStateChanged);
    resolve = null;
    containerElement = null;
  }

  handleHistoryStateChanged() {
    this.setState({ isOpen: false }, () => {
      DeleteUserDialog.destroy();
    });
  }

  static destroy(retVal = false) {
    if (containerElement) {
      setTimeout(() => {
        rootEl.unmount();
      }, 0);
    }
    resolve(retVal);
  }

  static show(user = null) {
    containerElement = document.createElement('div');
    rootEl = createRoot(containerElement);
    rootEl.render(
      <ThemeProvider theme={defaultTheme}>
        <QueryClientProvider client={queryCache}>
          <DeleteUserDialog user={user} />
        </QueryClientProvider>
      </ThemeProvider>
    );
    return new Promise((res) => {
      resolve = res;
    });
  }

  handleCloseDialog() {
    this.setState({ isOpen: false }, () => {
      DeleteUserDialog.destroy();
    });
  }

  handleConfirm() {
    if (isOrgRole(this.props.user?.role, OrganizationUserRole.Partner)) {
      this.setState({ isOpen: false }, () => {
        DeleteUserDialog.destroy({ hasConfirmed: true });
      });
      return;
    }
    if (Number(this.state.isTransferData) && !this.userSelectRef.current.isValid()) {
      return;
    }
    this.setState({ isOpen: false }, () => {
      DeleteUserDialog.destroy({
        hasConfirmed: true,
        isTransferData: Number(this.state.isTransferData),
        userTransfer: this.state.userTransfer,
      });
    });
  }

  onChangeTransformData(event) {
    this.setState({ isTransferData: event.target.value });
  }

  handleSelectedUserTransfer(value) {
    this.setState({ userTransfer: value });
  }

  handleRenderContent() {
    const { user } = this.props;
    if (isOrgRole(user.role, OrganizationUserRole.Partner)) {
      return (
        <Grid className="content" container direction="column">
          <h1 className="title">Delete partner</h1>
          <p className="description">
            This will delete the partner. They will no longer be able to log in or use SP_CE.
            Historical data in insights & analytics will not be affected. Are you sure you want to
            continue?
          </p>
        </Grid>
      );
    }
    return (
      <Grid className="content" container direction="column">
        <h1 className="title">Delete user</h1>
        <p className="description">
          This action will delete the user which means they will no longer be able to log in or use
          SP_CE. Let us know how you want to process this user’s data:
        </p>
        <RadioGroup
          onChange={this.onChangeTransformData}
          value={this.state.isTransferData}
          aria-label="process-delete-data"
          name="radios"
          className="delete-user-options"
        >
          <FormControlLabel
            className="label-radio"
            value="1"
            control={<StyledRadio />}
            label={
              <>
                <b>Transfer data to another user</b>
                <div className="label-sub">
                  <b>The transfer is immediate, you cannot undo this action. </b>
                  <br />
                  Transfer this user's spaces and templates to another user. Historical data in
                  insights & analytics will not be affected.{' '}
                </div>
                <UsersSelect
                  ref={this.userSelectRef}
                  disabled={this.state.isTransferData !== '1'}
                  label="You are transferring data to:"
                  className="participants-select"
                  placeholder="Please select a user"
                  name="participant-select"
                  onChange={this.handleSelectedUserTransfer}
                  exceptUserId={user?.userInfoId}
                  isCheckAdmin={isOrgRole(user.role, OrganizationUserRole.Admin)}
                  organizationId={currentOrganization.getOrganizationId()}
                />
              </>
            }
          />
          <FormControlLabel
            className="label-radio"
            value="0"
            control={<StyledRadio />}
            label={
              <>
                <b>Delete the data</b>
                <div className="label-sub">
                  Deletion is immediate, you cannot undo this action. Spaces and templates will be
                  deleted from the organization's SP_CE account.
                </div>
              </>
            }
          />
        </RadioGroup>
      </Grid>
    );
  }

  render() {
    const { isOpen } = this.state;
    const { user } = this.props;

    if (!this.state.isOpen) {
      return null;
    }

    return (
      <ResponsiveDialog
        modal
        open={isOpen}
        maxWidth="lg"
        className={clsx('white confirm-dialog delete-user-dialog', {
          'delete-partner': isOrgRole(user.role, OrganizationUserRole.Partner),
        })}
        onClose={this.handleCloseDialog}
      >
        <>
          {this.handleRenderContent()}
          <div className="buttons">
            <OfficialButton
              onClick={this.handleCloseDialog}
              variant="large-secondary"
              label={i18n.t('cancel')}
            />
            <OfficialButton
              onClick={this.handleConfirm}
              variant="large-primary"
              label={i18n.t('Confirm')}
            />
          </div>
        </>
      </ResponsiveDialog>
    );
  }
}

DeleteUserDialog.propTypes = {
  user: PropTypes.instanceOf(Object),
};

export default DeleteUserDialog;
