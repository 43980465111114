import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n';
import HeaderUser from '../components/HeaderUser';
import TabPanel from '../../components/TabPanel';

import './Notifications.scss';
import SettingNotificationsForYou from './SettingNotificationsForYou';
import SettingNotificationsForParticipants from './SettingNotificationsForParticipants';
import Tabs, { useTabs } from '../../components/Tabs';
import currentOrganization from '../../commons/CurrentOrganization';

const NotificationPage = (props) => {
  const { handleDrawerToggle, isOpen } = props;
  const { tabs, changeTab, activeTab } = useTabs(
    [i18n.t('For you'), i18n.t('For users')],
    location?.state?.tab || 0
  );
  const isPartner = currentOrganization.isPartner();

  return (
    <div className="notifications">
      <HeaderUser onClick={handleDrawerToggle} isOpen={isOpen} title={i18n.t('Notifications')}>
        {!isPartner && <Tabs tabs={tabs} onChange={changeTab} variant="tabs-level-2" />}
      </HeaderUser>

      <TabPanel value={activeTab} index={0}>
        <SettingNotificationsForYou />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <SettingNotificationsForParticipants />
      </TabPanel>
    </div>
  );
};

NotificationPage.propTypes = {
  handleDrawerToggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default NotificationPage;
