import React, { useMemo } from 'react';
import { Select } from '@mui/material';

import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import './RoleUserSelect.scss';
import { OrganizationUserRole } from '../../app/appConstants';
import LightTooltip from '../../components/LightTooltip';
import { isOrgRole } from '../../commons/utils';
import { MenuEnhancedProps } from '../../components/SelectEnhanced';

const RoleUserSelect = (props) => {
  const options = useMemo(() => {
    const opts = [
      {
        id: OrganizationUserRole.Admin,
        name: 'Admin',
      },
      {
        id: OrganizationUserRole.User,
        name: 'User',
      },
      {
        id: OrganizationUserRole.Partner,
        name: 'Partner',
        disabled: props.isEdit,
        disabledTooltipTitle:
          'Unfortunately, changing the user type to Partner is not currently possible.',
        disabledTooltip: 'Unfortunately, changing the user type is not currently possible.',
      },
    ];

    return opts;
  }, [props.isEdit]);

  const [value, setValue] = React.useState(() => {
    const v = props.defaultValue;
    const foundOption = options.find((e) => e.id === v);
    return foundOption || options[0];
  });
  if (!value) {
    return null;
  }

  function handleOnChange(event) {
    const { target } = event;
    const foundOption = options.find((e) => e.id === target.value);
    if (foundOption.disabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    setValue(foundOption);
    props.onChange({ target: { name: 'role', value: target.value } });
  }

  return (
    <Select
      name="role"
      className="role-user-select"
      value={value.id}
      onChange={handleOnChange}
      disabled={props.disabled || value.disabled}
      MenuProps={{ ...MenuEnhancedProps, classes: { paper: 'select-input-menu select-menu' } }}
    >
      {options.map((item, index) => {
        const isPartner = isOrgRole(value.id, OrganizationUserRole.Partner);
        return (
          <MenuItem key={index} value={item.id} disabled={item.disabled || isPartner}>
            <LightTooltip
              title={isPartner ? item.disabledTooltip : item.disabledTooltipTitle}
              disableHoverListener={!item.disabled}
            >
              <div className="role-option">{item.name}</div>
            </LightTooltip>
          </MenuItem>
        );
      })}
    </Select>
  );
};

RoleUserSelect.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isEdit: PropTypes.bool,
};

RoleUserSelect.defaultProps = {
  variant: 'black',
};

export default RoleUserSelect;
