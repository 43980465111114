/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import _cloneDeep from 'lodash/cloneDeep';
import i18n from '../../../i18n';
import ContentTab from './ContentTab';
import LayoutForm from './LayoutForm';
import ContentStyleTab from './ContentStyleTab';
import { useThemeSettingsContext } from '../context/ThemeSettingsContextProvider';
import {
  getLayout,
  updateBlockProp,
  getDefaultDesignSettings,
  updateHeadingFontColorAndFamily,
  updateHeadingFontSize,
  getResourceFieldName,
} from './functions';
import { CMS_COMPONENT_STYLE, CMS_COMPONENT_TYPE, CMS_DEFAULT_VALUE } from '../../cmsConstants';
import { newGuid } from '../../../commons/utils';
import SearchUnsplash from '../../../components/Unsplash/SearchUnsplash';
import { getExtensionFromFileName } from '../../../commons/ResourceUtils';
import { ResourceType } from '../../../app/appConstants';
import currentOrganization from '../../../commons/CurrentOrganization';
import TabPanel from './TabPanel';
import updateVideoBlockProps from './updateVideoBlockProps';
import { useAppContext } from '../../../components/AppProvider';
import { rearrangePages } from '../../cmsServices';
import PagesContentTab from './PagesContentTab';

function EditingCmsComponentPanel(props) {
  const { setFooter, spaceId, setHeader, isAdmin } = props;
  const {
    updateBlock,
    selectedBlock,
    space,
    openUnsplashPreview,
    workingData,
    setOpenUnsplashPreview,
    setWorkingData,
  } = useThemeSettingsContext();

  const [tab, setTab] = useState(0);
  const [contentSelectedTab, setContentSelectedTab] = useState(workingData?.currentTab || 0);

  const { isMobile } = useAppContext();

  const layout = useMemo(
    () => getLayout(selectedBlock, isMobile, workingData?.currentTab),
    [isMobile, selectedBlock, workingData?.currentTab]
  );

  const type = selectedBlock?.items?.[0]?.type;

  useEffect(() => {
    if (workingData?.currentTab !== undefined) {
      setContentSelectedTab(workingData?.currentTab);
    }
  }, [workingData?.currentTab]);

  useEffect(() => {
    return () => {
      setFooter(null);
    };
  }, [setFooter]);

  function handleCloseUnsplashPreview() {
    setOpenUnsplashPreview(false);
    setWorkingData(null);
    if (props.onClose) props.onClose();
  }

  function goBackToContentTab() {
    setOpenUnsplashPreview(false);
    setWorkingData(null);
    setTab(1);
  }

  useEffect(() => {
    if (openUnsplashPreview) {
      setHeader(
        <Box className="designer-side-panel-header">
          <Typography className="title">
            <ArrowBackIosIcon className="back-button" onClick={goBackToContentTab} />
            {i18n.t('  Unsplash')}
          </Typography>
          <IconButton onClick={handleCloseUnsplashPreview}>
            <CloseIcon />
          </IconButton>
        </Box>
      );

      setFooter(null);
    }

    return () => {
      setHeader(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openUnsplashPreview]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    if (workingData?.currentTab !== undefined) {
      setContentSelectedTab(workingData?.currentTab);
    }
  };

  const updateColumn = (block, data) => {
    const { action } = data;
    let newBlock = _cloneDeep(block);
    let isGreater = false; // the new number of columns > the old value or not

    if (action === 'remove') {
      newBlock.items.splice(data.value, 1);
    } else if (action === 'add') {
      isGreater = true;
      const currentLength = newBlock.items.length;
      const newItem = _cloneDeep(newBlock.items[currentLength - 1]);
      newItem.id = newGuid();
      // remove resource links
      delete newItem.backgroundImageUrl;
      delete newItem.backgroundImageUrlMaterialId;
      delete newItem.backgroundImageUrlResourceId;
      delete newItem.backgroundImageCropped;
      delete newItem.backgroundImageUrlCropped;
      delete newItem.backgroundImageUrlCroppedMaterialId;
      delete newItem.backgroundImageUrlCroppedResourceId;
      delete newItem.videoUrl;
      delete newItem.videoUrlMaterialId;
      delete newItem.videoUrlResourceId;
      newBlock.items.push(newItem);
    }

    const isOverlay =
      !newBlock.layout || newBlock.layout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.OVERLAY;
    const noColumns = newBlock.items.length;
    newBlock.columns = noColumns;
    switch (type) {
      case CMS_COMPONENT_TYPE.VIDEO:
        if (noColumns === 1) {
          const sizeText =
            newBlock.size === CMS_COMPONENT_STYLE.SIZE.MEDIUM
              ? CMS_COMPONENT_STYLE.SIZE.MEDIUM
              : CMS_COMPONENT_STYLE.SIZE.LARGE;
          newBlock = updateHeadingFontColorAndFamily(space?.theme, newBlock, sizeText);
          newBlock = updateBlockProp(newBlock, 'textSize', sizeText);
        } else if (noColumns === 2) {
          newBlock = updateHeadingFontColorAndFamily(
            space?.theme,
            newBlock,
            CMS_COMPONENT_STYLE.SIZE.MEDIUM
          );
          newBlock = updateBlockProp(newBlock, 'textSize', CMS_COMPONENT_STYLE.SIZE.MEDIUM);
          newBlock.size = CMS_COMPONENT_STYLE.SIZE.FULLWIDTH;
        } else if (noColumns > 2) {
          if (isOverlay) {
            newBlock = updateBlockProp(newBlock, 'description', null);
          }
          newBlock = updateHeadingFontColorAndFamily(
            space?.theme,
            newBlock,
            CMS_COMPONENT_STYLE.SIZE.SMALL
          );
          newBlock = updateBlockProp(newBlock, 'textSize', CMS_COMPONENT_STYLE.SIZE.SMALL);
          newBlock.size = CMS_COMPONENT_STYLE.SIZE.FULLWIDTH;
        }
        newBlock = updateHeadingFontSize(space?.theme, newBlock, isGreater);
        break;
      case CMS_COMPONENT_TYPE.CARD_TEXT:
        if (noColumns === 1) {
          newBlock = updateHeadingFontColorAndFamily(
            space?.theme,
            newBlock,
            CMS_COMPONENT_STYLE.SIZE.LARGE
          );
          newBlock = updateBlockProp(newBlock, 'textSize', CMS_COMPONENT_STYLE.SIZE.LARGE);
        } else if (noColumns === 2) {
          newBlock = updateHeadingFontColorAndFamily(
            space?.theme,
            newBlock,
            CMS_COMPONENT_STYLE.SIZE.MEDIUM
          );
          newBlock = updateBlockProp(newBlock, 'textSize', CMS_COMPONENT_STYLE.SIZE.MEDIUM);
          newBlock.size = CMS_COMPONENT_STYLE.SIZE.FULLWIDTH;
        } else if (noColumns > 2) {
          newBlock = updateHeadingFontColorAndFamily(
            space?.theme,
            newBlock,
            CMS_COMPONENT_STYLE.SIZE.SMALL
          );
          newBlock = updateBlockProp(newBlock, 'textSize', CMS_COMPONENT_STYLE.SIZE.SMALL);
          newBlock.size = CMS_COMPONENT_STYLE.SIZE.FULLWIDTH;
        }
        newBlock = updateHeadingFontSize(space?.theme, newBlock, isGreater);
        break;
      case CMS_COMPONENT_TYPE.CARD_IMAGE_AND_TEXT:
        if (noColumns === 1) {
          newBlock = updateHeadingFontColorAndFamily(
            space?.theme,
            newBlock,
            CMS_COMPONENT_STYLE.SIZE.LARGE
          );
          newBlock = updateBlockProp(newBlock, 'textSize', CMS_COMPONENT_STYLE.SIZE.LARGE);
        } else if (noColumns === 2) {
          newBlock = updateHeadingFontColorAndFamily(
            space?.theme,
            newBlock,
            CMS_COMPONENT_STYLE.SIZE.MEDIUM
          );
          newBlock = updateBlockProp(newBlock, 'textSize', CMS_COMPONENT_STYLE.SIZE.MEDIUM);
          newBlock.size = CMS_COMPONENT_STYLE.SIZE.FULLWIDTH;
        } else if (noColumns > 2) {
          newBlock = updateHeadingFontColorAndFamily(
            space?.theme,
            newBlock,
            CMS_COMPONENT_STYLE.SIZE.SMALL
          );
          newBlock = updateBlockProp(newBlock, 'textSize', CMS_COMPONENT_STYLE.SIZE.SMALL);
          newBlock.size = CMS_COMPONENT_STYLE.SIZE.FULLWIDTH;
        }
        newBlock = updateBlockProp(
          newBlock,
          'textPosition',
          CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM
        );
        newBlock = updateHeadingFontSize(space?.theme, newBlock, isGreater);
        break;
      case CMS_COMPONENT_TYPE.CARD_IMAGE_OVERLAY: {
        if (noColumns === 1) {
          newBlock = updateHeadingFontColorAndFamily(
            space?.theme,
            newBlock,
            CMS_COMPONENT_STYLE.SIZE.LARGE
          );
          newBlock = updateBlockProp(newBlock, 'textSize', CMS_COMPONENT_STYLE.SIZE.LARGE);
        } else if (noColumns === 2) {
          newBlock = updateHeadingFontColorAndFamily(
            space?.theme,
            newBlock,
            CMS_COMPONENT_STYLE.SIZE.MEDIUM
          );
          newBlock = updateBlockProp(newBlock, 'textSize', CMS_COMPONENT_STYLE.SIZE.MEDIUM);
          newBlock.size = CMS_COMPONENT_STYLE.SIZE.FULLWIDTH;
        } else if (noColumns > 2) {
          newBlock = updateHeadingFontColorAndFamily(
            space?.theme,
            newBlock,
            CMS_COMPONENT_STYLE.SIZE.SMALL
          );
          newBlock = updateBlockProp(newBlock, 'textSize', CMS_COMPONENT_STYLE.SIZE.SMALL);
          newBlock.size = CMS_COMPONENT_STYLE.SIZE.FULLWIDTH;
        }
        newBlock = updateBlockProp(
          newBlock,
          'textPosition',
          CMS_COMPONENT_STYLE.TEXT_POSITION.MIDDLE_CENTER
        );
        newBlock = updateHeadingFontSize(space?.theme, newBlock, isGreater);
        break;
      }
      case CMS_COMPONENT_TYPE.WEB_CONTENT:
      case CMS_COMPONENT_TYPE.HTML_CONTENT:
        if (noColumns >= 2) {
          newBlock.size = CMS_COMPONENT_STYLE.SIZE.FULLWIDTH;
        }
        break;
      default:
        break;
    }

    return newBlock;
  };

  const updatePages = async (block, data) => {
    const newPageDetails = data?.value;
    const newBlock = _cloneDeep(block);
    const action = data?.action;

    if (action === 'remove') {
      newBlock.items[0].pages = newBlock.items[0].pages.filter(
        (page) => page.id !== newPageDetails.id
      );
    } else if (action === 'add') {
      newBlock.items[0].pages.push(newPageDetails);
    }
    return newBlock;
  };

  const updatePagePosition = async (block, pages) => {
    // const { dragIndex, hoverIndex } = value;
    if (block.items[0].pages === pages) {
      return block;
    }
    const newBlock = _cloneDeep(block);
    newBlock.modifiedPages = newBlock.modifiedPages || {};
    newBlock.items[0].pages = pages;
    const pageIds = newBlock.items[0].pages.map((page) => page.id);
    const resp = await rearrangePages(spaceId, block.id, { pageIds });
    if (resp) {
      newBlock.items[0].pages = resp.pages;
    }
    return newBlock;
  };

  const updateSliders = (block, data) => {
    const newBlock = _cloneDeep(block);

    const { action, value } = data;

    if (action === 'remove') {
      newBlock.items[0].items.splice(value, 1);
    } else if (action === 'add') {
      const blockItems = newBlock.items[0].items;
      const newItem = _cloneDeep(blockItems[blockItems.length - 1]);
      newItem.id = newGuid();
      // remove resource links
      delete newItem.backgroundImageUrl;
      delete newItem.backgroundImageUrlMaterialId;
      delete newItem.backgroundImageUrlResourceId;
      delete newItem.backgroundImageCropped;
      delete newItem.backgroundImageUrlCropped;
      delete newItem.backgroundImageUrlCroppedMaterialId;
      delete newItem.backgroundImageUrlCroppedResourceId;
      blockItems.push(newItem);
    }
    return newBlock;
  };

  const updateNumberOfWidgets = (block, value) => {
    let newBlock = _cloneDeep(block);
    if (value > newBlock.items.length) {
      [...Array(value - newBlock.items.length).keys()].forEach(() => {
        const newItem = _cloneDeep(newBlock.items[0]);
        newItem.id = newGuid();
        newBlock.items.push(newItem);
      });
    } else if (value < newBlock.items.length) {
      [...Array(newBlock.items.length - value).keys()].forEach(() => {
        newBlock.items.pop();
      });
    }

    let newSize = newBlock.items[0].size;
    // update size
    if (value === 1) {
      newSize = CMS_COMPONENT_STYLE.SIZE.LARGE;
    } else if (value >= 2 && value <= 4) {
      newSize = CMS_COMPONENT_STYLE.SIZE.MEDIUM;
    } else if (value > 4) {
      newSize = CMS_COMPONENT_STYLE.SIZE.SMALL;
    }
    newBlock = updateBlockProp(newBlock, 'size', newSize);

    let columns = 1;
    switch (newSize) {
      case CMS_COMPONENT_STYLE.SIZE.LARGE:
        columns = 1;
        break;
      case CMS_COMPONENT_STYLE.SIZE.MEDIUM:
        columns = value > 2 ? 2 : value;
        break;
      case CMS_COMPONENT_STYLE.SIZE.SMALL:
        columns = value > 3 ? 3 : value;
        break;
      default:
        break;
    }
    newBlock.columns = columns;

    return newBlock;
  };

  const updateSizeForCardImageOverlay = (block, value, index) => {
    const textSize =
      value === CMS_COMPONENT_STYLE.SIZE.BANNER || value === CMS_COMPONENT_STYLE.SIZE.MEDIUM
        ? CMS_COMPONENT_STYLE.TEXT_SIZE.MEDIUM
        : CMS_COMPONENT_STYLE.TEXT_SIZE.LARGE;
    return updateBlockProp(block, 'textSize', textSize, index);
  };

  const handleOnLayoutChange = async (key, value, index) => {
    // update block data
    let newBlock = _cloneDeep(selectedBlock);
    switch (key) {
      case 'columns':
        newBlock = updateColumn(newBlock, value);
        break;
      case 'pages':
        newBlock = await updatePages(newBlock, value);
        console.log('newBlock: ', newBlock);
        break;
      case 'showHeading': {
        const heading = value ? CMS_DEFAULT_VALUE.HEADING : null;
        newBlock = updateBlockProp(newBlock, 'heading', heading, index);
        break;
      }
      case 'showDescription': {
        const description = value ? CMS_DEFAULT_VALUE.DESCRIPTION : null;
        newBlock = updateBlockProp(newBlock, 'description', description, index);
        break;
      }
      case 'showButton': {
        const buttonLabel = value ? CMS_DEFAULT_VALUE.BUTTON_LABEL : null;
        newBlock = updateBlockProp(newBlock, 'buttonLabel', buttonLabel, index);
        break;
      }
      case 'showSecondaryButton': {
        const secondaryButtonLabel = value ? CMS_DEFAULT_VALUE.SECONDARY_BUTTON_LABEL : null;
        newBlock = updateBlockProp(newBlock, 'secondaryButtonLabel', secondaryButtonLabel, index);
        break;
      }
      case 'showBackgroundImage':
        newBlock.showBackgroundImage = value;
        // newBlock.backgroundImageUrl = value ? CMS_DEFAULT_VALUE.WIDGET_BACKGROUND_IMAGE : null;
        break;
      case 'size':
        if (type === CMS_COMPONENT_TYPE.WIDGET) {
          newBlock = updateBlockProp(newBlock, key, value, index);
          if (value === CMS_COMPONENT_STYLE.SIZE.LARGE && newBlock.items.length > 1) {
            [...Array(newBlock.items.length - 1).keys()].forEach(() => {
              newBlock.items.pop();
            });
            newBlock.columns = 1;
          } else if (value === CMS_COMPONENT_STYLE.SIZE.MEDIUM && newBlock.items.length > 4) {
            [...Array(newBlock.items.length - 4).keys()].forEach(() => {
              newBlock.items.pop();
            });
            newBlock.columns = 2;
          }
        } else if (type === CMS_COMPONENT_TYPE.VIDEO) {
          if (newBlock.columns === 1) {
            const size =
              value === CMS_COMPONENT_STYLE.SIZE.MEDIUM
                ? CMS_COMPONENT_STYLE.SIZE.MEDIUM
                : CMS_COMPONENT_STYLE.SIZE.LARGE;
            newBlock = updateBlockProp(newBlock, 'textSize', size, index);
          }
          newBlock.size = value;
        } else if (
          type === CMS_COMPONENT_TYPE.CARD_IMAGE_OVERLAY ||
          type === CMS_COMPONENT_TYPE.CARD_IMAGE_AND_TEXT
        ) {
          newBlock = updateSizeForCardImageOverlay(newBlock, value, index);
          newBlock.size = value;
        } else {
          newBlock.size = value;
        }
        break;
      case 'textAlign':
      case 'opacity':
      case 'textPosition':
      case 'autoplay':
      case 'assessments':
        newBlock = updateBlockProp(newBlock, key, value, index);
        break;
      case 'imageRatio':
        newBlock = updateBlockProp(newBlock, key, value);
        break;
      case 'videoAlign':
      case 'imageAlign': {
        const textPosition =
          value === CMS_COMPONENT_STYLE.TEXT_ALIGN.LEFT
            ? CMS_COMPONENT_STYLE.TEXT_ALIGN.RIGHT
            : CMS_COMPONENT_STYLE.TEXT_ALIGN.LEFT;
        newBlock = updateBlockProp(newBlock, 'textPosition', textPosition, index);
        break;
      }
      case 'numberOfWidgets': {
        newBlock = updateNumberOfWidgets(newBlock, value);
        break;
      }
      case 'sliders': {
        newBlock = updateSliders(newBlock, value);
        break;
      }
      case 'columnPosition': {
        // let newItems = [...newBlock.items];
        const newItems = [];
        if (type === CMS_COMPONENT_TYPE.CAROUSEL) {
          value.forEach((item) => {
            if (newBlock.items[0]?.items[item.id]) {
              newItems.push(newBlock.items[0].items[item.id]);
            }
          });
          newBlock.items[0].items = newItems;
        } else {
          value.forEach((item) => {
            if (newBlock.items[item.id]) {
              newItems.push(newBlock.items[item.id]);
            }
          });
          newBlock.items = newItems;
        }
        break;
      }
      case 'pagePosition': {
        newBlock = await updatePagePosition(newBlock, value);
        break;
      }
      case 'layout': {
        if (type === CMS_COMPONENT_TYPE.VIDEO) {
          newBlock = updateVideoBlockProps(space, newBlock, value);
        }
        newBlock.layout = value;
        break;
      }
      default:
        break;
    }
    const shouldResetData =
      type === CMS_COMPONENT_TYPE.VIDEO &&
      selectedBlock.items?.length > newBlock.items?.length &&
      workingData.currentTab > 0;
    if (shouldResetData) {
      const newData = { ...workingData, currentTab: 0 };
      setWorkingData(newData);
    }
    updateBlock(newBlock);
  };

  const handleContentChange = useCallback(
    (items, forceUpdate) => {
      const newBlock = _cloneDeep(selectedBlock);
      newBlock.items = items;
      updateBlock(newBlock, forceUpdate);
    },
    [selectedBlock, updateBlock]
  );

  function handleStyleChange(prop, value) {
    if (!selectedBlock?.items?.length) return;
    const newBlock = _cloneDeep(selectedBlock);

    if (prop === 'blockBackgroundColor') {
      newBlock.backgroundColor = value;
    } else {
      const blockType = newBlock.items?.[0]?.type; // the type of the 1st items in a block/row
      if (blockType === CMS_COMPONENT_TYPE.CAROUSEL) {
        newBlock.items[0].items.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item[prop] = value;
        });
      } else {
        newBlock.items.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item[prop] = value;
        });
      }
    }

    updateBlock(newBlock);
  }

  async function handleOnSelectUnsplashPhoto(value) {
    if (
      !value ||
      !value.urls?.full ||
      !workingData ||
      workingData.currentTab === null ||
      workingData.currentTab === undefined
    )
      return;

    const src = value.urls.full;
    const url = src.split('?')[0];
    const parts = url.split('/');
    let fileName = parts[parts.length - 1];
    const extension = getExtensionFromFileName(fileName);
    if (!extension) fileName = `${fileName}.jpg`;

    // console.log('### selected Image', value);

    const fieldName = getResourceFieldName(workingData.contentType);
    if (!fieldName) return;

    // console.log('###', workingData, fileName, fieldName);

    const newResource = {
      uniqueId: newGuid(),
      fieldName,
      action: 'add',
      data: {
        type: ResourceType.image,
        resourceType: ResourceType.image,
        fileDescription: value.alt_Description || '',
        fileName,
        src,
        isExternal: true,
        order: 0,
      },
      isFileUploader: true,
    };

    const newBlock = _cloneDeep(selectedBlock);
    const blockType = newBlock.items?.[0]?.type;
    if (blockType === CMS_COMPONENT_TYPE.WIDGET) {
      newBlock.resources = [newResource];
    } else if (blockType === CMS_COMPONENT_TYPE.CAROUSEL) {
      newBlock.items[0].items[workingData.currentTab].resources = [newResource];
    } else {
      newBlock.items[workingData.currentTab].resources = [newResource];
    }

    updateBlock(newBlock, true);

    const currentContentTab = workingData.currentTab;
    setOpenUnsplashPreview(false);
    setWorkingData(null);
    setTab(1);
    setContentSelectedTab(currentContentTab);
  }

  function renderStyleTab() {
    const defaultDesignSettings = getDefaultDesignSettings(selectedBlock, space?.theme, true);
    console.log('defaultDesignSettings : ', defaultDesignSettings);
    return (
      <ContentStyleTab
        layout={layout}
        onChange={handleStyleChange}
        defaultDesignSettings={defaultDesignSettings}
      />
    );
  }

  if (!layout) {
    return null;
  }

  if (openUnsplashPreview) {
    return (
      <SearchUnsplash
        slots={{
          button: {
            variant: 'regular-green',
            className: 'search-unsplash-select-button',
          },
        }}
        onSelectPhoto={handleOnSelectUnsplashPhoto}
      />
    );
  }
  return (
    <>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        className="cms-editing-component-panel-tabs"
      >
        <Tab label={i18n.t('Layout')} className="cms-editing-component-panel-tab" />
        <Tab label={i18n.t('Content')} className="cms-editing-component-panel-tab" />
        {!currentOrganization.isFreemium() && (
          <Tab label={i18n.t('Style')} className="cms-editing-component-panel-tab" />
        )}
      </Tabs>
      {tab === 0 && (
        <TabPanel value={tab} index={0} className="cms-editing-component-panel-tabpanel">
          <LayoutForm
            key={layout?.id}
            defaultTab={contentSelectedTab}
            spaceId={spaceId}
            layout={layout}
            onChange={handleOnLayoutChange}
          />
        </TabPanel>
      )}
      {tab === 1 && (
        <TabPanel value={tab} index={1} className="cms-editing-component-panel-tabpanel">
          {tab === 1 && !selectedBlock?.isPagesBlock ? (
            <ContentTab
              onChange={handleContentChange}
              layout={layout}
              spaceId={spaceId}
              defaultTab={contentSelectedTab}
              isAdmin={isAdmin}
            />
          ) : (
            <PagesContentTab layout={layout} spaceId={spaceId} isAdmin={isAdmin} />
          )}
        </TabPanel>
      )}
      {tab === 2 && (
        <TabPanel value={tab} index={2} className="cms-editing-component-panel-tabpanel">
          {renderStyleTab()}
        </TabPanel>
      )}
    </>
  );
}

EditingCmsComponentPanel.propTypes = {
  setFooter: PropTypes.func,
  setHeader: PropTypes.func,
  spaceId: PropTypes.string,
  onClose: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default EditingCmsComponentPanel;
