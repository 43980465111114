import { MenuItem, Select, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import ButtonEnhanced from '../../components/ButtonEnhanced';
import i18n from '../../i18n';
import { useThemeSettingsContext } from './context/ThemeSettingsContextProvider';
import {
  getDefaultScriptProvider,
  isHubspotScript,
  isJavascript,
  isMarketoScript,
  isSalesforceScript,
  isFacebookScript,
  // isSupportedScript,
  parseScript,
  ProviderId,
  ScriptProviders,
  validateScript,
} from '../cmsScriptUtils';
import { CacheKeys, clearCache } from '../../app/queryCache';
import { useUnmountEffect } from '../../commons/CustomHooks';
import CmsCheckbox from '../commons/CmsCheckbox';
import useMountEffect from '../../commons/useMountEffect';
import Icons from '../../components/Icons';
import { MenuEnhancedProps } from '../../components/SelectEnhanced';

function ScriptInput(props) {
  const { defaultValue, onChange, componentSettings } = props;
  const { scriptProvider, useStyle } = componentSettings;
  const [script, setScript] = React.useState(defaultValue);
  const { onClickPreviewScript, isTemplate, isCreatedFromTemplate } = useThemeSettingsContext();
  const { register, errors, trigger } = useForm();
  const [providerId, setProviderId] = React.useState(scriptProvider || '');
  const [applyDefaultStyle, setApplyDefaultStyle] = React.useState(useStyle || false);

  // console.log('errors', errors);

  function isOneScriptPerSpace(value) {
    const isValid = validateScript(value);
    return isValid;
  }

  // function isSupported(value) {
  //   if (!value) {
  //     return true;
  //   }
  //   const scriptObject = isSupportedScript(value);
  //   return !!scriptObject;
  // }

  function isValidScriptValidation(value) {
    if (!value) {
      return true;
    }
    const scriptObject = parseScript(value);
    return !!scriptObject;
  }

  function isValidData(value) {
    const duplicateCheck = isOneScriptPerSpace(value);
    const validCheck = isValidScriptValidation(value);
    return duplicateCheck && validCheck;
  }

  function isScriptMatchedProvider(value) {
    if (!value) {
      return true;
    }
    return (
      (providerId === ProviderId.Hubspot && isHubspotScript(value)) ||
      (providerId === ProviderId.Marketo && isMarketoScript(value)) ||
      (providerId === ProviderId.Salesforce && isSalesforceScript(value)) ||
      (providerId === ProviderId.Facebook && isFacebookScript(value)) ||
      (providerId === ProviderId.Javascript && isJavascript(value))
    );
  }

  const handleOnChangeContent = useCallback(
    (event) => {
      const { name, value, checked } = event.target;
      const values = [script, providerId, applyDefaultStyle];
      switch (name) {
        case 'script':
          setScript(value);
          onChange('script', '');
          values[0] = value;
          // Determine the default provider if provider is not selected
          if (!providerId && value) {
            const newProvider = getDefaultScriptProvider(undefined, value);
            setProviderId(newProvider?.id);
            values[1] = newProvider?.id;
          }
          break;
        case 'scriptProvider':
          setProviderId(value);
          values[1] = value;
          if (value !== ProviderId.Salesforce) {
            setApplyDefaultStyle(false);
            values[2] = false;
          }
          break;
        case 'useStyle':
          setApplyDefaultStyle(checked);
          values[2] = checked;
          break;
        default:
          break;
      }
      // trigger('script');
      setTimeout(() => {
        onChange(['script', 'scriptProvider', 'useStyle'], values);
      }, 300);
    },
    [applyDefaultStyle, script, providerId, onChange]
  );
  const inputRef = register('script', {
    validate: {
      isValidScript: (s) =>
        isValidScriptValidation(s) ||
        i18n.t('We can’t preview your script. Please check and try preview again.'),
      isMatchedScript: (s) =>
        isScriptMatchedProvider(s) || i18n.t('Your script is not from the selected provider.'),
    },
  });
  useMountEffect(() => {
    // Determine the default provider for old data
    if (!scriptProvider && defaultValue) {
      const provider = getDefaultScriptProvider(scriptProvider, defaultValue);
      setProviderId(provider?.id);
      handleOnChangeContent({ target: { name: 'scriptProvider', value: provider?.id } });
    }
  });

  React.useEffect(() => {
    // trigger script to validate default values
    // also validate when change provider and script
    trigger('script');
  }, [trigger, providerId, script]);

  useUnmountEffect(() => {
    clearCache([CacheKeys.fetchSpaceInvitation], false);
    clearCache([CacheKeys.fetchSpaceAcessInfo], false);
  });

  function handleOnClickPreview() {
    if (onClickPreviewScript) {
      onClickPreviewScript(componentSettings);
    }
  }

  return (
    <div className="script-input">
      <div className="cms-component-panel-control">
        <label className="cms-component-panel-control-label">{i18n.t('Script provider')}</label>
        <Select
          name="scriptProvider"
          variant="outlined"
          fullWidth
          onChange={handleOnChangeContent}
          value={providerId}
          MenuProps={MenuEnhancedProps}
          // error={!providerId}
          renderValue={(v) => {
            if (!v) return i18n.t('Select an option');
            const selectedProvider = getDefaultScriptProvider(v);
            return selectedProvider?.name;
          }}
          displayEmpty
        >
          {ScriptProviders.map((e) => (
            <MenuItem key={e.id} value={e.id}>
              <Typography variant="inherit">{e.name}</Typography>
            </MenuItem>
          ))}
        </Select>
      </div>
      {providerId === 3 ? (
        <CmsCheckbox
          key="useStyle"
          checked={applyDefaultStyle}
          onChange={handleOnChangeContent}
          label={i18n.t('Apply custom design')}
          name="useStyle"
        />
      ) : null}
      <div className="cms-component-panel-control">
        <label className="cms-component-panel-control-label">{i18n.t('Script')}</label>
        <TextField
          autoComplete="off"
          fullWidth
          multiline
          className="cms-component-panel-control-input textarea-script"
          variant="outlined"
          placeholder={i18n.t('Enter your script here')}
          defaultValue={defaultValue}
          name="script"
          onChange={handleOnChangeContent}
          error={!!errors?.script}
          helperText={errors?.script?.message}
          inputRef={inputRef.ref}
          disabled={isCreatedFromTemplate || !providerId}
        />
        {!errors?.script?.message && (
          <p className="input-hint">
            {i18n.t('One script per space is recommended for the best user experience.')}
          </p>
        )}
      </div>

      <ButtonEnhanced
        className="add-btn preview-btn"
        onClick={handleOnClickPreview}
        disabled={!script || !isValidData(script)}
      >
        {i18n.t('Preview')}
      </ButtonEnhanced>

      {isTemplate && (
        <div className="alert-info">
          <Icons name="icon-new-info" className="icon" />
          <span>
            {i18n.t(
              'Please note that this script will be active in all spaces created from this template.'
            )}
          </span>
        </div>
      )}
    </div>
  );
}

ScriptInput.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  componentSettings: PropTypes.instanceOf(Object),
};

export default ScriptInput;
