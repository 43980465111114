import React from 'react';
import { Waypoint } from 'react-waypoint';
import PropTypes from 'prop-types';
import NotificationItem from './NotificationItem';
import NoDataBox from '../NoDataBox';

import Loading from '../Loading';

const noDatas = {
  notifications: {
    icon: <span className="icon-notify" />,
    title: 'You have no new notifications',
    description: 'You are all caught up',
  },
  messages: {
    icon: <span className="icon-chat" />,
    title: '',
    description: 'Notifications about new chat messages in your spaces will show up here',
  },
};

const ListItems = (props) => {
  const {
    type,
    list,
    isLoading,
    onClickNotification,
    onLoadMore,
    currentUserInfo,
    isFetchingNextPage,
  } = props;

  if (isLoading) {
    return (
      <div className="loading-box">
        <Loading />
      </div>
    );
  }

  if (!list?.length) {
    const { icon, title, description } = noDatas[type];
    return <NoDataBox icon={icon} title={title} description={description} />;
  }

  return (
    <div className="content">
      {/* <Box className="content-top">
        <strong>{i18n.t('Update')}</strong>
        <span className="text-blue" onClick={onClickReadAll}>
          {i18n.t('Mark all as read')}
        </span>
      </Box> */}
      <div className="notify-list">
        {list?.map((item) => (
          <NotificationItem
            currentUserInfo={currentUserInfo}
            item={item}
            key={item?.id}
            onClick={onClickNotification}
          />
        ))}
        {isFetchingNextPage && (
          <div className="loading-box">
            <Loading />
          </div>
        )}
        <Waypoint
          onEnter={() => {
            console.log('loadmore');
            onLoadMore();
          }}
        />
      </div>
    </div>
  );
};

ListItems.propTypes = {
  list: PropTypes.instanceOf(Array),
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  onClickNotification: PropTypes.func,
  onLoadMore: PropTypes.func,
  currentUserInfo: PropTypes.instanceOf(Object),
  isFetchingNextPage: PropTypes.bool,
};

export default ListItems;
