import React, { Suspense, useContext } from 'react';
import { Box } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import './UserContainer.scss';
import UserProfileMenu from './UserProfileMenu';
import UserProfileDetails from './UserProfileDetails';
import { useUserContainerContext } from './UserContainerProvider';
import { AppContext } from '../../components/AppProvider';
import currentOrganization from '../../commons/CurrentOrganization';
import NotificationPage from '../notifications/NotificationPage';
import HomeButton from '../../components/HomeButton';
import { FeatureFlagsType } from '../../app/appConstants';
import { useFeatureFlagsContext } from '../../commons/FeatureFlags/FeatureFlagsContextProvider';
import TagsManagement from '../tags/TagsManagement';
import DesignSettingsContainer from '../designSetting/DesignSettingContainer';
import UserManagement from '../userManagement/UserManagement';
import GlobalResourcesSetting from '../../globalResources/GlobalResourcesSetting';
import IntegrationContextProvider from '../../integrations/IntegrationContextProvider';
import Loading from '../../components/Loading';

const AssessmentSettingsContainer = React.lazy(
  () => import('../../assessments/settings/AssessmentsManagement')
);

function UserProfile() {
  const { open, handleDrawerToggle } = useUserContainerContext();
  const { slug } = useParams();
  const location = useLocation();
  const { pathname } = location;
  const { userInfo } = useContext(AppContext);
  const organizationId = currentOrganization.getOrganizationId();
  const organizationName = currentOrganization.getOrganizationName();
  const isAdmin = currentOrganization.isAdmin();

  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [enabledTags, isEnabledAssessments] = checkEnabledFeatures([
    FeatureFlagsType.TAGS,
    FeatureFlagsType.ASSESSMENTS,
  ]);

  const userDetails = React.useMemo(() => {
    return {
      ...userInfo,
      organizationId,
      organizationName,
    };
  }, [userInfo, organizationId, organizationName]);

  function renderTagsPage() {
    if (isAdmin && enabledTags) {
      return <TagsManagement />;
    }
    return (
      <div className="subscription loading-container">
        <p>You have no permission to access this page</p>
      </div>
    );
  }

  function renderDesignSetting() {
    if (isAdmin) {
      return <DesignSettingsContainer />;
    }
    return (
      <div className="subscription loading-container">
        <p>You have no permission to access this page</p>
      </div>
    );
  }

  function renderUserManagementPage() {
    if (isAdmin) {
      return <UserManagement />;
    }
    return (
      <div className="subscription loading-container">
        <p>You have no permission to access this page</p>
      </div>
    );
  }

  function renderGlobalResourcesPage() {
    if (isAdmin) {
      return (
        <IntegrationContextProvider showLoading>
          <GlobalResourcesSetting />
        </IntegrationContextProvider>
      );
    }
    return (
      <div className="subscription loading-container">
        <p>You have no permission to access this page</p>
      </div>
    );
  }

  function renderAssessmentSettingsPage() {
    if (isAdmin && isEnabledAssessments) {
      return (
        <Suspense fallback={<Loading />}>
          <AssessmentSettingsContainer />
        </Suspense>
      );
    }
    return (
      <div className="subscription loading-container">
        <p>You have no permission to access this page</p>
      </div>
    );
  }

  return (
    <>
      <div className=" btn-back-home-setting">
        <HomeButton />
      </div>
      <div className="user-container">
        <Box className="user-container-box">
          <div className={`user-container-menu ${open ? 'open' : ''}`}>
            <UserProfileMenu userInfo={userDetails} slug={slug} pathname={pathname} />
          </div>

          <div className={`user-container-content ${open ? 'open' : ''}`}>
            {pathname === '/profile/settings' && <UserProfileDetails userInfo={userDetails} />}
            {pathname === '/profile/settings/notifications' && (
              <NotificationPage handleDrawerToggle={handleDrawerToggle} isOpen={open} />
            )}
            {pathname === '/profile/settings/user-management' && renderUserManagementPage()}
            {pathname === '/profile/settings/design' && renderDesignSetting()}
            {pathname === '/profile/settings/global-resources' && renderGlobalResourcesPage()}
            {pathname === '/profile/settings/tags' && renderTagsPage()}
            {pathname === '/profile/settings/assessments' && renderAssessmentSettingsPage()}
          </div>
        </Box>
      </div>
    </>
  );
}

export default UserProfile;
