/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox } from '@mui/material';
import UserAvatar from '../UserAvatar';
import LightTooltip from '../LightTooltip';
import Tags from '../Tags';
import { OrganizationUserRole } from '../../app/appConstants';
import AddButton from '../AddButton';
import { isOrgRole } from '../../commons/utils';

function ParticipantSelectOption({
  option,
  onAddParticipantClick,
  isAvatar,
  canSelect,
  disabledText,
  selected,
  ...optionProps
}) {
  const participant = option;

  async function handleAddParticipantClick(event) {
    console.log('handleAddParticipantClick', event);
    event.preventDefault();
    event.stopPropagation();
    if (onAddParticipantClick) {
      await onAddParticipantClick({ email: participant.inputValue });
    }
  }

  function getTags() {
    const tags = [];
    const userRole = participant.userRole;

    if (isOrgRole(userRole, OrganizationUserRole.Admin)) {
      tags.push({
        backgroundColor: '#DBE1E3',
        color: '#000',
        text: 'Admin',
      });
    }
    return tags;
  }

  function render() {
    const tags = getTags();
    if (!participant) return null;
    if (participant.nodeElement) {
      return <div className="participant-option">{participant.nodeElement}</div>;
    }

    if (participant.title) {
      if (participant.isNewEmail) {
        return (
          <div className="participant-select-option add-new-option" key={optionProps.key}>
            <AddButton
              label={participant.title}
              onClick={handleAddParticipantClick}
              variant="rectangle-primary"
            />
          </div>
        );
      }
      return <span dangerouslySetInnerHTML={{ __html: participant.title }} />;
    }

    if (option.selectedAll) {
      return (
        <div
          {...optionProps}
          key={optionProps.key}
          className="participant-select-option selected-all"
        >
          <div className="participant-info">{option.selectedText}</div>
          <div>
            <Checkbox
              color="primary"
              checked={option.selected}
              indeterminate={option.indeterminate}
            />
          </div>
        </div>
      );
    }

    return (
      <LightTooltip
        title={participant.disabled ? disabledText : ''}
        placement="right"
        disableHoverListener={!participant.disabled}
        disableFocusListener={!participant.disabled}
      >
        <Box
          key={optionProps.key}
          component="li"
          {...optionProps}
          className={`participant-select-option ${participant.disabled ? 'disabled' : ''}`}
        >
          {isAvatar && (
            <div className="avatar-block">
              <div className="avatar">
                <UserAvatar fullName={participant.fullName} email={participant.email} />
              </div>
            </div>
          )}
          <div className="participant-info">
            <div className="participant-fullname">
              {participant.fullName}
              {tags?.length > 0 && <Tags items={tags} />}
            </div>
            <div className="participant-email">{participant.email}</div>
          </div>
          <div>
            {canSelect && (
              <Checkbox
                color="primary"
                checked={selected}
                disabled={participant.disabledCheckBox}
              />
            )}
          </div>
        </Box>
      </LightTooltip>
    );
  }

  return render();
}

ParticipantSelectOption.propTypes = {
  option: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onAddParticipantClick: PropTypes.func,
  isAvatar: PropTypes.bool,
  canSelect: PropTypes.bool,
  disabledText: PropTypes.string,
};

ParticipantSelectOption.defaultProps = {
  isAvatar: true,
};

export default ParticipantSelectOption;
