import RuntimeApi from '../../commons/RuntimeApi';
import { getROIRuntimeApi } from '../../commons/utils';

export async function fetchROICalculationData() {
  return RuntimeApi.get(`${getROIRuntimeApi()}/roi-calculation`);
}

function setFormulaEmpty(jsonObject) {
  // Convert JSON object to string
  let jsonString = JSON.stringify(jsonObject);
  // Regex pattern to find the formula property
  const pattern = /"formula":\s*".*?"/g;
  // Replacement string to set formula to empty
  const replacement = '"formula": ""';
  // Perform the replacement
  jsonString = jsonString.replace(pattern, replacement);
  // Convert string back to JSON object
  return JSON.parse(jsonString);
}

export async function submitRoiCalculation(data) {
  // to bypass WAF in frontdoor, we remove all formulas before sending the data
  const adjustedData = setFormulaEmpty(data);
  return RuntimeApi.post(`${getROIRuntimeApi()}/roi-calculation`, adjustedData);
}

export async function getRoiReports(data) {
  return RuntimeApi.get(`${getROIRuntimeApi()}/roi-calculation/reports`, data);
}

export async function getRoiReportDocument(id) {
  const response = await RuntimeApi.fetch(
    `${getROIRuntimeApi()}/roi-calculation/reports/${id}/document`
  );
  if (!response) {
    return null;
  }
  const blob = await response.blob();
  return {
    url: URL.createObjectURL(blob),
  };
}
