import memoizeOne from 'memoize-one';

const descendingComparator = memoizeOne((a, b, orderBy) => {
  const valueA = a[orderBy];
  const valueB = b[orderBy];

  if (valueB !== null && valueB !== undefined && (valueA === undefined || valueA === null))
    return -1;
  if ((valueB === undefined || valueB === null) && valueA !== null && valueA !== undefined)
    return 1;

  if (valueB < valueA) return -1;
  if (valueB > valueA) return 1;

  return 0;
});

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
export function stableSort(array, comparator, priority) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    if (priority && (a[0][priority] || b[0][priority])) {
      return 1;
    }
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
