import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import _remove from 'lodash/remove';
import {
  LinearProgress,
  TextField,
  InputLabel,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { getResourceFileType } from '../../commons/ResourceUtils';
import { sendNotification, newGuid } from '../../commons/utils';
import { uploadResource } from '../../commons/CommonServices';
import { UploadingFileState } from '../../app/appConstants';
import ResourcePreviewDialog, {
  getResourcePreviewContext,
} from '../../resources/ResourcePreviewDialog';
// import Loading from '../../components/Loading';
import i18n from '../../i18n';
// import GoogleDrivePicker from '../GoogleDrivePicker';
// import GoogleDriveImage from '../../images/google-drive.png';
import FilePicker from '../../components/FilePicker';
import ConfirmService from '../../components/ConfirmService';
import LightTooltip from '../../components/LightTooltip';
import {
  RetryTimes,
  validateInput,
  renderErrorMessage,
  getProgress,
} from '../../components/DndFileUploader3/utils';
import FormHelper from '../../components/DndFileUploader3/FormHelper';
import './TermsAndConditionFileUploader.scss';

class TermsAndConditionFileUploader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      progresses: null,
      files: [], // picked files, only valid files
      resources: [], // saved files
      invalidResources: [], // for displaying only
      drag: false,
      errors: null, // validation errors
      uploadingState: null,
      retries: [], // queue for retrying files.
      // for retrying three times per file.
      warnings: null,
      resource: null,
      termsAndCondition: this.props.termsAndCondition?.src ? this.props.termsAndCondition : null,
    };
    // this.dropRef = createRef();
    this.filePickerRef = createRef();

    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleUploadFiles = this.handleUploadFiles.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleDragIn = this.handleDragIn.bind(this);
    this.handleDragOut = this.handleDragOut.bind(this);
    this.handleRetryButtonClick = this.handleRetryButtonClick.bind(this);
    this.pickFiles = this.pickFiles.bind(this);
    this.handleInputValidation = this.handleInputValidation.bind(this);
    this.handleDeleteResourceClick = this.handleDeleteResourceClick.bind(this);
    this.handleResourcePreview = this.handleResourcePreview.bind(this);
    this.uploadingFiles = [];
  }

  async componentDidUpdate() {
    const { progresses, uploadingState, files, retries, invalidResources } = this.state;
    const { onUploadingStateChange } = this.props;

    if (uploadingState === UploadingFileState.Started) {
      const progress = getProgress(progresses);
      if (progress === 100) {
        // console.log('### case 1: ');

        setTimeout(() => {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({
            uploadingState: UploadingFileState.Completed,
            progresses: null,
            files: [],
          });
          if (onUploadingStateChange) onUploadingStateChange(UploadingFileState.Completed);
        }, 1800);
      }
    } else {
      if (files.length > 0) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ files: [], progresses: null, uploadingState: UploadingFileState.Started });
        if (onUploadingStateChange) onUploadingStateChange(UploadingFileState.Started);
        await this.handleUploadFiles(files);
      }

      if (retries.length > 0) {
        const newRetries = _cloneDeep(retries);
        const retryingResource = newRetries.shift(); // get front
        const retryingFile = retryingResource.file;

        if (!retryingFile) return;

        // remove from list.
        const clonedInvalidResources = _cloneDeep(invalidResources);
        const filteredInvalidResources = clonedInvalidResources.filter(
          (r) => r.id !== retryingResource.id
        );
        // console.log('### case 3: ', filteredInvalidResources);

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ retries: newRetries, invalidResources: filteredInvalidResources });

        const isValid = this.handleInputValidation(
          { target: { name: 'file', value: retryingFile } },
          false
        );
        if (!isValid) return; // error

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ progresses: null, uploadingState: UploadingFileState.Started });
        if (onUploadingStateChange) onUploadingStateChange(UploadingFileState.Started);
        await this.handleUploadFile(retryingFile);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this

  onCancel() {
    if (this.uploaders) {
      for (let i = 0; i < this.uploaders.length; i += 1) {
        const uploader = this.uploaders[i];
        uploader.cancel();
      }
      this.uploaders = null;
      this.uploadingFiles = [];
      this.setState({
        files: [],
        // resources: [],
        progresses: null,
        uploadingState: UploadingFileState.Canceled,
      });
      if (this.props.onUploadingStateChange) {
        this.props.onUploadingStateChange(UploadingFileState.Canceled);
      }
    }
  }

  async handleRetryButtonClick(retryingResource) {
    // console.log('### handleRetryButtonClick', retryingResource, this.state);
    console.log(RetryTimes);
    const { file, id } = retryingResource;
    const { retries, invalidResources } = this.state;
    if (file) {
      const newRetries = _cloneDeep(retries);
      newRetries.push(retryingResource); // push to queue.
      this.setState({ retries: newRetries });

      const newInvalidResources = _cloneDeep(invalidResources);
      const foundResource = newInvalidResources.find((r) => r.id === id);
      if (foundResource) {
        foundResource.isRetrying = true;
        this.setState({ invalidResources: newInvalidResources });
      }
    }
  }

  handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.pickFiles(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  }

  handleDragIn(e) {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter += 1;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  handleDragOut(e) {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter -= 1;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  }

  async handleDeleteResourceClick() {
    const { onChange } = this.props;
    const isConfirmed = await ConfirmService.show(
      `${i18n.t('DELETE TERMS & CONDITIONS')}`,
      i18n.t(
        'This will remove terms and conditions from all restricted and private spaces. Do you want to continue?'
      )
    );
    if (isConfirmed) {
      // handleDeleteResource(this.state.termsAndCondition);
      this.setState({ termsAndCondition: null });
      if (onChange) onChange(null);
    }
  }

  handleInputValidation(event, callOnError = true) {
    const { name, value } = event.target;
    const validationMethod = this.props.validationMethod || validateInput;
    const result = validationMethod(name, value, this.props);

    // delete old.
    let newErrors = null;
    if (this.state.errors) {
      newErrors = { ...this.state.errors };
      delete newErrors[name];
    }
    let newWarnings = null;
    if (this.state.warnings) {
      newWarnings = { ...this.state.warnings };
      delete newWarnings[name];
    }

    let isValid = true;
    if (result) {
      const { canSubmit } = result;
      if (canSubmit) {
        newWarnings = { ...newWarnings, [name]: result };
      } else {
        isValid = false;
        newErrors = { ...newErrors, [name]: result };
      }
    }
    if (name === 'file') {
      // reset url's errors and warnings
      if (newErrors) {
        delete newErrors.files;
      }
      if (newWarnings) {
        delete newWarnings.files;
      }
    }

    if (newErrors && Object.keys(newErrors).length === 0) newErrors = null;
    if (newWarnings && Object.keys(newWarnings).length === 0) newWarnings = null;

    this.setState({ errors: newErrors, warnings: newWarnings });

    if (this.props.onError && callOnError) {
      this.props.onError(newErrors);
    }
    return isValid;
  }

  async pickFiles(data) {
    const files = data instanceof FileList ? data : [data];
    // console.log('### picked files:', files, this.state.resources, this.state.invalidResources);

    const totalFiles = [...files, ...this.state.resources, ...this.state.invalidResources];
    const isValid = this.handleInputValidation({
      target: { name: 'files', value: totalFiles },
    });
    if (!isValid) return; // error

    const invalidFiles = [];
    const validFiles = [];
    const validationMethod = this.props.validationMethod || validateInput;

    Array.from(files).forEach((file) => {
      const result = validationMethod('file', file, this.props);

      const resourceType = getResourceFileType(file);
      const newFile = file;
      newFile.resourceType = resourceType;
      newFile.fileName = file.name;
      newFile.fileDescription = '';

      if (!result) {
        validFiles.push(newFile);
      } else {
        invalidFiles.push(newFile);
      }
    });

    // console.log('### invalidFiles', invalidFiles);
    // console.log('### validFiles', validFiles);

    this.setState({ files: validFiles }); // will upload files in the componentDidUpdate function

    if (invalidFiles.length > 0) {
      const newResources = _cloneDeep(this.state.resources);
      invalidFiles.forEach((file) => {
        const invalidResource = {
          name: file.name,
          fileName: file.name,
          file,
          isValid: false,
          id: newGuid(),
        };
        newResources.push(invalidResource);
      });

      setTimeout(() => {
        if (invalidFiles.length === 1 && validFiles.length === 0) {
          this.handleRetryButtonClick(newResources[0]); // trigger automatically if only one file was picked
          return;
        }
        this.setState({ invalidResources: newResources }); // for displaying only
      }, 800);
    }
  }

  async handleUploadFiles(files) {
    if (!files || files.length === 0) return;
    const promises = files.map((f) => this.handleUploadFile(f));
    await Promise.all(promises);
  }

  async handleUploadFile(file) {
    const {
      spaceId,
      onChange,
      onUploadingStateChange,
      oldResourceId,
      spacePrivacy,
      onError,
      uploadContext,
    } = this.props;

    this.setState({ termsAndCondition: null });
    this.setState({ errors: null, warnings: null });
    const finalOldResourceId = !oldResourceId ? null : oldResourceId;
    // start uploading
    this.uploadingFiles.push(file);

    const uploader = await uploadResource(
      spaceId,
      file,
      onError,
      finalOldResourceId,
      spacePrivacy,
      null,
      uploadContext
    );

    const sessionId = uploader.opts.sessionId; // make sure have sessionId here.
    // console.log('### upload session id:', sessionId);

    uploader.on('fileProgress', (f) => {
      const currentProgress = Math.round(f.progress() * 100);
      if (sessionId) {
        const newProgresses = { ...this.state.progresses };
        newProgresses[sessionId] = currentProgress;
        // console.log('### fileProgress: ', newProgresses);
        this.setState({ progresses: newProgresses });
      }
    });

    uploader.on('fileSuccess', (f, message) => {
      _remove(this.uploadingFiles, (e) => e === f.file);
      const messageObj = JSON.parse(message);
      messageObj.sessionId = sessionId;
      messageObj.name = messageObj.fileName || file.name;

      // console.log('### fileSuccess: ', messageObj); // 2
      // console.log(onChange);

      // update state
      const newResource = messageObj;

      this.setState({ termsAndCondition: newResource });
      if (onChange) onChange(newResource);
    });

    uploader.on('fileError', (f, message) => {
      _remove(this.uploadingFiles, (e) => e === f.file);
      const error = JSON.parse(message);
      sendNotification(error?.message, { type: 'error' });
      this.setState({ uploadingState: UploadingFileState.Failed, files: [] });
      if (onUploadingStateChange) onUploadingStateChange(UploadingFileState.Failed);
    });

    // keep uploaders
    if (!this.uploaders) {
      this.uploaders = [];
    }
    this.uploaders.push(uploader);
  }

  handleResourcePreview(previewingResource) {
    // console.log('### preview', previewingResource);
    if (!this.props.canPreview || !previewingResource) return;
    const previewContext = this.props.isPortal
      ? getResourcePreviewContext('portal')
      : getResourcePreviewContext('space');
    ResourcePreviewDialog.show(previewingResource, previewContext);
  }

  render() {
    const { fileInputLabel, canPreview, uploadBtnLabel } = this.props;

    const { uploadingState, progresses, termsAndCondition, drag } = this.state;
    const isFailed = uploadingState === UploadingFileState.Failed;
    const totalUploadingFiles = this.uploadingFiles.length;
    const finalProgress = totalUploadingFiles > 0 ? getProgress(progresses) : 0;
    const showUploadBtn = uploadingState !== UploadingFileState.Started && !termsAndCondition?.src;
    const showFileInput = termsAndCondition?.src;
    const termsAndConditionFileName = termsAndCondition?.fileName || termsAndCondition?.title;

    return (
      <div className="terms-and-condition-upload-container">
        <FilePicker
          accept={this.props.accept}
          onChange={this.pickFiles}
          ref={this.filePickerRef}
          validatePowerPointFile={false}
        />

        <div className="upload-area">
          <div className={`terms-and-condition-file-uploader-control`}>
            <div className="terms-and-condition-file-uploader-control-label-wrapper">
              {!showFileInput && (
                <InputLabel
                  htmlFor="upload-btns"
                  className="terms-and-condition-file-uploader-control-label"
                >
                  {fileInputLabel}
                </InputLabel>
              )}
            </div>

            {showUploadBtn && (
              <>
                <div className="upload-btns" id="upload-btns">
                  <div
                    className={`upload-btn ${drag ? 'active' : ''}`}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      this.filePickerRef?.current?.onClick();
                    }}
                    onDrop={(e) => this.handleDrop(e)}
                    onDragOver={(e) => this.handleDrag(e)}
                    onDragEnter={(e) => this.handleDragIn(e)}
                    onDragLeave={(e) => this.handleDragOut(e)}
                  >
                    <p>
                      {i18n.t('Drop a file here,')}
                      <br></br>
                      {` ${i18n.t('or')} `}
                      <a>{i18n.t(uploadBtnLabel)}</a>
                    </p>
                  </div>
                </div>
                <FormHelper
                  show={!!this.state.errors?.file}
                  helperText={(this.props.renderErrorMessageMethod || renderErrorMessage)(
                    this.state.errors,
                    'file'
                  )}
                />
                <FormHelper
                  show={!!this.state.errors?.files}
                  helperText={(this.props.renderErrorMessageMethod || renderErrorMessage)(
                    this.state.errors,
                    'files',
                    this.props
                  )}
                />
              </>
            )}

            {totalUploadingFiles > 0 && (
              <div className="upload-progress">
                <span className="file-name">{this.uploadingFiles[0].name}</span>
                <LinearProgress
                  variant="determinate"
                  value={finalProgress}
                  className={`${isFailed ? 'failed' : ''}`}
                />
                <div className="bottom-section">
                  {isFailed && (
                    <>
                      <span className="message failed">{i18n.t('Upload failed')}</span>
                    </>
                  )}
                  {uploadingState === UploadingFileState.Completed && (
                    <>
                      <span className="message completed">{i18n.t('Complete')}</span>
                    </>
                  )}
                  {uploadingState <= UploadingFileState.Started && (
                    <>
                      <span className="message">
                        {`${i18n.t('Uploading')} ${finalProgress}%...`}
                      </span>
                      <Button className="action-button" onClick={this.onCancel}>
                        {i18n.t('Cancel')}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            )}
            {showFileInput && (
              <section className={`uploaded-files-section`}>
                <TextField
                  key={termsAndCondition.resourceId}
                  id="file-input"
                  name="resource-name"
                  defaultValue={termsAndConditionFileName}
                  autoComplete="off"
                  className={`terms-and-condition-file-uploader-control-input ${
                    canPreview && termsAndConditionFileName ? 'text-underline' : ''
                  }`}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    // console.log('### onClick to preview', resource);
                    const resourceToPreview = {
                      resourceId: termsAndCondition?.resourceId,
                      name: termsAndConditionFileName,
                      src: termsAndCondition?.src,
                    };
                    this.handleResourcePreview(resourceToPreview);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className="action-buttons">
                          {this.props.canUploadNewVersion && (
                            <LightTooltip title="Upload" position="bottom-start">
                              <IconButton
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  this.filePickerRef?.current?.onClick();
                                }}
                              >
                                <i className="icon-upload" />
                              </IconButton>
                            </LightTooltip>
                          )}
                          <LightTooltip title="Delete" position="bottom-start">
                            <IconButton
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                this.handleDeleteResourceClick();
                              }}
                            >
                              <DeleteIcon className="delete-icon" />
                            </IconButton>
                          </LightTooltip>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelper
                  show={!!this.state.errors?.file}
                  helperText={(this.props.renderErrorMessageMethod || renderErrorMessage)(
                    this.state.errors,
                    'file'
                  )}
                />
              </section>
            )}
          </div>
        </div>
      </div>
    );
  }
}

TermsAndConditionFileUploader.propTypes = {
  spaceId: PropTypes.string,
  accept: PropTypes.string,
  fileInputLabel: PropTypes.string,
  validationMethod: PropTypes.func,
  renderErrorMessageMethod: PropTypes.func,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  isPortal: PropTypes.bool,
  canPreview: PropTypes.bool,
  onUploadingStateChange: PropTypes.func,
  // saveAsOriginalFormat: PropTypes.bool,
  uploadBtnLabel: PropTypes.string,
  // maxFiles: PropTypes.number,
  // maxFileSize: PropTypes.number, // currently, only for ppt file.
  oldResourceId: PropTypes.number, // to upload a new version of the resource.
  spacePrivacy: PropTypes.string,
  canUploadNewVersion: PropTypes.bool,
  termsAndCondition: PropTypes.arrayOf(Object),
  uploadContext: PropTypes.number,
};

TermsAndConditionFileUploader.defaultProps = {
  accept: '.pdf',
  fileInputLabel: i18n.t('Add file'),
  isPortal: false,
  canPreview: false,
  uploadBtnLabel: i18n.t('browse your device'),
};

TermsAndConditionFileUploader.displayName = 'TermsAndConditionFileUploader3';

export default TermsAndConditionFileUploader;
