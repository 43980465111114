import React, { useState, createContext, useEffect } from 'react';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { useAppContext } from '../../components/AppProvider';

const getUserContainerContext = memoizeOne((open, isMobile) => {
  return {
    open,
    isMobile,
  };
});

export const UserContainerContext = createContext();

export const useUserContainerContext = () => React.useContext(UserContainerContext);

function UserDashboardProvider(props) {
  const { children } = props;
  const { isMobilePortrait: isMobile } = useAppContext();

  const [open, setOpen] = useState();

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const context = getUserContainerContext(open, isMobile);

  context.handleDrawerToggle = handleDrawerToggle;

  return <UserContainerContext.Provider value={context}>{children}</UserContainerContext.Provider>;
}

UserDashboardProvider.propTypes = {
  children: PropTypes.element,
};

export default UserDashboardProvider;
