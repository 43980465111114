import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import _cloneDeep from 'lodash/cloneDeep';
import { useSpaceContext } from '../SpaceContext';
import { updateNotificationRead } from '../../users/notifications/service';
import useUnmountEffect from '../../commons/useUnmountEffect';

const CommentNotificationsHandler = (props) => {
  const { spaceId, channelId, isPrivate, isVisible } = props;
  const { newNotifications, setNotifications } = useSpaceContext();

  const notification = useMemo(() => {
    if (!newNotifications?.Chats?.details) {
      return null;
    }

    let foundChanel = newNotifications.Chats.details.newPublicMessages;
    let indexChanel = -1;
    if (isPrivate) {
      indexChanel = newNotifications.Chats.details.newPrivateMessages.findIndex(
        (item) => item.channelId === channelId
      );
      if (indexChanel !== -1) {
        foundChanel = newNotifications.Chats.details.newPrivateMessages[indexChanel];
      }
    }

    if (!foundChanel?.totalBadgeCount) {
      return null;
    }
    return { hasNewUpdates: foundChanel.totalBadgeCount > 0, indexChanel };
  }, [isPrivate, newNotifications?.Chats?.details, channelId]);

  const updateNotifications = useDebouncedCallback(async () => {
    if (!notification?.hasNewUpdates) {
      return;
    }
    const newData = _cloneDeep(newNotifications);
    const data = {
      readItems: {
        chats: [channelId.toString()],
      },
      context: 'Chats',
    };
    const result = await updateNotificationRead(spaceId, data);

    if (result) {
      if (isPrivate) {
        newData.Chats.details.newPrivateMessages[notification.indexChanel].total = 0;
        newData.Chats.details.newPrivateMessages[notification.indexChanel].totalBadgeCount = 0;
        newData.Chats.total = 0;
      } else {
        newData.Chats.details.newPublicMessages.totalBadgeCount = 0;
        newData.Chats.details.newPublicMessages.total = 0;
        newData.Chats.total = 0;
      }
      setNotifications(newData);
    }
  }, 5000);

  useEffect(() => {
    console.log('### 51 CommentNotificationsHandler', isVisible, notification?.hasNewUpdates);

    if (isVisible && notification?.hasNewUpdates) {
      updateNotifications(channelId);
    }
  }, [channelId, notification?.hasNewUpdates, updateNotifications, isVisible]);

  useUnmountEffect(() => {
    updateNotifications.flush();
  });
  return null;
};

CommentNotificationsHandler.propTypes = {
  spaceId: PropTypes.string,
  channelId: PropTypes.string,
  isPrivate: PropTypes.bool,
  isVisible: PropTypes.bool,
};

export default CommentNotificationsHandler;
