import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { getRoiReportDocument } from '../../components/ROICalculation/servise';

function useGetRoiDocumentQuery(documentId) {
  const document = useQuery({
    queryKey: [CacheKeys.fetchRoiReportDocument, documentId],
    queryFn: async () => {
      const resp = await getRoiReportDocument(documentId);
      return resp;
    },
    enabled: !!documentId,
  });

  return document;
}

export default useGetRoiDocumentQuery;
