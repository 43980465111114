import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import ListItems from './ListItems';
import './Notifications.scss';

import { fetchNotifications } from '../../users/notifications/service';

const NotificationUpdates = (props) => {
  const { onClickNotification, onClickReadAll, currentUserInfo } = props;
  const [notifications, setNotifications] = useState();

  const fetchData = ({ pageParam = 1 }) => {
    return fetchNotifications(pageParam);
  };

  const getNextPageParam = (lastPage) => {
    if (lastPage.hasMore) {
      return lastPage.currentPage + 1;
    }
    return undefined;
  };

  const notificationsQuery = useInfiniteQuery({
    queryKey: [CacheKeys.fetchUserNotifications],
    queryFn: fetchData,
    ...{
      getNextPageParam,
      retry: 2,
    },
  });

  useEffect(() => {
    if (notificationsQuery?.data) {
      const { pages } = notificationsQuery.data || [];
      const listSpace = pages.reduce((m, page) => {
        const { items } = page;
        if (!items) {
          console.error('items is undefined', { pages });
          return m;
        }
        return m.concat(items);
      }, []);

      setNotifications(listSpace);
    }
  }, [notificationsQuery?.data]);

  async function handleOnClickItem(item) {
    await onClickNotification(item, CacheKeys.fetchUserNotifications, 'newUpdates');
  }

  function handleOnClickReadAll() {
    onClickReadAll(CacheKeys.fetchUserNotifications, 'newUpdates');
  }

  return (
    <ListItems
      type="notifications"
      list={notifications}
      isFetchingNextPage={notificationsQuery.isFetchingNextPage}
      isLoading={notificationsQuery.isLoading}
      onClickNotification={handleOnClickItem}
      onClickReadAll={handleOnClickReadAll}
      onLoadMore={() => notificationsQuery.fetchNextPage()}
      currentUserInfo={currentUserInfo}
    />
  );
};

NotificationUpdates.propTypes = {
  onClickNotification: PropTypes.func,
  onClickReadAll: PropTypes.func,
  currentUserInfo: PropTypes.instanceOf(Object),
};

export default NotificationUpdates;
