export default {
  // 'Welcome to React': 'Välkommen till React och react-i18nästa',
  // Welcome: 'Välkommen',
  // 'We can see that you have done this before. Would you like to access one of your earlier conferences, then just press it. If you would like to create a totally new one, then press the "plus sign"':
  //   'Vi kan se att du har gjort det tidigare. Vill du gå till en av dina tidigare möten/konferenser, klicka bara nedan. Om du vill skapa en helt ny aktivitet, tryck sedan på "plus-knappen"',
  // 'Do you need further assistance, then just press the button.':
  //   'Behöver du mer hjälp, bara klicka på knappen',
  // 'Do you need assistance then you are able to reach us at 08 - 5000 000, or through the button below.':
  //   'Behöver du hjälp så når du oss på telefon 08 - 5000 000, eller via knappen nedan. ',
  // 'I would like to get some assistance': 'Jag vill gärna ha hjälp! ',
  // Coffee: 'Kaffe & Fika',
  // Dinner: 'Middag',
  // VENUE: 'PLATS',
  // 'AGENDA & CONTENT': 'AGENDA & INNEHÅLL',
  // 'We want to meet up...': 'Vi vill mötas...',
  // 'Physically in one venue': 'Fysiskt på en plats',
  // 'Streamed from multiple sites (Hybrid)': 'Fysiskt och streamat från flera platser (Hybrid)',
  // 'Online / Virtual': 'Online / Digitalt',
  // 'Name on activity': 'Aktivitetens namn',
  // Purpose: 'Vad är syftet, vad vill ni uppnå?',
  // 'Start date': 'Startdatum',
  // 'Start time': 'Tid',
  // 'End date': 'Slutdatum',
  // 'End time': 'Tid',
  // 'Number of attendance': 'Antal deltagare',
  // 'Next step': 'Nästa steg',
  // 'Where would you like to be?': 'Var vill du vara?',
  // 'Meet each other physically in one single location, or many – with streaming. Please choose venue for your activity here, be in your own location or have the participants located at on or multiple locations, hotels and rooms. You get one quote, with one transparent price and have full control over the total cost.':
  //   'Möt varandra fysiskt, eller ifrån olika platser – streamat. Här väljer du plats för aktiviteten. Välj om ni vara i egna lokaler, eller fördela deltagarna på en eller flera orter, hotell och konferensrum. Du får en offert, med ett transparent pris och kontrollerar den totala kostnaden.',
  // 'Our own location': 'I egna lokaler',
  // 'Drag activity here': 'Dra och släpp valfri aktivitet hit',
  // 'Add an activity': 'LÄGG TILL EN AKTIVITET',
  // 'Choose an activity type to add into the timeline':
  //   'Väj en aktivitetstyp och addera till tidslinjen',
  // 'Choose an external moderator ': 'Välj en extern moderator ',
  // 'Pulse Measure': 'PULSMÄTNING',
  // 'Ask and take the temperature on all attendants':
  //   'Ta temperaturen och ställ frågor till deltagarna på konferensen',
  // 'Quiz - Q&A': 'QUIZ',
  // 'Engage the attendants with an Q&A': 'Engagera deltagarna med Quiz- frågor under konferensen',
  // 'External Speaker': 'EXTERN TALARE',
  // 'Add in an external speaker': 'Lägg till en extern föreläsare',
  // 'Internal Speaker': 'INTERN TALARE',
  // 'Add in an internal speaker': 'Lägg till en intern talare',
  // 'Add a breakout session': 'Lägg till en breakout session',
  // 'Coffee Break': 'FIKAPAUS',
  // 'Add in a break for coffee & Tea': 'Lägg in ett break för fika',
  // 'Add in a break for Lunch': 'Lägg in ett break för lunch',
  // Pause: 'PAUS',
  // 'Add in a break': 'Lägg in en bensträckare',
  // 'Request quotation': 'Begär offert',
  // 'Are you all ready to send your request': 'Är du redo att skicka din offertförfrågan',
  // 'The request process will verify all availability, which could take up to 48 hours. During this process you will not be able to change your setup. Are you still OK with sending your request?':
  //   'Vi kommer verifiera tillgängligheten på talare/moderatorer/lokaler, vilket kan ta upp till 48 timmar. Under processen kan du inte ändra här i planeringen. Är du säker på att du vill skicka förfrågan?',
  // Cancel: 'Bakåt',
  // 'Thank you for your request. We´ll send you a formal proposal through the e-service Get Accept. If it has not arrived in a minute, please take a look in your spam box.':
  //   'Tack för din förfrågan. Vi skickar ett mer formellt anbud genom e-tjänsten Get Accept. Om det inte kommit fram inom någon minut, ta en titt i din spam-box.',
  // 'Less than 48 hrs': 'Mindre än 48 timmar',
  // 'Less than a second': 'Mindre än en sekund',
  // 'Request Sent': 'Förfrågan skickad',
  // 'Check Availability': 'Verifierar tillgängligheten',
  // 'Verify Total Price': 'Verifiera totalt pris',
  // 'Send Quotation': 'Skicka offert',
  // 'Closing in {{closingTime}} seconds...': 'Stänger inom {{closingTime}} sekunder...',
  // 'Meet each other physically, digitally or from different places - streamed (hybrid). Then simply choose the place for the activity and create your agenda. Use our service and do everything by yourself, completely free of charge, or get help from a professional moderator and choose among the most inspiring lecturers in the market. Be in your own premises or distribute the team at one or more venues. All based on your budget and ambition, and with three simple clicks in our super-simple service.':
  //   'Möt varandra fysiskt, digitalt eller ifrån olika platser – streamat (hybrid). Väljer därefter enkelt plats för aktiviteten och skapa din agenda. Använd vår tjänst och gör allt själv helt utan kostnad, eller ta hjälp av en proffsmoderator och välj bland landets mest inspirerande föreläsare. Var i egna lokaler eller fördela teamet på ett eller flera konferenshotell. Allt utifrån er budget och ambition, med tre enkla klick i vår super-enkla tjänst.',
  // 'Filter no of participants': 'Filtrera på antal deltagare per rum',
  // 'No of participants': 'Antal deltagare',
  // 'Sorry, there are no rooms available.': 'Ledsen men det finns inga rum lediga.',
  // 'Do you want any meal packages?': 'Vill du ha paket för mat & dryck?',
  // 'Enter address': 'Ange platselleradress',
  // 'Select speaker': 'Välj föreläsare',
  // Area: 'Område',
  // 'Drive our Corporate Culture and Values': 'Utveckla vår organisationskultur och våra värderingar',
  // 'Align people around our Strategy forward': 'Samla människor runt vår strategi framåt',
  // 'Provide information about some more or less important stuff':
  //   'Informera om mer eller mindre viktiga saker',
  // 'Have a great Kickoff for the coming period': 'Hålla en kickoff inför den kommande perioden',
  // 'Plan our way out of Covid-19': 'Planera vår resa ut ur Covid-19',
  // 'Inspire and give people a true boost of energy':
  //   'Inspirera och ge människor en verklig energi-boost',
  // 'Hold a Leadership Conference': 'Genomföra en ledarkonferens',
  // 'Get together for Education or training': 'Samlas för utbildning och träning',
  // 'Innovate and create together': 'Driva innovation och skapa tillsammans',
  // 'Team Building': 'Team Building',
  // 'Hold a magical Customer Event': 'Hålla ett magiskt kundevent',
  // 'Choose area': 'Välj område',
  // 'Save & Exit': 'Spara',
  // 'Select moderator': 'Välj moderator',
  // 'Live, On Site, Physical': 'Live & fysiskt på plats',
  // 'Live, Digital streamed': 'Live & digitalt streamat',
  // 'Pre-recorded video': 'Förinspelad video',
  // 'You have not yet chosen a venue or given an address. You can do this later, but eventual venues will not be included in the proposal from us. Are you sure that you want to save and exit?':
  //   'Du har ännu inte valt en plats för din aktivitet. Du kan göra detta senare, men eventuella konferenslokaler kommer inte att inkluderas i förslaget från oss. Är du säker på att du vill spara och avsluta?',
  // 'Not found conference': 'Jag hittar inte din aktivitet',
  // 'Not found invitation': 'Jag hittar inte din inbjudan',
  // 'Invitation expired': 'Din inbjudan har gått ut',
  // 'Url is not valid': 'URL:en är inte giltig',
  // 'Sending confirmation': 'Skickar bekräftelse',
  // 'You´re all in! Thank you for your confirmation': 'Yes, Du är med! Tack för din bekräftelse',
  // 'Oh, that was a pity but thanks for your response! Maybe next time':
  //   'Åh, det var synd, men varmt tack för ditt svar! Kanske nästa gång',
  // Accepted: 'Accepterat',
  // Rejected: 'Nekat',
  // Planned: 'Planerade',
  // 'Waiting for offer': 'Väntar på offert',
  // Offered: 'Offererad',
  // Ordered: 'Bokad',
  // Completed: 'Genomförd',
  // "You haven't added activities on {{date}}": 'Du har inte lagt in aktiviteter den {{date}}',
  // 'OK to proceed anyway': 'Är det OK att fortsätta ändå',
  // Yes: 'Ja',
  // No: 'Nej',
  // 'This speaker is right now in your agenda. You could add him/her in more slots in the same day at 0 extra cost':
  //   'Talaren ligger i din agenda. Du kan addera fler inslag utan extra kostnad under samma dag',
  // "Hi, I'm Meeting Maker and I will help you make your activity awesome!<br /><p class='second'> First, <b>let's give your activity a name</b> so we all know what to call it...</p>":
  //   "Hej, välkommen till Meeting Maker. Låt oss ta stegen tillsammans!<br /><p class='second'>Först, <b>låt oss ge din aktivitet ett namn</b> så att vi alla vet vad vi ska kalla den ...</p>",
  // 'Amazing, almost there!': 'Tiden går fort och vi är snart klara här!',
  // 'Estimated no of participants...': 'Uppskattat antal deltagare..',
  // howMany: '<1>Hur många blir ni?</1>',
  // Previous: 'Föregående',
  // Next: 'Nästa',
  // "Let's plan": 'Låt oss planera',
  // Continue: 'Fortsätt',
  // whyBehindIt: 'Vilken typ av aktivitet är det, vad är syftet eller vad vill ni uppnå?',
  // "Let's go!": 'Då kör vi!',
  // 'Activity Name': 'Namn på aktiviteten',
  // '<b>When</b> do you want it all to happen?': '<b>När</b> vill du köra aktiviteten?',
  // 'Have you considered the format yet? You want to meet up all in one place (like in the good old days) or do you want to go digital?':
  //   'Har du funderat på formatet ännu? Vill ni mötas fysiskt på en gemensam plats (som på den gamla goda tiden), eller vill du köra allt digitalt?',
  // 'We have a third option as well, where you get the best of two worlds. Split your team on different locations and run a streamed conference.':
  //   'Vi har ett tredje alternativ också där du får det bästa av två världar. Dela upp deltagarna i mindre grupper, på olika platser och kör både fysiskt och digitalt på en och samma gång - vad vi brukar kalla hybrid.',
  // 'Physical and on site': 'Fysiskt på en plats',
  // 'Online / Digital': 'Online / Digitalt',
  // Select: 'Lägg till',
  // Profile: 'Profil',
  // 'Thank you for your request. We´ll send you a formal proposal through the e-service Get Accept. If it has not arrived in a minute, please take a look in your spam box. Do you want to proceed?':
  //   'Tack för din förfrågan. Vi skickar ett mer formellt anbud genom e-tjänsten Get Accept. Om det inte kommit fram inom någon minut, ta en titt i din spam-box. Är du säker på att du vill skicka förfrågan?',
  // 'Great work! Now you can choose a great venue and plan your agenda. If you want a professional moderator or maybe one or two top-rated speakers, you will find them here as well.':
  //   'Bra jobbat! Nu kan du välja en bra plats och planera din agenda. Om du vill ha en professionell moderator eller föreläsare, så hittar du dem här också.',
  //
  // 'You can always ask for help from our team. A human, who is a true expert in making meetings magical, is just a click away.':
  //   'Du kan när som helst be om hjälp från vårt team eller addera en erfaren projektledare till din aktivitet. En expert med lång erfarenhet av att göra möten magiska, är bara ett klick bort.',
  //
  // 'Great work! Now you can choose some great venues and plan your agenda. If you want a professional moderator or maybe one or two top-rated speakers, you will find them here as well.':
  //   'Bra jobbat! Nu kan du välja några bra platser att vara på och planera din agenda. Om du vill ha en professionell moderator eller föreläsare, så hittar du dem här också.',
  // 'You can always ask for help from our team. A real human, who is a true expert in making meetings magical, is just a click away.':
  //   'Du kan när som helst be om hjälp från vårt team eller addera en erfaren projektledare till din aktivitet. En expert med lång erfarenhet av att göra möten magiska, är bara ett klick bort.',
  //
  // 'Great work! You have chosen to go virtual and now you can plan your agenda. If you want a professional moderator or maybe one or two top-rated speakers, you will find them here as well.':
  //   'Bra jobbat! Du har valt att köra helt digitalt och nu kan planera din agenda. Om du vill ha en professionell moderator eller föreläsare, så hittar du dem här också.',
  // 'You can always ask for help from our team. A real human, who is a true expert in digital meetings, is just a click away.':
  //   'Du kan när som helst be om hjälp från vårt team eller addera en erfaren projektledare till din aktivitet. En expert med lång erfarenhet av att göra möten magiska, är bara ett klick bort.',
  // 'Type your purpose or choose area with the button':
  //   'Skriv in syftet här eller välj område med knappen',
  // 'You have existing venue(s) booked. These will be deleted if you change date/time. Do you want to proceed?':
  //   'Du har lokal(er) bokade. Dessa kommer raderas om du ändrar datum/tid. Vill du fortfarande ändra tiden?',
  // 'You have activities in your agenda. These will be deleted if you change date/time. Do you want to proceed?':
  //   'Du har aktiviteter i agendan. Dessa kommer raderas om du ändrar datum/tid. Vill du fortfarande ändra tiden?',
  // 'You have existing venue(s) booked and activities in your agenda. These will be deleted if you change date/time. Do you want to proceed?':
  //   'Du har lokal(er) bokade och aktiviteter i agendan. Dessa kommer raderas om du ändrar datum/tid. Vill du fortfarande ändra tiden?',
  // 'By changing from physical On-site to Online we must first clear your venue bookings and agenda and you would need to add activities again. OK to proceed?':
  //   'Genom att byta från en fysisk aktivitet till digitalt behöver vi först radera eventuellt valda platser och du behöver lägga in dina aktiviteter i agendan igen. OK att fortsätta?',
  // 'You have chosen a Physical On-site event, which is restricted to one (1) venue only. You would need a hybrid event to host multi-venues.':
  //   'Du har valt att köra en fysiskt aktivitet på en (1) plats. Du behöver köra en Hybrid-aktivitet för att kunna fördela deltagarna på olika platser.',
  // 'Activity details': 'Aktivitet',
  // 'Activity name': 'Namn på aktivitet',
  // Description: 'Beskrivning',
  // Venue: 'Plats',
  // 'minute(s)': 'minuter',
  // 'You have added a room with 0 participants. OK to proceed?':
  //   'Du har lagt till ett rum utan att välja antal personer. OK att fortsätta?',
  // 'Choose speaker later': 'Välj talare senare',
  // 'Choose moderator later': 'Välj moderator senare',
  // 'Open speaker slot': 'Öppen talartid',
  // 'Request a specific speaker': 'Begär en specifik talare',
  // 'Be calm, our team will help you with the request and in 48 hours the speaker will be here in your schedule and you will get your full quote':
  //   'Var lugn, vårt team kommer hjälpa dig med begäran. Det kan ta upp till 2 arbetsdagar och sedan uppdateras din agenda och du får en offert på helheten.',
  // 'Save activity': 'Spara aktivitet',
  // 'Thank you for your request! You have added a speaker or moderator who´s availability just need to be verified first. We will check it all up and send our super-transparent offer via the e-service Get Accept within 48 hours. If the speaker is not available we will get back to you the soonest with alternatives.':
  //   'Tack för din förfrågan! Du har lagt till en talare eller moderator som eventuellt kan vara bokad. Vi verifierar detta åt dig och skickar vårt super-transparenta anbud via e-tjänsten Get Accept inom 48 timmar. Om talaren/moderatorn inte är tillgänglig, kommer vi tillbaka omgående med förslag på alternativ.',
  // 'Share Meeting Maker and win up to SEK 15,000 for an inspirational lecture':
  //   'Dela Meeting Maker och vinn upp till 15 000 kr till en inspirationsföreläsning',
  // 'Be calm, our team will help you with the request and in 48 hours the moderator will be here in your schedule and you will get your full quote':
  //   'Var lugn, vårt team kommer hjälpa dig med begäran. Det kan ta upp till 2 arbetsdagar och sedan uppdateras din agenda och du får en offert på helheten.',
  // 'Got any comments or input you want to parse to':
  //   'Har du några kommentarer eller inmatningar som du vill lägga till',
  // 'Add them in the dialogue box above': 'Lägg då till dem i dialogrutan ovan',
  // "HELP FROM THE PRO'S": 'HJÄLP FRÅN PROFFSEN',
  // 'MAKE MY OWN AGENDA': 'GÖR MIN EGEN AGENDA',
  // 'Request a specific moderator': 'Begär en specifik moderator',
  // 'Speaker name': 'Talare Namn',
  // 'Moderator name': 'Moderator Namn',
  // 'Streamed from any place': 'Streamat från valfri plats',
  // 'Selected Venue(s)': 'Utvalda Platser',
  // Summary: 'Sammanfattning',
  // 'Go Back': 'Gå tillbaka',
  // Add: 'Lägg till',
  // 'Your request is being processed. Please wait...':
  //   'Din förfrågan behandlas. Var snäll och vänta...',
  // 'Activity duration': 'Aktivitetens längd',
  // Participants: 'Deltagare',
  // Invite: 'Bjuda in',
  // Run: 'Kör',
  // 'Follow Up': 'Följ upp',
  // 'Our Checklist': 'Vår Checklista',
  // 'Theme Settings': 'Temainställningar',
  // Settings: 'Inställningar',
  // Communications: 'Kommunikation',
  // 'Days Left': 'Dagar Kvar',
  // 'Venue(s)': 'Platser',
  // 'Premium content, can be added after order.': 'Premiuminnehåll kan läggas till efter order.',
  // 'Our speakers and moderators have together held over 5,000 conferences. We have gathered their experience in Meeting Maker, as inspiration and help along the way. Choose from Out-of-the-box best practice agendas, optimized for what you are going to do ({{purpose}}), change freely or create your very own agenda.':
  //   'Våra föreläsare och moderatorer har gemensamt gjort över 5 000 konferenser. Vi har samlat deras erfarenhet i Meeting Maker, som inspiration och hjälp på vägen. Välj mellan färdiga upplägg som är optimerade för det du ska göra ({{purpose}}), ändra fritt eller skapa din helt egna agenda.',
  // 'Share and win up to 15000 SEK to an inspirational speech.':
  //   'Dela och vinn upp till 15 000 kr till en inspirationsföreläsning',
  // 'We want to help more people creating magic. Meeting Maker can be used by anyone who wants to arrange a meeting or conference.':
  //   'Vi vill hjälpa fler med mer. Meeting Maker kan användas av alla som vill arrangera ett möte eller en konferens.',
  // 'Share your Meeting Maker discovery with up to five friends or colleagues (preferably in other organizations so they can also get the chance to save time and money in its next conference). Each person gives you an opportunity to win and every week we draw a lucky winner of 5,000, 10,000 or 15,000 SEK which can be used to maximize the next meeting or conference.':
  //   'Dela din upptäckt av Meeting Maker med upp till fem vänner eller kollegor (gärna i andra organisationer så kan de också få chansen att spara tid och pengar i sin nästa konferens). Varje person ger dig en möjlighet att vinna och varje vecka drar vi en lycklig vinnare av 5 000, 10 000 eller 15 000 kr som kan användas för att maxa nästa möte eller konferens.',
  // 'Look at the email to your friend here': 'Se hur mailet till din vän ser ut här',
  // BONUS: 'BONUS',
  // "This month you could win 1 hour of coaching with one of  Sweden's leading experts in digital meetings; Beata Wickbom.":
  //   'Denna månad lottar vi dessutom ut 1 timmes coaching med en ev Sveriges ledande experter på digitala möten; Beata Wickbom.',
  // Plan: 'PLANERA',
  // 'Enter your details and create an account': 'Lägg in dina uppgifter och skapa ett konto',
  // 'Request moderator(s)': 'Begär moderator',
  // 'Request speaker(s)': 'Begär talare',
  // 'Selected Moderator(s)': 'Utvalda moderator',
  // 'Selected Speaker(s)': 'Utvalda talare',
  // 'Ideas & Inspiration': 'Idéer & inspiration',
  // 'My Meetings': 'Mina möten',
  // 'The {{speakerType}} looks booked, but there might be an opening. If you choose "{{speakerName}}" you will get a confirmation in less than 48 hours.':
  //   '{{speakerType}} ser bokad ut men det kan finnas en öppning. Väljer du "{{speakerName}}" så kommer du få bekräftat inom 48 timmar.',
  // 'Sorry, but the {{speakerType}} is booked at this time.':
  //   'Ledsen, men {{speakerType}} är bokad denna tid.',
  // moderator: 'moderator',
  // speaker: 'talare',
  // generic_error_message: 'Något gick fel, försök igen',
  // Activity: 'Aktivitet',
  // Responsible: 'Ansvarig',
  // 'Due Date': 'Färdig Datum',
  // 'Add subtask': 'Lägg till underaktivitet',
  // Update: 'Uppdatera',
  // Delete: 'Radera',
  // 'Add Activity': 'Lägg till aktivitet',
  // 'Are you sure you want to delete this checklist activity':
  //   'Är du säker på att du vill radera aktiviteten',
  // 'Parent activity': 'Huvudaktivitet',
  // Priority: 'Prioritet',
  // Save: 'Spara',
  // NotStarted: 'Ej påbörjad',
  // Started: 'Påbörjad',
  // Finished: 'Avslutad',
  // Speaker: 'Talaren',
  // Moderator: 'Moderator',
  // '{{speakerType}} not yet confirmed': '{{speakerType}} ej bekräftad',
  // Suggested: 'Föreslagen',
  // 'Help me in Planning': 'Jag vill gärna ha hjälp',
  // 'Thanks for your reply, we´ll keep our fingers cross and work for the event to happen.':
  //   'Tack för ditt svar, vi jobbar för att aktiviteten skall bli av.',
  // 'Thanks for your reply, we´ll contact the client and will work to find an alternative.':
  //   'Tack för ditt svar, vi meddelar kunden och jobbar för att finna ett alternativ.',
  // 'Materials for participants': 'Material till deltagare',
  // 'Drag and drop your files here': 'Dra och släpp dina filer hit',
  // 'Add Directory': 'Lägg till',
  // "You can not yet change this here, but we will be happy to help you. Please add your request below and we'll contact you the soonest.":
  //   'Du kan inte ändra detta här och nu själv, men vi hjälper dig gärna. Beskriv vad du vill ändra nedan, så kontaktar vi dig omgående.',
  // 'To add content for pre-read and watch, please create catalogue (or more) with the button "Add"':
  //   'För att lägga till innehåll som deltagarna kan läsa och se på, skapa först en katalog (eller fler) med knappen "Lägg till"',
  // 'Finalize and move to planning': 'Avsluta och gå till planering',
  // 'Go back': 'Gå tillbaka',
  // 'Activate Participants': 'Aktivera deltagare',
  // Close: 'Stäng',
  // 'Top label here': 'Övre värde här',
  // 'Bottom label here': 'Nedre värde här',
  // 'Left label here': 'Vänster värde här',
  // 'Right label here': 'Höger värde här',
  // 'Top label': 'Övre värde',
  // 'Bottom label': 'Nedre värde',
  // 'Left label': 'Vänster värde',
  // 'Right label': 'Höger värde',
  // Caption: 'Rubrik',
  // Question: 'Fråga',
  // 'Add option': 'Lägg till alternativ',
  // 'Min value': 'Minsta värde',
  // 'Max value': 'Högsta värde',
  // 'Word already exists': 'Ord finns redan',
  // Tutorials: 'Handledning',
  // 'Correct answer': 'Rätt svar',
  // 'Please select an answer': 'Var vänlig välj ett svar',
  // 'Add new word': 'Lägg till nytt ord',
  // 'Mark if correct answer': 'Markera rätt svar',
  // 'Answer option {{index}} here': 'Svarsalternativ {{index}} här',
  // Watch: 'Se video',
  // Result: 'Resultat',
  // 'Edit invitation text': 'Ändra text till inbjudan',
  // 'Stream will start playing automatically when it is live':
  //   'Streamingen kommer starta automatiskt när vi går LIVE',
  // 'Interactive meeting with audio/video option for all participants where you have the full power of breakout rooms and live dialogue.':
  //   'interaktivt möte med ljud och bild för alla deltagare, där ni kan nyttja Breakout-rum och föra en aktiv dialog.',
  // 'Streaming option where you will broadcast. The audience will view and can interact through a feed channel.':
  //   'Live-sändning / Streaming där deltagarna följer er som åhörare. Interaktion sker i en separat Feed.',
  // 'Right now our Live meetings are open for activities up to 75 people.':
  //   'Live-möten är just nu endast tillgängliga för aktiviteter upp till 75 personer',
  // 'Hi! We are not yet mobile responsive and recommend Desktop usage of Meeting Maker.':
  //   'Hej! Vi är inte fullt ut mobilanpassade ännu och rekommenderar att du kör Meeting Maker på Desktop/Dator.',
  // Back: 'Tillbaka',
  // 'No data available': 'Ingen tillgänglig data',
  // Contributors: 'Persongalleri',
  // 'Group Chats': 'Gruppchatt',
  // Role: 'Roll',
  // Confirmed: 'Bekräftad',
  // 'Send SMS': 'SMS',
  // Email: 'Epost',
  // 'This meeting has been ended.': 'Det här mötet har avslutats',
  // 'Add your text here to comment, raise a question or chat...':
  //   'Skriv här för att kommentera, fråga eller chatta...',
  // 'No materials available.': 'Det finns ingen material ännu',
  // 'Open Camera/Mic for Everyone': 'Öppna kamera och mic för alla',
  // 'Close Camera/Mic for Everyone': 'Stäng kamera/mic för alla',
  // 'Enter main stage': 'Gå på scen',
  // "Let's start the meeting": 'Låt oss starta mötet!',
  // 'Choose your starting mode': 'Välj hur du vill starta',
  // 'You as moderator and your camera visible.': 'Du som moderator och din kamera synlig.',
  // 'All team gathered with open audio/video.': 'Alla deltagare samlade med öppen audio/video.',
  // 'No camera, your audio only and a static image.':
  //   'Ingen kamera, endast ditt ljud och en statisk bild.',
  // 'Choose Transition': 'Välj Övergång',
  // 'How do you want to move between the activities?':
  //   'Hur vill du se övergången mellan aktiviteterna?',
  // 'Static picture and text for coming activity + Moderator live cam.':
  //   'Statisk bild och text för nästa aktivitet + Moderatorns kamera aktiv.',
  // 'Give me more alternative speakers': 'Jag vill gärna få förslag på alternativa talare',
  // Hotel: 'Konferenslokaler',
  // 'Do you want to order any Addons?': 'Tillägg: Välj till för att höja upplevelsen ytterligare',
  // 'Help with content ?': 'Hjälp med innehåll ?',
  // 'We will get back tou you the soonest (normally within a one working day)':
  //   'En av våra mötesexperter ringer upp dig',
  // 'Hi! Meeting Maker only supports Safari browsers on the iPhone right now.':
  //   'Hej! Meeting Maker stödjer ännu så länge bara Safari på iPhone-mobiler idag.',
  // End: 'Avsluta',
  // 'Take a break': 'Ta en paus',
  // 'Switch to Ant media': 'Byt till Ant Media',
  // 'Switch to vimeo': 'Byt till Vimeo',
  // 'Show pause image': 'Visa Pausbild',
  // 'Activate breakout room': 'Aktivera Breakout rum',
  // 'Keep main stage open': 'Håll scenen öppen',
  // 'TIME FOR BREAKOUT SESSION!': 'Tid för en breakout!',
  // 'How many participants do you want in each room?': 'Hur många deltagare vill du ha per rum?',
  // 'Close Introduction': 'Stäng intro',
  // 'We are serious when it comes to security and therefore your password needs to be strong and minimum 8 characters long, have a capital letter, a number and a special sign.':
  //   'Vi tar säkerheten på allvar. Därför behöver ditt lösenord vara minst 8 tecken långt och innehålla minst: en stor bokstav, en siffra och ett specialtecken',
  // 'Coming soon': 'Kommer snart',
  // Like: 'Gilla',
  // Reply: 'Svara',
  // 'Enter Breakout Room': 'Gå till Breakout-rum',
  // 'Exit Breakout Room': 'Gå ur Breakout-rum',
  // 'Add Admin Users': 'Bjud in en kollega',
  // 'Invite others to collaborate in the planning of your activity':
  //   'Bjud in andra till att samarbeta i planeringen av din aktivitet',
  // 'Exit main stage': 'Gå av scen',
  // 'Which breakout room do you want to join?': 'Vilket rum vill du gå in i?',
  // 'Close Breakout': 'Stäng Breakout',
  // 'Close Breakout Room': 'Stäng Breakout-rum',
  // Enter: 'Gå in',
  // 'Upload participants': 'Ladda upp deltagare',
  // 'Send invitation': 'Skicka inbjudan',
  // 'Send invitations': 'Skicka inbjudningar',
  // 'First name': 'Förnamn',
  // 'Last name': 'Efternamn',
  // Phone: 'Telefon',
  // State: 'Status',
  // "Don't send invitation": 'Skicka inte inbjudan ännu',
  // 'Send invitation with confirmation': 'Skicka inbjudan i 2 steg (med bekräftelse)',
  // 'Send invitation without confirmation': 'Skicka inbjudan i 1 steg (med länk direkt)',
  // 'Invite user': 'Bjud in en kollega som administratör',
  // Resend: 'Bjud in',
  // Send: 'Skicka',
  // Participant: 'Deltagare',
  // 'Upload your logo here in .png format': 'Ladda upp din logotyp här i .png format',
  // 'Get help from one of our experts': 'Få hjälp med innehåll från en av våra experter',
  // 'See how you do in short videos': 'Se hur du gör i några enkla videos',
  // 'Upload your own theme header image here - 1200 x 630px in .jpg or .png format':
  //   'Ladda upp din egen bakgrundsbild i .jpg eller .png format med format 1200 x 630px',
  // 'Open WhiteBoard': 'Öppna Whiteboard',
  // 'Close WhiteBoard': 'Stäng Whiteboard',
  // 'Enable whiteboard as well': 'Aktivera whiteboard',
  // 'Ask for feedback': 'Be om feedback',
  // 'Experience & Interaction': 'Upplevelse & Interaktion',
  // "Enhance participant's experience": 'Förhöj deltagarnas upplevelse',
  // 'Select experience': 'Välj upplevelse',
  // policy:
  //   'Angående personuppgifter: Läs om hur vi hanterar data. Genom att använda MeetingMaker samtycker du med våra villkor och hanteringen enligt GDPR, som du finner <1>här</1>',
  // 'We, and all who arrange a meeting, love to learn, and the best way to do that  is through feedback. Therefore we would appreciate if you could answer some quick questions.':
  //   'Vi och alla de som arrangerar möten älskar att lära nytt, och något av de bästa sätten att göra detta är genom feedback. Vi skulle därför uppskatta om du kunde svara på några snabba frågor.',
  // 'Answering the questions takes around 30 seconds.': 'Detta tar runt 30 sekunder.',
  // 'All answers are anonymous': 'Alla svar är anonyma.',
  // 'Give feedback': 'Ge feedback',
  // Choose: 'Välj',
  // 'Camera mode Moderator live cam only and nothing else.':
  //   'Moderatorns kamera påslagen, ingenting annat.',
  // 'Instant activation with no moderation.': 'Gå direkt till nästa aktivitet utan paus',
  // "Mark as my default, so I don't have to choose next time.":
  //   'Markera som standard, så jag inte behöver välja nästa gång.',
  // 'Choose later': 'Välj senare',
  // 'Resource name': 'Filnamn',
  // 'Click here to add material': 'Klicka här för att addera material',
  // 'Open Session': 'Enkel aktivitet',
  // 'Add any activity to the agenda': 'Lägg till en enkel agendapunkt',
  // Presentation: 'Presentation',
  // 'Add dedicated speaker(s) who should present': 'Lägg till dedikerad(e) talare till agendan',
  // Break: 'Paus',
  // 'Add break of any kind': 'Lägg till valfri typ av paus',
  // 'External Moderator': 'Extern Moderator',
  // 'Add and book an external moderator': 'Lägg till och boka extern proffsmoderator',
  // 'Add and book external speaker': 'Lägg till och boka extern föreläsare',
  // 'Add a pre-recorded video': 'Lägg till en förinspelad video',
  // 'Add a team activity': 'TEAMAKTIVITET',
  // 'Add activity with all on stage': 'Lägg till en aktivitet med alla deltagare',
  // 'Engage the attendants': 'Engagera deltagarna',
  // 'Make breakout sessions in virtual rooms': 'Dela upp deltagarna i virtuella rum',
  // Done: 'GÅ VIDARE',
  // "Don't show this anymore": 'Visa inte detta igen',
  // 'This option is not available when broadcasting':
  //   'Detta val är ej tillgängligt vid Live Broadcast',
  // 'This option is not available': 'Det här alternativet är inte tillgängligt',
  // Quick: 'Snabb',
  // 'Run a meeting': 'Kör ett möte',
  // 'Invite people and run a meeting, super-simple with a click, with or without an agenda.':
  //   'Bjud in andra till ett möte superenkelt med ett klick, med eller utan agenda.',
  // 'Plan a meeting': 'Planera ett möte',
  // 'Plan, book, invite and run a meeting or a conference, with the full power of Meeting Maker.':
  //   'Planera, boka, bjud in och kör ett möte eller en konferensen med full funktionalitet från Meeting Maker.',
  // 'My meetings': 'Mina möten',
  // 'Manage planning, run a scheduled meeting or follow up and learn from feedback received.':
  //   'Hantera dina planerade möten och följ upp den feedback ni fått.',
  // 'Enter email address here...': 'Skriv mailadressen här...',
  // 'You can enter email addresses one by one or paste them in here as a comma separated list.':
  //   'Du kan skriva in mailadresser en och en, eller klistra in flera ifrån en komma- eller semikolonseparerad lista.',
  // 'Please start adding emails...': 'Var vänlig och lägg in mejladresser...',
  // 'Select All Emails': 'Markera alla',
  // "Now, let's go!": 'Nu, låt oss köra!',
  // "Later, let's schedule first...": 'Senare, planera in det…',
  // "Let's start!": 'Starta mötet!',
  // 'Add agenda first!': 'Skapa agenda först!',
  // 'Save & Invite': 'Spara och bjud in',
  // 'Would you like to run your meeting now or later?': 'Vill du köra ditt möte nu eller senare?',
  // 'If you want to, name the meeting here...': 'Om du vill namnge mötet, gör det här...',
  // 'The invitations are soon on their way!': 'Inbjudningarna är snart på väg!',
  // 'Start your meeting now or use some seconds to add your agenda first.':
  //   'Starta ditt möte nu, eller lägg några sekunder och skapa en agenda först.',
  // 'When would you like to run your meeting?': 'När vill du köra ditt möte?',
  // 'Meeting duration': 'Längd på mötet',
  // 'Invitations will be sent to you and the others, with a calendar file.':
  //   'Inbjudan kommer att skickas med en kalenderfil till dig och övriga deltagare.',
  // Time: 'Tid',
  // 'Thanks! We sent you a confirmation, please check your email and if not there, please check your spam-folder. See you soon!':
  //   'Thanks! We sent you a confirmation, please check your email and if not there, please check your spam-folder. See you soon!',
  // 'Please enter a valid email': 'Vänligen ange en giltig mejladress',
  // 'Not sent yet': 'Inbjudan ej skickad',
  // 'Plan the details': 'Planera på detaljnivå',
  // 'We right now support Google Chrome and Microsoft Edge (all except version 18 and 44) since these browsers provide the best experience - please choose any of these':
  //   'Vi stödjer just nu Google Chrome och Microsoft Edge (alla versioner förutom 18 och 44) då de ger den bästa upplevelsen i våra videomöten.',
  // 'Breakout Rooms': 'Virtuella Grupprum',
  // 'You have added Breakout Session(s) to your agenda. Please go to "Breakout rooms" to create rooms and allocate participants (or moderator) after participants have been invited.':
  //   'Du har lagt till en eller flera Breakout-sessions till din agenda. Gå till "Virtuella grupprum", skapa rum och allokera deltagare (och ev. moderatorer) efter du lagt till användarna till mötet.',
  // 'All cameras open': 'Allas kameror är på',
  // 'This option has a maximum of 20 people per room':
  //   'Detta val ger en maxgräns på 20 deltagare per rum',
  // 'Speaker/moderator camera only': 'Talare/Moderator kameror är på',
  // 'This option gives you the opportunity to open the room for more participants. Limit 50 people in Premium+ and 100 people in Enterprise subscription':
  //   'Detta val ger dig möjligheten att bjuda in fler deltagare i ett rum. Max 50 deltagare per rum i Premium+ och 100 deltagare per rum i Enterprise-abonnemanget',
  // 'Delete agenda template?': 'Radera agendamall?',
  // 'Are you sure you want to delete this template?': 'Är du säker på att du vill radera denna mall?',
  // 'Saved Agenda': 'Sparade agendor',
  // 'Save agenda as template': 'Spara agenda som mall',
  // 'Who can see this?': 'Vem kan se denna?',
  // 'Only me': 'Endast jag själv',
  // 'Share across my organization': 'Dela inom organisationen',
  // 'Save as template': 'Spara som mall',
  // 'Currently we only support saving agenda templates for 1-day conferences':
  //   'Just nu stöder vi bara aktiviteter över 1 dag.',
  // 'Copy Agenda': 'Kopiera agenda',
  // 'Copy Materials': 'Kopiera material',
  // 'Copy Participants': 'Kopiera deltagare',
  // Copy: 'Kopiera',
  // 'Join meeting': 'Gå till möte',
  // joinMeetingDescription:
  //   'Välkommen till detta möte i Meeting Maker. Genom att gå in och delta samtycker du med våra villkor och hanteringen enligt GDPR, som du finner <1>här</1>.',
  // 'Audio/Video Options': 'Ljud/Bild alternativ',
  // 'Save agenda as template for later re-use': 'Spara agendan som en mall du kan återanvända',
  // 'Invitation text is used in invitation email send to participants. Default text is "Welcome to the activity <ConferenceName>"':
  //   'Inbjudningstext används i inbjudningsmejl som skickas till deltagare. Standard text är "Välkommen till aktiviteten <mötesnamn>"',
  // 'Choose layout': 'Välj uppställning',
  // 'Broadcast type': 'Mötestyp',
  // 'Live meeting': 'Live möte',
  // 'Advanced broadcast': 'Studiosändning',
  // 'Privacy Level': 'Säkerhetsnivå',
  // Open: 'Öppen',
  // 'Password required': 'Lösenord krävs',
  // 'Show time box': 'Visa tidsintervall',
  // 'Feedback Result': 'Feedbackresultat',
  // 'Start recording': 'Starta Inspelning',
  // 'Conference is ready. Please wait for it to start!':
  //   'Mötet är redo. Var vänlig vänta tills det startar',
  // 'Starting in': 'Startar om',
  // days: 'dagar',
  // hours: 'timmar',
  // seconds: 'sekunder',
  // day: 'dagar',
  // hour: 'timmar',
  // second: 'sekunder',
  // 'Breakout session about to start': 'Breakout-rum öppnas inom kort',
  // 'Start Recording': 'Starta inspelning',
  // 'Stop recording': 'Stoppa inspelning',
};
