// /* eslint react/jsx-props-no-spreading: 0 */

import { IconButton, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Suspense, useMemo, useRef, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PeopleIcon from '@mui/icons-material/People';
import LightTooltip from '../../components/LightTooltip';
import Loading from '../../components/Loading';
import UserAvatar from '../../components/UserAvatar';
import i18n from '../../i18n';
import RoleUserSelect from './RoleUserSelect';
import { OrganizationUserRole, OrganizationUserState } from '../../app/appConstants';
import { Tag } from '../../components/Tags';
import { formatTimeLastActive, getTimeActive } from './function';
import NoDaTaBox from '../../components/NoDataBox';
import currentOrganization from '../../commons/CurrentOrganization';
import { isOrgRole } from '../../commons/utils';
import './UserManagementTable.scss';
import EnhancedTable from '../../components/EnhancedTable/EnhancedTable';
import { getComparator, stableSort } from '../../components/EnhancedTable/functions';

export const IconItem = (props) => {
  const { onClick, icon, title } = props;
  const [processing, setProcessing] = useState(false);
  async function handleOnClick() {
    setProcessing(true);
    const rs = await onClick();
    if (!rs) {
      setProcessing(false);
    }
  }

  return (
    <LightTooltip title={title} placement="bottom-start">
      <span>
        <IconButton disabled={processing} onClick={handleOnClick}>
          <span className={`icon ${processing ? 'icon-spinner' : icon}`}></span>
        </IconButton>
      </span>
    </LightTooltip>
  );
};

IconItem.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  title: PropTypes.string,
};

const UserManagementTable = (props) => {
  const {
    data,
    handleFormEditUser,
    handleResentEmail,
    handleDeleteUser,
    handleChangeRole,
    isActivatedTab,
  } = props;
  const [selectedUser, setSelectedUser] = useState({});
  const addUserFormRef = useRef();
  const [sortData, setSortData] = useState({ order: 'asc', orderBy: 'role' });

  const visibleRows = useMemo(
    () => stableSort(data, getComparator(sortData.order, sortData.orderBy), 'isCurrentUser'),
    [data, sortData.order, sortData.orderBy]
  );

  console.log('selectedUser: ', selectedUser);

  // Temporary remove hubspot tracking
  // React.useEffect(() => {
  //   async function loadScript() {
  //     await appendScript(hubspotTrackingScript);
  //   }

  //   loadScript();
  // }, []);
  const headCells = useMemo(() => {
    const cells = [
      { id: 'fullName', disablePadding: false, label: 'Name', hidden: false },
      { id: 'role', disablePadding: false, label: 'Role', hidden: false },
      { id: 'lastActive', disablePadding: false, label: 'Last active', hidden: !isActivatedTab },
      { id: 'state', disablePadding: false, label: 'Status', hidden: isActivatedTab },
      {
        id: null,
        align: 'center',
        disablePadding: false,
        label: 'Action',
        disableSorting: true,
      },
    ];
    return cells.filter((cell) => !cell.hidden);
  }, [isActivatedTab]);

  function onClickResend(rowData) {
    const organization = currentOrganization.getData();
    handleResentEmail(rowData);
    setSelectedUser({
      ...rowData,
      organizationName: organization?.name,
      subscriptionType: organization?.subscriptionType,
    });
    setTimeout(() => {
      console.log('addUserFormRef.current', addUserFormRef.current);
      if (addUserFormRef.current) {
        addUserFormRef.current.submitForm();
      }
    });
  }

  function handleOnChangeSort(order, orderBy) {
    setSortData({ order, orderBy });
  }

  function renderRow(rowData) {
    return (
      <TableRow key={rowData.id} className="user-management-table-row">
        <TableCell>
          <div className="item-name-wrapper">
            <div className="item-name-avatar">
              <UserAvatar fullName={rowData?.fullName} email={rowData?.contactEmail} />
            </div>
            <div className="item-name-details">
              <div className="item-details-name">{rowData.fullName}</div>
              <div className="item-details-email">{rowData.contactEmail}</div>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <RoleUserSelect
            disabled={
              rowData.isCurrentUser || isOrgRole(rowData.role, OrganizationUserRole.Partner)
            }
            isEdit
            defaultValue={rowData.role}
            onChange={({ target: input }) => handleChangeRole(input.value, rowData)}
          />
        </TableCell>
        {isActivatedTab ? (
          <TableCell>
            <div>{getTimeActive(rowData.lastActive, rowData.isCurrentUser)}</div>
          </TableCell>
        ) : (
          <TableCell>
            <div>
              {rowData.state === OrganizationUserState.Created ? (
                <Tag className="tag-item" color="#000" backgroundColor="#DBE1E3">
                  Not invited
                </Tag>
              ) : (
                <Tag className="tag-item" color="#000" backgroundColor="#FAADAD">
                  Pending Login
                </Tag>
              )}
              {rowData.lastInvited && (
                <div className="text-desc">
                  {i18n.t('Last invited')} {formatTimeLastActive(rowData.lastInvited)}
                </div>
              )}
            </div>
          </TableCell>
        )}

        <TableCell align="center">
          <div className="user-action">
            <LightTooltip title={i18n.t('Edit')} placement="bottom-start">
              <span>
                <IconButton onClick={() => handleFormEditUser(rowData)}>
                  <EditIcon />
                </IconButton>
              </span>
            </LightTooltip>
            {!isActivatedTab && (
              <IconItem
                onClick={() => onClickResend(rowData)}
                icon="icon-send-email"
                title={
                  rowData.state === OrganizationUserState.Created
                    ? 'Send invitation'
                    : 'Resend invitation'
                }
              />
            )}

            {!rowData.isCurrentUser && (
              <LightTooltip title={i18n.t('Delete user')} placement="bottom-start">
                <span>
                  <IconButton>
                    <DeleteIcon onClick={() => handleDeleteUser(rowData)} />
                  </IconButton>
                </span>
              </LightTooltip>
            )}
          </div>
        </TableCell>
      </TableRow>
    );
  }

  if (data.length === 0) {
    return <NoDaTaBox icon={<PeopleIcon />} title="No data" description="No users to display" />;
  }

  return (
    <Suspense fallback={<Loading style={{ textAlign: 'center', marginTop: 15 }} />}>
      <EnhancedTable
        headCells={headCells}
        defaultSort={sortData}
        className="user-management-table"
        onChangeSort={handleOnChangeSort}
      >
        {visibleRows.map((rowData) => renderRow(rowData))}
      </EnhancedTable>
    </Suspense>
  );
};

UserManagementTable.propTypes = {
  data: PropTypes.instanceOf(Array),
  handleChangeRole: PropTypes.func,
  handleFormEditUser: PropTypes.func,
  handleResentEmail: PropTypes.func,
  handleDeleteUser: PropTypes.func,
  isActivatedTab: PropTypes.bool,
};

export default UserManagementTable;
