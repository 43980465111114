/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import Popover from '@mui/material/Popover';
import { IconButton, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import i18n from '../../i18n';
import useMobileQuery from '../../commons/useMobileQuery';
import NotificationUpdatesTab from './NotificationUpdatesTab';
import Badge from '../Badge.js';
import queryCache, { CacheKeys } from '../../app/queryCache';
import {
  updateNotificationsSeenAll,
  getNotificationsBadges,
  updateMessagesSeenAll,
  updateNotificationsBadges,
  updateNotificationsSeen,
} from '../../users/notifications/service';
import { contextNotificationsType, messageTemplates } from './constants';

import './NotificationButton.scss';
import ResourcePreviewDialog, {
  getResourcePreviewContext,
} from '../../resources/ResourcePreviewDialog';
import { SERVICE_PROVIDER } from '../../integrations/integrationConstants';
import DriveDeletedNotificationDialog from '../../integrations/components/ExternalResourceDialog/DriveDeletedNotificationDialog';
import { Ids } from '../../commons/pendoTaggings';
import SpacesAffectedDialog from '../../cms/designer/SpacesAffectedDialog';
import { useAppContext } from '../AppProvider';
import { GLOBAL_PROVIDER_TYPE } from '../../globalResources/utils';
import { SidebarType } from '../../app/appConstants';

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};
const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

function NotificationButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationsBadges, setNotificationsBadges] = useState();
  const { isAuthenticated, userInfo } = useAppContext();
  const open = Boolean(anchorEl);

  const { isMobile } = useMobileQuery();
  const navigate = useNavigate();

  const fetchNotificationsBadges = useQuery({
    queryKey: [CacheKeys.fetchNotificationsBadges],
    queryFn: async () => {
      const resp = await getNotificationsBadges();
      return resp;
    },
    retry: 1,
    retryDelay: () => 5000,
    enabled: isAuthenticated === true,
  });
  useEffect(() => {
    if (fetchNotificationsBadges?.data) {
      setNotificationsBadges(fetchNotificationsBadges?.data);
    }
  }, [fetchNotificationsBadges?.data]);

  useEffect(() => {
    let title = document.title;
    const regex = /\(\d*?\)\s/;
    const isNewNotification = regex.test(title);
    const isNewTotalBadges =
      notificationsBadges?.totalBadges && notificationsBadges?.totalBadges > 0;

    if (isNewNotification) {
      title = title.replace(regex, '');
    }
    document.title = isNewTotalBadges ? `(${notificationsBadges?.totalBadges}) ${title}` : title;
  }, [notificationsBadges?.totalBadges]);

  function handleClose() {
    setAnchorEl(null);
  }

  function gotoNotificationSetting() {
    navigate('/profile/settings/notifications');
    handleClose();
  }

  async function handleRequestAccessToSpaceMessageOnClick(item) {
    const requestId = item.messageTemplateData.requestId;
    const spaceId = item.messageTemplateData?.spaceId;
    const url = `/s/${spaceId}?req-id=${requestId}`;
    if (requestId) {
      navigate(url);
    }
  }

  async function handleOnClickNotification(item) {
    if (item.seenAt === null) {
      await updateNotificationsSeen([item.id]);
    }
    const data = item.messageTemplateData || {};
    const spaceUrl = `/s/${data.spaceUrlSlug ? data.spaceUrlSlug : data.spaceId}`;

    switch (item.messageTemplateId) {
      case messageTemplates.AddNewResourceVersion:
      case messageTemplates.AddNewOneDriveResourceVersion: {
        const material = { resourceId: data.resourceId };
        ResourcePreviewDialog.show(material, getResourcePreviewContext('portal'));
        break;
      }
      case messageTemplates.DeleteOneDriveResource: {
        DriveDeletedNotificationDialog.show('OneDrive');
        break;
      }
      case messageTemplates.AddNewGoogleDriveResourceVersion: {
        const material = { resourceId: data.resourceId };
        ResourcePreviewDialog.show(material, getResourcePreviewContext('portal'));
        break;
      }
      case messageTemplates.DeleteGoogleDriveResource: {
        DriveDeletedNotificationDialog.show('GoogleDrive');
        break;
      }
      case messageTemplates.ExternalInitProcessCompleted: {
        if (isMobile) {
          return;
        }
        if (data.provider) {
          let globalResourcesTab = null;
          switch (data.provider) {
            case SERVICE_PROVIDER.ONEDRIVE:
              globalResourcesTab = GLOBAL_PROVIDER_TYPE.ONE_DRIVE;
              break;
            case SERVICE_PROVIDER.GOOGLE_DRIVE:
              globalResourcesTab = GLOBAL_PROVIDER_TYPE.GOOGLE_DRIVE;
              break;
            default:
              break;
          }
          if (globalResourcesTab) {
            window.localStorage.setItem(
              `settings-gr-selectedTab-${userInfo.id}`,
              globalResourcesTab
            );
          }
          navigate('/profile/settings/global-resources');
        } else {
          // navigate('/profile/settings/integrations');
        }

        break;
      }
      case messageTemplates.RequestAccessToSpace: {
        handleRequestAccessToSpaceMessageOnClick(item);
        break;
      }
      case messageTemplates.SpaceAccessGranted: {
        navigate(spaceUrl);
        break;
      }
      case messageTemplates.VideoMessageViewed: {
        const material = { materialId: data.messageInfo[0]?.materialId };
        const channelId = data.messageInfo[0]?.channelId;

        if (channelId === 0) {
          navigate(`${spaceUrl}?srId=${material.materialId}`, {
            state: {
              openSidebar: SidebarType.Material,
            },
          });
          break;
        }
        navigate(`${spaceUrl}?srId=${material.materialId}&channelId=${channelId}`, {
          state: {
            openSidebar: SidebarType.Chat,
          },
        });
        break;
      }
      case messageTemplates.SentVideoMessage: {
        const materialId = data.messageInfo[0]?.materialId;
        const channelId = data.messageInfo[0]?.channelId;
        navigate(`${spaceUrl}?srId=${materialId}&channelId=${channelId}`, {
          state: {
            openSidebar: SidebarType.Chat,
          },
        });
        break;
      }
      case messageTemplates.SentChatMessage: {
        const channelId = data.messageInfo[0]?.channelId;
        // console.log('channel', channelId);
        navigate(`${spaceUrl}?channelId=${channelId}`, {
          state: {
            openSidebar: SidebarType.Chat,
          },
        });
        break;
      }
      case messageTemplates.GlobalTemplateUpdated: {
        // console.log('GlobalTemplateUpdated', data);
        await SpacesAffectedDialog.show(data);
        break;
      }
      case messageTemplates.AddedResource: {
        let url = `${spaceUrl}`;
        if (data.folderId) {
          url += `?drId=${data.folderId}`;
        }
        navigate(url, { state: { openSidebar: SidebarType.Material } });
        break;
      }
      default: {
        const url = `${spaceUrl}`;

        navigate(url, {
          state: {
            openSidebar: contextNotificationsType[item.context],
          },
        });
      }
    }
  }

  const handleClickReadAll = React.useCallback(async (keyCache, badgesType) => {
    let resp = false;

    if (badgesType === 'newUpdates') {
      resp = await updateNotificationsSeenAll();
    } else {
      resp = await updateMessagesSeenAll();
    }
    if (resp) {
      queryCache.setQueryData([keyCache], (oldData) => {
        if (!oldData) {
          return null;
        }
        const newData = cloneDeep(oldData);
        if (newData?.pages.length) {
          newData.pages.forEach((page, index) => {
            newData.pages[index].items = page?.items?.map((item) => ({
              ...item,
              seenAt: resp.seenAt,
            }));
          });
        }

        return newData;
      });
    }
  }, []);

  const handleSeenBadge = React.useCallback(async () => {
    if (notificationsBadges?.totalBadges) {
      const resp = await updateNotificationsBadges({
        totalBadgesSeen: notificationsBadges.totalBadges,
      });
      if (resp) {
        queryCache.setQueryData([CacheKeys.fetchNotificationsBadges], (oldData) => {
          const payload = {
            ...oldData,
            totalBadges: 0,
          };
          setNotificationsBadges(payload);
          return payload;
        });
      }
    }
  }, [notificationsBadges]);

  function handleOpen(e) {
    handleSeenBadge();
    setAnchorEl(e.currentTarget);
  }

  function renderContent() {
    return (
      <NotificationUpdatesTab
        onClickNotification={handleOnClickNotification}
        onClickReadAll={handleClickReadAll}
        currentUserInfo={userInfo}
      />
    );
  }

  function renderButton() {
    if (isMobile) {
      return (
        <IconButton className="button-close" onClick={handleClose}>
          <span className="icon-close"></span>
        </IconButton>
      );
    }
    return (
      <IconButton
        className="button-setting"
        onClick={gotoNotificationSetting}
        data-id={Ids.SettingIconButton}
      >
        <span className="icon-settings"></span>
      </IconButton>
    );
  }

  function renderPopover() {
    if (!open) return null;

    return (
      <Popover
        open={open}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        className="notify-popup"
        anchorEl={anchorEl}
        onClose={handleClose}
        marginThreshold={0}
      >
        <Box className="notify-popup-header">
          <span className="notify-popup-header-title">{i18n.t('Notifications')}</span>
          <Box display="flex" gap={8}>
            {renderButton()}
          </Box>
        </Box>
        <div className="tab-content">{renderContent()}</div>
      </Popover>
    );
  }
  if (!isAuthenticated || !userInfo) {
    return null;
  }
  return (
    <>
      <IconButton
        className={`notify-button button-icon ${open ? 'active' : ''}`}
        onClick={handleOpen}
        data-id={Ids.NotificationButton}
      >
        <Badge badgeContent={notificationsBadges?.totalBadges} max={99}>
          <span className="icon-notify" />
        </Badge>
      </IconButton>
      {renderPopover()}
    </>
  );
}

export default NotificationButton;
