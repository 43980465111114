import Api from '../../commons/api';
import { getCoreApi } from '../../commons/utils';

export function updateUserProfile(data) {
  return Api.put(`${getCoreApi()}/users/profile`, data);
}

export function changePassword(oldPassword, newPassword) {
  return Api.put(`${getCoreApi()}/users/password`, {
    oldPassword,
    newPassword,
  });
}
