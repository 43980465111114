import React, { lazy } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AppRoute from '../app/AppRoute';
// import ApiAuthorizationRoutes from '../components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';
import TestScreen from './TestScreen';
import SpacesOverview from '../homepage/SpacesOverview';
import Logout from '../components/api-authorization/Logout';
import LoginSilentCallback from '../components/api-authorization/LoginSilentCallback';
import LogoutCallback from '../components/api-authorization/LogoutCallback';
import LoggedOut from '../components/api-authorization/LoggedOut';
import UserSettings from '../users/settings/UserContainerWrapper';
import ForgotPassword from '../users/ForgotPassword';
import ResetPassword from '../users/ResetPassword';
import Redirectors from '../homepage/Redirectors';
import SpaceAccessContainer from '../spaces/SpaceAccessContainer';

import SpaceAnalytics from '../SpaceAnalytics';
import SpaceDesignerContainer from '../cms/designer/SpaceDesignerContainer';
import OAuthCallbackScreen from './OAuthCallbackScreen';
import ErrorPage from '../components/ErrorPage';

const DesignAssessment = lazy(() => import('../assessments/settings/DesignAssessment'));
const InsightsAndAnalytics = lazy(() => import('../CompanyAnalytics'));
const ConfirmInvitationOrganization = lazy(() => import('../users/ConfirmInvitation'));
const SignUp = lazy(() => import('../users/SignUp'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppRoute path="/" isPortal component={SpacesOverview} />,
  },
  {
    path: ApplicationPaths.LoginSilentCallback,
    element: <LoginSilentCallback />,
  },
  { path: ApplicationPaths.LogOut, element: <Logout /> },
  { path: ApplicationPaths.LogOutCallback, element: <LogoutCallback /> },
  { path: ApplicationPaths.LoggedOut, element: <LoggedOut /> },
  {
    path: '/profile/settings/:slug?',
    element: <AppRoute path="/" isPortal component={UserSettings} />,
  },
  {
    path: '/forgotpassword',
    element: <AppRoute isPublic path="/" noSignalR hideHeader component={ForgotPassword} />,
  },
  {
    path: '/resetpassword',
    element: <AppRoute isPublic path="/" noSignalR hideHeader component={ResetPassword} />,
  },
  {
    path: '/test',
    element: <AppRoute isPublic noSignalR hideHeader hideFooter path="/" component={TestScreen} />,
  },
  {
    path: '/login-callback',
    element: <AppRoute isPortal hideHeader exact path="/" component={Redirectors} />,
  },
  {
    path: '/space/:id/design',
    element: <AppRoute isPortal exact component={SpaceDesignerContainer} hideHeader hideFooter />,
  },
  {
    path: '/s/:urlSlug',
    element: <AppRoute isPublic isSpace hideHeader hideFooter component={SpaceAccessContainer} />,
  },
  {
    path: '/company-analytics',
    element: <AppRoute isPortal exact hideFooter component={InsightsAndAnalytics} />,
  },
  {
    path: '/space-analytics/:spaceId',
    element: <AppRoute isPortal exact hideFooter hideHeader component={SpaceAnalytics} />,
  },

  {
    path: '/users/invite',
    element: (
      <AppRoute
        isPublic
        hideHeader
        hideFooter
        noSignalR
        exact
        component={ConfirmInvitationOrganization}
      />
    ),
  },
  {
    path: '/oauth/callback',
    element: <AppRoute isPublic exact component={OAuthCallbackScreen} hideFooter hideHeader />,
  },
  {
    path: '/signup',
    element: <AppRoute isPublic noSignalR hideHeader hideFooter exact component={SignUp} />,
  },
  {
    path: '/error',
    element: (
      <AppRoute isPublic noSignalR hideHeader hideFooter exact path="/" component={ErrorPage} />
    ),
  },
  {
    path: '/assessment/:id',
    element: <AppRoute isPortal exact component={DesignAssessment} hideHeader hideFooter />,
  },
  {
    path: '*',
    element: () => {
      window.location.href = '/404.html';
      return null;
    },
  },
]);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
