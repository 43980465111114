// /* eslint react/jsx-props-no-spreading: 0 */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import _, { cloneDeep } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { OrganizationUserRole, OrganizationUserState } from '../../app/appConstants';
import queryCache, { CacheKeys } from '../../app/queryCache';
import currentOrganization from '../../commons/CurrentOrganization';
import { isOrgRole, sendNotification } from '../../commons/utils';
import { AppContext } from '../../components/AppProvider';

import Loading from '../../components/Loading';
import I18n from '../../i18n';
import './UserManagement.scss';
import UserManagementAddUser from './UserManagementAddUser';
import {
  getOrganizationUsers,
  inviteOrganizationUser,
  sendOrganizationUserInvitation,
  deleteUserOrganization,
  updateOrganizationUserProfile,
} from '../UserServices';
import { getNumberOfUsersLimit } from '../../commons/Subscription/subscriptionServices';
import { getTagLabel } from '../../commons/Subscription/functions';
import {
  getRequireSubscription,
  showPopup,
  SubscriptionFeaturesTypes,
} from '../../commons/Subscription';

import HeaderUser from '../components/HeaderUser';
import UserManagementTable from './UserManagementTable';
import UserManagementMobile from './UserManagementMobile';
import DeleteUserDialog from './DeleteUserDialog';
import UserManagementHandler from './UserManagementHandler';
import OfficialButton from '../../components/OfficialButtons';
import Tabs, { useTabs } from '../../components/Tabs';
import { newDate } from '../../commons/DateTimeUtils';
import { useUserContainerContext } from '../settings/UserContainerProvider';

const UserManagement = () => {
  const { open, handleDrawerToggle, isMobile } = useUserContainerContext();
  const [data, setData] = useState([]);
  const [isAddUser, setIsAddUser] = useState(false);
  const [isEditUser, setIsEditUser] = useState(false);
  const [userSelect, setUserSelect] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [processing, setProcessing] = useState(false);
  const { userInfo } = useContext(AppContext);
  const [limitNumber, setLimitNumber] = React.useState(11);
  const [isTransferDataUserDeleted, setIsTransferDataUserDeleted] = useState(false);
  const [isDeletePartner, setIsDeletingPartner] = useState(false);

  const { tabs, changeTab, activeTab } = useTabs([I18n.t('Active'), I18n.t('Pending')]);

  const organization = currentOrganization.getData();
  const checkHostUser = (value) => {
    return value === userInfo.contactEmail;
  };

  const allocatedUsers = {
    current: data.length,
    total: limitNumber,
  };

  const fetchOrganizationUsers = useQuery({
    queryKey: [CacheKeys.getOrganizationUsers],
    queryFn: async () => {
      const resp = await getOrganizationUsers(organization?.id);
      return resp;
    },
    retry: 1,
    retryDelay: () => 5000,
    enabled: !!organization?.id,
  });

  const fetchNumberOfUserLimit = useQuery({
    queryKey: [CacheKeys.getNumberOfUsersLimit],
    queryFn: async () => {
      const resp = await getNumberOfUsersLimit(organization?.id);
      return resp;
    },
    retry: 1,
    retryDelay: () => 5000,
    enabled: !!organization?.id,
  });

  useEffect(() => {
    if (fetchOrganizationUsers.data) {
      const dataMapping = fetchOrganizationUsers.data.map((item) => ({
        ...item,
        isCurrentUser: item.userInfoId === userInfo.id,
      }));
      setData(dataMapping);
    }
    setIsLoading(fetchOrganizationUsers.isFetching);
  }, [fetchOrganizationUsers.data, userInfo, fetchOrganizationUsers.isFetching]);

  useEffect(() => {
    setLimitNumber(fetchNumberOfUserLimit?.data?.limit);
  }, [fetchNumberOfUserLimit]);

  const usersList = useMemo(() => {
    const filtered = data.filter((user) => {
      if (activeTab === 0) {
        return user.lastActive || user.state === OrganizationUserState.Active;
      } else {
        return !user.lastActive && user.state !== OrganizationUserState.Active;
      }
    });
    if (activeTab === 0) {
      return _.sortBy(filtered, (user) => !user.isCurrentUser);
    }
    return filtered.map((user) => ({ ...user, id: user.userInfoId }));
  }, [activeTab, data]);

  const handleCloseAddUser = () => {
    setIsAddUser(false);
  };
  const updatedUserInQuery = (id, user) => {
    queryCache.setQueryData([CacheKeys.getOrganizationUsers], (oldData) => {
      if (!oldData) {
        return null;
      }
      const newData = cloneDeep(oldData);
      if (newData) {
        const foundIndexUser = newData.findIndex((u) => u.userInfoId === id);
        if (foundIndexUser !== -1) {
          newData[foundIndexUser] = { ...newData[foundIndexUser], ...user };
        } else {
          newData.push(user);
        }
      }
      return newData;
    });
  };

  const handleAddUser = async (user) => {
    setIsSubmitting(true);
    try {
      const result = await inviteOrganizationUser(user);
      if (result) {
        const message = user.shouldSendInvitation
          ? I18n.t('Way to go! The user has been added and invited.')
          : I18n.t('Way to go! The user has been added.');
        sendNotification(message, { type: 'success' });

        const userData = {
          ...user,
          lastInvited: user.shouldSendInvitation ? newDate().format() : null,
          contactEmail: user.email,
          userInfoId: result.id,
          state: result.state,
          shouldSendInvitation: false,
        };
        updatedUserInQuery(userData.id, userData);
        setIsSubmitting(false);
        handleCloseAddUser();
        const openTab = result?.state === OrganizationUserState.Active ? 0 : 1;
        changeTab(openTab);
        queryCache.removeQueries({ queryKey: [CacheKeys.getOrganizationsUsers], exact: false });
      }
    } catch (error) {
      setIsSubmitting(false);
      const errorMessage =
        error?.type === 'TranslatableException'
          ? I18n.t(error.message)
          : I18n.t('Sorry, something went wrong. Please try again!');
      sendNotification(errorMessage, { type: 'error' });
    }
  };

  const handleEditUser = async (id, user) => {
    setIsSubmitting(true);
    try {
      const result = await updateOrganizationUserProfile(id, user);
      if (result) {
        sendNotification(I18n.t('Successfully!'), {
          type: 'success',
        });
        setIsSubmitting(false);

        updatedUserInQuery(id, user);

        handleCloseAddUser();
      }
    } catch (error) {
      setIsSubmitting(false);
      console.log('result: ', error.message);
      sendNotification(error.message, { type: 'error' });
    }
  };

  const handleFormEditUser = (user) => {
    setIsAddUser(true);
    setIsEditUser(true);
    setUserSelect(user);
  };

  // const handleMakeAdmin = () => {
  //   const userRole =
  //     isOrgRole(userSelect.role , OrganizationUserRole.Admin)
  //       ? OrganizationUserRole.User
  //       : OrganizationUserRole.Admin;
  //   const payload = {
  //     ...userSelect,
  //     role: userRole,
  //   };
  //   console.log('payload :>> ', payload);
  //   handleEditUser(userSelect.userInfoId, payload);
  // };
  // const handleTransferSpaces = () => {
  //   console.log('test');
  // };

  function handleDeleteFinished() {
    setProcessing(false);
    setIsTransferDataUserDeleted(false);
    fetchOrganizationUsers.refetch();
  }

  const handleDeleteUser = async (user) => {
    const isPartner = isOrgRole(user?.role, OrganizationUserRole.Partner);
    setIsDeletingPartner(isPartner);
    const { hasConfirmed, isTransferData, userTransfer } = await DeleteUserDialog.show(user);
    setIsTransferDataUserDeleted(isTransferData);
    if (!hasConfirmed) {
      return;
    }
    setProcessing(true);

    const payload = { userInfoId: user.userInfoId };

    if (isTransferData) {
      payload.transferToUserInfoId = userTransfer.userInfoId;
    }
    try {
      queryCache.removeQueries({ queryKey: [CacheKeys.getOrganizationsUsers], exact: false });

      await deleteUserOrganization(organization?.id, payload);
    } catch (error) {
      setProcessing(false);
      sendNotification(error.message, { type: 'error' });
    }
  };

  const handleResentEmail = async (user) => {
    try {
      const result = await sendOrganizationUserInvitation(user.userInfoId);
      if (result) {
        sendNotification(I18n.t('Invitation has been sent.'), {
          type: 'success',
        });
        const newValue = { ...user, lastInvited: newDate().format() };
        if (user.state === OrganizationUserState.Created) {
          newValue.state = OrganizationUserState.InActive;
        }
        updatedUserInQuery(user.userInfoId, newValue);
      }
    } catch (error) {
      console.log('result: ', error.message);
      sendNotification(error.message, { type: 'error' });
    }
  };

  function handleChangeRole(id, user) {
    // declare a new data array from state (data)
    const newData = [...data];
    // find user from data
    const foundUser = _.find(newData, (item) => {
      return item.userInfoId === user.userInfoId;
    });
    foundUser.role = id;
    // re-set data to state
    const payload = {
      ...user,
      role: id,
    };
    handleEditUser(user.userInfoId, payload);
    setData(newData);
  }

  const renderListUsers = () => {
    if (isLoading) {
      return (
        <div className="text-content">
          <Loading />
        </div>
      );
    }

    if (!data.length) {
      return <div className="text-content">{I18n.t('Not found results')}</div>;
    }
    if (isMobile) {
      return (
        <UserManagementMobile
          data={usersList}
          handleEditUser={handleEditUser}
          handleFormEditUser={handleFormEditUser}
          handleResentEmail={handleResentEmail}
          handleDeleteUser={handleDeleteUser}
          handleChangeRole={handleChangeRole}
          isActivatedTab={activeTab === 0}
        />
      );
    }
    return (
      <UserManagementTable
        data={usersList}
        handleEditUser={handleEditUser}
        handleFormEditUser={handleFormEditUser}
        handleResentEmail={handleResentEmail}
        handleDeleteUser={handleDeleteUser}
        handleChangeRole={handleChangeRole}
        isActivatedTab={activeTab === 0}
      />
    );
  };

  const showLimitPopup = () => {
    const currentSubscription = organization?.subscriptionTypeId;
    const subscriptionRequire = getRequireSubscription(currentSubscription);
    const validationMessage = `Oops, you've reached the ${getTagLabel(
      currentSubscription
    )} limit of ${allocatedUsers.total} users`;
    showPopup(
      SubscriptionFeaturesTypes.user,
      validationMessage,
      currentSubscription,
      subscriptionRequire
    );
  };

  const handleOpenAddUser = () => {
    if (allocatedUsers.current >= allocatedUsers.total) {
      showLimitPopup();
    } else {
      setIsEditUser(false);
      setIsAddUser(true);
      setUserSelect(null);
    }
  };

  return (
    <>
      {!isAddUser && (
        <div className={`user-management ${isMobile ? 'user-management-mobile' : ''}`}>
          {/* header */}
          <HeaderUser
            onClick={handleDrawerToggle}
            isOpen={open}
            title={I18n.t('User Management')}
            isButtonClose={!isMobile}
            subTitle={
              allocatedUsers.current !== allocatedUsers.total ? (
                `${allocatedUsers.current} / ${allocatedUsers.total} subscription seats allocated`
              ) : (
                <div>
                  {allocatedUsers.current} / {allocatedUsers.total} subscription seats allocated.{' '}
                  <span
                    style={{ color: 'blue', textDecoration: 'underline' }}
                    onClick={() => showLimitPopup()}
                  >
                    Contact us
                  </span>{' '}
                  to add more seats.
                </div>
              )
            }
          >
            <div className="add-user-btn">
              <OfficialButton
                label="add user"
                icon={<AddIcon />}
                onClick={handleOpenAddUser}
                variant="rectangle-green"
              />
            </div>
          </HeaderUser>
          <Tabs tabs={tabs} onChange={changeTab} variant="tabs-level-2" />
          <div className="user-management-list">{renderListUsers()}</div>

          {processing && (
            <UserManagementHandler
              isListenerEventDelete
              isTransferUser={isTransferDataUserDeleted}
              onFinished={handleDeleteFinished}
              isDeletePartner={isDeletePartner}
            />
          )}
        </div>
      )}

      {/* Add new user form */}
      {isAddUser && (
        <div className="user-management">
          {/* header */}

          <UserManagementAddUser
            isHost={checkHostUser(userSelect?.contactEmail)}
            isSubmitting={isSubmitting}
            isEdit={isEditUser}
            userInfo={userSelect}
            onClose={handleCloseAddUser}
            onAddUser={handleAddUser}
            onEditUser={handleEditUser}
          />
        </div>
      )}
    </>
  );
};

UserManagement.propTypes = {};

export default UserManagement;
