import _cloneDeep from 'lodash/cloneDeep';

import Api from '../commons/api';
import { getLMSApi, getLMSRuntimeApi } from '../commons/utils';
import RuntimeApi from '../commons/RuntimeApi';

export function fetchAssessments(keywords, offset = 0, limit = 10) {
  let url = `${getLMSApi()}/assessments?limit=${limit}&offset=${offset}`;
  if (keywords) {
    url = `${url}&keywords=${keywords}`;
  }

  return Api.get(url);
}

function getAssessment(data) {
  const newData = _cloneDeep(data);
  if (data.percentageNeededToPass) {
    newData.percentageNeededToPass = parseInt(data.percentageNeededToPass);
  } else {
    delete newData.percentageNeededToPass;
  }
  data.questions.forEach((question, index) => {
    const newQuestion = _cloneDeep(question);
    newQuestion.options?.forEach((option, indexOption) => {
      const newOption = _cloneDeep(option);
      if (newOption.isNewOption || newQuestion.isNewQuestion) {
        delete newOption.isNewOption;
        delete newOption.id;
        newQuestion.options[indexOption] = newOption;
      }
    });
    if (newQuestion.isNewQuestion) {
      delete newQuestion.isNewQuestion;
      delete newQuestion.id;
    }
    newData.questions[index] = newQuestion;
  });
  return newData;
}

export function addAssessment(data) {
  return Api.post(`${getLMSApi()}/assessments`, data);
}

export function updateAssessment(data) {
  const newData = getAssessment(data);

  return Api.post(`${getLMSApi()}/assessments`, newData);
}

export function fetchAssessment(id) {
  return Api.get(`${getLMSApi()}/assessments/${id}`);
}

export function deleteAssessment(id) {
  return Api.delete(`${getLMSApi()}/assessments/${id}`);
}

export function duplicateAssessment(id) {
  return Api.post(`${getLMSApi()}/assessments/${id}/clone`);
}

export function fetchAssessmentsByIds(ids, offset = 0, limit = 10) {
  if (!ids) {
    return null;
  }
  let params = `?limit=${limit}&offset=${offset}`;

  if (ids.length > 0) {
    params = `${params}&ids=${ids.join(',')}`;
  }
  return Api.get(`${getLMSApi()}/assessments${params}`);
}

export function fetchSpaceAssessments(ids, isPortal = true) {
  if (ids?.length === 0) {
    return null;
  }
  const params = `?assessmentIds=${ids.join(',')}`;
  if (isPortal) {
    return Api.get(`${getLMSApi()}/user-assessments${params}`);
  }
  return RuntimeApi.get(`${getLMSRuntimeApi()}/space/user-assessments${params}`);
}

export function fetchSpaceAssessment(id) {
  return RuntimeApi.get(`${getLMSRuntimeApi()}/space/assessments/${id}`);
}

export function submitSpaceAssessment(data) {
  return RuntimeApi.post(`${getLMSRuntimeApi()}/space/user-assessments`, data);
}

export function runAssessment(assessmentId) {
  return RuntimeApi.get(`${getLMSRuntimeApi()}/run/assessments/${assessmentId}`);
}

export function fetchUserSpaceAssessment(assessmentId) {
  return RuntimeApi.get(`${getLMSRuntimeApi()}/user-assessments/${assessmentId}`);
}

export function startSpaceAssessment(assessmentId) {
  return RuntimeApi.post(`${getLMSRuntimeApi()}/space/user-assessments/start`, {
    assessmentId,
  });
}

export function fetchResultUserSpaceAssessment(userAssessmentId) {
  return RuntimeApi.get(`${getLMSRuntimeApi()}/space/user-assessments/${userAssessmentId}`);
}

export function fetchSpaceUserCertificates() {
  return RuntimeApi.get(`${getLMSRuntimeApi()}/space/assessments/user-certificates`);
}

export async function getCertificateFile(userCredentialId) {
  const response = await RuntimeApi.fetch(
    `${getLMSRuntimeApi()}/space/assessments/user-certificates/${userCredentialId}/document`
  );
  if (!response) {
    return null;
  }
  const blob = await response.blob();
  return {
    url: URL.createObjectURL(blob),
  };
}
