/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useRef } from 'react';
import HeaderUser from '../users/components/HeaderUser';
import i18n from '../i18n';
import { openWindow } from '../commons/utils';
import { GLOBAL_PROVIDER_TYPE, GLOBAL_RESOURCE_TYPE, getSource } from './utils';
import { isExternalLinkNotVideo } from '../commons/ResourceUtils';
import GlobalResourcePreviewPane from './GlobalResourcePreviewPane';
import ResourcePreviewDialog, {
  getResourcePreviewContext,
} from '../resources/ResourcePreviewDialog';
import './GlobalResourcesSetting.scss';
import GlobalResourcesTabs from './GlobalResourcesTabs';
import GlobalResourcesSpceTable from './GlobalResourcesSpceTable';
import { useLocalStorage, useUnmountEffect } from '../commons/CustomHooks';
import { useIntegrationContext } from '../integrations/IntegrationContextProvider';
import { SERVICE_PROVIDER } from '../integrations/integrationConstants';
import OneDriveResourcePreviewPane from '../integrations/onedrive/OneDriveResourcePreviewPane';
import GlobalResourcesOneDriveTable from '../integrations/onedrive/GlobalResourcesOneDriveTable';
import GoogleDriveTable from '../integrations/googleDrive/GoogleDriveTable/GoogleDriveTable';
import GoogleDrivePreview from '../integrations/googleDrive/common/GoogleDrivePreview';
import { useFeatureFlagsContext } from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import { FeatureFlagsType } from '../app/appConstants';
import ResourcesViewContextProvider from '../components/ResourcesView/ResourcesViewContextProvider';
import currentOrganization from '../commons/CurrentOrganization';
import GlobalResourcesBreadcrumbs from './GlobalResourcesBreadcrumbs';
import { useAppContext } from '../components/AppProvider';
import queryCache, { CacheKeys } from '../app/queryCache';
import GlobalResourcesContextProvider, {
  GlobalResourcesContextConsumer,
} from './context/GlobalResourcesContextProvider';
import { useUserContainerContext } from '../users/settings/UserContainerProvider';

const GlobalResourcesSetting = () => {
  const { open, handleDrawerToggle, isMobile } = useUserContainerContext();

  // const [breadcrumbData, setBreadcrumbData] = useState(null);
  const { userInfo } = useAppContext();
  const { hasConnection } = useIntegrationContext();
  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [idFlagOneDrive, idFlagGoogleDrive] = checkEnabledFeatures([
    FeatureFlagsType.INTEGRATIONS_ONEDRIVE,
    FeatureFlagsType.INTEGRATIONS_GOOGLEDRIVE,
  ]);
  const isOneDriveEnabled = hasConnection(SERVICE_PROVIDER.ONEDRIVE);
  const isGoogleDriveEnabled = hasConnection(SERVICE_PROVIDER.GOOGLE_DRIVE);
  const tabRef = useRef();

  const defaultValue =
    localStorage.getItem(`settings-gr-selectedTab-${userInfo.id}`) || GLOBAL_PROVIDER_TYPE.SPCE;

  const [selectedTab, setSelectedTab] = useLocalStorage(
    `settings-gr-selectedTab-${userInfo.id}`,
    defaultValue
  );

  const [isEnabledMaterialSlider] = checkEnabledFeatures([FeatureFlagsType.MATERIAL_SLIDER]);
  const viewingResourceRef = useRef(null);

  useUnmountEffect(() => {
    queryCache.removeQueries({ queryKey: [CacheKeys.getGlobalResourcesMngt], exact: false });
    // sessionStorage.removeItem('gr-currentFolderId');
  });

  const previewItemInPopup = useCallback(
    (resource, resourceHubConnection) => {
      if (isExternalLinkNotVideo(resource, true)) {
        openWindow(resource.url, '_blank');
        return;
      }
      // preview resource
      viewingResourceRef.current = resource;
      ResourcePreviewDialog.show(
        resource,
        { type: 'portal' },
        isEnabledMaterialSlider,
        resourceHubConnection
      ).then(() => {
        viewingResourceRef.current = null;
      });
    },
    [isEnabledMaterialSlider]
  );

  function handleClickOnBreadcrumbItem(breadcrumb, prevBreadcrumb) {
    if (tabRef.current.navigate) {
      tabRef.current.navigate(breadcrumb, prevBreadcrumb);
    }
  }

  function handleOnChangeTab(value) {
    setSelectedTab(value);
  }

  const renderContent = () => {
    const enableGlobalResources = currentOrganization?.isEnterprise();
    switch (selectedTab) {
      case GLOBAL_PROVIDER_TYPE.SPCE:
        return (
          <GlobalResourcesSpceTable
            ref={tabRef}
            viewingResourceRef={viewingResourceRef}
            isGlobalResourcesEnabled={enableGlobalResources}
            previewItemInPopup={previewItemInPopup}
            isPortal
          />
        );
      case GLOBAL_PROVIDER_TYPE.ONE_DRIVE:
        return (
          <GlobalResourcesOneDriveTable
            ref={tabRef}
            isGlobalResourcesEnabled={enableGlobalResources}
            isOneDriveEnabled={isOneDriveEnabled}
            previewItemInPopup={previewItemInPopup}
            isPortal
          />
        );
      case GLOBAL_PROVIDER_TYPE.GOOGLE_DRIVE:
        return (
          <GoogleDriveTable
            ref={tabRef}
            isGlobalResourcesEnabled={enableGlobalResources}
            isGoogleDriveEnabled={isGoogleDriveEnabled}
            previewItemInPopup={previewItemInPopup}
            isPortal
          />
        );
      default:
        break;
    }

    return null;
  };

  function renderPreviewPane(selectedResource) {
    if (
      (selectedTab === GLOBAL_PROVIDER_TYPE.ONE_DRIVE && !isOneDriveEnabled) ||
      (selectedTab === GLOBAL_PROVIDER_TYPE.GOOGLE_DRIVE && !isGoogleDriveEnabled)
    ) {
      return null;
    }

    let content = null;
    if (selectedResource) {
      if (selectedTab === GLOBAL_PROVIDER_TYPE.ONE_DRIVE) {
        return (
          <OneDriveResourcePreviewPane
            {...selectedResource}
            isAdmin
            context={getResourcePreviewContext('portal')}
          />
        );
      } else if (selectedTab === GLOBAL_PROVIDER_TYPE.GOOGLE_DRIVE) {
        console.log('selectedResource', selectedResource);
        content = (
          <GoogleDrivePreview
            key={selectedResource.id}
            {...selectedResource}
            isAdmin
            context={getResourcePreviewContext('portal')}
          />
        );
      } else {
        if (selectedResource.type === GLOBAL_RESOURCE_TYPE.FOLDER) {
          content = (
            <GlobalResourcePreviewPane
              context={getResourcePreviewContext('portal')}
              name={selectedResource.name}
              description={selectedResource.description}
              modified={selectedResource.lastModified}
              isFolder
              isAdmin
            />
          );
        }

        if (selectedResource.type === GLOBAL_RESOURCE_TYPE.RESOURCE) {
          content = (
            <GlobalResourcePreviewPane
              {...selectedResource}
              key={selectedResource.id}
              type={selectedResource.resourceType}
              modified={selectedResource.lastModified}
              context={getResourcePreviewContext('portal')}
              isAdmin
            />
          );
        }
      }
    } else {
      content = (
        <div className="no-selected-file">
          {i18n.t('Select a file or folder to view the details')}
        </div>
      );
    }

    return <div className="global-resources-body-right">{content}</div>;
  }

  function renderBreadcrumb() {
    return (
      <GlobalResourcesBreadcrumbs
        className="gr-title"
        selectedTab={selectedTab}
        onClickItem={handleClickOnBreadcrumbItem}
      />
    );
  }

  return (
    <GlobalResourcesContextProvider key={selectedTab} provider={selectedTab}>
      <div className="global-resources">
        <HeaderUser
          onClick={handleDrawerToggle}
          isOpen={open}
          isButtonClose={!isMobile}
          title={renderBreadcrumb()}
          // subTitle={GLOBAL_RESOURCES_INFO[selectedTab]?.readonly}
        />
        <GlobalResourcesTabs
          selectedTab={selectedTab}
          onChangeTab={handleOnChangeTab}
          showGoogleDrive={idFlagGoogleDrive}
          showOneDrive={idFlagOneDrive}
        />

        <div className="global-resources-body">
          <div className={`global-resources-body-left justifyContentCenter`}>
            <ResourcesViewContextProvider
              key={selectedTab}
              id="global-resources"
              userInfoId={currentOrganization.getUserInfoId()}
              source={getSource(selectedTab)}
            >
              {renderContent()}
            </ResourcesViewContextProvider>
          </div>
          <GlobalResourcesContextConsumer>
            {({ previewResource }) => renderPreviewPane(previewResource)}
          </GlobalResourcesContextConsumer>
        </div>
      </div>
      {/* {renderResourceViewer()} */}
    </GlobalResourcesContextProvider>
  );
};

export default GlobalResourcesSetting;
