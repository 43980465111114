import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import ColorPicker from '../../cms/commons/ColorPicker';
// import FontSelect from '../../cms/commons/FontSelect';
import TextPreview from '../../cms/commons/TextPreview';
import i18n from '../../i18n';
import { TEXT_TYPE_VALUES } from '../../cms/cmsConstants';
import './TextSetting.scss';
import ButtonEnhanced from '../../components/ButtonEnhanced';
import FontsSelect from '../../cms/commons/FontSelect/FontSelect';
import FontSizeSelect from './../../cms/commons/FontSizeSelect';

const TextSetting = (props) => {
  const {
    color,
    fontFamily,
    type,
    hasBackground,
    name,
    blockBackgroundColor,
    buttonBackgroundColor,
    secondaryButtonBackgroundColor,
    fontSize,
    description,
  } = props;

  const [fontFamilyValue, setFontFamilyValue] = useState('');
  const [variantStyles, setVariantStyles] = useState(null);

  useEffect(() => {
    // console.log('### fontFamily', fontFamily);
    if (fontFamily?.includes(':')) {
      const stylesValues = fontFamily.split(':');
      setFontFamilyValue(stylesValues[0]);
      if (stylesValues[1] === 'regular' || stylesValues[1] === 'italic') {
        setVariantStyles({
          fontStyle: stylesValues[1] === 'regular' ? 'normal' : 'italic',
        });
      } else {
        setVariantStyles({ fontWeight: stylesValues[1] });
      }
    } else {
      setFontFamilyValue(fontFamily);
      setVariantStyles(null);
    }
  }, [fontFamily]);

  const handleOnChange = (value, field) => {
    props.onChange(`${name}${field}`, value);
  };

  const handOnChangeFont = (value) => {
    handleOnChange(value, 'FontFamily');
  };

  const handleOnChangeButtonBgColor = (value) => {
    props.onChange('buttonBackgroundColor', value);
  };

  const handOnChangeFontSize = (value) => {
    handleOnChange(value, 'FontSize');
  };

  const handleOnChangeSecondaryButtonBgColor = (value) => {
    props.onChange('secondaryButtonBackgroundColor', value);
  };

  const isPrimaryBtn = type === 'BUTTON';
  const isSecondaryBtn = type === 'SECONDARY_BUTTON';
  const isButton = isPrimaryBtn || isSecondaryBtn;
  const btnBGColor = isSecondaryBtn ? secondaryButtonBackgroundColor : buttonBackgroundColor;

  return (
    <Grid container className="text-setting" spacing={3}>
      <Grid item xs={12}>
        {isButton ? (
          <ButtonEnhanced
            className="btn btn primary"
            style={{
              color: color,
              backgroundColor: btnBGColor,
              fontSize: fontSize,
              lineHeight: fontSize,
              fontFamily: fontFamilyValue,
              ...variantStyles,
            }}
          >
            Get Started
          </ButtonEnhanced>
        ) : (
          <TextPreview
            type={TEXT_TYPE_VALUES[type]?.size}
            style={{
              color: color,
              fontFamily: fontFamilyValue,
              backgroundColor: hasBackground ? 'rgba(0, 0, 0, 0.2)' : blockBackgroundColor,
              fontSize: fontSize,
              lineHeight: fontSize,
              ...variantStyles,
            }}
          >
            {i18n.t(TEXT_TYPE_VALUES[type]?.text)}
          </TextPreview>
        )}
      </Grid>
      <Grid item xs={12} className="text-setting-right">
        <div className="setting-items">
          <FontsSelect
            label="Font"
            name={`${name}FontFamily`}
            defaultValue={fontFamily}
            onChange={handOnChangeFont}
          />
          <FontSizeSelect
            label="Size"
            name={`${name}FontSize`}
            defaultValue={fontSize}
            onChange={handOnChangeFontSize}
          />
          <ColorPicker
            label={`${isButton ? 'Text' : 'Color'}`}
            defaultColor={color}
            onChange={handleOnChange}
          />
          {isPrimaryBtn && (
            <ColorPicker
              label="BG"
              defaultColor={buttonBackgroundColor}
              onChange={handleOnChangeButtonBgColor}
            />
          )}
          {isSecondaryBtn && (
            <ColorPicker
              label="BG"
              defaultColor={secondaryButtonBackgroundColor}
              onChange={handleOnChangeSecondaryButtonBgColor}
            />
          )}
        </div>
        <div className="description-text">{description}</div>
      </Grid>
      {/* <Grid item xs={12}>
        <div className="description-text">{description}</div>
      </Grid> */}
    </Grid>
  );
};

TextSetting.propTypes = {
  color: PropTypes.string,
  fontFamily: PropTypes.string,
  type: PropTypes.string,
  hasBackground: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  blockBackgroundColor: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  secondaryButtonBackgroundColor: PropTypes.string,
  fontSize: PropTypes.string,
  description: PropTypes.string,
};
export default TextSetting;
