class EventBus {
  constructor() {
    // initialize event list
    this.eventObject = {};
    // id of the callback function list
    this.callbackId = 0;
  }
  // publish event
  publish(eventName, ...args) {
    console.log('EventBus publish: ', eventName, args);
    // Get all the callback functions of the current event
    const callbackObject = this.eventObject[eventName];

    if (!callbackObject) {
      console.warn(`${eventName} not found!`);
      return;
    }

    // execute each callback function
    Object.keys(callbackObject).forEach((id) => {
      // pass parameters when executing
      callbackObject[id](...args);

      // The callback function that is only subscribed once needs to be deleted
      if (id[0] === 'd') {
        delete callbackObject[id];
      }
    });
  }
  // Subscribe to events
  subscribe(eventName, callback) {
    // initialize this event
    console.log('EventBus subscribe: ', eventName);
    if (!this.eventObject[eventName]) {
      // Use object storage to improve the efficiency of deletion when logging out the callback function
      this.eventObject[eventName] = {};
    }

    // eslint-disable-next-line no-plusplus
    const id = this.callbackId++;

    // store the callback function of the subscriber
    // callbackId needs to be incremented after use for the next callback function
    this.eventObject[eventName][id] = callback;

    // Every time you subscribe to an event, a unique unsubscribe function is generated
    const unSubscribe = () => {
      console.log('EventBus unsubscribe: ', eventName);
      // clear the callback function of this subscriber
      delete this.eventObject[eventName][id];

      // If this event has no subscribers, also clear the entire event object
      if (Object.keys(this.eventObject[eventName]).length === 0) {
        delete this.eventObject[eventName];
      }
    };

    return unSubscribe;
  }

  // only subscribe once
  subscribeOnce(eventName, callback) {
    console.log('EventBus subscribeOnce: ', eventName);
    // initialize this event
    if (!this.eventObject[eventName]) {
      // Use object storage to improve the efficiency of deletion when logging out the callback function
      this.eventObject[eventName] = {};
    }

    // Callback function marked as subscribe only once
    // eslint-disable-next-line prefer-template, no-plusplus
    const id = 'd' + this.callbackId++;

    // store the callback function of the subscriber
    // callbackId needs to be incremented after use for the next callback function
    this.eventObject[eventName][id] = callback;

    // Every time you subscribe to an event, a unique unsubscribe function is generated
    const unSubscribe = () => {
      // clear the callback function of this subscriber
      delete this.eventObject[eventName][id];

      // If this event has no subscribers, also clear the entire event object
      if (Object.keys(this.eventObject[eventName]).length === 0) {
        delete this.eventObject[eventName];
      }
    };

    return unSubscribe;
  }

  // clear event
  clear(eventName) {
    // If no event name is provided, all events are cleared by default
    if (!eventName) {
      this.eventObject = {};
      return;
    }

    // clear the specified event
    delete this.eventObject[eventName];
  }
}

const eventBus = new EventBus();

export const EVENT_BUS = {
  SpaceAnalyticsEvents: 'SpaceAnalyticsEvents',
  DownloadResourcesEvent: 'DownloadResourcesEvent',
  MaterialAdded: 'MaterialAdded',
  MaterialUpdated: 'MaterialUpdated',
  MaterialDeleted: 'MaterialDeleted',
  MaterialMoved: 'MaterialMoved',
  MaterialsUpdatedFromTemplates: 'MaterialsUpdatedFromTemplates',
  ConversionFinished: 'ConversionFinished',
  TranscodingFinished: 'TranscodingFinished',
  DirectoryAdded: 'DirectoryAdded',
  DirectoryUpdated: 'DirectoryUpdated',
  SharedFolderUpdated: 'SharedFolderUpdated',
  DirectoryDeleted: 'DirectoryDeleted',
  DirectoryMoved: 'DirectoryMoved',
  DirectoryUploaded: 'DirectoryUploaded',
  ConversionStarted: 'ConversionStarted',
  ConversionFailed: 'ConversionFailed',
  ResourceUpdated: 'ResourceUpdated',
  ResourceDeleted: 'ResourceDeleted',
  ResourceAddedToFolder: 'ResourceAddedToFolder',
  ResourceFolderUpdated: 'ResourceFolderUpdated',
  ResourceFolderAdded: 'ResourceFolderAdded',
  ResourceFolderDeleted: 'ResourceFolderDeleted',
  VideoIndexed: 'VideoIndexed',
  VideoErrorAccess: 'VideoErrorAccess',
  VideoStateChanged: 'VideoStateChanged',
  JoinedParticipantUpdated: 'JoinedParticipantUpdated',
  ReloadConference: 'ReloadConference',
  ConferenceRunTimeUpdated: 'ConferenceRunTimeUpdated',
  ToggleUploadResourcePopup: 'ToggleUploadResourcePopup',
  OpenSidePanel: 'OpenSidePanel',
  CloseSidePanel: 'CloseSidePanel',
  ResetSidePanel: 'ResetSidePanel',
  ChallengeUpdated: 'ChallengeUpdated',
  AddNotificationMessage: 'AddNotificationMessage',
  RemoveNotificationMessage: 'RemoveNotificationMessage',
  ReloadSpaceContainer: 'ReloadSpaceContainer',
  AutoFocusSearch: 'AutoFocusSearch',
  MaterialsCopied: 'MaterialsCopied',
  BlockScreenChanged: 'BlockScreenChanged',
  ClosePreviewResourceDialog: 'ClosePreviewResourceDialog',
  UserAssessmentCompleted: 'UserAssessmentCompleted',
  UserCredentialCompleted: 'UserCredentialCompleted',
};

export default eventBus;
