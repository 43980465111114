import { useQuery } from '@tanstack/react-query';
import { useAppContext } from '../components/AppProvider';
import currentOrganization from '../commons/CurrentOrganization';
import { CacheKeys } from '../app/queryCache';
import { getOrganizationsUsers } from './UserServices';

const useOrganizationsUsersQuery = (enabled = true, key = 'space-filter-in-space-overview') => {
  const { isPortal } = useAppContext();
  const organizationId = currentOrganization.getOrganizationId();
  const getOrgUsersQuery = useQuery({
    queryKey: [CacheKeys.getOrganizationsUsers, isPortal, key],
    queryFn: async () => {
      const resp = await getOrganizationsUsers(organizationId, isPortal);
      return resp;
    },
    retry: 1,
    retryDelay: () => 5000,
    enabled: enabled,
  });
  return {
    data: getOrgUsersQuery.data,
    query: getOrgUsersQuery,
  };
};

export default useOrganizationsUsersQuery;
