import Api from '../api';
import { getCoreApi } from '../utils';

export function getSubscriptionRule(action, context, subscriptionType) {
  let url = `/api/subscription/rule?action=${action}&context=${context}`;
  if (subscriptionType) {
    url += `&subscriptionTypeId=${subscriptionType}`;
  }
  return Api.get(url);
}

export function getNumberOfUsersLimit(organizationId) {
  const url = `/organizations/${organizationId}/users/limit`;
  return Api.get(`${getCoreApi()}${url}`);
}
