/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useGetDriveInfoQuery from '../../../integrations/onedrive/useGetDriveInfoQuery';
import ResourceItem from './ResourceItem';

const ExternalResourceItem = (props) => {
  const { resource, onDriveInfoUpdated, ...thisProps } = props;

  const folderSettings = resource?.externalFolderSettings;
  const driveId = folderSettings?.driveId;
  const folderId = folderSettings?.folderId;

  const parentFolderId = resource?.parentFolderId;
  const parentFolder = resource?.parentFolder;

  const getDriveInfoQuery = useGetDriveInfoQuery(
    driveId,
    folderId,
    folderSettings?.provider,
    !!folderId && (!parentFolderId || !parentFolder?.isExternal)
  );

  React.useEffect(() => {
    const data = getDriveInfoQuery.driveInfo;

    if (data && onDriveInfoUpdated) {
      onDriveInfoUpdated({
        externalFolderId: data.id,
        name: data.name,
        lastModified: data.lastModified,
        externalFolderSettings: {
          webUrl: data.webUrl,
        },
      });
    }
  }, [getDriveInfoQuery.driveInfo, onDriveInfoUpdated]);

  const externalResource = useMemo(() => {
    if (getDriveInfoQuery.driveInfo) {
      return {
        ...resource,
        resourceName: getDriveInfoQuery.driveInfo.name,
        lastModified: getDriveInfoQuery.driveInfo.lastModified,
        directSubFolderCount: getDriveInfoQuery.driveInfo.itemsCount,
      };
    }
    return resource;
  }, [resource, getDriveInfoQuery.driveInfo]);

  return <ResourceItem {...thisProps} resource={externalResource} />;
};

ExternalResourceItem.propTypes = {
  viewMode: PropTypes.string,
  resource: PropTypes.instanceOf(Object),
  dragObject: PropTypes.instanceOf(Object),
  newFiles: PropTypes.instanceOf(Array),
  selected: PropTypes.bool,
  draggable: PropTypes.bool,
  isDragging: PropTypes.bool,
  onClickItem: PropTypes.func,
  onClickCheckbox: PropTypes.func,
  onClickMoreButton: PropTypes.func,
  showActionMenu: PropTypes.bool,
  disabledMoreButton: PropTypes.bool,
  processingResources: PropTypes.instanceOf(Array),
  newResourceId: PropTypes.number,
  isWebinar: PropTypes.bool,
  isMobile: PropTypes.bool,
  subscriptionLock: PropTypes.bool,
  selectedFileId: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  disabled: PropTypes.bool,
  labelId: PropTypes.string,
  isPortal: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.string),
  onDriveInfoUpdated: PropTypes.func,
};

export default React.memo(ExternalResourceItem);
